<template>
  <v-expansion-panels v-model="expansion" focusable>
    <v-expansion-panel>
      <v-expansion-panel-header disable-icon-rotate @click="toggleView">
        Data
        <template v-slot:actions>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="setTooltip(on)"
                size="40"
                v-bind:color="setSelectedColor()"
                >{{ setSelectedIcon() }}</v-icon
              >
            </template>
            <span>{{ getTooltipText() }}</span>
          </v-tooltip>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-skeleton-loader
          v-if="isLoading"
          ref="skeleton"
          type="table"
          class="mx-auto"
        ></v-skeleton-loader>
        <v-data-table
          :headers="headers"
          :items="items"
          hide-default-footer
          disable-pagination
          v-else
        >
          <template v-slot:item="{ item }">
            <tr>
              <td v-for="(header, index) in headers" v-bind:key="index">
                <span v-if="index === 0">{{ item.Name || item.name }}</span>
                <v-tooltip v-else top>
                  <template v-slot:activator="{ on }">
                    <v-switch
                      @change="toggle(item.Id || item.id, header.attributeId)"
                      class="my-1"
                      color="primary"
                      hide-details
                      :input-value="
                        getSwitchValue(
                          item.Id || item.id,
                          header.userDataAuthorizations
                        )
                      "
                      v-on="header.description ? on : ''"
                    ></v-switch>
                  </template>
                  <span>{{ header.description }}</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
          <template v-slot:no-data></template>
        </v-data-table>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { mapActions } from "vuex";
import {
  TOGGLE_ACTION_AUTHORIZATION,
  TOGGLE_DATA_AUTHORIZATION,
} from "../../store/modules/user";

export default {
  props: ["attributes"],
  data: () => ({
    actionAttributes: [],
    expansion: null,
    headers: [],
    items: [],
    isLoading: false,
    vuexAction: "",
    error: "",
  }),
  created() {
    let expansion = null;
    let headers = [
      {
        text: "Name",
        value: "Name",
      },
    ];
    let vuexAction = "";

    this.actionAttributes = this.attributes.reduce(function(
      filtered,
      attribute
    ) {
      if (attribute.Action.startsWith("Data")) {
        if (
          attribute.Action === "Data" &&
          attribute.UserAuthorizations.length > 0
        ) {
          expansion = 0;
        }

        if (attribute.Action !== "Data") {
          let text = attribute.Action.split("-")[1];
          headers.push({
            text: text,
            value: text,
            description: attribute.Description,
            userDataAuthorizations: attribute.UserDataAuthorizations,
            attributeId: attribute.Id,
          });
        } else {
          vuexAction = attribute.VuexAction;
        }

        filtered.push({
          id: attribute.Id,
          action: attribute.Action,
          description: attribute.Description,
          vuexAction: attribute.VuexAction,
          value: attribute.UserAuthorizations.length > 0,
          UserAuthorizations: attribute.UserAuthorizations,
          UserDataAuthorizations: attribute.UserDataAuthorizations,
        });
      }
      return filtered;
    },
    []);

    if (expansion === 0) {
      this.getData(vuexAction);
    }

    this.expansion = expansion;
    this.headers = headers;
    this.vuexAction = vuexAction;
  },
  methods: {
    ...mapActions("user", {
      toggleActionAuthorization: TOGGLE_ACTION_AUTHORIZATION,
      toggleDataAuthorization: TOGGLE_DATA_AUTHORIZATION,
    }),
    setSelectedIcon() {
      return this.expansion === 0 ? "toggle_off" : "toggle_on";
    },
    setSelectedColor() {
      return this.expansion === 0 ? "gray" : "primary";
    },
    setTooltip(on) {
      let attribute = this.actionAttributes.find((actionAttribute) => {
        return actionAttribute.action === "Data";
      });
      return attribute.description ? on : "";
    },
    getTooltipText() {
      let attribute = this.actionAttributes.find((actionAttribute) => {
        return actionAttribute.action === "Data";
      });
      return attribute.description;
    },
    toggleView() {
      this.getData(this.vuexAction);

      let dataAttribute = this.actionAttributes.find(function(attribute) {
        return attribute.action === "Data";
      });

      this.toggleActionAuthorization({
        userId: this.$route.params.id,
        attributeId: dataAttribute.id,
      });
    },
    async getData(action) {
      if (this.expansion !== 0) {
        try {
          this.isLoading = true;
          this.items = await this.$store.dispatch(action);
        } catch (error) {
          this.error = error;
        } finally {
          this.isLoading = false;
        }
      }
    },
    getSwitchValue(id, userDataAuthorizations) {
      let hasAuthorization = userDataAuthorizations.findIndex(function(
        authorization
      ) {
        return authorization.DataId === id;
      });

      return hasAuthorization !== -1;
    },
    toggle(id, attributeId) {
      this.toggleDataAuthorization({
        userId: this.$route.params.id,
        dataId: id,
        attributeId,
      });
    },
  },
};
</script>
