<template>
  <v-dialog v-model="dialog" persistent max-width="800px">
    <v-card>
      <v-form ref="form">
        <v-card-title>
          <span class="headline">Title Auths</span>
        </v-card-title>
        <v-alert
          v-show="!!error"
          text
          prominent
          type="error"
          icon="mdi-alert-circle"
          >{{ error }}</v-alert
        >
        <v-alert
          v-show="!!success"
          text
          prominent
          type="success"
          icon="mdi-cloud-check"
          >Succesfull</v-alert
        >
        <v-container>
          <v-row>
            <v-row>
              <v-col cols="1" sm="1" md="1"></v-col>
              <v-col cols="7" sm="2" md="7">
                <v-autocomplete
                  v-model="titleId"
                  :items="titles"
                  item-text="Name"
                  item-value="Id"
                  dense
                  label="Select Title"
                ></v-autocomplete>
              </v-col>
              <v-col cols="1" sm="1" md="1">
                <v-btn color="primary" depressed v-on:click="save">Add</v-btn>
              </v-col>
              <v-col cols="2" sm="1" md="2">
                <v-btn color="primary" depressed v-on:click="toggleTitleDialog"
                  >Titles</v-btn
                >
              </v-col>
              <v-col cols="1" sm="1" md="1"></v-col>
            </v-row>
            <v-col cols="12" sm="6" md="12">
              <v-data-table
                :headers="headers"
                :items="auths"
                class="shadow-xl mx-5 my-5"
                :search="search"
                hide-default-footer
              >
                <template v-slot:top>
                  <v-toolbar flat color="white">
                    <v-row>
                      <v-col cols="10" sm="3" md="4" offset-md="8" class="px-5">
                        <v-text-field
                          v-model="search"
                          append-icon="search"
                          label="Search"
                          single-line
                          hide-details
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-toolbar>
                </template>
                <template v-slot:item.action="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn depressed @click="deleteAuth(item.Id)" v-on="on">
                        <v-icon size="18">delete</v-icon>
                      </v-btn>
                    </template>
                    <span>Delete Auth</span>
                  </v-tooltip>
                </template>
                <template v-slot:no-data></template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" text @click="closeDialog">Close</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from "vuex";
import {
  FETCH_TITLES,
  FETCH_TITLE_AUTH,
  CREATE_TITLE_AUTH,
  DELETE_TITLE_AUTH,
  TOGGLE_TITLE_AUTH_DIALOG,
  TOGGLE_TITLE_DIALOG,
} from "../../store/modules/digistaff";

export default {
  data: () => ({
    headers: [
      {
        text: "Title",
        value: "Title.Name",
      },
      {
        text: "Skill",
        value: "Skill.Name",
      },
      {
        text: "Actions",
        value: "action",
        align: "right",
      },
    ],
    search: "",
    titleId: "",
  }),
  computed: {
    ...mapState("digistaff", {
      skillId: (state) => state.titleAuthSkillId,
      titles: (state) => state.titles,
      auths: (state) => state.titleAuths,
      isLoading: (state) => state.titleAuthIsLoading,
      dialog: (state) => state.titleAuthDialog,
      success: (state) =>
        state.createTitleAuth.success || state.deleteTitleAuth.success,
      error: (state) =>
        state.createTitleAuth.error || state.deleteTitleAuth.error,
    }),
  },
  watch: {
    skillId(val) {
      if (val) {
        this.fetchTitleAuth(val);
        this.fetchTitles();
      }
    },
  },
  methods: {
    ...mapActions("digistaff", {
      fetchTitles: FETCH_TITLES,
      fetchTitleAuth: FETCH_TITLE_AUTH,
      createTitleAuth: CREATE_TITLE_AUTH,
      deleteTitleAuth: DELETE_TITLE_AUTH,
      toggleDialog: TOGGLE_TITLE_AUTH_DIALOG,
      toggleTitleDialog: TOGGLE_TITLE_DIALOG,
    }),
    save() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.createTitleAuth({
        SkillId: this.skillId,
        TitleId: this.titleId,
      });

      this.titleId = "";
    },
    deleteAuth(authId) {
      this.deleteTitleAuth({ skillId: this.skillId, authId });

      this.titleId = "";
    },
    closeDialog() {
      this.toggleDialog();
    },
  },
};
</script>
