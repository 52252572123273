<template>
  <v-main class="mx-4 my-4">
    <UpgradeAlert />
    <router-view></router-view>
  </v-main>
</template>

<script>
import UpgradeAlert from "../Layout/UpgradeAlert";
export default {
  components: {
    UpgradeAlert,
  },
};
</script>
