import Api from "../../api";
export const FETCH_AGENTS = "FETCH_AGENTS";
export const FILTER_AGENTS = "FILTER_AGENTS";
export const FETCH_AGENT_DOWNLOAD_SETTINGS = "FETCH_AGENT_DOWNLOAD_SETTINGS";
export const TOGGLE_EDIT_DIALOG = "TOGGLE_EDIT_DIALOG";
export const OPEN_EDIT_DIALOG = "OPEN_EDIT_DIALOG";
export const CLOSE_EDIT_DIALOG = "CLOSE_EDIT_DIALOG";
export const OPEN_AGENT_DELETE_DIALOG = "OPEN_AGENT_AGENT_DIALOG";
export const CLOSE_AGENT_DELETE_DIALOG = "CLOSE_AGENT_AGENT_DIALOG";
export const DELETE_AGENT = "DELETE_AGENT";
export const SET_AGENTS = "SET_AGENTS";
export const SET_AGENT = "SET_AGENT";
export const GET_AGENTS = "GET_AGENTS";

function mutator(name) {
  return function() {
    arguments[0][name] = arguments[1];
  };
}

const state = {
  agents: [],
  pagedAgents: [],
  totalCount: 0,
  agentDownloadSettings: [],
  agentDownloadSettingsLoading: false,
  openEditDialog: false,
  listRefreshing: false,
  listLoading: false,
  editedAgent: {
    Name: "",
    Id: "",
  },
  createLoading: [],
  updateLoading: [],
  listError: "",
  createError: "",
  updateError: "",
  deleteDialog: false,
  deleteLoading: false,
  deleteError: "",
  deleteSuccess: false,
  deletedItem: {},
  deleteWarningMessage: "",
};

const getters = {};

const actions = {
  [SET_AGENTS]: ({ commit }, agents) => {
    agents = agents.map((item) => {
      item.Status = item.Status == "2" ? "Connected" : "Disconnected";
      return item;
    });
    commit("setAgents", agents);
  },
  [SET_AGENT]: ({ commit }, agent) => {
    commit("setAgent", agent);
  },
  [FETCH_AGENTS]: async ({ commit }, isRefreshing = true) => {
    commit("clearListOperations");
    if (isRefreshing) commit("setListRefreshing", true);
    else commit("setListLoading", true);

    try {
      let list = await Api.listAgents();
      list = list.map((item) => {
        item.Status = item.Status == "2" ? "Connected" : "Disconnected";
        return item;
      });
      commit("setAgents", list);
    } catch (err) {
      commit("setListError", err.response.data.reason);
    }

    if (isRefreshing) commit("setListRefreshing", false);
    else commit("setListLoading", false);
  },

  [FILTER_AGENTS]: async ({ commit }, { options, isRefreshing = true }) => {
    commit("clearListOperations");
    if (isRefreshing) commit("setListRefreshing", true);
    else commit("setListLoading", true);

    try {
      let result = await Api.filterAgents(options);
      commit("setTotalCount", result.count);

      let list = result.rows.map((item) => {
        item.Status = item.Status == "2" ? "Connected" : "Disconnected";
        return item;
      });
      commit("setPagedAgents", list);
    } catch (err) {
      commit("setListError", err.response.data.reason);
    }

    if (isRefreshing) commit("setListRefreshing", false);
    else commit("setListLoading", false);
  },

  [OPEN_EDIT_DIALOG]: ({ commit }, agent) => {
    commit("openEditDialog", agent);
  },

  [CLOSE_EDIT_DIALOG]: ({ commit }) => {
    commit("closeEditDialog");
  },
  [OPEN_AGENT_DELETE_DIALOG]: async ({ commit }, deletedItem) => {
    commit("clearDeleteDialog");
    commit("setDeletedItem", { ...deletedItem });
    commit("setDeleteDialog", true);
    commit("setDeleteLoading", true);
    let tasks = await Api.agentTasks(deletedItem.Id);
    if (tasks.length && tasks.length > 0) {
      commit(
        "setDeleteWarningMessage",
        `
            This agent is registered to following tasks:<ul> 
            ${tasks.map((t) => `<li><strong>${t.Name}</strong></li>`).join("")}</ul>
            If deleted, agent will be removed from tasks above
            `
      );
    }
    commit("setDeleteLoading", false);
  },
  [CLOSE_AGENT_DELETE_DIALOG]: async ({ commit }) => {
    commit("setDeleteDialog", false);
  },
  [DELETE_AGENT]: async ({ commit, dispatch }, agent) => {
    commit("setDeleteError", "");
    commit("setDeleteLoading", true);
    try {
      await Api.deleteAgent(agent.Id);
      commit("setDeleteWarningMessage", "");
      commit("setDeleteLoading", false);
      commit("setDeleteSuccess", true);
      dispatch(FILTER_AGENTS, { options: {}, isRefreshing: false });
    } catch (e) {
      commit("setDeleteError", e.response.data.reason);
    }
    commit("setDeleteLoading", false);
  },
  [FETCH_AGENT_DOWNLOAD_SETTINGS]: async ({ commit }) => {
    commit("setAgentDownloadSettingsLoading", true);
    let agentDownloadSettings = await Api.listAgentDownloadSettigns();
    commit("setAgentDownloadSettings", agentDownloadSettings);
    commit("setAgentDownloadSettingsLoading", false);
  },
  [GET_AGENTS]: async () => {
    return await Api.listAgents();
  },
};

const mutations = {
  setAgents: (state, agents) => (state.agents = agents),
  setPagedAgents: (state, agents) => (state.pagedAgents = agents),
  setTotalCount: (state, count) => (state.totalCount = count),
  setListRefreshing: (state, status) => (state.listRefreshing = status),
  setListLoading: (state, status) => (state.listLoading = status),

  setAgentDownloadSettingsLoading: (state, status) => (state.agentDownloadSettingsLoading = status),
  setAgentDownloadSettings: (state, agentDownloadSettigns) => (state.agentDownloadSettings = agentDownloadSettigns),

  setListError: (state, error) => (state.listError = error),
  clearListOperations: (state) => {
    state.listError = "";
    state.listLoading = false;
  },
  openEditDialog: (state, agent) => {
    state.openEditDialog = true;
    state.editedAgent = { ...agent };
  },
  closeEditDialog: (state) => {
    state.openEditDialog = false;
    state.editedAgent = { Name: "", Id: "" };
  },
  setDeleteLoading: mutator("deleteLoading"),
  setDeleteError: mutator("deleteError"),
  setDeleteSuccess: mutator("deleteSuccess"),
  setDeleteDialog: mutator("deleteDialog"),
  setDeletedItem: mutator("deletedItem"),
  setDeleteWarningMessage: mutator("deleteWarningMessage"),
  clearDeleteDialog: (state) => {
    state.deleteLoading = false;
    state.deleteSuccess = false;
    state.deleteError = false;
    state.deleteWarningMessage = "";
  },
  setAgent: (state, agent) => {
    state.agents = state.agents.map((item) => {
      if (item.Id === agent.Id) {
        item.Status = agent.Status == "2" ? "Connected" : "Disconnected";
      }

      return item;
    });

    state.pagedAgents = state.pagedAgents.map((item) => {
      if (item.Id === agent.Id) {
        item.Status = agent.Status == "2" ? "Connected" : "Disconnected";
      }

      return item;
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
