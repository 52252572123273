<template>
    <v-dialog v-model="dialog" width="400">
        <template v-slot:activator="{ on }">
            <v-btn color="orange white--text" depressed v-on="on" class="mr-3 my-3" @click="e6 = 4">
                <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M6,15A2,2 0 0,1 4,17A2,2 0 0,1 2,15A2,2 0 0,1 4,13H6V15M7,15A2,2 0 0,1 9,13A2,2 0 0,1 11,15V20A2,2 0 0,1 9,22A2,2 0 0,1 7,20V15M9,7A2,2 0 0,1 7,5A2,2 0 0,1 9,3A2,2 0 0,1 11,5V7H9M9,8A2,2 0 0,1 11,10A2,2 0 0,1 9,12H4A2,2 0 0,1 2,10A2,2 0 0,1 4,8H9M17,10A2,2 0 0,1 19,8A2,2 0 0,1 21,10A2,2 0 0,1 19,12H17V10M16,10A2,2 0 0,1 14,12A2,2 0 0,1 12,10V5A2,2 0 0,1 14,3A2,2 0 0,1 16,5V10M14,18A2,2 0 0,1 16,20A2,2 0 0,1 14,22A2,2 0 0,1 12,20V18H14M14,17A2,2 0 0,1 12,15A2,2 0 0,1 14,13H19A2,2 0 0,1 21,15A2,2 0 0,1 19,17H14Z" />
                </svg>
                {{buttonName || "Slack"}}
            </v-btn>
        </template>
        <v-card class="px-4 py-4">
            <v-card-title>
                Add {{buttonName || "Slack"}} Alert
            </v-card-title>
            <v-card-text>
                <v-text-field ref="urlField" label="Webhook URL" :value="value" v-on:change="$emit('input', $event)" outlined dense :rules="urlRules"></v-text-field>
            </v-card-text>

            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="save()" color="primary" depressed>
                    Save
                </v-btn>
                <v-btn @click="cancel()" depressed>
                    Cancel
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

</template>

<script>
export default {
    props:["value","buttonName"],
    methods:{
        save(){
            if(!this.$refs.urlField.validate()){
                return;
            }

            this.dialog = false;
        },
        cancel(){
            this.$emit('input','');
            this.dialog = false;
        }
    },
    data() {
        return {
            dialog: false,
            tasksStatus: [
                "Task completes","Task Fail"
            ],
            urlRules:[
                v => !!v || "url must not be empty",
                v => /^(https?|chrome):\/\/[^\s$.?#].[^\s]*$/gm.test(v) || "must be a valid url"
            ],
            webhookurl : "",
            Status: ""
        }
    },
}
</script>
