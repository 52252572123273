<template>
  <v-card v-if="shadowData">
    <v-card-text>
      <v-switch
        v-model="shadowData.alwaysOutputData"
        label="Always output data"
        @change="emit"
      />
      <v-switch
        @change="emit"
        v-model="shadowData.continueOnFail"
        label="Continue on fail"
      />
      <v-switch
        @change="emit"
        v-model="shadowData.executeOnce"
        label="Execute Once"
      />
      <v-switch
        @change="emit"
        v-model="shadowData.retryOnFail"
        label="Retry On Fail"
      />
      <v-text-field
        @change="emit"
        v-if="shadowData.retryOnFail"
        type="number"
        :min="2"
        :max="5"
        v-model="shadowData.maxTries"
      />
      <v-text-field
        @change="emit"
        v-if="shadowData.retryOnFail"
        type="number"
        :min="0"
        :max="5000"
        v-model="shadowData.waitBetweenTries"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import { deepCopy } from "../../helpers/object";
const defaultData = {
  alwaysOutputData: false,
  continueOnFail: false,
  executeOnce: false,
  retryOnFail: false,
  maxTries: 3,
  waitBetweenTries: 1000,
};
export default {
  props: ["value"],
  data: () => ({
    shadowData: null,
  }),
  mounted() {
    console.log("here");
    this.setShadowData();
  },
  watch: {
    value: {
      deep: true,
      handler() {
        this.setShadowData();
      },
    },
  },
  methods: {
    emit() {
      this.$emit("input", this.shadowData);
    },
    setShadowData() {
      this.shadowData = deepCopy(this.value || defaultData);
    },
  },
};
</script>