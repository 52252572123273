var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isLoading)?_c('v-skeleton-loader',{ref:"skeleton",staticClass:"mx-auto",attrs:{"type":"table"}}):_c('v-data-table',{staticClass:"shadow-xl mx-5 my-5",attrs:{"headers":_vm.headers,"items":_vm.skills,"footer-props":{
      itemsPerPageOptions: [10, 50, 100],
    }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('div',{staticClass:"d-none d-sm-flex"},[_c('v-toolbar-title',[_vm._v("Skills")])],1),_c('v-spacer'),(_vm.digistaffId && _vm.hasAuth('Skills', 'New Skill'))?_c('new-skill-dialog',{attrs:{"digistaffId":_vm.digistaffId}}):_vm._e(),(_vm.digistaffId && _vm.hasAuth('Skills', 'Edit Skill'))?_c('edit-skill-dialog',{attrs:{"digistaffId":_vm.digistaffId}}):_vm._e(),(_vm.digistaffId && _vm.hasAuth('Skills', 'Delete Skill'))?_c('delete-skill-dialog',{attrs:{"digistaffId":_vm.digistaffId}}):_vm._e(),(_vm.hasAuth('Skills', 'Department Auths'))?_c('department-auth-dialog'):_vm._e(),(_vm.hasAuth('Departments', 'View'))?_c('department-dialog'):_vm._e(),(_vm.hasAuth('Skills', 'Title Auths'))?_c('title-auth-dialog'):_vm._e(),(_vm.hasAuth('Titles', 'View'))?_c('title-dialog'):_vm._e(),(_vm.hasAuth('Skills', 'User Auths'))?_c('user-auth-dialog'):_vm._e()],1)]},proxy:true},{key:"item.action",fn:function(ref){
    var item = ref.item;
return [(_vm.hasAuth('Skills', 'Edit Skill'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"depressed":""},on:{"click":function () { return _vm.editSkill(item); }}},on),[_c('v-icon',{attrs:{"size":"18"}},[_vm._v("edit")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit Skill")])]):_vm._e(),(_vm.hasAuth('Skills', 'Delete Skill'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"depressed":""},on:{"click":function($event){return _vm.deleteSkill(item.Id)}}},on),[_c('v-icon',{attrs:{"size":"18"}},[_vm._v("delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete Skill")])]):_vm._e(),(_vm.hasAuth('Skills', 'Department Auths'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"depressed":"","color":"primary"},on:{"click":function($event){return _vm.openDepartmentAuth(item.Id)}}},on),[_c('v-icon',{attrs:{"size":"18"}},[_vm._v("assignment_ind")])],1)]}}],null,true)},[_c('span',[_vm._v("Department Auth")])]):_vm._e(),(_vm.hasAuth('Skills', 'Title Auths'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"depressed":"","color":"primary"},on:{"click":function($event){return _vm.openTitleAuth(item.Id)}}},on),[_c('v-icon',{attrs:{"size":"18"}},[_vm._v("account_circle")])],1)]}}],null,true)},[_c('span',[_vm._v("Title Auth")])]):_vm._e(),(_vm.hasAuth('Skills', 'User Auths'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"depressed":"","color":"primary"},on:{"click":function($event){return _vm.openUserAuth(item.Id)}}},on),[_c('v-icon',{attrs:{"size":"18"}},[_vm._v("account_box")])],1)]}}],null,true)},[_c('span',[_vm._v("User Auth")])]):_vm._e()]}},{key:"no-data",fn:function(){return undefined},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }