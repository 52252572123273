<template>
  <v-dialog :width="width" v-model="internalDialog">
    <v-alert style="margin-bottom: 0" :type="options.type">{{
      options.text
    }}</v-alert>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  computed: {
    ...mapState('formDialog', {
      dialog: state => state.alertDialog,
      options: state => state.alertOptions,
    }),
    internalDialog: {
      get() {
        return this.dialog
      },
      set(val) {
        if (!val) {
          this.closeAlertDialog()
        }
      },
    },
    width() {
      return this.options.width ?? 400
    },
  },
  methods: {
    ...mapActions('formDialog', ['closeAlertDialog']),
    onOpen() {
      console.log('here')
    },
    onClose() {},
  },
}
</script>
