<template>
  <v-row justify="center">
    <v-col cols="12" md="6">
      <v-card class="shadow-xl pa-5" :loading="isLoading">
        <v-card-text>
          <v-toolbar-title>Active Directory</v-toolbar-title>
          <v-divider class="my-5"></v-divider>
          <v-alert v-show="!!error" text prominent type="error" icon="mdi-alert-circle">{{ error }}</v-alert>
          <v-alert v-show="!!success" text prominent type="success" icon="mdi-cloud-check">Active Directory information has been updated.</v-alert>
          <v-form ref="form" v-on:submit.prevent="save">
            <v-text-field label="Url" v-model="ad.url" required outlined :rules="urlRules" maxlength="100" counter />
            <v-text-field label="BaseDN" v-model="ad.baseDN" required outlined :rules="baseDNRules" maxlength="100" counter />
            <v-text-field
              label="Username"
              v-model="ad.username"
              type="text"
              required
              outlined
              :rules="usernameRules"
              maxlength="100"
              counter
              autocomplete="off"
              readonly
              onfocus="this.removeAttribute('readonly')"
            />
            <v-text-field
              label="Password"
              v-model="ad.password"
              type="password"
              required
              outlined
              :rules="passwordRules"
              maxlength="100"
              counter
              autocomplete="off"
              readonly
              onfocus="this.removeAttribute('readonly')"
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" @click="save" depressed block>Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { UPDATE_AD, FETCH_AD } from "../../store/modules/activeDirectory";

export default {
  data: () => ({
    ad: {
      url: "",
      baseDN: "",
      username: "",
      password: "",
    },
    urlRules: [(v) => !!v || "Url is required"],
    baseDNRules: [(v) => !!v || "BaseDN is required"],
    usernameRules: [(v) => !!v || "Username is required"],
    passwordRules: [(v) => !!v || "Password is required"],
  }),
  computed: {
    ...mapState("activeDirectory", {
      activeDirectory: (state) => state.ad,
      isLoading: (state) => state.isLoading,
      success: (state) => state.success,
      error: (state) => state.error,
    }),
  },
  watch: {
    activeDirectory(value) {
      if (value) {
        Object.assign(this.ad, value);
      }
    },
  },
  created() {
    this.fetchAd();
  },
  methods: {
    ...mapActions("activeDirectory", {
      updateAd: UPDATE_AD,
      fetchAd: FETCH_AD,
    }),
    save() {
      if (!this.$refs.form.validate()) return;

      this.updateAd(this.ad);
    },
  },
};
</script>
