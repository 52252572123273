<template>
  <div style="padding: 50px">
    <v-card flat :elevation="2">
      <v-card-text>
        <v-data-table
          :loading="loading"
          :headers="headers"
          :items="workflows"
          :sort-by="['updatedAt']"
          :sort-desc="[true]"
          :search="search"
          :items-per-page="7"
        >
          <template v-slot:top>
            <div class="d-flex flex-row">
              <span class="title">Workflows</span>
              <v-spacer />
              <v-text-field
                class="flex-grow-0"
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                height="35px"
                dense
              ></v-text-field>
              <v-btn
                v-if="hasAuth('New Workflow')"
                elevation="0"
                class="ml-5"
                color="primary"
                to="/workflow"
              >
                <v-icon>mdi-plus</v-icon> New Workflow
              </v-btn>
            </div>
          </template>
          <template v-slot:item.action="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-if="item.DataEdit"
                  depressed
                  @click="openWorkflowSettings(item.id)"
                  v-on="on"
                >
                  <v-icon size="18">mdi-settings</v-icon>
                </v-btn>
              </template>
              <span>Workflow Settings</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  class="mx-1"
                  v-if="item.DataEdit"
                  depressed
                  :to="`workflows/${item.id}`"
                  v-on="on"
                >
                  <v-icon size="18">mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Edit Workflow</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-if="item.DataDelete"
                  depressed
                  class="mx-1"
                  @click="deleteWorkflow(item.id)"
                  v-on="on"
                >
                  <v-icon size="18">delete</v-icon>
                </v-btn>
              </template>
              <span>Delete Workflow</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn v-if="item.DataStatus" depressed class="mx-1" v-on="on">
                  <v-switch
                    :loading="activeLoading[item.id] ? `warning` : null"
                    v-model="item.active"
                    @change="(value) => setActiveStatus(item.id, value)"
                    class="my-1"
                    hide-details
                  ></v-switch>
                </v-btn>
              </template>
              <span>Enable/Disable</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Api from "../api";
import formDialog from "../mixins/formDialog";
import Vue from "vue";
import AuthMixin from "../mixins/auth";
import { timeAwareFormat } from "../../time";

export default {
  mixins: [formDialog, AuthMixin("Workflow")],
  data: () => ({
    search: "",
    loading: false,
    headers: [
      { text: "Name", value: "name" },
      { text: "Created At", value: "createdAtAware" },
      { text: "Updated At", value: "updatedAtAware" },
      { text: "Actions", value: "action" },
    ],
    activeLoading: {},
  }),
  computed: {
    ...mapState("workflows", {
      workflows: (state) =>
        state.workflows.map((i) => ({
          ...i,
          createdAtAware: timeAwareFormat(i.createdAt),
          updatedAtAware: timeAwareFormat(i.updatedAt),
        })),
    }),
  },
  methods: {
    ...mapActions("workflows", ["loadWorkflows"]),
    async setActiveStatus(id, status) {
      this.activeLoading[id] = true;
      try {
        await Api.updateWorkflow(id, { active: status });
      } catch (err) {
        this.$alert({
          text: err.response.data.message,
          type: "error",
          duration: 5,
        });
        let item = this.workflows.find((item) => item.id == id);
        item.active = !status;
      }

      Vue.delete(this.activeLoading, id);
    },
    async deleteWorkflow(id) {
      let result = await this.$confirm({
        text: "Do you confirm to delete this workflow ?",
        title: "Delete Workflow",
      });
      if (!result.status) return;

      try {
        await Api.deleteWorkflow(id);
        await this.loadWorkflows();
      } catch (err) {
        this.$alert({
          text: err.response.data.message,
          type: "error",
          duration: 5,
        });
      }
    },
    async openWorkflowSettings(id) {
      let editedWorkflow = this.workflows.find((wf) => wf.id == id);
      let timezones = await Api.getTimeZones();
      let workflows = this.workflows.map((element) => ({
        name: element.name,
        value: element.id,
      }));
      let options = Object.entries(timezones).map(([key]) => ({
        name: key,
        value: key,
      }));
      workflows = [
        {
          name: "No Workflow",
          value: null,
        },
        ...workflows,
      ];



      let result = await this.$input({
        title: "Edit Workflow Settings",
        properties: [
          { name: "name", displayName: "name", type: "string" },
          {
            name: "timezone",
            displayName: "timezone",
            type: "options",
            options,
          },
          {
            name: "errorWorkflow",
            displayName: "error workflow",
            type: "options",
            options: workflows,
          },
        ],
        initialFormData: {
          name: editedWorkflow.name,
          timezone: editedWorkflow.settings?.timezone,
          errorWorkflow: editedWorkflow.settings?.errorWorkflow,
        },
      });

      let updateData = {
        name: result.data.name,
        settings: {
          timezone: result.data.timezone,
          errorWorkflow: result.data.errorWorkflow,
        },
      };

      if (result.status) {
        try {
          await Api.updateWorkflow(id, updateData);
          await this.loadWorkflows();
        } catch (e) {
          await this.$alert({
            text: e.response.data.message,
            type: "error",
          });
        }
      }
    },
  },
  mounted() {
    this.loading = true;
    this.loadWorkflows().then(() => {
      this.loading = false;
    });
  },
};
</script>
