import Api from "../../api";
export const FETCH_USER = "FETCH_USER";
export const UPDATE_USER = "UPDATE_USER";
export const HAS_CUSTOMER = "HAS_CUSTOMER";
export const REFRESH_UPDATE_STATE = "REFRESH_UPDATE_STATE";
export const GET_SUB_USER = "GET_SUB_USER";
export const FILTER_SUB_USER = "FILTER_SUB_USER";
export const CREATE_USER = "CREATE_USER";
export const CLOSE_CREATE_USER_DIALOG = "CLOSE_CREATE_USER_DIALOG";
export const OPEN_CREATE_USER_DIALOG = "OPEN_CREATE_USER_DIALOG";
export const GET_USER_ROLE = "GET_USER_ROLE";
export const TOGGLE_USER_STATUS = "TOGGLE_USER_STATUS";
export const UPDATE_SUB_USER = "UPDATE_SUB_USER";
export const CLOSE_UPDATE_USER_DIALOG = "CLOSE_UPDATE_USER_DIALOG";
export const OPEN_UPDATE_USER_DIALOG = "OPEN_UPDATE_USER_DIALOG";
export const SET_SELECTED_SUB_USER = "SET_SELECTED_SUB_USER";
export const GET_SUB_USER_BY_ID = "GET_SUB_USER_BY_ID";
export const GET_USER_AUTHORIZATION = "GET_USER_AUTHORIZATION";
export const TOGGLE_VIEW_AUTHORIZATION = "TOGGLE_VIEW_AUTHORIZATION";
export const TOGGLE_ACTION_AUTHORIZATION = "TOGGLE_ACTION_AUTHORIZATION";
export const TOGGLE_DATA_AUTHORIZATION = "TOGGLE_DATA_AUTHORIZATION";
export const GET_PAGES = "GET_PAGES";
export const HAS_ACTION_AUTHORIZATION = "HAS_ACTION_AUTHORIZATION";
export const GET_SESSION_INFO = "GET_SESSION_INFO";

const state = {
  user: {},
  subUser: {
    selectedSubUser: {},
    subUsers: [],
    pagedSubUsers: [],
    totalCount: 0,
    subUser: {},
    userRoles: [],
    isLoading: false,
    createSuccess: false,
    createError: "",
    createDialog: false,
    updateSuccess: false,
    updateError: "",
    updateDialog: false,
  },
  authorization: {
    authError: "",
    name: "",
    pages: [],
    selectedItems: [],
  },
  hasCustomer: false,
  isLoading: false,
  error: "",
  updateSuccess: false,
  updateError: false,
  isUpdating: false,
  pages: [],
  session: {},
};

const getters = {};

const actions = {
  [FETCH_USER]: async ({ commit }) => {
    commit("setLoading", true);
    try {
      let user = await Api.userInfo();
      commit("setUser", user);
    } catch (err) {
      commit("setError", err.response.data.reason);
    }
    commit("setLoading", false);
  },
  [UPDATE_USER]: async ({ commit }, userInfo) => {
    commit("setIsUpdating", true);
    commit("setUpdateError", "");
    try {
      let user = await Api.updateUser(userInfo.name, userInfo.password, userInfo.newPassword, userInfo.reTypeNewPassword, userInfo.changePassword);
      commit("setUpdateSuccess", true);
      commit("setUser", user);
    } catch (err) {
      commit("setUpdateError", err.response.data.reason);
    }
    commit("setIsUpdating", false);
  },
  [HAS_CUSTOMER]: async ({ commit }) => {
    let hasCustomer = await Api.hasPaymentCustomer();
    commit("setHasCustomer", hasCustomer);
  },
  [REFRESH_UPDATE_STATE]: ({ commit }) => {
    commit("setUpdateError", "");
    commit("setUpdateSuccess", false);
  },
  [GET_SUB_USER]: async ({ commit }, loading = true) => {
    try {
      if (loading) commit("setCreateLoading", true);
      let list = await Api.getSubUsers();
      commit("setSubUsers", list);
    } catch (err) {
      commit("setError", err.response.data.reason);
    } finally {
      if (loading) commit("setCreateLoading", false);
    }
  },
  [FILTER_SUB_USER]: async ({ commit }, { options }) => {
    try {
      commit("setCreateLoading", true);

      let result = await Api.filterSubUsers(options);

      commit("setPagedSubUsers", result.rows);
      commit("setTotalCount", result.count);
    } catch (err) {
      commit("setError", err.response.data.reason);
    } finally {
      commit("setCreateLoading", false);
    }
  },
  [CREATE_USER]: async ({ commit, dispatch }, user) => {
    try {
      commit("setCreateError", "");
      await Api.createUser(user);
      dispatch(FILTER_SUB_USER, { options: { itemsPerPage: 10, page: 1 } });
      commit("setCreateLoading", false);
      commit("setCreateSuccess", true);
    } catch (err) {
      commit("setCreateError", err.response.data.reason);
    }
  },
  [OPEN_CREATE_USER_DIALOG]: async ({ commit, dispatch }) => {
    dispatch(GET_USER_ROLE);
    commit("clearCreateDialog");
    commit("setCreateDialog", true);
  },
  [CLOSE_CREATE_USER_DIALOG]: async ({ commit }) => {
    commit("setCreateDialog", false);
  },
  [GET_USER_ROLE]: async ({ commit }) => {
    let list = await Api.getUserRoles();
    commit("setUserRoles", list);
  },
  [TOGGLE_USER_STATUS]: async ({ dispatch }, userId) => {
    await Api.toggleUserStatus(userId);
    dispatch(FILTER_SUB_USER, { options: { itemsPerPage: 10, page: 1 } });
  },
  [UPDATE_SUB_USER]: async ({ commit, dispatch }, { userId, user }) => {
    try {
      commit("setSubUserUpdateError", "");
      await Api.updateSubUser(userId, user);
      dispatch(FILTER_SUB_USER, { options: { itemsPerPage: 10, page: 1 } });
      commit("setUpdateLoading", false);
      commit("setSubUserUpdateSuccess", true);
    } catch (err) {
      commit("setSubUserUpdateError", err.response.data.reason);
    }
  },
  [OPEN_UPDATE_USER_DIALOG]: async ({ commit, dispatch }) => {
    dispatch(GET_USER_ROLE);
    commit("clearUpdateDialog");
    commit("setUpdateDialog", true);
  },
  [CLOSE_UPDATE_USER_DIALOG]: async ({ commit, dispatch }) => {
    dispatch(SET_SELECTED_SUB_USER, {});
    commit("setUpdateDialog", false);
  },
  [SET_SELECTED_SUB_USER]: async ({ commit }, user) => {
    commit("setSelectedSubUser", user);
  },
  [GET_SUB_USER_BY_ID]: async ({ commit }, userId) => {
    try {
      let user = await Api.getSubUserById(userId);
      commit("setSubUser", user);
    } catch (err) {
      commit("setAuthError", err.response.data.reason);
    }
  },
  [GET_USER_AUTHORIZATION]: async ({ commit }, userId) => {
    try {
      let authorizations = await Api.getUserAuthorization(userId);
      commit("setAuthorization", authorizations);
    } catch (err) {
      commit("setAuthError", err.response.data.reason);
    }
  },
  [TOGGLE_VIEW_AUTHORIZATION]: async ({ commit, dispatch }, { userId, pageId }) => {
    try {
      await Api.toggleViewAuthorization(userId, pageId);
    } catch (err) {
      commit("setAuthError", err.response.data.reason);
    } finally {
      dispatch(GET_USER_AUTHORIZATION, userId);
    }
  },
  [TOGGLE_ACTION_AUTHORIZATION]: async ({ commit, dispatch }, { userId, attributeId }) => {
    try {
      await Api.toggleActionAuthorization(userId, attributeId);
    } catch (err) {
      commit("setAuthError", err.response.data.reason);
    } finally {
      dispatch(GET_USER_AUTHORIZATION, userId);
    }
  },
  [TOGGLE_DATA_AUTHORIZATION]: async ({ commit, dispatch }, { userId, dataId, attributeId }) => {
    try {
      await Api.toggleDataAuthorization(userId, dataId, attributeId);
    } catch (err) {
      commit("setAuthError", err.response.data.reason);
    } finally {
      dispatch(GET_USER_AUTHORIZATION, userId);
    }
  },
  [GET_PAGES]: async ({ commit }) => {
    try {
      let pages = await Api.getPages();
      commit("setPages", pages);
      return pages;
    } catch (error) {
      console.error(error);
    }
  },
  [HAS_ACTION_AUTHORIZATION]: ({ state }, { page, action }) => {
    if (state.user.Role === "Owner" || state.user.Role === "Administrator") {
      return true;
    }

    let auth = state.pages.find((item) => {
      if (item.Name === page && item.AuthorizationAttributes) {
        let attributes = item.AuthorizationAttributes.find((attribute) => {
          return attribute.Action === action && attribute.UserAuthorizations;
        });
        return attributes;
      }
    });

    return auth;
  },
  [GET_SESSION_INFO]: async ({ commit }) => {
    try {
      let session = await Api.getSessionInfo();
      commit("setSessionInfo", session);
    } catch (error) {
      console.error(error);
    }
  },
};

const mutations = {
  setUser: (state, user) => (state.user = user),
  setLoading: (state, status) => (state.isLoading = status),
  setError: (state, error) => (state.error = error),
  setUpdateError: (state, error) => (state.updateError = error),
  setIsUpdating: (state, status) => (state.isUpdating = status),
  setUpdateSuccess: (state, status) => (state.updateSuccess = status),
  setHasCustomer: (state, hasCustomer) => (state.hasCustomer = hasCustomer),
  clearCreateDialog: (state) => {
    state.subUser.isLoading = false;
    state.subUser.createSuccess = false;
    state.subUser.createError = "";
  },
  setCreateDialog: (state, value) => (state.subUser.createDialog = value),
  setUserRoles: (state, userRoles) => (state.subUser.userRoles = userRoles),
  setSubUsers: (state, users) => (state.subUser.subUsers = users),
  setCreateLoading: (state, status) => (state.subUser.isLoading = status),
  setCreateSuccess: (state) => (state.subUser.createSuccess = true),
  setCreateError: (state, error) => (state.subUser.createError = error),
  setUpdateDialog: (state, value) => (state.subUser.updateDialog = value),
  setUpdateLoading: (state, status) => (state.subUser.isLoading = status),
  setSubUserUpdateSuccess: (state) => (state.subUser.updateSuccess = true),
  setSubUserUpdateError: (state, error) => (state.subUser.updateError = error),
  clearUpdateDialog: (state) => {
    state.subUser.isLoading = false;
    state.subUser.updateSuccess = false;
    state.subUser.updateError = "";
  },
  setSelectedSubUser: (state, user) => {
    state.subUser.selectedSubUser = user;
  },
  setAuthorization: (state, data) => {
    state.authorization.name = data.name;
    state.authorization.pages = data.pages;

    let selectedItems = [];
    data.pages.map((page, index) => {
      let filter = page.AuthorizationAttributes.filter(function(el) {
        return el.Action === "View" && el.UserAuthorizations.length > 0;
      });
      if (filter.length > 0) {
        selectedItems.push(index);
      }
    });
    state.authorization.selectedItems = selectedItems;
  },
  setAuthError: (state, error) => (state.subUser.authError = error),
  setSubUser: (state, user) => (state.subUser.subUser = user),
  setPages: (state, pages) => (state.pages = pages),
  setPagedSubUsers: (state, users) => (state.subUser.pagedSubUsers = users),
  setTotalCount: (state, totalCount) => (state.subUser.totalCount = totalCount),
  setSessionInfo: (state, sessionInfo) => (state.session = sessionInfo),
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
