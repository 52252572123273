import Api from '../../api'

export const FETCH_PLAN_STATUS = "FETCH_PLAN_STATUS"
export const SET_PLAN_STATUS = "SET_PLAN_STATUS"
export const FETCH_ACCOUNT = "FETCH_ACCOUNT"
export const SET_ACCOUNT = "SET_ACCOUNT"
export const SET_PAYMENT_SECTION = "SET_PAYMENT_SECTION"
export const SET_SELECTED_PLAN_ID = "SET_SELECTED_PLAN_ID"
export const SET_PAGE_LOADED = "SET_PAGE_LOADED"

const state = {
    planStatus: {},
    account: {},
    payment: {
        paymentSection: false,
        selectedPlanId: ''
    },
    pageLoaded: false
}

const actions = {
    [FETCH_PLAN_STATUS]: async ({ commit }) => {
        try {
            let data = await Api.getUserPlanStatus();
            commit('SET_PLAN_STATUS', data);
        } catch (err) {
            console.error(err)
        }
    },
    [FETCH_ACCOUNT]: async ({ commit }) => {
        try {
            const data = await Api.getAccountInfo()
            commit('SET_ACCOUNT', data)
        } catch (error) {
            console.error(error)
        }
        finally {
            commit('SET_PAGE_LOADED', true)
        }
    }
}

const mutations = {
    [SET_PLAN_STATUS]: (state, data) => state.planStatus = data,
    [SET_ACCOUNT]: (state, data) => state.account = data,
    [SET_PAYMENT_SECTION]: (state, value) => state.payment.paymentSection = value,
    [SET_SELECTED_PLAN_ID]: (state, value) => state.payment.selectedPlanId = value,
    [SET_PAGE_LOADED]: (state, value) => state.payment.pageLoaded = value
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}