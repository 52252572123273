<template>
  <v-row justify="center">
    <v-col cols="12" md="6">
      <v-card class="shadow-xl pa-5">
        <v-card-text>
          <v-toolbar-title>Run Api</v-toolbar-title>
          <v-divider class="my-5"></v-divider>
          <v-text-field label="Api Url" v-model="apiUrl" type="text" outlined readonly v-clipboard:copy="apiUrl" @click="copy" />
          <v-text-field label="Your client id" v-model="user.Id" type="text" outlined readonly v-clipboard:copy="user.Id" @click="copy" />
          <v-text-field label="Your client secret" v-model="user.Key" type="text" required outlined readonly v-clipboard:copy="user.Key" @click="copy" />
        </v-card-text>
        <v-card-actions>
          <v-container>
            <v-row>
              <v-btn color="primary" @click.prevent="downloadFile()" depressed block>Download Postman Collection</v-btn>
            </v-row>
            <br />
            <v-row>
              <v-btn color="primary" :href="user.PostmanDocumentationUrl" target="_blank" depressed block>View Postman Documentation</v-btn>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-col>

    <v-snackbar v-model="snackbar">
      Copied!
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
import { gatewayURL } from "../../config";
import { getFile } from "../../asset";
import Axios from "axios";

export default {
  data() {
    return {
      apiUrl: `${gatewayURL}/api`,
      snackbar: false,
    };
  },
  computed: {
    ...mapState("user", {
      user: (state) => state.user,
    }),
  },
  methods: {
    downloadFile() {
      let url = getFile(this.user.PostmanCollectionUrl);
      if (!url) return;

      Axios.get(url, {
        responseType: "blob",
      }).then((response) => {
        const blob = new Blob([response.data], { type: "application/json" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = this.user.PostmanCollectionUrl;
        link.click();
        URL.revokeObjectURL(link.href);
      });
    },
    copy() {
      this.snackbar = true;
    },
  },
};
</script>
