<template>
  <v-dialog v-model="dialog" max-width="500px" persistent>
    <template v-slot:activator="{ on }">
      <v-btn color="primary" depressed v-on="on">
        <v-icon left>add</v-icon>Add Queue Item
      </v-btn>
    </template>
    <v-card>
      <v-form ref="form">
        <v-card-title>
          <span class="headline">Add Queue Item</span>
        </v-card-title>
        <v-card-text>
          <v-alert v-show="!!error" text prominent type="error" icon="mdi-alert-circle">{{error}}</v-alert>
          <v-alert
            v-show="!!success"
            text
            prominent
            type="success"
            icon="mdi-cloud-check"
          >Succesfully Added</v-alert>
          <v-container class="text-center" v-if="loading">
            <v-progress-circular :size="70" :width="7" color="purple" indeterminate></v-progress-circular>
          </v-container>
          <v-container v-else>
            <v-row>
              <v-col cols="12" sm="6" md="12">
                <v-text-field
                  v-model="item.Reference"
                  label="Reference"
                  required
                  :rules="referenceRules"
                  maxlength="25"
                  counter
                  :disabled="success"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  v-model="item.Priority"
                  :items="priorities"
                  item-text="name"
                  item-value="name"
                  dense
                  label="Select Priority"
                  :disabled="success"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="12">
                <v-textarea
                  v-model="item.Data"
                  auto-grow
                  counter
                  placeholder="JSON Data"
                  rows="1"
                  required
                  :rules="dataRules"
                  :disabled="success"
                  maxlength="2000"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed color="primary" v-show="!success && !loading" @click="save">Save</v-btn>
          <v-btn color="grey darken-1" text @click="toggleDialog">Cancel</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
import {
  ADD_QUEUE_ITEM,
  TOGGLE_ADD_QUEUE_ITEM_DIALOG
} from "../../store/modules/queue";
import { check } from "../../json";

export default {
  props: ["id"],
  data: () => ({
    referenceRules: [v => !!v || "Reference is required"],
    dataRules: [v => !!v || "Data is required", v => check(v)],
    item: {
      Reference: "",
      Priority: "",
      Data: ""
    },
    priorities: [
      {
        name: "Low"
      },
      {
        name: "High"
      }
    ]
  }),
  computed: {
    dialog: {
      get() {
        return this.$store.state.queue.addQueueItem.dialog;
      },
      set() {
        this.toggleDialog();
      }
    },
    ...mapState("queue", {
      loading: state => state.addQueueItem.isLoading,
      success: state => state.addQueueItem.success,
      error: state => state.addQueueItem.error
    })
  },
  methods: {
    ...mapActions("queue", {
      addQueueItem: ADD_QUEUE_ITEM,
      toggleDialog: TOGGLE_ADD_QUEUE_ITEM_DIALOG
    }),
    save() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.addQueueItem({ queueId: this.id, queueItem: this.item });
    },
    checkJson(json) {
      try {
        JSON.parse(json);
        return true;
      } catch (error) {
        return error;
      }
    }
  }
};
</script>