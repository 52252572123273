<template>
  <v-dialog v-model="dialog" max-width="500px" persistent>
    <v-card>
      <v-form ref="form">
        <v-card-title>
          <span class="headline">Edit Digistaff</span>
        </v-card-title>
        <v-card-text>
          <v-alert v-show="!!error" text prominent type="error" icon="mdi-alert-circle">{{ error }}</v-alert>
          <v-alert v-show="!!success" text prominent type="success" icon="mdi-cloud-check">Succesfully Created</v-alert>
          <v-container class="text-center" v-if="loading">
            <v-progress-circular :size="70" :width="7" color="purple" indeterminate></v-progress-circular>
          </v-container>
          <v-container v-else>
            <v-row>
              <v-col cols="12" sm="6" md="12">
                <v-text-field v-model="editItem.Name" label="Name" required :rules="nameRules" maxlength="50" counter :disabled="success"></v-text-field>
              </v-col>
              <v-col cols="8" sm="4" md="8">
                <v-autocomplete
                  v-model="editItem.DepartmentIds"
                  :items="departments"
                  item-text="Name"
                  item-value="Id"
                  dense
                  multiple
                  label="Select Department"
                  :disabled="success"
                ></v-autocomplete>
              </v-col>
              <v-col cols="4" sm="2" md="4">
                <v-btn color="primary" depressed v-if="hasAuth('Departments', 'View')" v-on:click="openDepartmentDialog" :disabled="success">Departments</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed color="primary" v-show="!success && !loading" @click="save">Save</v-btn>
          <v-btn color="grey darken-1" text @click="toggleDialog">Cancel</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { FETCH_DEPARTMENTS, UPDATE_DIGISTAFF, TOGGLE_UPDATE_DIGISTAFF_DIALOG, TOGGLE_DEPARTMENT_DIALOG, CHANGE_DIALOG } from "../../store/modules/digistaff";
import { hasActionAuthorization } from "../../authorization";

export default {
  data: () => ({
    editItem: {},
    nameRules: [(v) => !!v || "Digistaff name is required"],
  }),
  computed: {
    ...mapState("digistaff", {
      item: (state) => state.updateDigistaff.item,
      departments: (state) => state.departments,
      loading: (state) => state.updateDigistaff.isLoading,
      success: (state) => state.updateDigistaff.success,
      error: (state) => state.updateDigistaff.error,
      dialog: (state) => state.updateDigistaff.dialog,
    }),
  },
  watch: {
    item(value) {
      Object.assign(this.editItem, value);

      if (value) this.editItem.DepartmentIds = value.Departments.map((item) => item.Id);
      else this.editItem.DepartmentIds = [];
    },
    dialog(value) {
      if (value) this.fetchDepartments();
    },
  },
  methods: {
    ...mapActions("digistaff", {
      fetchDepartments: FETCH_DEPARTMENTS,
      updateDigistaff: UPDATE_DIGISTAFF,
      toggleDialog: TOGGLE_UPDATE_DIGISTAFF_DIALOG,
      toggleDepartmentDialog: TOGGLE_DEPARTMENT_DIALOG,
      changeDialog: CHANGE_DIALOG,
    }),
    save() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.updateDigistaff(this.editItem);
    },
    hasAuth(page, action) {
      return hasActionAuthorization(this.$store, page, action);
    },
    openDepartmentDialog() {
      this.toggleDepartmentDialog();
    },
  },
};
</script>
