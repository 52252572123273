import Api from "../../api";
export const FETCH_DIGISTAFFS = "FETCH_DIGISTAFFS";
export const TOGGLE_CREATE_DIGISTAFF_DIALOG = "TOGGLE_CREATE_DIGISTAFF_DIALOG";
export const CREATE_DIGISTAFF = "CREATE_DIGISTAFF";
export const TOGGLE_UPDATE_DIGISTAFF_DIALOG = "TOGGLE_UPDATE_DIGISTAFF_DIALOG";
export const UPDATE_DIGISTAFF = "UPDATE_ASSET";
export const TOGGLE_DELETE_DIGISTAFF_DIALOG = "TOGGLE_DELETE_DIGISTAFF_DIALOG";
export const DELETE_DIGISTAFF = "DELETE_DIGISTAFF";
export const FETCH_DEPARTMENTS = "FETCH_DEPARTMENTS";
export const TOGGLE_DEPARTMENT_DIALOG = "TOGGLE_DEPARTMENT_DIALOG";
export const CREATE_DEPARTMENT = "CREATE_DEPARTMENT";
export const UPDATE_DEPARTMENT = "UPDATE_DEPARTMENT";
export const DELETE_DEPARTMENT = "DELETE_DEPARTMENT";
export const CHANGE_DIALOG = "CHANGE_DIALOG";
export const FETCH_SKILLS = "FETCH_SKILLS";
export const TOGGLE_CREATE_SKILL_DIALOG = "TOGGLE_CREATE_SKILL_DIALOG";
export const CREATE_SKILL = "CREATE_SKILL";
export const TOGGLE_UPDATE_SKILL_DIALOG = "TOGGLE_UPDATE_SKILL_DIALOG";
export const UPDATE_SKILL = "UPDATE_SKILL";
export const TOGGLE_DELETE_SKILL_DIALOG = "TOGGLE_DELETE_SKILL_DIALOG";
export const DELETE_SKILL = "DELETE_SKILL";
export const TOGGLE_DEPARTMENT_AUTH_DIALOG = "TOGGLE_DEPARTMENT_AUTH_DIALOG";
export const FETCH_DEPARTMENT_AUTH = "FETCH_DEPARTMENT_AUTH";
export const CREATE_DEPARTMENT_AUTH = "CREATE_DEPARTMENT_AUTH";
export const DELETE_DEPARTMENT_AUTH = "DELETE_DEPARTMENT_AUTH";
export const TOGGLE_TITLE_AUTH_DIALOG = "TOGGLE_TITLE_AUTH_DIALOG";
export const FETCH_TITLE_AUTH = "FETCH_TITLE_AUTH";
export const CREATE_TITLE_AUTH = "CREATE_TITLE_AUTH";
export const DELETE_TITLE_AUTH = "DELETE_TITLE_AUTH";
export const TOGGLE_USER_AUTH_DIALOG = "TOGGLE_USER_AUTH_DIALOG";
export const FETCH_USER_AUTH = "FETCH_USER_AUTH";
export const CREATE_USER_AUTH = "CREATE_USER_AUTH";
export const TOGGLE_USER_AUTH = "TOGGLE_USER_AUTH";
export const DELETE_USER_AUTH = "DELETE_USER_AUTH";
export const TOGGLE_TITLE_DIALOG = "TOGGLE_TITLE_DIALOG";
export const FETCH_TITLES = "FETCH_TITLES";
export const CREATE_TITLE = "CREATE_TITLE";
export const DELETE_TITLE = "DELETE_TITLE";

const state = {
  digistaff: [],
  digistaffIsLoading: false,
  createDigistaff: {
    dialog: false,
    isLoading: false,
    success: false,
    error: "",
  },
  updateDigistaff: {
    item: {},
    dialog: false,
    isLoading: false,
    success: false,
    error: "",
  },
  deleteDigistaff: {
    id: 0,
    dialog: false,
    isLoading: false,
    success: false,
    error: "",
  },
  departments: [],
  departmentIsLoading: false,
  departmentDialog: false,
  createDepartment: {
    isLoading: false,
    success: false,
    error: "",
  },
  updateDepartment: {
    isLoading: false,
    success: false,
    error: "",
  },
  deleteDepartment: {
    isLoading: false,
    success: false,
    error: "",
  },
  dialog: "",
  skills: [],
  skillIsLoading: false,
  createSkill: {
    dialog: false,
    isLoading: false,
    success: false,
    error: "",
  },
  updateSkill: {
    item: {},
    dialog: false,
    isLoading: false,
    success: false,
    error: "",
  },
  deleteSkill: {
    id: 0,
    dialog: false,
    isLoading: false,
    success: false,
    error: "",
  },
  departmentAuths: [],
  departmentAuthIsLoading: false,
  departmentAuthDialog: false,
  departmentAuthSkillId: "",
  createDepartmentAuth: {
    isLoading: false,
    success: false,
    error: "",
  },
  deleteDepartmentAuth: {
    isLoading: false,
    success: false,
    error: "",
  },
  titleAuths: [],
  titleAuthIsLoading: false,
  titleAuthDialog: false,
  titleAuthSkillId: "",
  createTitleAuth: {
    isLoading: false,
    success: false,
    error: "",
  },
  deleteTitleAuth: {
    isLoading: false,
    success: false,
    error: "",
  },
  userAuths: [],
  userAuthIsLoading: false,
  userAuthDialog: false,
  userAuthSkillId: "",
  createUserAuth: {
    isLoading: false,
    success: false,
    error: "",
  },
  toggleUserAuth: {
    isLoading: false,
    success: false,
    error: "",
  },
  deleteUserAuth: {
    isLoading: false,
    success: false,
    error: "",
  },
  titles: [],
  titleIsLoading: false,
  titleDialog: false,
  createTitle: {
    isLoading: false,
    success: false,
    error: "",
  },
  deleteTitle: {
    isLoading: false,
    success: false,
    error: "",
  },
};

const actions = {
  [FETCH_DIGISTAFFS]: async ({ commit }) => {
    commit("setDigistaffLoading", true);

    try {
      let list = await Api.getDigistaffs();
      commit("setDigistaffs", list);
    } catch (err) {
      commit("setDigistaffs", []);
    } finally {
      commit("setDigistaffLoading", false);
    }
  },
  [TOGGLE_CREATE_DIGISTAFF_DIALOG]: ({ commit }) => {
    commit("setCreateDigistaffDialog");
  },
  [CREATE_DIGISTAFF]: async ({ commit, dispatch }, digistaff) => {
    commit("setCreateDigistaffLoading", true);

    try {
      await Api.createDigistaff(digistaff);
      commit("setCreateDigistaffSuccess");
    } catch (error) {
      commit("setCreateDigistaffError", error.response.data.reason);
    } finally {
      commit("setCreateDigistaffLoading", false);
      dispatch(FETCH_DIGISTAFFS);
    }
  },
  [TOGGLE_UPDATE_DIGISTAFF_DIALOG]: ({ commit }, item = {}) => {
    commit("setUpdateDigistaffDialog", item);
  },
  [UPDATE_DIGISTAFF]: async ({ commit, dispatch }, digistaff) => {
    commit("setUpdateDigistaffLoading", true);

    try {
      await Api.updateDigistaff(digistaff);
      commit("setUpdateDigistaffSuccess");
    } catch (error) {
      commit("setUpdateDigistaffError", error.response.data.reason);
    } finally {
      commit("setUpdateDigistaffLoading", false);
      dispatch(FETCH_DIGISTAFFS);
    }
  },
  [TOGGLE_DELETE_DIGISTAFF_DIALOG]: ({ commit }, id = 0) => {
    commit("setDeleteDigistaffDialog", id);
  },
  [DELETE_DIGISTAFF]: async ({ commit, dispatch }, digistaffId) => {
    commit("setDeleteDigistaffLoading", true);

    try {
      await Api.deleteDigistaff(digistaffId);
      commit("setDeleteDigistaffSuccess");
    } catch (error) {
      commit("setDeleteDigistaffError", error.response.data.reason);
    } finally {
      commit("setDeleteDigistaffLoading", false);
      dispatch(FETCH_DIGISTAFFS);
    }
  },
  [TOGGLE_DEPARTMENT_DIALOG]: ({ commit }) => {
    commit("setDepartmentDialog");
  },
  [FETCH_DEPARTMENTS]: async ({ commit }) => {
    commit("setDepartmentLoading", true);

    try {
      let departments = await Api.getDepartments();
      commit("setDepartments", departments);
    } catch (error) {
      commit("setDepartments", []);
    } finally {
      commit("setDepartmentLoading", false);
    }
  },
  [CREATE_DEPARTMENT]: async ({ commit, dispatch }, department) => {
    commit("clearDepartment");
    commit("setCreateDepartmentLoading", true);

    try {
      await Api.createDepartment(department);
      commit("setCreateDepartmentSuccess");
    } catch (error) {
      commit("setCreateDepartmentError", error.response.data.reason);
    } finally {
      commit("setCreateDepartmentLoading", false);
      dispatch(FETCH_DEPARTMENTS);
    }
  },
  [UPDATE_DEPARTMENT]: async (
    { commit, dispatch },
    { departmentId, department }
  ) => {
    commit("clearDepartment");
    commit("setUpdateDepartmentLoading", true);

    try {
      await Api.updateDepartment(departmentId, department);
      commit("setUpdatedepartmentSuccess");
    } catch (error) {
      commit("setUpdateDepartmentError", error.response.data.reason);
    } finally {
      commit("setUpdateDepartmentLoading", false);
      dispatch(FETCH_DEPARTMENTS);
    }
  },
  [DELETE_DEPARTMENT]: async ({ commit, dispatch }, departmentId) => {
    commit("clearDepartment");
    commit("setDeleteDepartmentLoading", true);

    try {
      await Api.deleteDepartment(departmentId);
      commit("setDeleteDepartmentSuccess");
    } catch (error) {
      commit("setDeleteDepartmentError", error.response.data.reason);
    } finally {
      commit("setDeleteDepartmentLoading", false);
      dispatch(FETCH_DEPARTMENTS);
    }
  },
  [CHANGE_DIALOG]: async ({ commit }, dialog) => {
    commit("setDialog", dialog);
  },

  [FETCH_SKILLS]: async ({ commit }, digistaffId) => {
    commit("setSkillLoading", true);

    try {
      let list = await Api.getSkills(digistaffId);
      commit("setSkills", list);
    } catch (err) {
      commit("setSkills", []);
    } finally {
      commit("setSkillLoading", false);
    }
  },
  [TOGGLE_CREATE_SKILL_DIALOG]: ({ commit }) => {
    commit("setCreateSkillDialog");
  },
  [CREATE_SKILL]: async ({ commit, dispatch }, { digistaffId, skill }) => {
    commit("setCreateSkillLoading", true);

    try {
      await Api.createSkill(digistaffId, skill);
      commit("setCreateSkillSuccess");
    } catch (error) {
      commit("setCreateSkillError", error.response.data.reason);
    } finally {
      commit("setCreateSkillLoading", false);
      dispatch(FETCH_SKILLS, digistaffId);
    }
  },
  [TOGGLE_UPDATE_SKILL_DIALOG]: ({ commit }, item = {}) => {
    commit("setUpdateSkillDialog", item);
  },
  [UPDATE_SKILL]: async ({ commit, dispatch }, { digistaffId, id, skill }) => {
    commit("setUpdateSkillLoading", true);

    try {
      await Api.updateSkill(digistaffId, id, skill);
      commit("setUpdateSkillSuccess");
    } catch (error) {
      commit("setUpdateSkillError", error.response.data.reason);
    } finally {
      commit("setUpdateSkillLoading", false);
      dispatch(FETCH_SKILLS, digistaffId);
    }
  },
  [TOGGLE_DELETE_SKILL_DIALOG]: ({ commit }, id = 0) => {
    commit("setDeleteSkillDialog", id);
  },
  [DELETE_SKILL]: async ({ commit, dispatch }, { digistaffId, id }) => {
    commit("setDeleteSkillLoading", true);

    try {
      await Api.deleteSkill(digistaffId, id);
      commit("setDeleteSkillSuccess");
    } catch (error) {
      commit("setDeleteSkillError", error.response.data.reason);
    } finally {
      commit("setDeleteSkillLoading", false);
      dispatch(FETCH_SKILLS, digistaffId);
    }
  },

  [TOGGLE_DEPARTMENT_AUTH_DIALOG]: ({ commit }, skillId) => {
    commit("clearDepartmentAuth");
    commit("setDepartmentAuthDialog", skillId);
  },
  [FETCH_DEPARTMENT_AUTH]: async ({ commit }, skillId) => {
    commit("setDepartmentAuthLoading", true);

    try {
      let departmentAuths = await Api.getDepartmentAuths(skillId);
      commit("setDepartmentAuths", departmentAuths);
    } catch (error) {
      commit("setDepartmentAuths", []);
    } finally {
      commit("setDepartmentAuthLoading", false);
    }
  },
  [CREATE_DEPARTMENT_AUTH]: async ({ commit, dispatch }, auth) => {
    commit("clearDepartmentAuth");
    commit("setCreateDepartmentAuthLoading", true);

    try {
      await Api.createDepartmentAuth(auth);
      commit("setCreateDepartmentAuthSuccess");
    } catch (error) {
      commit("setCreateDepartmentAuthError", error.response.data.reason);
    } finally {
      commit("setCreateDepartmentAuthLoading", false);
      dispatch(FETCH_DEPARTMENT_AUTH, auth.SkillId);
    }
  },
  [DELETE_DEPARTMENT_AUTH]: async (
    { commit, dispatch },
    { skillId, authId }
  ) => {
    commit("clearDepartmentAuth");
    commit("setDeleteDepartmentAuthLoading", true);

    try {
      await Api.deleteDepartmentAuth(authId);
      commit("setDeleteDepartmentAuthSuccess");
    } catch (error) {
      commit("setDeleteDepartmentAuthError", error.response.data.reason);
    } finally {
      commit("setDeleteDepartmentAuthLoading", false);
      dispatch(FETCH_DEPARTMENT_AUTH, skillId);
    }
  },

  [TOGGLE_TITLE_AUTH_DIALOG]: ({ commit }, skillId) => {
    commit("setTitleAuthDialog", skillId);
  },
  [FETCH_TITLE_AUTH]: async ({ commit }, skillId) => {
    commit("setTitleAuthLoading", true);

    try {
      let titleAuths = await Api.getTitleAuths(skillId);
      commit("setTitleAuths", titleAuths);
    } catch (error) {
      commit("setTitleAuths", []);
    } finally {
      commit("setTitleAuthLoading", false);
    }
  },
  [CREATE_TITLE_AUTH]: async ({ commit, dispatch }, auth) => {
    commit("setCreateTitleAuthLoading", true);

    try {
      await Api.createTitleAuth(auth);
      commit("setCreateTitleAuthSuccess");
    } catch (error) {
      commit("setCreateTitleAuthError", error.response.data.reason);
    } finally {
      commit("setCreateTitleAuthLoading", false);
      dispatch(FETCH_TITLE_AUTH, auth.SkillId);
    }
  },
  [DELETE_TITLE_AUTH]: async ({ commit, dispatch }, { skillId, authId }) => {
    commit("setDeleteTitleAuthLoading", true);

    try {
      await Api.deleteTitleAuth(authId);
      commit("setDeleteTitleAuthSuccess");
    } catch (error) {
      commit("setDeleteTitleAuthError", error.response.data.reason);
    } finally {
      commit("setDeleteTitleAuthLoading", false);
      dispatch(FETCH_TITLE_AUTH, skillId);
    }
  },

  [TOGGLE_USER_AUTH_DIALOG]: ({ commit }, skillId) => {
    commit("setUserAuthDialog", skillId);
  },
  [FETCH_USER_AUTH]: async ({ commit }, skillId) => {
    commit("setUserAuthLoading", true);

    try {
      let userAuths = await Api.getUserAuths(skillId);
      commit("setUserAuths", userAuths);
    } catch (error) {
      commit("setUserAuths", []);
    } finally {
      commit("setUserAuthLoading", false);
    }
  },
  [CREATE_USER_AUTH]: async ({ commit, dispatch }, auth) => {
    commit("setCreateUserAuthLoading", true);

    try {
      await Api.createUserAuth(auth);
      commit("setCreateUserAuthSuccess");
    } catch (error) {
      commit("setCreateUserAuthError", error.response.data.reason);
    } finally {
      commit("setCreateUserAuthLoading", false);
      dispatch(FETCH_USER_AUTH, auth.SkillId);
    }
  },
  [TOGGLE_USER_AUTH]: async (
    { commit, dispatch },
    { authId, skillId, auth }
  ) => {
    commit("setToggleUserAuthLoading", true);

    try {
      await Api.toggleUserAuth(authId, auth);
      commit("setToggleUserAuthSuccess");
    } catch (error) {
      commit("setToggleUserAuthError", error.response.data.reason);
    } finally {
      commit("setToggleUserAuthLoading", false);
      dispatch(FETCH_USER_AUTH, skillId);
    }
  },
  [DELETE_USER_AUTH]: async ({ commit, dispatch }, { skillId, authId }) => {
    commit("setDeleteUserAuthLoading", true);

    try {
      await Api.deleteUserAuth(authId);
      commit("setDeleteUserAuthSuccess");
    } catch (error) {
      commit("setDeleteUserAuthError", error.response.data.reason);
    } finally {
      commit("setDeleteUserAuthLoading", false);
      dispatch(FETCH_USER_AUTH, skillId);
    }
  },

  [TOGGLE_TITLE_DIALOG]: ({ commit }) => {
    commit("setTitleDialog");
  },
  [FETCH_TITLES]: async ({ commit }) => {
    commit("setTitleLoading", true);

    try {
      let titles = await Api.getTitles();
      commit("setTitles", titles);
    } catch (error) {
      commit("setTitles", []);
    } finally {
      commit("setTitleLoading", false);
    }
  },
  [CREATE_TITLE]: async ({ commit, dispatch }, title) => {
    commit("clearTitle");
    commit("setCreateTitleLoading", true);

    try {
      await Api.createTitle(title);
      commit("setCreateTitleSuccess");
    } catch (error) {
      commit("setCreateTitleError", error.response.data.reason);
    } finally {
      commit("setCreateTitleLoading", false);
      dispatch(FETCH_TITLES);
    }
  },
  [DELETE_TITLE]: async ({ commit, dispatch }, titleId) => {
    commit("clearTitle");
    commit("setDeleteTitleLoading", true);

    try {
      await Api.deleteTitle(titleId);
      commit("setDeleteTitleSuccess");
    } catch (error) {
      commit("setDeleteTitleError", error.response.data.reason);
    } finally {
      commit("setDeleteTitleLoading", false);
      dispatch(FETCH_TITLES);
    }
  },
};

const mutations = {
  setDigistaffLoading: (state, isLoading) =>
    (state.digistaffIsLoading = isLoading),
  setDigistaffs: (state, digistaff) => (state.digistaff = digistaff),

  setCreateDigistaffDialog: (state) => {
    state.createDigistaff.dialog = !state.createDigistaff.dialog;
    state.createDigistaff.isLoading = false;
    state.createDigistaff.success = false;
    state.createDigistaff.error = "";
  },
  setCreateDigistaffLoading: (state, isLoading) =>
    (state.createDigistaff.isLoading = isLoading),
  setCreateDigistaffSuccess: (state) => (state.createDigistaff.success = true),
  setCreateDigistaffError: (state, error) =>
    (state.createDigistaff.error = error),

  setUpdateDigistaffDialog: (state, item) => {
    state.updateDigistaff.item = item;

    state.updateDigistaff.dialog = !state.updateDigistaff.dialog;
    state.updateDigistaff.isLoading = false;
    state.updateDigistaff.success = false;
    state.updateDigistaff.error = "";
  },
  setUpdateDigistaffDialogById: (state, id) => {
    state.updateDigistaff.item = state.digistaffs.find((digistaff) => {
      return digistaff.Id === id;
    });
  },
  setUpdateDigistaffLoading: (state, isLoading) =>
    (state.updateDigistaff.isLoading = isLoading),
  setUpdateDigistaffSuccess: (state) => (state.updateDigistaff.success = true),
  setUpdateDigistaffError: (state, error) =>
    (state.updateDigistaff.error = error),

  setDeleteDigistaffDialog: (state, id) => {
    state.deleteDigistaff.id = id;
    state.deleteDigistaff.dialog = !state.deleteDigistaff.dialog;
    state.deleteDigistaff.isLoading = false;
    state.deleteDigistaff.success = false;
    state.deleteDigistaff.error = "";
  },
  setDeleteDigistaffLoading: (state, isLoading) =>
    (state.deleteDigistaff.isLoading = isLoading),
  setDeleteDigistaffSuccess: (state) => (state.deleteDigistaff.success = true),
  setDeleteDigistaffError: (state, error) =>
    (state.deleteDigistaff.error = error),

  setDepartmentLoading: (state, isLoading) =>
    (state.departmentIsLoading = isLoading),
  setDepartments: (state, departments) => (state.departments = departments),
  setDepartmentDialog: (state) =>
    (state.departmentDialog = !state.departmentDialog),

  setCreateDepartmentLoading: (state, isLoading) =>
    (state.createDepartment.isLoading = isLoading),
  setCreateDepartmentSuccess: (state) =>
    (state.createDepartment.success = true),
  setCreateDepartmentError: (state, error) =>
    (state.createDepartment.error = error),

  setUpdateDepartmentLoading: (state, isLoading) =>
    (state.updateDepartment.isLoading = isLoading),
  setUpdateDepartmentSuccess: (state) =>
    (state.updateDepartment.success = true),
  setUpdateDepartmentError: (state, error) =>
    (state.updateDepartment.error = error),

  setDeleteDepartmentLoading: (state, isLoading) =>
    (state.deleteDepartment.isLoading = isLoading),
  setDeleteDepartmentSuccess: (state) =>
    (state.deleteDepartment.success = true),
  setDeleteDepartmentError: (state, error) =>
    (state.deleteDepartment.error = error),

  setDialog: (state, dialog) => (state.dialog = dialog),

  clearDepartment: (state) => {
    state.createDepartment.success = false;
    state.createDepartment.error = "";
    state.updateDepartment.success = false;
    state.updateDepartment.error = "";
    state.deleteDepartment.success = false;
    state.deleteDepartment.error = "";
  },

  setSkillLoading: (state, isLoading) => (state.skillIsLoading = isLoading),
  setSkills: (state, skills) => (state.skills = skills),

  setCreateSkillDialog: (state) => {
    state.createSkill.dialog = !state.createSkill.dialog;
    state.createSkill.isLoading = false;
    state.createSkill.success = false;
    state.createSkill.error = "";
  },
  setCreateSkillLoading: (state, isLoading) =>
    (state.createSkill.isLoading = isLoading),
  setCreateSkillSuccess: (state) => (state.createSkill.success = true),
  setCreateSkillError: (state, error) => (state.createSkill.error = error),

  setUpdateSkillDialog: (state, item) => {
    state.updateSkill.item = item;

    state.updateSkill.dialog = !state.updateSkill.dialog;
    state.updateSkill.isLoading = false;
    state.updateSkill.success = false;
    state.updateSkill.error = "";
  },
  setUpdateSkillLoading: (state, isLoading) =>
    (state.updateSkill.isLoading = isLoading),
  setUpdateSkillSuccess: (state) => (state.updateSkill.success = true),
  setUpdateSkillError: (state, error) => (state.updateSkill.error = error),

  setDeleteSkillDialog: (state, id) => {
    state.deleteSkill.id = id;
    state.deleteSkill.dialog = !state.deleteSkill.dialog;
    state.deleteSkill.isLoading = false;
    state.deleteSkill.success = false;
    state.deleteSkill.error = "";
  },
  setDeleteSkillLoading: (state, isLoading) =>
    (state.deleteSkill.isLoading = isLoading),
  setDeleteSkillSuccess: (state) => (state.deleteSkill.success = true),
  setDeleteSkillError: (state, error) => (state.deleteSkill.error = error),

  setDepartmentAuthLoading: (state, isLoading) =>
    (state.departmentAuthIsLoading = isLoading),
  setDepartmentAuths: (state, departmentAuths) =>
    (state.departmentAuths = departmentAuths),
  setDepartmentAuthDialog: (state, skillId) => {
    state.departmentAuthDialog = !state.departmentAuthDialog;
    state.departmentAuthSkillId = skillId ? skillId : "";
  },

  setCreateDepartmentAuthLoading: (state, isLoading) =>
    (state.createDepartmentAuth.isLoading = isLoading),
  setCreateDepartmentAuthSuccess: (state) =>
    (state.createDepartmentAuth.success = true),
  setCreateDepartmentAuthError: (state, error) =>
    (state.createDepartmentAuth.error = error),

  setDeleteDepartmentAuthLoading: (state, isLoading) =>
    (state.deleteDepartmentAuth.isLoading = isLoading),
  setDeleteDepartmentAuthSuccess: (state) =>
    (state.deleteDepartmentAuth.success = true),
  setDeleteDepartmentAuthError: (state, error) =>
    (state.deleteDepartmentAuth.error = error),

  clearDepartmentAuth: (state) => {
    state.createDepartmentAuth.success = false;
    state.createDepartmentAuth.error = "";
    state.deleteDepartmentAuth.success = false;
    state.deleteDepartmentAuth.error = "";
  },

  setTitleAuthLoading: (state, isLoading) =>
    (state.titleAuthIsLoading = isLoading),
  setTitleAuths: (state, titleAuths) => (state.titleAuths = titleAuths),
  setTitleAuthDialog: (state, skillId) => {
    state.titleAuthDialog = !state.titleAuthDialog;
    state.titleAuthSkillId = skillId ? skillId : "";
  },

  setCreateTitleAuthLoading: (state, isLoading) =>
    (state.createTitleAuth.isLoading = isLoading),
  setCreateTitleAuthSuccess: (state) => (state.createTitleAuth.success = true),
  setCreateTitleAuthError: (state, error) =>
    (state.createTitleAuth.error = error),

  setDeleteTitleAuthLoading: (state, isLoading) =>
    (state.deleteTitleAuth.isLoading = isLoading),
  setDeleteTitleAuthSuccess: (state) => (state.deleteTitleAuth.success = true),
  setDeleteTitleAuthError: (state, error) =>
    (state.deleteTitleAuth.error = error),

  setUserAuthLoading: (state, isLoading) =>
    (state.userAuthIsLoading = isLoading),
  setUserAuths: (state, userAuths) => (state.userAuths = userAuths),
  setUserAuthDialog: (state, skillId) => {
    state.userAuthDialog = !state.userAuthDialog;
    state.userAuthSkillId = skillId ? skillId : "";
  },

  setCreateUserAuthLoading: (state, isLoading) =>
    (state.createUserAuth.isLoading = isLoading),
  setCreateUserAuthSuccess: (state) => (state.createUserAuth.success = true),
  setCreateUserAuthError: (state, error) =>
    (state.createUserAuth.error = error),

  setDeleteUserAuthLoading: (state, isLoading) =>
    (state.deleteUserAuth.isLoading = isLoading),
  setDeleteUserAuthSuccess: (state) => (state.deleteUserAuth.success = true),
  setDeleteUserAuthError: (state, error) =>
    (state.deleteUserAuth.error = error),

  setToggleUserAuthLoading: (state, isLoading) =>
    (state.toggleUserAuth.isLoading = isLoading),
  setToggleUserAuthSuccess: (state) => (state.toggleUserAuth.success = true),
  setToggleUserAuthError: (state, error) =>
    (state.toggleUserAuth.error = error),

  setTitleLoading: (state, isLoading) => (state.titleIsLoading = isLoading),
  setTitles: (state, titles) => (state.titles = titles),
  setTitleDialog: (state) => (state.titleDialog = !state.titleDialog),

  setCreateTitleLoading: (state, isLoading) =>
    (state.createTitle.isLoading = isLoading),
  setCreateTitleSuccess: (state) => (state.createTitle.success = true),
  setCreateTitleError: (state, error) => (state.createTitleerror = error),

  setDeleteTitleLoading: (state, isLoading) =>
    (state.deleteTitle.isLoading = isLoading),
  setDeleteTitleSuccess: (state) => (state.deleteTitle.success = true),
  setDeleteTitleError: (state, error) => (state.deleteTitle.error = error),

  clearTitle: (state) => {
    state.createTitle.success = false;
    state.createTitle.error = "";
    state.deleteTitle.success = false;
    state.deleteTitle.error = "";
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
