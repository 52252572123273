

export function readFileAsText(file) {

    return new Promise(function (resolve, reject) {

        const reader = new FileReader();
        reader.addEventListener("load", (event) => {
            resolve(event.target.result);
        });
        reader.addEventListener('error', () => {
            reject(new Error(`Error occurred reading file`));
        });
        reader.readAsText(file);

    })

}