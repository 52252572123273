<template>
  <div>
    <div v-for="(property, index) in data" :key="`binary-property-` + index">
      <v-card
        class="mt-5"
        v-for="([name, binary], index) in Object.entries(property)"
        :key="`binary-` + index"
        :elevation="3"
      >
        <v-card-title>
          <v-chip class="ma-2" color="primary" label text-color="white">
            <v-icon left> mdi-label </v-icon>
            {{ name }}
          </v-chip>
          {{ binary.fileName }}
        </v-card-title>
        <v-card-text>
          {{ binary.mimeType }}
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  props: ["data"],
  mounted() {
    console.log("binary", this.data);
  },
};
</script>
