<template>
  <v-dialog class="pa-0" dense v-model="show" max-width="800px" persistent>
    <v-card class="pa-0">
      <v-form class="pa-0" ref="form" v-model="valid">
        <v-alert v-show="!!error || !!fileError" text prominent type="error" icon="mdi-alert-circle">{{ fileError || error }}</v-alert>
        <v-alert v-show="!!success" text prominent type="success" icon="mdi-cloud-check">Succesfully Created</v-alert>
        <v-card-text>
          <v-container class="text-center" v-if="loading">
            <v-progress-circular :size="70" :width="7" color="purple" indeterminate></v-progress-circular>
            <p>This process may take a few minutes</p>
          </v-container>
          <v-container v-else>
            <v-tabs color="primary" v-model="outerTab">
              <v-tabs-slider></v-tabs-slider>

              <v-tab href="#script"> <v-icon>mdi-bash</v-icon>Script </v-tab>

              <v-tab href="#env"> <v-icon>mdi-console</v-icon>Environments </v-tab>

              <v-tab href="#attendendEnv"> <v-icon>mdi-console</v-icon>Attendend Environments </v-tab>

              <v-tab-item :eager="true" value="script">
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <p v-html="plugin && plugin.HtmlDescription" />
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="editedItem.Name"
                      :disabled="!!success"
                      name="Name"
                      autofocus
                      label="Script Name"
                      maxlength="50"
                      counter
                      required
                      :rules="scriptNameRules"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="12">
                    <v-text-field
                      v-model="editedItem.Description"
                      :disabled="!!success"
                      name="Description"
                      label="Description"
                      maxlength="200"
                      counter
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="12">
                    <v-tabs v-model="tab">
                      <v-tabs-slider></v-tabs-slider>

                      <v-tab href="#cmd" v-if="showCmd"> <v-icon>mdi-console</v-icon>Batch (Windows) </v-tab>

                      <v-tab href="#bash" v-if="showBash"> <v-icon>mdi-bash</v-icon>Bash (Linux , Mac) </v-tab>

                      <v-tab href="#kill_cmd" v-if="showKillCmd"> <v-icon>mdi-console</v-icon>Batch (Kill) </v-tab>

                      <v-tab href="#kill_bash" v-if="showKillBash"> <v-icon>mdi-bash</v-icon>Bash (Kill) </v-tab>

                      <v-tab-item :eager="true" value="cmd">
                        <v-card flat tile>
                          <v-textarea
                            v-model="editedItem.CmdScript"
                            :disabled="!!success"
                            name="CmdScript"
                            label="Batch Command Line"
                            maxlength="2000"
                            counter
                            style="font-family: 'IBM Plex Mono', monospace;"
                            required
                            :rules="CmdCommandLineRules"
                          ></v-textarea>
                        </v-card>
                      </v-tab-item>
                      <v-tab-item :eager="true" value="bash">
                        <v-card flat tile>
                          <v-textarea
                            v-model="editedItem.BashScript"
                            :disabled="!!success"
                            name="BashScript"
                            label="Bash Command Line"
                            maxlength="2000"
                            counter
                            required
                            style="font-family: 'IBM Plex Mono', monospace;"
                            :rules="BashCommandLineRules"
                          ></v-textarea>
                        </v-card>
                      </v-tab-item>
                      <v-tab-item :eager="true" value="kill_cmd">
                        <v-card flat tile>
                          <v-textarea
                            v-model="editedItem.KillCmdScript"
                            :disabled="!!success"
                            name="KillCmdScript"
                            label="Cmd Command Line ( Kill )"
                            maxlength="2000"
                            counter
                            required
                            style="font-family: 'IBM Plex Mono', monospace;"
                            :rules="CmdCommandLineRules"
                          ></v-textarea>
                        </v-card>
                      </v-tab-item>
                      <v-tab-item :eager="true" value="kill_bash">
                        <v-card flat tile>
                          <v-textarea
                            v-model="editedItem.KillBashScript"
                            :disabled="!!success"
                            name="KillBashScript"
                            label="Bash Command Line ( Kill )"
                            maxlength="2000"
                            counter
                            required
                            style="font-family: 'IBM Plex Mono', monospace;"
                            :rules="BashCommandLineRules"
                          ></v-textarea>
                        </v-card>
                      </v-tab-item>
                    </v-tabs>
                  </v-col>

                  <v-col cols="12" sm="6" md="12">
                    <v-text-field name="Timeout" type="number" v-model="editedItem.Timeout" label="Timeout (sec) (Leave Blank To Make Infinite)"></v-text-field>
                  </v-col>

                  <v-row>
                    <v-col cols="12" sm="2" md="4" v-if="editedItem.Timeout">
                      <v-checkbox name="RunKillScript" v-model="editedItem.RunKillScript" label="Run kill script on timeout"></v-checkbox>
                    </v-col>

                    <v-col cols="12" :sm="editedItem.Timeout ? 2 : 3" :md="editedItem.Timeout ? 4 : 6">
                      <v-checkbox name="Rdp" v-model="editedItem.Rdp" label="Rdp"></v-checkbox>
                    </v-col>

                    <v-col cols="12" :sm="editedItem.Timeout ? 2 : 3" :md="editedItem.Timeout ? 4 : 6">
                      <v-checkbox name="Queue" v-model="editedItem.Queue" label="Queue"></v-checkbox>
                    </v-col>
                  </v-row>

                  <v-col cols="12" sm="6" md="12">
                    <v-file-input
                      v-if="!hasFileInEnvironment"
                      accept="application/zip"
                      :disabled="!!success"
                      name="File"
                      :show-size="true"
                      counter
                      label="File input"
                      required
                      :rules="filesRules"
                    ></v-file-input>
                    <p v-if="isPlugin && plugin.FileDescription" class="caption">
                      {{ plugin.FileDescription }}
                    </p>
                  </v-col>
                </v-row>
              </v-tab-item>

              <v-tab-item :eager="true" value="env">
                <vue-form-json-schema class="py-5" v-if="schema" v-model="pluginEnvironmentData" :schema="schema" :ui-schema="uiSchema"></vue-form-json-schema>

                <EnvironmentForm
                  @edit="isEnvironmentEdited = true"
                  @cancel="isEnvironmentEdited = false"
                  @save="isEnvironmentEdited = false"
                  :disabled="isEnvironmentEdited"
                  v-for="key in environmentKeys"
                  :key="key"
                  :envKey="key"
                  :environment="editedItem.Environment"
                />

                <EnvironmentForm :disabled="isEnvironmentEdited" :environment="editedItem.Environment" />
              </v-tab-item>

              <v-tab-item value="attendendEnv" :eager="true">
                <AttendedEnvironmentForm
                  :disableFile="disableFile"
                  @remove="editedItem.AttendedEnvironment.splice(index, 1)"
                  @edit="() => (isAttendedEditing = true)"
                  @cancel="() => (isAttendedEditing = false)"
                  @save="() => (isAttendedEditing = false)"
                  v-for="(arg, index) in editedItem.AttendedEnvironment"
                  :key="arg.key + arg.type"
                  :id="`attendedEnv_${index}`"
                  v-model="editedItem.AttendedEnvironment[index]"
                  :disabled="isAttendedEditing"
                />

                <AttendedEnvironmentForm :disabled="isAttendedEditing" :disableFile="disableFile" @save="(env) => editedItem.AttendedEnvironment.push(env)" />
              </v-tab-item>
            </v-tabs>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed v-show="!loading && !success" color="primary" @click="save">Save</v-btn>
          <v-btn
            color="grey darken-1"
            text
            @click.stop="
              () => {
                if (success) {
                  $router.push('/scripts');
                } else {
                  show = false;
                }
              }
            "
            >{{ success ? "Close" : "Cancel" }}</v-btn
          >
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import Api from "../api";
import { mapActions, mapState } from "vuex";
import { OPEN_CREATE_DIALOG, CREATE_SCRIPT } from "../store/modules/script";
import environmentConventer from "../environmentConverter";
import AttendedEnvironmentForm from "./AttendedEnvironmentForm";
import EnvironmentForm from "./EnvironmentForm";

import { checkLiterals } from "../environmentCheck";
export default {
  props: ["buttonText", "plugin", "showActivator", "value"],
  components: {
    AttendedEnvironmentForm,
    EnvironmentForm,
  },
  computed: {
    ...mapState("script", {
      loading: (state) => state.createLoading,
      error: (state) => state.createError,
      success: (state) => state.createSuccess,
    }),
    hasFileInEnvironment() {
      if (this.plugin) {
        return this.plugin.EnvironmentSchema.find((v) => v.type == "file");
      }
      return false;
    },
    environmentKeys() {
      return Object.keys(this.editedItem.Environment);
    },
    showCmd() {
      if (this.plugin && !this.plugin.Platforms.find((v) => v == "windows")) {
        return false;
      }
      return true;
    },
    showBash() {
      if (this.plugin) {
        for (const platform of this.plugin.Platforms) {
          if (platform == "linux" || platform == "mac") {
            return true;
          }
        }
        return false;
      }
      return true;
    },
    showKillBash() {
      return this.showBash && this.editedItem.RunKillScript;
    },
    showKillCmd() {
      return this.showCmd && this.editedItem.RunKillScript;
    },
    show: {
      set(value) {
        this.$emit("input", value);
      },
      get() {
        return this.value;
      },
    },
    disableFile() {
      return !!this.editedItem.AttendedEnvironment.find((v) => v.type == "file");
    },
  },
  async created() {
    this.fileSize = await Api.getPlanScriptSize();
    this.filesRules = [
      (v) => {
        if (v) {
          if (this.fileSize > 0) {
            if (v.size > this.fileSize * 1048576) {
              return `Maximum ${this.fileSize} MB`;
            }
          }
        }
      },
    ];
  },
  watch: {
    value(val) {
      if (val) {
        this.outerTab = 0;
        this.tab = 0;
        this.openDialog();
        this.editedItem = {
          Name: "",
          Description: "",
          BashScript: "",
          CmdScript: "",
          File: "",
          Environment: {},
          AttendedEnvironment: [],
          Rdp: false,
          Queue: false,
        };
        this.isPlugin = false;

        if (this.plugin) {
          this.isPlugin = true;
          this.implementPlugin();
        } else {
          this.isPlugin = false;
          this.schema = null;
          this.uiSchema = null;
        }
      }
    },
  },
  methods: {
    implementPlugin() {
      if (this.plugin.EnvironmentSchema) {
        let { schema, uiSchema } = environmentConventer(this.plugin.EnvironmentSchema);
        this.schema = schema;
        this.uiSchema = uiSchema;
      }
      this.editedItem.Name = this.plugin.Name;
      this.editedItem.Description = this.plugin.Description;
      this.editedItem.BashScript = this.plugin.BashScript;
      this.editedItem.KillBashScript = this.plugin.KillBashScript;
      this.editedItem.CmdScript = this.plugin.CmdScript;
      this.editedItem.KillCmdScript = this.plugin.KillCmdScript;
      this.editedItem.AttendedEnvironment = this.plugin.AttendedEnvironmentSchema;
      this.editedItem.Timeout = this.plugin.Timeout;
      this.editedItem.Queue = this.plugin.Queue;
    },
    async save() {
      this.fileError = "";
      if (!this.$refs.form.validate()) {
        return;
      }
      let elem = this.$refs.form.$el;
      let data = new FormData(elem);

      data.set("RunKillScript", this.editedItem.RunKillScript || false);
      data.set("Rdp", this.editedItem.Rdp || false);
      data.set("Queue", this.editedItem.Queue || false);

      if (!this.editedItem.Timeout) {
        data.delete("Timeout");
      }

      if (data.has("File")) {
        let file = data.get("File");
        if (file.size && this.fileSize > 0) {
          if (file.size > this.fileSize * 1048576) {
            this.fileError = `Maximum ${this.fileSize} MB`;
            return;
          }
        }
      }

      if (this.isPlugin) {
        data.append("PluginId", this.plugin.Id);
      }
      data.append(
        "Environment",
        JSON.stringify({
          ...this.pluginEnvironmentData,
          ...this.editedItem.Environment,
        })
      );
      data.append("AttendedEnvironment", JSON.stringify(this.editedItem.AttendedEnvironment));
      await this.createScript(data);
    },
    ...mapActions("script", {
      openDialog: OPEN_CREATE_DIALOG,
      createScript: CREATE_SCRIPT,
    }),
  },
  data: () => ({
    pluginEnvironmentData: {},
    isEnvironmentEdited: false,
    isAttendedEditing: false,
    editName: false,
    editDescription: false,
    tab: "cmd",
    outerTab: "",
    fileSize: 0,
    fileError: "",
    valid: false,
    scriptNameRules: [(v) => !!v || "Script name is required"],
    keyRules: [(v) => !!v || "key must not be null", (v) => checkLiterals(v) || "key can only contains letters and underscores"],
    filesRules: [
      (v) => {
        if (v) {
          if (this.fileSize > 0) {
            if (v.size > this.fileSize * 1048576) {
              return `Maximum ${this.fileSize} MB`;
            }
          }
        }
      },
    ],
    uiSchema: [],
    schema: {},
    isPlugin: false,
    CmdCommandLineRules: [(v) => (v || "").length <= 2000 || `A maximum of 2000 characters is allowed`],
    BashCommandLineRules: [(v) => (v || "").length <= 2000 || `A maximum of 2000 characters is allowed`],
    editedItem: {
      Name: "",
      Description: "",
      BashScript: "",
      CmdScript: "",
      KillCmdScript: "",
      KillBashScript: "",
      File: "",
      Environment: {},
      AttendedEnvironment: [],
      RunKillScript: false,
      Timeout: null,
      Rdp: false,
      Queue: false,
    },
  }),
};
</script>
