import Api from "../../api";
export const UPDATE_AD = "UPDATE_AD";
export const FETCH_AD = "FETCH_AD";

const actions = {
  [UPDATE_AD]: async ({ commit }, ad) => {
    commit("setIsLoading", true);
    commit("setError", "");

    try {
      let data = await Api.updateAd(ad);
      commit("setSuccess", true);
      commit("setAd", data?.Value);
    } catch (err) {
      commit("setError", err.response.data.reason);
    }
    commit("setIsLoading", false);
  },
  [FETCH_AD]: async ({ commit }) => {
    commit("setIsLoading", true);
    try {
      let ad = await Api.getAd();
      commit("setAd", ad?.Value);
    } catch (err) {
      commit("setError", err.response.data.reason);
    }
    commit("setIsLoading", false);
  },
};

const state = {
  ad: {},
  isLoading: false,
  error: "",
  success: false,
};

const mutations = {
  setIsLoading: (state, isLoading) => (state.isLoading = isLoading),
  setAd: (state, ad) => (state.ad = ad),
  setError: (state, error) => (state.error = error),
  setSuccess: (state, success) => (state.success = success),
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
