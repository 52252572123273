<template>
    <v-tooltip color="transparent" top>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">mdi-information-outline</v-icon>
                </template>
                <v-card width="300">
                  <v-card-text> {{definition}}</v-card-text>
                </v-card>
              </v-tooltip>    
</template>

<script>
export default {
    props: ['name','definition']
}
</script>
