import DeferredPromise from "../../hype/helpers/deferredPromise";

import { convert } from "../../hype/helpers/propertyConverter";

import { getDefaultMutations } from "../../hype/helpers/vuex";

const baseState = {
  dialog: false,
  alertDialog: false,
  alertOptions: {},
  confirmDialog: false,
  confirmDialogOptions: {},
  deferredPromise: null,
  schema: {},
  uiSchema: {},
  options: {},
  initialFormData: {},
};
/** @type {import('vuex').Module} */
const formDialog = {
  namespaced: true,
  state: () => baseState,
  mutations: {
    ...getDefaultMutations(baseState),
  },
  actions: {
    save({ state }, payload) {
      state.dialog = false;
      state.deferredPromise.resolve({ status: true, data: payload });
    },
    cancel({ state, commit }) {
      commit("setDialog", false);
      state.deferredPromise.resolve({ status: false });
    },
    open({ state, commit }, payload) {
      state.deferredPromise = new DeferredPromise();
      let { properties, initialFormData, ...options } = payload;
      const { schema, uiSchema } = convert(properties, {
        hasExpressionEditor: false,
      });
      commit("setSchema", schema);
      commit("setUiSchema", uiSchema);
      commit("setOptions", options);
      commit("setInitialFormData", initialFormData);
      commit("setDialog", true);
      return state.deferredPromise.promise;
    },
    openAlert({ commit, state }, payload) {
      commit("setAlertOptions", payload);
      commit("setAlertDialog", true);
      commit("setDeferredPromise", new DeferredPromise());
      setTimeout(() => {
        commit("setAlertDialog", false);
        state.deferredPromise.resolve();
      }, (payload.duration ?? 1.5) * 1000);
      return state.deferredPromise.promise;
    },
    closeAlertDialog({ state }) {
      state.alertDialog = false;
    },
    openConfirmDialog({ state }, payload) {
      state.confirmDialogOptions = payload;
      state.confirmDialog = true;
      state.deferredPromise = new DeferredPromise();
      return state.deferredPromise.promise;
    },
    confirm({ state }) {
      state.confirmDialog = false;
      state.deferredPromise.resolve({ status: true });
    },
    cancelConfirm({ state }) {
      state.confirmDialog = false;
      state.deferredPromise.resolve({ status: false });
    },
  },
};

export default formDialog;
