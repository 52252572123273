<template>
  <v-dialog v-model="deleteDialog" max-width="500px" persistent>
    <v-card>
      <v-form ref="form" v-model="valid" :lazy-validation="false">
        <v-card-title>
          <span class="headline">Delete Agent</span>
        </v-card-title>
        <v-alert v-show="!!errorMessage" text prominent type="error" icon="mdi-alert-circle">
          <span v-html="errorMessage" />
        </v-alert>
        <v-alert
          v-show="!!isSuccess"
          text
          prominent
          type="success"
          icon="mdi-cloud-check"
        >Succesfully Deleted</v-alert>
        <v-alert v-show="!!warningMessage" text prominent type="warning" icon="mdi-alert-circle">
          <span v-html="warningMessage" />
        </v-alert>
        <v-card-text>
          <v-container v-if="!isSaving" v-show="!isSuccess">Are you sure to delete this agent ?</v-container>
          <v-container class="text-center" v-else>
            <v-progress-circular :size="70" :width="7" color="purple" indeterminate></v-progress-circular>
          </v-container>
        </v-card-text>

        <v-card-actions v-if="!isSaving">
          <v-spacer></v-spacer>
          <v-btn
            v-show="!isSuccess"
            depressed
            color="primary"
            @click="deleteItem(deletedItem)"
          >Delete</v-btn>
          <v-btn
            color="grey darken-1"
            text
            @click="closeDeleteDialog"
          >{{isSuccess ? "Close" : "Cancel"}}</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from "vuex";
import {
  CLOSE_AGENT_DELETE_DIALOG,
  OPEN_AGENT_DELETE_DIALOG,
  DELETE_AGENT
} from "../store/modules/agent";

export default {
  data: () => ({
    valid: false,
    nameRules: [v => !!v || "Name is required"]
  }),
  computed: {
    ...mapState("agent", {
      deletedItem: state => state.deletedItem,
      isSuccess: state => state.deleteSuccess,
      isSaving: state => state.deleteLoading,
      errorMessage: state => state.deleteError,
      warningMessage: state => state.deleteWarningMessage
    }),
    deleteDialog: {
      get() {
        return this.$store.state.agent.deleteDialog;
      },
      set(val) {
        if (val) this.openDeleteDialog();
        else this.closeDeleteDialog();
      }
    }
  },
  watch: {
    openEdit(val) {
      if (val) {
        //this.onOpened();
      } else {
        this.onClosed();
      }
    }
  },

  methods: {
    ...mapActions("agent", {
      closeDeleteDialog: CLOSE_AGENT_DELETE_DIALOG,
      openDeleteDialog: OPEN_AGENT_DELETE_DIALOG,
      deleteItem: DELETE_AGENT
    })
  }
};
</script>
<style scoped>
.multi-line {
  white-space: pre-line;
}
</style>