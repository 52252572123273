/** @type {import('vuex').Module} */
const dynamicFormStore = {
  state: () => ({
    data: {},
  }),
  mutations: {
    updateDynamicFormData: (state, data) => {
      state.data = data
    },
  },
  namespaced: true,
}

export default dynamicFormStore
