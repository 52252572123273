<template>
  <div>
    <div class="text-center">
      <v-pagination
        v-if="pageCount > 1"
        v-model="page"
        :length="pageCount"
        prev-icon="mdi-menu-left"
        next-icon="mdi-menu-right"
      ></v-pagination>
    </div>
    <tree-view
      :options="{ rootObjectKey: 'result' }"
      :data="currentData"
    ></tree-view>
  </div>
</template>

<script>
import { TreeView } from 'vue-json-tree-view'
export default {
  props: ['data'],
  components: {
    'tree-view': TreeView,
  },
  data: () => ({
    page: 1,
  }),
  computed: {
    pageCount() {
      return Math.ceil(this.data.length / 10)
    },
    dataWithKeys() {
      return { ...this.data }
    },
    currentData() {
      return Object.fromEntries(
        Object.entries(this.dataWithKeys).slice(
          (this.page - 1) * 10,
          this.page * 10
        )
      )
    },
  },
}
</script>
