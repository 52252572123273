<template>
  <div>
    <v-alert
      v-show="error"
      text
      prominent
      type="error"
      icon="mdi-alert-circle"
      >{{ error }}</v-alert
    >
    <v-row>
      <v-col>
        <v-card class="py-5 px-5">
          <h3>{{ name }}</h3>
          <p>
            You can authorize your user in detail on this screen. Define what
            pages the user has privileges on.
          </p>
        </v-card>
      </v-col>
    </v-row>
    <v-expansion-panels v-model="items" v-if="!error" multiple>
      <v-expansion-panel v-for="(page, index) in pages" :key="index">
        <v-expansion-panel-header>{{ page.Name }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <action-authorization
            :attributes="page.AuthorizationAttributes"
          ></action-authorization>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import {
  GET_USER_AUTHORIZATION,
  TOGGLE_VIEW_AUTHORIZATION,
} from "../../store/modules/user";
import ActionAuthorization from "./ActionAuthorization";

export default {
  data: () => ({
    items: [],
  }),
  components: {
    "action-authorization": ActionAuthorization,
  },
  computed: {
    ...mapState("user", {
      name: (state) => state.authorization.name,
      pages: (state) => {
        return state.authorization.pages.filter(
          (page) => page.AuthorizationAttributes.length > 0
        );
      },
      error: (state) => state.authorization.authError,
      selectedItems: (state) => state.authorization.selectedItems,
    }),
  },
  created() {
    let id = this.$route.params.id;
    if (id) {
      this.getUserAuthorization(id);
    }
  },
  methods: {
    ...mapActions("user", {
      getUserAuthorization: GET_USER_AUTHORIZATION,
      toggleViewAuthorization: TOGGLE_VIEW_AUTHORIZATION,
    }),
    setSelectedIcon(index) {
      return this.items.includes(index) ? "toggle_on" : "toggle_off";
    },
    setSelectedColor(index) {
      return this.items.includes(index) ? "green" : "gray";
    },
    setTooltip(page, on) {
      let attribute = page.AuthorizationAttributes.find(
        (authorizationAttribute) => {
          return authorizationAttribute.Action === "View";
        }
      );
      return attribute.Description ? on : "";
    },
    getTooltipText(page) {
      let attribute = page.AuthorizationAttributes.find(
        (authorizationAttribute) => {
          return authorizationAttribute.Action === "View";
        }
      );
      return attribute.Description;
    },
    contentVisibility(page) {
      let filter = page.AuthorizationAttributes.filter(function(el) {
        return el.Action !== "View";
      });
      return filter.length > 0;
    },
    toggleView(pageId) {
      this.toggleViewAuthorization({
        userId: this.$route.params.id,
        pageId,
      });
    },
  },
};
</script>

<style>
.v-icon.v-icon {
  font-size: 17px;
}
</style>
