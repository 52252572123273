export const OPEN_CREATE_DIALOG = "OPEN_CREATE_DIALOG";
export const CLOSE_CREATE_DIALOG = "CLOSE_CREATE_DIALOG";
export const CREATE_SCRIPT = "CREATE_SCRIPT";
export const FETCH_SCRIPTS = "FETCH_SCRIPTS";
export const OPEN_EDIT_SCRIPT_DIALOG = "OPEN_EDIT_SCRIPT_DIALOG";
export const CLOSE_EDIT_SCRIPT_DIALOG = "CLOSE_EDIT_SCRIPT_DIALOG";
export const EDIT_SCRIPT = "EDIT_SCRIPT";
export const OPEN_DELETE_SCRIPT_DIALOG = "OPEN_DELETE_SCRIPT_DIALOG";
export const CLOSE_DELETE_SCRIPT_DIALOG = "CLOSE_DELETE_SCRIPT_DIALOG";
export const DELETE_SCRIPT = "DELETE_SCRIPT";
export const CLEAR_ALL = "CLEAR_ALL";
export const FETCH_SCRIPT_WITH_PAGE = "FETCH_SCRIPT_WITH_PAGE";
export const GET_SCRIPTS = "GET_SCRIPTS";
import Api from "../../api";

const state = {
  scripts: [],
  pageScripts: [],
  totalCount: 0,
  isLoading: false,
  isRefreshing: false,
  fetchError: "",
  deleteDialog: false,
  deleteLoading: false,
  deleteError: "",
  deleteSuccess: false,
  deletedItem: {},
  createDialogOpen: false,
  createLoading: false,
  createError: "",
  createSuccess: false,
  editLoading: false,
  editError: "",
  editSuccess: false,
  editDialog: false,
  deleteWarningMessage: "",
  editItem: {
    Name: "",
    Description: "",
    CmdScript: "",
    BashScript: "",
    File: "",
    Enviroment: {},
    AttendedEnvironment: [],
  },
};

function mutator(name) {
  return function() {
    arguments[0][name] = arguments[1];
  };
}

const getters = {};

const mutations = {
  openCreateDialog(state) {
    state.createDialogOpen = true;
  },
  closeCreateDialog(state) {
    state.createDialogOpen = false;
  },
  setScripts(state, scripts) {
    state.scripts = scripts;
  },
  setLoading(state, status) {
    state.isLoading = status;
  },
  setRefreshing(state, status) {
    state.isRefreshing = status;
  },
  setFetchError(state, message) {
    state.fetchError = message;
  },
  setCreateError(state, message) {
    state.createError = message;
  },
  setCreateLoading(state, status) {
    state.createLoading = status;
  },
  setCreateSuccess(state, status) {
    state.createSuccess = status;
  },
  clearCreate(state) {
    state.createLoading = false;
    state.createSuccess = false;
    state.createError = false;
  },
  setEditLoading: mutator("editLoading"),
  setEditError: mutator("editError"),
  setEditSuccess: mutator("editSuccess"),
  setEditDialog: mutator("editDialog"),
  setEditItem: mutator("editItem"),
  clearEditDialog: (state) => {
    state.editLoading = false;
    state.editSuccess = false;
    state.editError = false;
  },
  setDeleteLoading: mutator("deleteLoading"),
  setDeleteError: mutator("deleteError"),
  setDeleteSuccess: mutator("deleteSuccess"),
  setDeleteDialog: mutator("deleteDialog"),
  setDeletedItem: mutator("deletedItem"),
  clearDeleteDialog: (state) => {
    state.deleteLoading = false;
    state.deleteSuccess = false;
    state.deleteError = false;
  },
  setDeleteWarningMessage: mutator("deleteWarningMessage"),
  setPageScripts: mutator("pageScripts"),
  setTotalCount: mutator("totalCount"),
};

const actions = {
  [CLEAR_ALL]: ({ commit }) => {
    commit("clearCreate");
    commit("clearEditDialog");
  },
  [OPEN_CREATE_DIALOG]: ({ commit }) => {
    commit("clearCreate");
    commit("openCreateDialog");
  },

  [CLOSE_CREATE_DIALOG]: ({ commit }) => {
    commit("closeCreateDialog");
  },

  [FETCH_SCRIPTS]: async ({ commit }, isRefreshing = true) => {
    if (isRefreshing) commit("setRefreshing", true);
    else commit("setLoading", true);

    try {
      let scripts = await Api.listScripts();
      commit("setScripts", scripts);
    } catch (e) {
      commit("setFetchError", e);
    }

    if (isRefreshing) commit("setRefreshing", false);
    else commit("setLoading", false);
  },
  [FETCH_SCRIPT_WITH_PAGE]: async ({ commit }, { options, isRefreshing = true }) => {

    if (isRefreshing) commit("setRefreshing", true);
    else commit("setLoading", true);

    try {
      let result = await Api.listScriptsWithPage(options);
      commit("setTotalCount", result.count);
      commit("setPageScripts", result.rows);
    } catch (e) {
      commit("setFetchError", e);
    }

    if (isRefreshing) commit("setRefreshing", false);
    else commit("setLoading", false);
  },
  [CREATE_SCRIPT]: async ({ commit, dispatch }, formData) => {
    commit("setCreateError", "");
    commit("setCreateLoading", true);
    try {
      await Api.createScript(formData);
      commit("setCreateSuccess", true);
    } catch (e) {
      commit("setCreateError", e.response.data.reason);
    }
    commit("setCreateLoading", false);
    dispatch(FETCH_SCRIPT_WITH_PAGE, {
      options: { itemsPerPage: 10, page: 1 },
      isRefreshing: true,
    });
  },
  [EDIT_SCRIPT]: async ({ commit, dispatch }, script) => {
    commit("setEditError", "");
    commit("setEditLoading", true);
    try {
      await Api.updateScript(script);
      commit("setEditLoading", false);
      commit("setEditSuccess", true);
      dispatch(FETCH_SCRIPT_WITH_PAGE, {
        options: { itemsPerPage: 10, page: 1 },
        isRefreshing: true,
      });
    } catch (e) {
      commit("setEditError", e.response.data.reason);
    }
    commit("setEditLoading", false);
  },
  [OPEN_EDIT_SCRIPT_DIALOG]: async ({ commit }, editItem) => {
    commit("clearEditDialog");
    commit("setEditItem", { ...editItem, Timeout: editItem.Timeout ? editItem.Timeout / 1000 : editItem.Timeout });
    commit("setEditDialog", true);
  },
  [CLOSE_EDIT_SCRIPT_DIALOG]: async ({ commit }) => {
    commit("setEditDialog", false);
  },
  [OPEN_DELETE_SCRIPT_DIALOG]: async ({ commit }, editItem) => {
    commit("clearDeleteDialog");
    commit("setDeletedItem", { ...editItem });
    commit("setDeleteDialog", true);
    commit("setDeleteLoading", true);

    let tasks = await Api.scriptTasks(editItem.Id);
    if (tasks.length && tasks.length > 0) {
      commit(
        "setDeleteWarningMessage",
        `
            This script is registered to following tasks:<ul> 
            ${tasks.map((t) => `<li><strong>${t.Name}</strong></li>`).join("")}</ul>
            If deleted, script will be removed from tasks above
            `
      );
    }

    commit("setDeleteLoading", false);
  },
  [CLOSE_DELETE_SCRIPT_DIALOG]: async ({ commit }) => {
    commit("setDeleteDialog", false);
  },
  [DELETE_SCRIPT]: async ({ commit, dispatch }, script) => {
    commit("setDeleteError", "");
    commit("setDeleteLoading", true);
    try {
      await Api.deleteScript(script.Id);
      commit("setDeleteWarningMessage", "");
      commit("setDeleteLoading", false);
      commit("setDeleteSuccess", true);
      dispatch(FETCH_SCRIPT_WITH_PAGE, {
        options: { itemsPerPage: 10, page: 1 },
        isRefreshing: true,
      });
    } catch (e) {
      commit("setDeleteError", e.response.data.reason);
    }
    commit("setDeleteLoading", false);
  },
  [GET_SCRIPTS]: async () => {
    return Api.listScripts();
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
