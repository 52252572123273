export function deepCopy(obj) {
  return JSON.parse(JSON.stringify(obj))
}

export function updateOrCreate(obj, path, val) {
  let last = path.splice(path.length - 1, 1)[0]
  let temp = obj
  for (const token of path) {
    if (temp[token] === undefined || temp[token] === null) {
      temp[token] = {}
    }
    temp = temp[token]
  }
  temp[last] = val
}
