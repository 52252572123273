import { updateOrCreate } from "../../hype/helpers/object";

/** @type {import('vuex').Module} */
const credentialsStore = {
  namespaced: true,
  state: () => ({
    expressionEditorParameter: "",
    expressionData: { $node: {} },
    currentEditedData: "",
    codeEditor: false,
  }),
  mutations: {
    updateCurrentEditedData(state, data) {
      state.currentEditedData = data;
    },
    updateExpressionEditorParameter(state, { path, value, codeEditor = false }) {
      state.expressionEditorParameter = path;
      state.currentEditedData = value;
      state.codeEditor = codeEditor;
    },
    resetExpressionData(state) {
      let expressionData = { $node: {} };
      state.expressionData = expressionData;
    },
    updateExpressionData(state, editor) {
      let nodes = editor.getNodes();
      let expressionData = state.expressionData;
      for (const node of Object.values(nodes)) {
        //TODO: Allah rizasi icin burayi refaktor et

        let jsonData =
          node.data.executions.length && !node.data.executions[node.data.executions.length - 1].data.error
            ? node.data.executions[node.data.executions.length - 1].data.data.main?.find((output) => !!output[0])?.find((output) => !!output.json)?.json
            : {};

        updateOrCreate(expressionData, ["$node", node.name, "json"], jsonData);
      }
      state.expressionData = expressionData;
    },
    updateContextExpressionData(state, context) {
      for (const [key, value] of Object.entries(context)) {
        if (key.startsWith("node:")) {
          let nodeName = key.slice("node:".length);
          updateOrCreate(state, ["expressionData", "$node", nodeName, "context"], value);
        }
      }
    },
  },
};

export default credentialsStore;
