<template>
  <div>
    <v-card :disabled="disabled" outlined class="ma-2 pa-3" v-if="envKey">
      <v-row>
        <v-col md="10">
          <span class="title">{{ this.envKey }}</span>
          <span class="subtitle ml-2">{{ this.environment[this.envKey] }}</span>
        </v-col>

        <v-col class="ml-auto d-flex justify-center" md="2">
          <v-btn class="ma-3" icon @click="openEdit()">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>

          <v-btn @click="remove()" class="ma-3" icon color="red">
            <v-icon>mdi-trash-can</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
    <v-btn class="my-3" color="primary" v-if="!this.envKey && !disabled && !showForm" @click="openSave"><v-icon>mdi-plus</v-icon>add</v-btn>
    <v-expand-transition>
      <v-card v-if="showForm">
        <v-card-text>
          <v-form ref="form">
            <v-row>
              <v-col cols="4">
                <v-text-field v-model="$key" :rules="keyRules" label="Key"></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field v-model="$value" label="Value" counter maxlength="2000"></v-text-field>
              </v-col>
              <v-spacer />
              <v-col cols="12">
                <v-btn color="primary" @click="save">Save</v-btn>
                <v-btn color="grey" text @click="cancel">Cancel</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-expand-transition>
  </div>
</template>

<script>
import { checkLiterals } from "../environmentCheck";
import Vue from "vue";
export default {
  props: ["envKey", "environment", "disabled"],
  data() {
    return {
      showForm: false,
      keyRules: [(v) => !!v || "Key is required", (v) => checkLiterals(v) || "Key can only contain letters and dashes"],
      $key: "",
      $value: "",
    };
  },
  created() {
    this.setEnvironment();
  },
  methods: {
    setEnvironment() {
      if (this.envKey) {
        this.$key = JSON.parse(JSON.stringify(this.envKey));
        this.$value = JSON.parse(JSON.stringify(this.environment[this.envKey]));
      } else {
        this.$key = "";
        this.$value = "";
      }
    },
    cancel() {
      this.showForm = false;
      this.$emit("cancel");
    },
    save() {
      if (this.$refs.form.validate()) {
        if (this.$key != this.envKey) {
          Vue.delete(this.environment, this.envKey);
        }
        Vue.set(this.environment, this.$key, this.$value);

        this.$emit("save", JSON.parse(JSON.stringify(this.environment)));
        this.showForm = false;
      }
    },
    openEdit() {
      this.$emit("edit", this.envKey);
      this.showForm = true;
      this.setEnvironment();
    },
    openSave() {
      this.showForm = true;
      this.setEnvironment();
    },
    remove() {
      Vue.delete(this.environment, this.envKey);
      this.$emit("remove", this.envKey);
    },
  },
};
</script>
