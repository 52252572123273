<template>
  <div>
    <v-stepper v-model="e6" vertical class="shadow-xl mx-5 my-5 pa-5">
      <router-link to="/alerts">
        <v-btn depressed color="primary" class="mb-5"> <v-icon>arrow_back</v-icon>Go back </v-btn>
      </router-link>
      <h3 class="title">Add alert</h3>
      <v-stepper-step :complete="e6 > 1" step="1" editable>Alert Name</v-stepper-step>
      <v-stepper-content step="1">
        <v-row>
          <v-col cols="12" sm="6" md="3">
            <v-text-field label="Alert Name" maxlength="30" counter v-model="Name" outlined dense></v-text-field>
          </v-col>
        </v-row>
        <v-btn color="primary" depressed class="mr-3 my-3" @click="e6 = 2">Continue</v-btn>
      </v-stepper-content>
      <v-stepper-step :complete="e6 > 2" step="2" editable>Alert for</v-stepper-step>
      <v-stepper-content step="2">
        <v-row>
          <v-col cols="12" sm="6" md="3">
            <v-autocomplete dense v-model="NotificationType" :items="Types" item-text="0" item-value="1" label="Select Type" outlined></v-autocomplete>
          </v-col>
        </v-row>
        <v-row v-if="NotificationType === notificationTypes.TASK">
          <v-col cols="12" sm="6" md="3">
            <v-autocomplete dense v-model="ScriptId" :items="scripts" item-text="Name" item-value="Id" label="Select Script" outlined></v-autocomplete>
            <v-autocomplete dense v-model="AgentIds" :items="agents" item-text="Name" item-value="Id" label="Select Agents" multiple outlined></v-autocomplete>
            <v-autocomplete dense v-model="NotifyOn" :items="jobStatuses" item-text="0" item-value="1" label="Select Job Status" outlined></v-autocomplete>
          </v-col>
        </v-row>
        <v-row v-if="NotificationType === notificationTypes.AGENT">
          <v-col cols="12" sm="6" md="3">
            <v-autocomplete dense v-model="AgentIds" :items="agents" item-text="Name" item-value="Id" label="Select Agents" multiple outlined></v-autocomplete>
          </v-col>
        </v-row>
        <v-btn color="primary" depressed class="mr-3 my-3" @click="e6 = 3">Continue</v-btn>
      </v-stepper-content>
      <v-stepper-step :complete="e6 > 3" step="3" editable>Alert to</v-stepper-step>
      <v-stepper-content step="3" class="my-0 py-0 mx-0 my-0">
        <TaskMailDialog v-model="EmailList" />
        <TaskSlackDialog v-model="SlackWebHookUrl" />
        <TaskSlackDialog v-model="MicrosoftTeamsWebHookUrl" buttonName="Microsoft Teams" />
        <TaskSlackDialog v-model="GoogleChatWebHookUrl" buttonName="Google Chat" />
        <TaskSlackDialog v-model="HypeWebHookUrl" buttonName="Hype" />
        <TaskSlackDialog v-model="WebHookUrl" buttonName="Webhook" />
        <v-card flat outlined class="my-1" v-show="!!EmailList">
          <v-card-text class="pb-1">
            <div>E-Mail Alert</div>
            <div class="text--primary">
              Send an e-mail to {{ EmailList }}
              <v-btn @click="testMail()" small depressed> <v-icon left>play_arrow</v-icon>Test Now </v-btn>
            </div>
          </v-card-text>
          <v-card-actions class="pt-0">
            <v-btn @click="EmailList = ''" text small class="pl-2" color="red white--text" depressed> <v-icon small>delete_outline</v-icon>Delete </v-btn>
          </v-card-actions>
        </v-card>
        <v-card flat outlined class="my-1" v-show="!!SlackWebHookUrl">
          <v-card-text class="pb-1">
            <div>Slack Alert</div>
            <div class="text--primary">
              Send an slack message to
              <a :href="SlackWebHookUrl">WebhookURL</a>
              <v-btn @click="testWebHook(SlackWebHookUrl)" small depressed> <v-icon left>play_arrow</v-icon>Test Now </v-btn>
            </div>
          </v-card-text>
          <v-card-actions class="pt-0">
            <v-btn @click="SlackWebHookUrl = ''" text small class="pl-2" color="red white--text" depressed> <v-icon small>delete_outline</v-icon>Delete </v-btn>
          </v-card-actions>
        </v-card>
        <v-card flat outlined class="my-1" v-show="!!MicrosoftTeamsWebHookUrl">
          <v-card-text class="pb-1">
            <div>Microsoft Teams Alert</div>
            <div class="text--primary">
              Send an message to
              <a :href="MicrosoftTeamsWebHookUrl">Microsoft Teams WebhookURL</a>
              <v-btn @click="testWebHook(MicrosoftTeamsWebHookUrl)" small depressed> <v-icon left>play_arrow</v-icon>Test Now </v-btn>
            </div>
          </v-card-text>
          <v-card-actions class="pt-0">
            <v-btn @click="MicrosoftTeamsWebHookUrl = ''" text small class="pl-2" color="red white--text" depressed>
              <v-icon small>delete_outline</v-icon>Delete
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card flat outlined class="my-1" v-show="!!GoogleChatWebHookUrl">
          <v-card-text class="pb-1">
            <div>Google Chat Alert</div>
            <div class="text--primary">
              Send an message to
              <a :href="GoogleChatWebHookUrl">Google Chat WebhookURL</a>
              <v-btn @click="testWebHook(GoogleChatWebHookUrl)" small depressed> <v-icon left>play_arrow</v-icon>Test Now </v-btn>
            </div>
          </v-card-text>
          <v-card-actions class="pt-0">
            <v-btn @click="GoogleChatWebHookUrl = ''" text small class="pl-2" color="red white--text" depressed> <v-icon small>delete_outline</v-icon>Delete </v-btn>
          </v-card-actions>
        </v-card>
        <v-card flat outlined class="my-1" v-show="!!HypeWebHookUrl">
          <v-card-text class="pb-1">
            <div>Hype Alert</div>
            <div class="text--primary">
              Send an message to
              <a :href="HypeWebHookUrl">Hype WebhookURL</a>
              <v-btn @click="testWebHook(HypeWebHookUrl)" small depressed> <v-icon left>play_arrow</v-icon>Test Now </v-btn>
            </div>
          </v-card-text>
          <v-card-actions class="pt-0">
            <v-btn @click="HypeWebHookUrl = ''" text small class="pl-2" color="red white--text" depressed> <v-icon small>delete_outline</v-icon>Delete </v-btn>
          </v-card-actions>
        </v-card>
        <v-card flat outlined class="my-1" v-show="!!WebHookUrl">
          <v-card-text class="pb-1">
            <div>Hype Alert</div>
            <div class="text--primary">
              Send an message to
              <a :href="WebHookUrl">WebhookURL</a>
              <v-btn @click="testWebHook(WebHookUrl)" small depressed> <v-icon left>play_arrow</v-icon>Test Now </v-btn>
            </div>
          </v-card-text>
          <v-card-actions class="pt-0">
            <v-btn @click="WebHookUrl = ''" text small class="pl-2" color="red white--text" depressed> <v-icon small>delete_outline</v-icon>Delete </v-btn>
          </v-card-actions>
        </v-card>
        <br />
        <v-btn
          class="my-5 mx-1"
          @click="save"
          v-show="!!EmailList || !!SlackWebHookUrl || !!GoogleChatWebHookUrl || !!MicrosoftTeamsWebHookUrl || !!HypeWebHookUrl || !!WebHookUrl"
          depressed
          color="primary"
          >SAVE ALERT</v-btn
        >
        <router-link to="/alerts" style="text-decoration:none;">
          <v-btn class="my-5 mx-1" depressed>CANCEL</v-btn>
        </router-link>
      </v-stepper-content>
      <ConfirmDialog />
    </v-stepper>
  </div>
</template>

<script>
import TaskMailDialog from "./TaskMailDialog";
import TaskSlackDialog from "./TaskSlackDialog";
import ConfirmDialog from "../ConfirmDialog";
import { mapState, mapActions } from "vuex";
import { FETCH_SCRIPTS } from "../../store/modules/script";
import { FETCH_AGENTS } from "../../store/modules/agent";
import { OPEN_DIALOG } from "../../store/modules/confirm";
import { NotificationTypes, NotificationNotifyOnStatuses } from "../../store/modules/alert";
import Api from "../../api";

export default {
  components: { TaskMailDialog, TaskSlackDialog, ConfirmDialog },
  computed: {
    ...mapState("script", {
      scripts: (state) => state.scripts,
    }),
    ...mapState("agent", {
      agents: (state) => state.agents,
    }),
  },
  methods: {
    ...mapActions("script", {
      fetchScripts: FETCH_SCRIPTS,
    }),
    ...mapActions("agent", {
      fetchAgents: FETCH_AGENTS,
    }),
    ...mapActions("confirm", {
      confirm: OPEN_DIALOG,
    }),
    async testMail() {
      await Api.sendTestMail(this.EmailList);
      this.confirm({
        message: "A test mail has been sent.",
        title: "information",
        showCancelButton: false,
      });
    },
    async testWebHook(url) {
      await Api.sendTestWebHookMessage(url);
      this.confirm({
        message: "A test message has been sent.",
        title: "information",
        showCancelButton: false,
      });
    },
    async save() {
      this.validation();

      if (this.Id) {
        this.update();
      } else {
        this.create();
      }
    },
    validation() {
      if (this.NotificationType === NotificationTypes.AGENT) (this.ScriptId = ""), (this.NotifyOn = 0);
    },
    async create() {
      (await Api.createAlert({
        Name: this.Name,
        ScriptId: this.ScriptId,
        AgentIds: this.AgentIds,
        NotificationType: this.NotificationType,
        NotifyOn: this.NotifyOn,
        EmailList: this.EmailList,
        SlackWebHookUrl: this.SlackWebHookUrl,
        MicrosoftTeamsWebHookUrl: this.MicrosoftTeamsWebHookUrl,
        GoogleChatWebHookUrl: this.GoogleChatWebHookUrl,
        HypeWebHookUrl: this.HypeWebHookUrl,
        WebHookUrl: this.WebHookUrl,
      })) &&
        (await this.confirm({
          message: "Alert has successfully created!",
          title: "Success",
          okButtonTitle: "OK",
          showCancelButton: false,
        })) &&
        this.$router.push("/alerts");
    },
    async update() {
      (await Api.updateAlert(this.Id, {
        Name: this.Name,
        ScriptId: this.ScriptId,
        AgentIds: this.AgentIds,
        NotificationType: this.NotificationType,
        NotifyOn: this.NotifyOn,
        EmailList: this.EmailList,
        SlackWebHookUrl: this.SlackWebHookUrl,
        MicrosoftTeamsWebHookUrl: this.MicrosoftTeamsWebHookUrl,
        GoogleChatWebHookUrl: this.GoogleChatWebHookUrl,
        HypeWebHookUrl: this.HypeWebHookUrl,
        WebHookUrl: this.WebHookUrl,
      })) &&
        (await this.confirm({
          message: "Alert has successfully updated!",
          title: "Success",
          okButtonTitle: "OK",
          showCancelButton: false,
        })) &&
        this.$router.push("/alerts");
    },
  },
  watch: {
    e6(val) {
      switch (val) {
        case 2:
          if (!this.Name) {
            this.e6 = 1;
          }
          break;
        case 3:
          if (this.NotificationType === 0) this.e6 = 2;
          else if (this.NotificationType === NotificationTypes.TASK && this.ScriptId === "") this.e6 = 2;
          break;
      }
    },
    NotificationType(val) {
      if (val === NotificationTypes.AGENT) {
        (this.ScriptId = ""), (this.NotifyOn = 0);
      }
    },
  },
  data: () => ({
    e6: 1,
    Id: "",
    Name: "",
    NotificationType: 0,
    Types: Object.entries(NotificationTypes),
    notificationTypes: NotificationTypes,
    ScriptId: "",
    AgentIds: [],
    NotifyOn: 0,
    EmailList: "",
    SlackWebHookUrl: "",
    MicrosoftTeamsWebHookUrl: "",
    GoogleChatWebHookUrl: "",
    HypeWebHookUrl: "",
    WebHookUrl: "",
    jobStatuses: Object.entries(NotificationNotifyOnStatuses),
    userPlan: {},
  }),
  async beforeCreate() {
    this.userPlan = await Api.getUserPlan();
  },
  async created() {
    await this.fetchScripts();
    await this.fetchAgents();

    let id = this.$route.params.id;
    if (id) {
      Api.getAlert(id).then((alert) => {
        Object.assign(this, alert);
        this.AgentIds = alert.Agents.map((agent) => agent.Id);
      });
    }
  },
};
</script>

<style>
.shadow-xl {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
}
</style>
