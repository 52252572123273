<template>
  <input-wrapper :has-expression="hasExpression" @click="openExpressionEditor" @remove="remove" :removable="removable">
    <template v-slot:default="{ disabled }">
      <v-switch
        :label="property.displayName"
        v-model="data"
        :disabled="options.disabled || disabled"
        :append-icon="appendIcon"
        @click:append="openExpressionEditor"
      >
        <template v-slot:append-outer>
          <definition-tip
            :name="property.displayName"
            :definition="property.description"
          ></definition-tip>
        </template>
      </v-switch>
    </template>
  </input-wrapper>
</template>

<script>
import InputWrapper from './InputWrapper'
import dynamicProperty from '../../mixins/dynamicProperty'
import DefinitionTip from '../DefinitionTip'
export default {
  components: { InputWrapper , DefinitionTip},
  mixins: [dynamicProperty],
  inheritAttrs: false,
  props: ['value', 'property', 'options', 'path'],
}
</script>
