<template>
  <div>
    <v-skeleton-loader
      v-if="isLoading"
      ref="skeleton"
      type="table"
      class="mx-auto"
    ></v-skeleton-loader>
    <v-data-table
      :headers="headers"
      :items="tasks"
      class="shadow-xl mx-5 my-5"
      :server-items-length="totalCount"
      :options.sync="options"
      :footer-props="{
        itemsPerPageOptions: [10, 50, 100],
      }"
      v-else
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <div class="d-none d-sm-flex">
            <v-toolbar-title>
              Tasks
              <v-progress-circular
                v-show="isRefreshing"
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-toolbar-title>
          </div>
          <v-spacer></v-spacer>
          <RunTaskDialog />

          <edit-task-dialog :filterAndOptions="{...options, ...filter}"  />
          <confirm-dialog  />
          <v-dialog v-show="false" v-model="dialog_edit_task" max-width="500px">
            <v-card>
              <v-form ref="form">
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="6" md="12">
                        <v-text-field
                          v-model="editedItem.scheduleName"
                          label="Schedule Name"
                          required
                          :rules="nameRules"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="12">
                        <v-text-field
                          v-model="editedItem.cronJob"
                          label="Cron Job"
                          required
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12">
                        <v-autocomplete
                          v-model="editedItem.agents"
                          :items="agentName"
                          dense
                          multiple
                          label="Select Agent"
                        ></v-autocomplete>
                      </v-col>

                      <v-col cols="12">
                        <v-autocomplete
                          v-model="editedItem.scripts"
                          :items="scriptName"
                          dense
                          label="Script"
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn depressed color="primary" @click="save2">Save</v-btn>
                  <v-btn color="grey darken-1" text @click="close2"
                    >Cancel</v-btn
                  >
                </v-card-actions>
              </v-form>
            </v-card>
          </v-dialog>
        </v-toolbar>

        <v-row class="px-5">
          <v-col cols="12" xs="6" sm="6" md="8">
            <v-text-field
              v-model="filter.taskName"
              label="Task Name"
            ></v-text-field>
          </v-col>
          <v-col cols="12" xs="6" sm="6" md="2">
            <v-btn
              depressed
              color="primary"
              class="mt-5"
              @click="getFilterTaskList"
              block
              >Refresh</v-btn
            >
          </v-col>
          <v-col cols="12" xs="6" sm="6" md="2">
            <new-task-dialog class="mt-5" :filterAndOptions="{...options, ...filter}"  block v-if="hasAuth('New Task')" />
            <!-- <v-btn depressed color="primary"   v-if="hasAuth('New Script')" block> <v-icon left>add</v-icon>Add Script </v-btn> -->
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.action="{ item }">
        <v-tooltip
          bottom
          :color="item.agents.length === 0 || !item.Script ? 'red' : ''"
          v-if="hasRowAuth(item.DataRun, item.GlobalAuthControl, 'Run Task')"
        >
          <template v-slot:activator="{ on }">
            <span v-on="on">
              <v-btn
                depressed
                @click.stop="() => run(item)"
                :disabled="item.agents.length === 0 || !item.Script"
              >
                <v-icon size="18">play_arrow</v-icon>
              </v-btn>
            </span>
          </template>
          <span>{{ getRunButtonText(item) }}</span>
        </v-tooltip>
        <v-tooltip
          bottom
          v-if="hasRowAuth(item.DataEdit, item.GlobalAuthControl, 'Edit Task')"
        >
          <template v-slot:activator="{ on }">
            <v-btn depressed class="mx-1" @click="editTask(item)" v-on="on">
              <v-icon size="18">edit</v-icon>
            </v-btn>
          </template>
          <span>Edit Task</span>
        </v-tooltip>
        <v-tooltip
          bottom
          v-if="
            hasRowAuth(item.DataDelete, item.GlobalAuthControl, 'Delete Task')
          "
        >
          <template v-slot:activator="{ on }">
            <v-btn depressed @click="deleteItem(item)" v-on="on">
              <v-icon size="18">delete</v-icon>
            </v-btn>
          </template>
          <span>Delete Task</span>
        </v-tooltip>
        <v-tooltip
          bottom
          v-if="
            hasRowAuth(item.DataStatus, item.GlobalAuthControl, 'Status Task')
          "
        >
          <template v-slot:activator="{ on }">
            <v-btn depressed class="mx-1" v-on="on">
              <v-switch
                :loading="item.EnabledLoading"
                @change="toggle(item)"
                v-model="item.Enabled"
                class="my-1"
                hide-details
                :disabled="item.agents.length === 0 || !item.Script"
              ></v-switch>
            </v-btn>
          </template>
          <span>Enable/Disable</span>
        </v-tooltip>
      </template>
      <template v-slot:no-data></template>
    </v-data-table>
    <DynamicFormDialog
      :extra-data="extraData"
      :schema="envSchema"
      :ui-schema="envUiSchema"
      :width="500"
      v-model="attendedTaskRunDialog"
      @save="attendedSave"
      @cancel="attendedCancel"
      :save-button-text="'send'"
      :card-title="'Environments'"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import {
  FETCH_TASK_WITH_PAGE,
  DELETE_TASK,
  OPEN_EDIT_TASK_DIALOG,
  OPEN_RUN_DIALOG,
  RUN_TASK,
} from "../store/modules/task";
import { OPEN_DIALOG } from "../store/modules/confirm";
import NewTaskDialog from "./NewTaskDialog";
import EditTaskDialog from "./EditTaskDialog";
import RunTaskDialog from "./RunTaskDialog";
import ConfirmDialog from "./ConfirmDialog";
import DynamicFormDialog from "./DynamicFormDialog";
import environmentConverter from "../environmentConverter";
import Api from "../api";
import { relative, timeAwareFormat } from "../time";
import cronstrue from "cronstrue";
import { hasActionAuth } from "../authorization";

export default {
  components: {
    "new-task-dialog": NewTaskDialog,
    "edit-task-dialog": EditTaskDialog,
    "confirm-dialog": ConfirmDialog,
    DynamicFormDialog,
    RunTaskDialog,
  },

  data: () => ({
    date: new Date().toISOString().substr(0, 10),
    options: { itemsPerPage: 10, page: 1 },
    attendedTaskRunDialog: false,
    filter: {
      taskName: "",
    },
    textFieldProps: {
      appendIcon: "event",
    },
    envSchema: {
      type: "object",
      properties: {},
    },
    extraData: {},
    envUiSchema: [],
    agents: [],
    agentName: ["Ahmet", "Mehmet", "Ali", "John", "Doe", "Ayşe", "Meltem"],
    scripts: [],
    scriptName: [
      "MyWindows",
      "Home PC",
      "Other PC",
      "Mac Book",
      "Lorem",
      "Ipsum",
    ],

    menu2: false,
    dialog_new_task: false,
    dialog_edit_task: false,
    valid: false,
    nameRules: [(v) => !!v || "Schedule Name is required"],
    menu: false,
    modal: false,
    headers: [
      {
        text: "Task Name",
        align: "left",
        sortable: true,
        value: "Name",
      },
      {
        text: "Cron Explanation",
        value: "cronExplanation",
      },
      {
        text: "Next Run Time",
        value: "nextRunTime",
      },

      {
        text: "Last Run Time",
        value: "lastRunTime",
      },
      {
        text: "Created Time",
        value: "createdAt",
      },
      {
        text: "Created",
        value: "Created.Name",
      },
      {
        text: "Actions",
        value: "action",
        sortable: false,
      },
    ],

    editedIndex: -1,
    editedItem: {
      agents: "",
      scripts: "",
      cronJob: "",
      scheduleName: "",
      createdtime: "",
      nextRunTime: "",
      lastRunTime: "",
      lastRunResult: "",
      status: "Deactive",
    },
    defaultItem: {
      agents: "",
      scripts: "",
      cronJob: "",
      scheduleName: "",
      createdtime: "",
      nextRunTime: "",
      lastRunTime: "",
      lastRunResult: "",
      status: "Deactive",
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Task" : "Edit Task";
    },
    ...mapState("task", {
      tasks: (state) =>
        state.pageTasks.map((i) => ({
          ...i,
          EnabledLoading: false,
          Enabled: i.Enabled == 1 ? true : false,
          createdAt: timeAwareFormat(i.createdAt),
          lastRunTime: i.lastRunTime && relative(i.lastRunTime),
          nextRunTime: i.nextRunTime && relative(i.nextRunTime),
          cronExplanation:
            i.CronDefinition === ""
              ? "Unscheduled"
              : cronstrue.toString(i.CronDefinition),
        })),
      totalCount: (state) => state.totalCount,
      isLoading: (state) => state.isLoading,
      isRefreshing: (state) => state.isRefreshing,
    }),
  },
  watch: {
    options: {
      handler(newVal, oldVal) {
        if (
          newVal.page != oldVal.page ||
          newVal.itemsPerPage != oldVal.itemsPerPage
        ) {
          this.fetchTasks({ options:  { ...newVal, ...this.filter }, isRefreshing: false });
        }
      },
      deep: true,
    },
    dialog_new_task(val) {
      val || this.close();
    },
    dialog_edit_task(val) {
      val || this.close2();
    },
  },

  created() {
    this.fetchTasks({
        options: { ...this.options, ...this.filter },
        isRefreshing: false,
      });
  },

  methods: {
    ...mapActions("task", {
      fetchTasks: FETCH_TASK_WITH_PAGE,
      deleteTask: DELETE_TASK,
      editTask: OPEN_EDIT_TASK_DIALOG,
      openRunDialog: OPEN_RUN_DIALOG,
      runTask: RUN_TASK,
    }),
    ...mapActions("confirm", {
      confirm: OPEN_DIALOG,
    }),
    getFilterTaskList() {
      this.fetchTasks({
        options: { ...this.options, ...this.filter },
        isRefreshing: false,
      });
    },
    editItem(item) {
      this.editedIndex = this.tasks.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog_edit_task = true;
    },
    attendedCancel() {
      this.extraData = {};
    },
    async attendedSave({ formData, extraData }) {
      formData.append("TaskId", extraData.task.Id);
      await this.runTask(formData);
      this.openRunDialog();
    },
    async deleteItem(item) {
      let message = "";
      let warningMessage = "";
      message += "Are you sure you want to delete this Task?";
      (await this.confirm({
        warningMessage,
        message,
        title: "Delete Task",
      })) && (await this.deleteTask({taskId: item.Id, filterAndOptions: { ...this.options, ...this.filter } }));
    },

    close2() {
      this.dialog_edit_task = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
    async run(task) {
      if (task.Script.AttendedEnvironment.length > 0) {
        let { schema, uiSchema } = environmentConverter(
          task.Script.AttendedEnvironment
        );
        this.envUiSchema = uiSchema;
        this.envSchema = schema;
        this.extraData = { task };
        this.attendedTaskRunDialog = true;
      } else {
        let form = new FormData();
        form.append("TaskId", task.Id);

        await this.runTask(form);
        this.openRunDialog();
      }
    },

    async stopTask(taskId) {
      await Api.stopTask(taskId);
    },

    async toggle(task) {
      task.EnabledLoading = true;
      await Api.toggleTask(task.Id, false);
      task.EnabledLoading = false;
    },

    save2() {
      if (this.editedIndex > -1) {
        Object.assign(this.tasks[this.editedIndex], this.editedItem);
      } else {
        this.tasks.push(this.editedItem);
      }
      this.close2();
    },
    hasAuth(action) {
      return hasActionAuth("Tasks", action);
    },
    hasRowAuth(auth, globalAuthControl, action) {
      if (!auth && globalAuthControl) {
        return this.hasAuth(action);
      }

      return auth;
    },
    getRunButtonText(item) {
      if (item.agents.length === 0 && !item.Script)
        return "There is no agent and script assigned to this task";
      else if (item.agents.length === 0)
        return "There is no agent assigned to this task";
      else if (!item.Script) return "There is no script assigned to this task";
      else return "Run Task";
    },
  },
};
</script>

<style scoped>
.shadow-xl {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
}
</style>
