<template>
  <v-dialog persistent v-model="dialog" max-width="300">
    <v-card>
      <v-card-title v-if="options.title">{{ options.title }}</v-card-title>
      <v-card-text>
        <vue-form-json-schema
          v-if="schema"
          :schema="schema"
          :uiSchema="uiSchema"
          v-model="model"
        />
      </v-card-text>
      <v-card-actions class="d-flex justify-center">
        <v-btn text @click="cancel" color="red">cancel</v-btn>
        <v-btn @click="saveForm">save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { deepCopy } from '../../helpers/object';
export default {
  data: () => ({
    model: {},
  }),
  computed: {
    ...mapState("formDialog", {
      dialog(state) {
        if (state.dialog) this.onOpen();
        else this.onClose();
        return state.dialog;
      },
      schema: (state) => state.schema,
      uiSchema: (state) => state.uiSchema,
      options: (state) => state.options,
      initialFormData: (state) => state.initialFormData
    }),
  },
  // watch: {
  //   dialog(val) {
  //     if (val) this.onOpen()
  //     else this.onClose()
  //   },
  // },
  methods: {
    ...mapActions("formDialog", ["save", "cancel"]),
    saveForm() {
      this.save(JSON.parse(JSON.stringify(this.model)));
    },
    onOpen() {
      // console.log('opened')
      this.model = deepCopy(this.initialFormData ?? {});
    },
    onClose() {
      // console.log('closed')
      this.model = {};
    },
  },
};
</script>
