<template>
  <div>
    <v-skeleton-loader v-if="isLoading" ref="skeleton" type="table" class="mx-auto"></v-skeleton-loader>
    <v-data-table
      :headers="headers"
      :items="users"
      class="shadow-xl mx-5 my-5"
      :options.sync="options"
      :server-items-length="totalCount"
      :footer-props="{
        itemsPerPageOptions: [10, 50, 100],
      }"
      v-else
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <div class="d-none d-sm-flex">
            <v-toolbar-title>Users</v-toolbar-title>
          </div>
          <v-spacer></v-spacer>
          <new-user-dialog />
          <edit-user-dialog />
          <department-dialog />
          <title-dialog />
        </v-toolbar>
        <v-row class="px-5">
          <v-col cols="12" xs="6" sm="6" md="2">
            <v-text-field v-model="filters.name" label="Name"></v-text-field>
          </v-col>
          <v-col cols="12" xs="6" sm="6" md="2">
            <v-text-field v-model="filters.email" label="Email"></v-text-field>
          </v-col>
          <v-col cols="12" xs="6" sm="6" md="2">
            <v-autocomplete v-model="filters.role" :items="userRoles" clearable item-text="name" item-value="name" label="Select Role"></v-autocomplete>
          </v-col>
          <v-col cols="12" xs="6" sm="6" md="2">
            <v-autocomplete v-model="filters.department" :items="departments" clearable item-text="Name" item-value="Id" label="Select Department"></v-autocomplete>
          </v-col>
          <v-col cols="12" xs="6" sm="6" md="2">
            <v-autocomplete v-model="filters.title" :items="titles" clearable item-text="Name" item-value="Id" label="Select Title"></v-autocomplete>
          </v-col>
          <v-col cols="12" xs="6" sm="6" md="2">
            <v-btn depressed color="primary" class="mt-5" @click="filter" block>Refresh</v-btn>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.action="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn depressed class="mx-1" @click="editUser(item)" v-on="on">
              <v-icon size="18">edit</v-icon>
            </v-btn>
          </template>
          <span>Edit</span>
        </v-tooltip>
        <v-tooltip bottom v-if="item.auth">
          <template v-slot:activator="{ on }">
            <v-btn depressed class="mx-1" @click="redirectAuth(item)" v-on="on">
              <v-icon size="18">fingerprint</v-icon>
            </v-btn>
          </template>
          <span>Permission</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn depressed class="mx-1" v-on="on">
              <v-switch :loading="item.EnabledLoading" @change="toggle(item)" v-model="item.IsActive" class="my-1" color="green" hide-details></v-switch>
            </v-btn>
          </template>
          <span>{{ item.IsActive ? "Block User" : "Unblock User" }}</span>
        </v-tooltip>
      </template>
      <template v-slot:no-data></template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { FILTER_SUB_USER, TOGGLE_USER_STATUS, SET_SELECTED_SUB_USER, OPEN_UPDATE_USER_DIALOG, GET_USER_ROLE } from "../../store/modules/user";
import { FETCH_DEPARTMENTS, FETCH_TITLES } from "../../store/modules/digistaff";
import NewUserDialog from "./NewUserDialog";
import EditUserDialog from "./EditUserDialog";
import DepartmentDialog from "../department/DepartmentDialog";
import TitleDialog from "../title/TitleDialog";
import { timeAwareFormat } from "../../time";

export default {
  data: () => ({
    headers: [
      {
        text: "Name",
        align: "left",
        sortable: true,
        value: "Name",
      },
      {
        text: "Email",
        value: "Email",
      },
      {
        text: "Role",
        value: "UserRole",
      },
      {
        text: "Department",
        value: "Department.Name",
      },
      {
        text: "Title",
        value: "Title.Name",
      },
      {
        text: "Created",
        value: "createdAt",
      },
      {
        text: "Actions",
        value: "action",
        sortable: false,
      },
    ],
    options: { itemsPerPage: 10, page: 1 },
    filters: { name: "", email: "", role: "", department: "", title: "" },
  }),
  components: {
    "new-user-dialog": NewUserDialog,
    "edit-user-dialog": EditUserDialog,
    "department-dialog": DepartmentDialog,
    "title-dialog": TitleDialog,
  },
  computed: {
    ...mapState("user", {
      users: (state) =>
        state.subUser.pagedSubUsers.map((i) => ({
          ...i,
          createdAt: timeAwareFormat(i.createdAt),
        })),
      totalCount: (state) => state.subUser.totalCount,
      isLoading: (state) => state.subUser.isLoading,
      userRoles: (state) => state.subUser.userRoles,
    }),
    ...mapState("digistaff", {
      departments: (state) => state.departments,
      titles: (state) => state.titles,
    }),
  },
  watch: {
    options: {
      handler(newVal, oldVal) {
        if (newVal.page != oldVal.page || newVal.itemsPerPage != oldVal.itemsPerPage) {
          this.filterSubUsers({ options: newVal });
        }
      },
      deep: true,
    },
  },
  created() {
    this.filterSubUsers({ options: this.options });
    this.fetchDepartments();
    this.fetchTitles();
    this.getUserRoles();
  },
  methods: {
    ...mapActions("user", {
      toggleUserStatus: TOGGLE_USER_STATUS,
      setSelectedSubUser: SET_SELECTED_SUB_USER,
      openUpdateUserDialog: OPEN_UPDATE_USER_DIALOG,
      filterSubUsers: FILTER_SUB_USER,
      getUserRoles: GET_USER_ROLE,
    }),
    ...mapActions("digistaff", {
      fetchDepartments: FETCH_DEPARTMENTS,
      fetchTitles: FETCH_TITLES,
    }),
    toggle(user) {
      this.toggleUserStatus(user.Id);
    },
    editUser(user) {
      this.setSelectedSubUser(user);
      this.openUpdateUserDialog();
    },
    redirectAuth(user) {
      this.$router.push(`/user/authorization/${user.Id}`);
    },
    filter() {
      this.filterSubUsers({ options: { ...this.options, ...this.filters } });
    },
  },
};
</script>
