function defaultComparator(a, b) {
  return a === b
}

/**
 * Uniques an array and returns a shallow copy
 * @param {Array} array
 * @param {Function} comparator
 */
export function unique(array, comparator = defaultComparator) {
  let newArray = []

  for (const addedCandidate of array) {
    if (
      newArray.findIndex(uniqueItem =>
        comparator(addedCandidate, uniqueItem)
      ) === -1
    ) {
      newArray.push(addedCandidate)
    }
  }

  return newArray
}
