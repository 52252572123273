import Vue from '../../index'

const mixin = {
  methods: {
    $input(options) {
      let promise = Vue.$store.dispatch('formDialog/open', options)
      return promise
    },
    $alert(options) {
      Vue.$store.dispatch('formDialog/openAlert', options)
    },
    $confirm(options) {
      return Vue.$store.dispatch('formDialog/openConfirmDialog', options)
    },
  },
}

export default mixin
