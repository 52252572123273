<template>
  <v-dialog v-model="dialog" max-width="500px" persistent>
    <v-card>
      <v-form ref="form">
        <v-card-title>
          <span class="headline">Queue Item</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="12">
                <v-text-field v-model="item.Id" readonly label="Queue Item Id"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="12">
                <v-textarea v-model="item.Data" auto-grow rows="1" readonly label="Data"></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="12">
                <v-textarea v-model="item.Error" auto-grow rows="1" readonly label="Error"></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" text @click="toggleDialog">Close</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { TOGGLE_VIEW_QUEUE_ITEM_DIALOG } from "../../store/modules/queue";

export default {
  computed: {
    ...mapState("queue", {
      item: state => state.viewQueueItem.item,
      dialog: state => state.viewQueueItem.dialog
    })
  },
  methods: {
    ...mapActions("queue", {
      toggleDialog: TOGGLE_VIEW_QUEUE_ITEM_DIALOG
    })
  }
};
</script>