<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card class="py-5 px-5">
          <h3>Settings</h3>
          <p>
            On this page, you can change your personal information, password or settings related to the run application.
          </p>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card>
          <v-tabs v-model="tab" background-color="primary accent-4" dark center-active fixed-tabs>
            <v-tab :key="item.name" v-for="item in tabs">{{ item.name }}</v-tab>
          </v-tabs>
        </v-card>
      </v-col>
    </v-row>
    <v-tabs-items v-model="tab">
      <v-tab-item :v-model="tab" :key="item.name" v-for="item in tabs"> <component v-bind:is="item.component"></component></v-tab-item
    ></v-tabs-items>
  </div>
</template>

<script>
import { mapState } from "vuex";

import Profile from "./Profile";
import Api from "./Api";
import TimeZone from "./TimeZone";
import Smtp from "./Smtp";
import ActiveDirectory from "./ActiveDirectory";
import License from "./License";

export default {
  data() {
    return {
      tab: 0,
      tabs: [],
    };
  },
  components: {
    profile: Profile,
    api: Api,
    "time-zone": TimeZone,
    smtp: Smtp,
    "active-directory": ActiveDirectory,
    license: License,
  },
  computed: {
    ...mapState("user", {
      role: (state) => state.user.Role,
    }),
  },
  watch: {
    role() {
      this.init();
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      if (this.tabs.length > 0) return;

      if (this.$route.query.l === "n") {
        this.tabs.push({ name: "License", component: "license" });
        return;
      }

      if (this.role) {
        this.tabs = [{ name: "Profile", component: "profile" }];

        if (this.role === "Owner")
          this.tabs.push(
            { name: "Api", component: "api" },
            { name: "Time Zone", component: "time-zone" },
            { name: "Smpt", component: "smtp" },
            { name: "Active Directory", component: "active-directory" },
            { name: "License", component: "license" }
          );
      }
    },
  },
};
</script>
