<template>
  <v-dialog v-model="dialog" max-width="800px" persistent>
    <v-card>
      <v-form ref="form">
        <v-card-title>
          <span class="headline">Edit Script</span>
        </v-card-title>

        <v-card-text>
          <v-alert v-show="!!error || !!fileError" text prominent type="error" icon="mdi-alert-circle">{{ fileError || error }}</v-alert>
          <v-alert v-show="!!success" text prominent type="success" icon="mdi-cloud-check">Succesfully Created</v-alert>
          <v-container class="text-center" v-if="loading">
            <v-progress-circular :size="70" :width="7" color="purple" indeterminate></v-progress-circular>
            <p>This process may take a few minutes</p>
          </v-container>
          <v-container v-else>
            <v-tabs v-model="outerTab">
              <v-tabs-slider></v-tabs-slider>

              <v-tab href="#script"> <v-icon>mdi-bash</v-icon>Script </v-tab>

              <v-tab href="#env"> <v-icon>mdi-console</v-icon>Environments </v-tab>

              <v-tab href="#attendendEnv"> <v-icon>mdi-console</v-icon>Attendend Environments </v-tab>

              <v-tab-item :eager="true" value="script">
                <v-col cols="12" sm="12" md="12">
                  <p v-html="editedItem.Plugin && editedItem.Plugin.HtmlDescription" />
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    v-model="editedItem.Name"
                    :disabled="!!success"
                    maxlength="50"
                    counter
                    name="Name"
                    autofocus
                    label="Script Name"
                    required
                    :rules="scriptNameRules"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="12">
                  <v-text-field
                    v-model="editedItem.Description"
                    maxlength="200"
                    counter
                    :disabled="!!success"
                    name="Description"
                    label="Description"
                    required
                  ></v-text-field>
                </v-col>
                <v-tabs v-model="tab">
                  <v-tabs-slider></v-tabs-slider>

                  <v-tab v-if="showCmd" href="#cmd"> <v-icon>mdi-console</v-icon>cmd (Windows) </v-tab>

                  <v-tab v-if="showBash" href="#bash"> <v-icon>mdi-bash</v-icon>Bash (Linux , Mac) </v-tab>

                  <v-tab href="#kill_cmd" v-if="showKillCmd"> <v-icon>mdi-console</v-icon>Batch (Kill) </v-tab>

                  <v-tab href="#kill_bash" v-if="showKillBash"> <v-icon>mdi-bash</v-icon>Bash (Kill) </v-tab>

                  <v-tab-item :eager="true" value="bash">
                    <v-card flat tile>
                      <v-col cols="12" sm="6" md="12">
                        <v-textarea
                          v-model="editedItem.BashScript"
                          :disabled="!!success"
                          name="BashScript"
                          label="Bash Command Line"
                          maxlength="2000"
                          counter
                          required
                          :rules="BashCommandLineRules"
                        ></v-textarea>
                      </v-col>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item value="cmd" :eager="true">
                    <v-card flat tile>
                      <v-col cols="12" sm="6" md="12">
                        <v-textarea
                          v-model="editedItem.CmdScript"
                          :disabled="!!success"
                          name="CmdScript"
                          label="Cmd Command Line"
                          maxlength="2000"
                          counter
                          required
                          :rules="CmdCommandLineRules"
                        ></v-textarea>
                      </v-col>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item :eager="true" value="kill_cmd">
                    <v-card flat tile>
                      <v-textarea
                        v-model="editedItem.KillCmdScript"
                        :disabled="!!success"
                        name="KillCmdScript"
                        label="Cmd Command Line ( Kill )"
                        maxlength="2000"
                        counter
                        required
                        style="font-family: 'IBM Plex Mono', monospace;"
                        :rules="CmdCommandLineRules"
                      ></v-textarea>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item :eager="true" value="kill_bash">
                    <v-card flat tile>
                      <v-textarea
                        v-model="editedItem.KillBashScript"
                        :disabled="!!success"
                        name="KillBashScript"
                        label="Bash Command Line ( Kill )"
                        maxlength="2000"
                        counter
                        required
                        style="font-family: 'IBM Plex Mono', monospace;"
                        :rules="BashCommandLineRules"
                      ></v-textarea>
                    </v-card>
                  </v-tab-item>
                </v-tabs>
                <v-col cols="12" sm="6" md="12">
                  <v-text-field name="Timeout" type="number" v-model="editedItem.Timeout" label="Timeout (sec) (Leave Blank To Make Infinite)"></v-text-field>
                </v-col>

                <v-row>
                  <v-col cols="12" sm="2" md="4" v-if="editedItem.Timeout">
                    <v-checkbox name="RunKillScript" v-model="editedItem.RunKillScript" label="Run kill script on timeout"></v-checkbox>
                  </v-col>

                  <v-col cols="12" :sm="editedItem.Timeout ? 2 : 3" :md="editedItem.Timeout ? 4 : 6">
                    <v-checkbox name="Rdp" v-model="editedItem.Rdp" label="Rdp"></v-checkbox>
                  </v-col>

                  <v-col cols="12" :sm="editedItem.Timeout ? 2 : 3" :md="editedItem.Timeout ? 4 : 6">
                    <v-checkbox name="Queue" v-model="editedItem.Queue" label="Queue"></v-checkbox>
                  </v-col>
                </v-row>

                <v-row v-if="!hasFileInEnvironment">
                  <v-col cols="12" sm="2" md="4">
                    <v-checkbox v-model="newFile" label="Upload New File"></v-checkbox>
                  </v-col>
                  <v-col cols="12" sm="4" md="8" v-if="!hasFileInEnvironment">
                    <v-file-input
                      v-show="newFile"
                      accept="application/zip"
                      :disabled="!!success"
                      name="File"
                      show-size
                      counter
                      label="File input"
                      required
                      :rules="filesRules"
                    ></v-file-input>
                  </v-col>
                </v-row>
              </v-tab-item>

              <v-tab-item :eager="true" value="env">
                <vue-form-json-schema class="py-5" v-if="schema" v-model="pluginEnvironmentData" :schema="schema" :ui-schema="uiSchema"></vue-form-json-schema>
                <EnvironmentForm
                  @edit="isEnvironmentEdited = true"
                  @cancel="isEnvironmentEdited = false"
                  @save="isEnvironmentEdited = false"
                  :disabled="isEnvironmentEdited"
                  v-for="key in environmentKeys"
                  :key="key"
                  :envKey="key"
                  :environment="editedItem.Environment"
                />

                <EnvironmentForm :disabled="isEnvironmentEdited" :environment="editedItem.Environment" />
              </v-tab-item>

              <v-tab-item value="attendendEnv" :eager="true">
                <AttendedEnvironmentForm
                  :disableFile="disableFile"
                  @remove="editedItem.AttendedEnvironment.splice(index, 1)"
                  @edit="() => (isAttendedEditing = true)"
                  @cancel="() => (isAttendedEditing = false)"
                  @save="() => (isAttendedEditing = false)"
                  v-for="(arg, index) in editedItem.AttendedEnvironment"
                  :key="arg.key + arg.type"
                  :id="`attendedEnv_${index}`"
                  v-model="editedItem.AttendedEnvironment[index]"
                  :disabled="isAttendedEditing"
                />

                <AttendedEnvironmentForm :disabled="isAttendedEditing" :disableFile="disableFile" @save="(env) => editedItem.AttendedEnvironment.push(env)" />
              </v-tab-item>
            </v-tabs>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed color="primary" v-show="!success && !loading" @click="save">Save</v-btn>
          <v-btn color="grey darken-1" text @click="closeEditDialog">{{ success ? "Close" : "Cancel" }}</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from "vue";
import { mapActions, mapState } from "vuex";
import { CLOSE_EDIT_SCRIPT_DIALOG, OPEN_EDIT_SCRIPT_DIALOG, EDIT_SCRIPT } from "../store/modules/script";
import { checkLiterals } from "../environmentCheck";
import environmentConventer from "../environmentConverter";
import AttendedEnvironmentForm from "./AttendedEnvironmentForm";
import EnvironmentForm from "./EnvironmentForm";
import Api from "../api";

export default {
  components: {
    AttendedEnvironmentForm,
    EnvironmentForm,
  },
  computed: {
    hasFileInEnvironment() {
      if (this.editedItem.Plugin) {
        return this.editedItem.Plugin.EnvironmentSchema.find((v) => v.type == "file");
      }
      return false;
    },
    showCmd() {
      if (this.editedItem.Plugin && !this.editedItem.Plugin.Platforms.find((v) => v == "windows")) {
        return false;
      }
      return true;
    },
    showBash() {
      if (this.editedItem.Plugin) {
        for (const platform of this.editedItem.Plugin.Platforms) {
          if (platform == "linux" || platform == "mac") {
            return true;
          }
        }
        return false;
      }
      return true;
    },
    showKillBash() {
      return this.showBash && this.editedItem.RunKillScript;
    },
    showKillCmd() {
      return this.showCmd && this.editedItem.RunKillScript;
    },
    environmentKeys() {
      return Object.keys(this.editedItem.Environment);
    },
    dialog: {
      get() {
        return this.$store.state.script.editDialog;
      },
      set(val) {
        if (val) {
          this.openEditDialog();
        } else {
          this.closeEditDialog();
        }
      },
    },
    disableFile() {
      return !!this.editedItem.AttendedEnvironment.find((v) => v.type == "file");
    },
    ...mapState("script", {
      scripts: (state) => state.scripts,
      error: (state) => state.editError,
      success: (state) => state.editSuccess,
      editedItem: (state) => state.editItem,
      loading: (state) => state.editLoading,
    }),
    uiSchema() {
      return this.editedItem.Plugin && environmentConventer(this.editedItem.Plugin.EnvironmentSchema).uiSchema;
    },
    schema() {
      return this.editedItem.Plugin && environmentConventer(this.editedItem.Plugin.EnvironmentSchema).schema;
    },
  },
  methods: {
    shouldShowAttendedValue(type = this.attendedEnv.type) {
      return !["file", "text", "switch"].find((v) => v == type);
    },
    addAttendedEnv() {
      if (!this.refs.attendedForm.validate()) {
        return;
      }

      if (this.attendedEnv.type == "file") {
        this.types = this.types.filter((v) => v != "file");
      }
      this.editedItem.AttendedEnvironment.push(this.attendedEnv);
      this.clearAttendedEnv();
    },
    clearAttendedEnv() {
      this.attendedEnv = {
        key: "",
        value: "",
        type: "text",
      };
    },
    addEnv() {
      if (!this.refs.envForm.validate()) {
        return;
      }

      Vue.set(this.editedItem.Environment, this.env.key, this.env.value);
      this.env = { key: "", value: "" };
    },
    setEnv(key, value) {
      Vue.set(this.editedItem.Environment, key, value);
    },
    removeEnv(key) {
      Vue.delete(this.editedItem.Environment, key);
    },
    ...mapActions("script", {
      openEditDialog: OPEN_EDIT_SCRIPT_DIALOG,
      closeEditDialog: CLOSE_EDIT_SCRIPT_DIALOG,
      editScript: EDIT_SCRIPT,
    }),

    save() {
      this.fileError = "";
      if (!this.$refs.form.validate()) {
        return;
      }

      let elem = this.$refs.form.$el;
      let data = new FormData(elem);

      data.set("RunKillScript", this.editedItem.RunKillScript || false);
      data.set("Rdp", this.editedItem.Rdp || false);
      data.set("Queue", this.editedItem.Queue || false);

      if (!this.editedItem.Timeout) {
        data.delete("Timeout");
      }

      if (data.has("File")) {
        let file = data.get("File");
        if (file.size && this.fileSize > 0) {
          if (file.size > this.fileSize * 1048576) {
            this.fileError = `Maximum ${this.fileSize} MB`;
            return;
          }
        }
      }

      data.set("ScriptId", this.editedItem.Id);
      data.append("AttendedEnvironment", JSON.stringify(this.editedItem.AttendedEnvironment));
      data.append(
        "Environment",
        JSON.stringify({
          ...this.pluginEnvironmentData,
          ...this.editedItem.Environment,
        })
      );
      if (!data.get("File").size) {
        delete data.delete("File");
      }

      this.editScript(data);
    },
  },
  async created() {
    this.fileSize = await Api.getPlanScriptSize();
    this.filesRules = [
      (v) => {
        if (v) {
          if (this.fileSize > 0) {
            if (v.size > this.fileSize * 1048576) {
              return `Maximum ${this.fileSize} MB`;
            }
          }
        }
      },
    ];
  },
  watch: {
    dialog(val) {
      if (val) {
        let data = {};

        (this.outerTab = 0), (this.tab = 0);
        if (this.editedItem.Plugin) {
          let fileField = this.editedItem.Plugin.EnvironmentSchema.find((v) => v.type == "file");
          if (fileField) {
            delete fileField.rules;
          }
          for (const { key } of this.editedItem.Plugin.EnvironmentSchema) {
            let val = this.editedItem.Environment[key];
            if (val) {
              data[key] = val;
              delete this.editedItem.Environment[key];
            }
          }
        }
        this.pluginEnvironmentData = data;
      } else {
        if (this.editedItem.Plugin) {
          for (const [key, value] of Object.entries(this.pluginEnvironmentData)) {
            this.editedItem.Environment[key] = value;
          }
        }
      }
    },
  },
  data: () => ({
    isEnvironmentEdited: false,
    isAttendedEditing: false,
    fileSize: 0,
    fileError: "",
    keyRules: [(v) => !!v || "key must not be null", (v) => checkLiterals(v) || "key can only contains letters and underscores"],
    types: ["selection", "multiple-selection", "text", "switch", "file"],
    newFile: false,
    scriptNameRules: [(v) => !!v || "Script name is required"],
    filesRules: [
      (v) => {
        if (v) {
          if (this.fileSize > 0) {
            if (v.size > this.fileSize * 1048576) {
              return `Maximum ${this.fileSize} MB`;
            }
          }
        }
      },
    ],
    CmdCommandLineRules: [(v) => (v || "").length <= 2000 || `A maximum of 2000 characters is allowed`],
    BashCommandLineRules: [(v) => (v || "").length <= 2000 || `A maximum of 2000 characters is allowed`],
    env: {
      key: "",
      value: "",
    },
    attendedEnv: {
      key: "",
      value: "",
      type: "text",
    },
    outerTab: 0,
    tab: 0,
  }),
};
</script>
