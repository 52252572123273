<template>
  <v-slide-x-transition>
    <v-combobox
      autofocus
      v-show="show"
      @change="onSelected"
      v-model="selectedItem"
      :items="items"
      :filter="filter"
      item-text="name"
      @blur="$emit('close')"
    >
      <template v-slot:item="{ item }">
        <v-list-item-content>
          <v-list-item-title
            >{{ item.name }}

            <v-chip color="primary" v-if="typeof item.value != 'object'">
              {{ item.value }}
            </v-chip>
          </v-list-item-title>
          <v-list-item-subtitle> {{ item.path }} </v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </v-combobox>
  </v-slide-x-transition>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: ["show"],
  data: () => ({
    selectedItem: null,
  }),
  computed: {
    ...mapState("editor", {
      expressionData: (state) => state.expressionData,
    }),
    items() {
      let items = [];
      this.convertItems(this.expressionData.$node, [], items);
      return items;
    },
  },
  methods: {
    filter(item, queryText) {
      const hasValue = (val) => (val != null ? val : "");

      const query = hasValue(queryText);
      let foundInKey =
        item.path
          .toString()
          .toLowerCase()
          .indexOf(query.toString().toLowerCase()) > -1;

      let foundInValue =
        item.value == null
          ? false
          : item.value
              .toString()
              .toLowerCase()
              .indexOf(query.toString().toLowerCase()) > -1;

      return foundInKey || foundInValue;
    },
    convertItems(item, parents = [], items) {
      if (item != null && typeof item == "object") {
        for (const [key, value] of Object.entries(item)) {
          items.push({
            name: key,
            path:
              "$node" +
              parents.reduce((prev, curr) => prev + `["${curr}"]`, "") +
              `["${key}"]`,
            value: value,
          });
          this.convertItems(value, [...parents, key], items);
        }
      }
    },
    onSelected() {
      if (typeof this.selectedItem == "object") {
        this.$emit("select", this.selectedItem.path);
      }
    },
  },
};
</script>

<style>
#expression-data-selector {
  position: fixed;
  top: 300;
  right: 0;
  height: 400px;
  width: 400px;
  background: white;
  z-index: 99999;
}
</style>
