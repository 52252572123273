<template>
  <div  :class="hasExpression || removable ? `d-flex` : ``">
    <slot :disabled="hasExpression" />
    
    <v-btn
      v-if="hasExpression"
      outlined
      class="caption pa-5 mt-1 ml-3"
      depressed
      dense
      color="red"
      @click="$emit('click')"
    >
      Exp
    </v-btn>
    <v-btn
      v-if="removable"
      icon
      class="mt-2 ml-3 pa-5"
      
      color="red"
      @click="$emit('remove')"
    >
      <v-icon small>mdi-trash-can</v-icon>
    </v-btn>
  </div>
</template>
<script>
export default {
  props: ['hasExpression','removable'],
}
</script>
