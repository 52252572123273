const { gatewayURL } = require("./config");

exports.getImage = (fileName) => {
  if (!fileName) {
    return "";
  }

  return `${gatewayURL}/file/public/image/frontend/${fileName}`;
};

exports.getPluginImage = (fileName) => {
  if (!fileName) {
    return "";
  }

  return `${gatewayURL}/file/public/image/plugins/${fileName}`;
};

exports.getFile = (fileName) => {
  if (!fileName) {
    return "";
  }

  return `${gatewayURL}/file/public/file/${fileName}`;
};
