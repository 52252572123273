var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isLoading)?_c('v-skeleton-loader',{ref:"skeleton",staticClass:"mx-auto",attrs:{"type":"table"}}):_c('v-data-table',{staticClass:"shadow-xl mx-5 my-5",attrs:{"headers":_vm.headers,"items":_vm.digistaff,"footer-props":{
      itemsPerPageOptions: [10, 50, 100],
    }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('div',{staticClass:"d-none d-sm-flex"},[_c('v-toolbar-title',[_vm._v("Digistaff")])],1),_c('v-spacer'),(_vm.hasAuth('Digistaffs', 'New Digistaff'))?_c('new-digistaff-dialog'):_vm._e(),(_vm.hasAuth('Digistaffs', 'Edit Digistaff'))?_c('edit-digistaff-dialog'):_vm._e(),(_vm.hasAuth('Digistaffs', 'Delete Digistaff'))?_c('delete-digistaff-dialog'):_vm._e(),(_vm.hasAuth('Departments', 'View'))?_c('department-dialog'):_vm._e()],1)]},proxy:true},{key:"item.Department",fn:function(ref){
    var item = ref.item;
return _vm._l((item.Departments),function(department){return _c('v-chip',{key:department.Id,staticClass:"ma-2",attrs:{"color":"primary","text-color":"white"}},[_vm._v(" "+_vm._s(department.Name)+" ")])})}},{key:"item.action",fn:function(ref){
    var item = ref.item;
return [(_vm.hasAuth('Digistaffs', 'Edit Digistaff'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"depressed":""},on:{"click":function () { return _vm.editDigistaff(item); }}},on),[_c('v-icon',{attrs:{"size":"18"}},[_vm._v("edit")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit Digistaff")])]):_vm._e(),(_vm.hasAuth('Digistaffs', 'Delete Digistaff'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"depressed":""},on:{"click":function($event){return _vm.deleteDigistaff(item.Id)}}},on),[_c('v-icon',{attrs:{"size":"18"}},[_vm._v("delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete Digistaff")])]):_vm._e(),(_vm.hasAuth('Skills', 'View'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"depressed":""},on:{"click":function () { return _vm.redirectDetail(item.Id); }}},on),[_c('v-icon',{attrs:{"size":"18"}},[_vm._v("settings_overscan")])],1)]}}],null,true)},[_c('span',[_vm._v("Skills")])]):_vm._e()]}},{key:"no-data",fn:function(){return undefined},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }