<template>
  <v-dialog v-model="dialog" max-width="500px" persistent>
    <v-card>
      <v-form ref="form" :lazy-validation="false">
        <v-card-title>
          <span class="headline">Delete Digistaff</span>
        </v-card-title>
        <v-alert
          v-show="!!error"
          text
          prominent
          type="error"
          icon="mdi-alert-circle"
        >
          <span v-html="error" />
        </v-alert>
        <v-alert
          v-show="!!success"
          text
          prominent
          type="success"
          icon="mdi-cloud-check"
          >Succesfully Deleted</v-alert
        >
        <v-card-text>
          <v-container v-if="!success"
            >Are you sure to delete this digistaff?</v-container
          >
          <v-container class="text-center" v-if="loading">
            <v-progress-circular
              :size="70"
              :width="7"
              color="purple"
              indeterminate
            ></v-progress-circular>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn v-show="!success" depressed color="primary" @click="deleteItem"
            >Delete</v-btn
          >
          <v-btn color="grey darken-1" text @click="toggleDialog">{{
            success ? "Close" : "Cancel"
          }}</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
import {
  DELETE_DIGISTAFF,
  TOGGLE_DELETE_DIGISTAFF_DIALOG,
} from "../../store/modules/digistaff";

export default {
  computed: {
    ...mapState("digistaff", {
      id: (state) => state.deleteDigistaff.id,
      dialog: (state) => state.deleteDigistaff.dialog,
      loading: (state) => state.deleteDigistaff.isLoading,
      success: (state) => state.deleteDigistaff.success,
      error: (state) => state.deleteDigistaff.error,
    }),
  },
  methods: {
    ...mapActions("digistaff", {
      deleteDigistaff: DELETE_DIGISTAFF,
      toggleDialog: TOGGLE_DELETE_DIGISTAFF_DIALOG,
    }),
    deleteItem() {
      this.deleteDigistaff(this.id);
    },
  },
};
</script>
