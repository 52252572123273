import { join } from "../helpers/dotNotation";
import Vue from "../../index";
import { mapState } from "vuex";
import eventBus from "./eventBus";
import { evaluate } from "../helpers/template";
const mixin = {
  mixins: [eventBus],
  computed: {
    ...mapState("editor", {
      expressionData: (state) => state.expressionData,
      expression: (state) => {
        return state.currentEditedData;
      },
      currentEditedParameter: (state) => {
        return state.expressionEditorParameter;
      },
    }),
    data: {
      get() {
        return this.hasExpression ? evaluate(this.value, this.expressionData) : this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    hasExpression() {
      return typeof this.value === "string" ? this.value.startsWith("=") : false;
    },
    removable() {
      return this.options?.removable;
    },
    appendIcon() {
      return this.options.hasExpressionEditor ? "mdi-code-tags" : null;
    },
    expressionEditorPath() {
      return join(this.path, this.property.name);
    },
  },
  methods: {
    openExpressionEditor() {
      console.log(this.options);
      Vue.$store.commit("editor/updateExpressionEditorParameter", {
        path: this.expressionEditorPath,
        value: this.value,
        codeEditor: this.options.codeEditor || false,
      });
    },
    remove() {
      this.$pushEvent("remove_path_from_data", this.path);
    },
  },
  watch: {
    expression() {
      if (this.currentEditedParameter === this.expressionEditorPath) {
        this.$emit("input", this.expression);
      }
    },
  },
};

export default mixin;
