class NodeNamer {
  constructor() {
    this.reset();
  }

  reset() {
    this.maxNameCount = {};
    this.names = new Set();
  }

  getName(desiredName) {
    const { name, count } = splitToCountAndName(desiredName);
    let currMax = this.updateMaxNameCount(name, count);
    let returningName;

    if (!this.names.has(desiredName)) {
      returningName = desiredName;
    } else {
      returningName = name + currMax;
    }

    this.names.add(returningName);

    return returningName;
  }

  updateMaxNameCount(name, count) {
    this.maxNameCount[name] = this.maxNameCount[name] != null ? Math.max(this.maxNameCount[name], count, count + 1, this.maxNameCount[name] + 1) : 0;
    return this.maxNameCount[name];
  }
}

export default new NodeNamer();

function splitToCountAndName(str) {
  const numberRegex = /[0-9]+/g;

  if (str[str.length - 1].match(numberRegex) === null) {
    return {
      count: 0,
      name: str,
    };
  }

  let matches = str.match(numberRegex);

  if (matches === null) {
    return {
      count: 0,
      name: str,
    };
  }

  let lastMatch = matches[matches.length - 1];
  return {
    count: Number(lastMatch),
    name: str.slice(0, -lastMatch.length).trim(),
  };
}
