export function resolve(obj, data) {
  if (obj === null) return null

  if (typeof obj == 'object') {
    let _obj = {}
    for (const [key, value] of Object.entries(obj)) {
      _obj[key] = resolve(value, data)
    }
    return _obj
  } else if (typeof obj == 'string') {
    return typeof data[obj] == 'object' ? resolve(data[obj], data) : data[obj]
  }
  return obj
}

/**
 * resolves any nested or flat object to array if possible
 * following object
 * {
 *    "hobbies": {
 *      "0": 'coding',
 *      "1": 'coding in vue'
 *    },
 *    name: "Ahmet"
 * }
 * will be converted to
 * {
 *    "hobbies": ['coding','coding in vue']
 *    "name": "Ahmet"
 * }
 * @param {*} obj
 */
export function resolveToArray(obj) {
  if (obj == null) return null

  if (typeof obj != 'object') return obj

  if (Object.keys(obj).length == 0) return {}

  //checks if all the keys of the object is numbers
  if (Object.keys(obj).every(i => i.match(/^\d+$/) != null)) {
    return Object.values(obj).map(i => resolveToArray(i))
  }

  let _obj = {}
  for (const [key, value] of Object.entries(obj)) {
    _obj[key] = resolveToArray(value)
  }

  return _obj
}
