<template>
  <v-dialog v-model="dialog" max-width="500px">
    <v-card>
      <v-form ref="form" :lazy-validation="false" persistent>
        <v-card-title>
          <span class="headline">{{!!title ? title : "Delete Agent"}}</span>
        </v-card-title>
        <v-alert v-show="!!errorMessage" text prominent type="error" icon="mdi-alert-circle">
          <span v-html="errorMessage" />
        </v-alert>
        <v-alert
          v-show="!!isSuccess"
          text
          prominent
          type="success"
          icon="mdi-cloud-check"
        >Succesfully Deleted</v-alert>
        <v-alert v-show="!!warningMessage" text prominent type="warning" icon="mdi-alert-circle">
          <span v-html="warningMessage" />
        </v-alert>
        <v-card-text>
          <v-container v-if="!isSaving" v-show="!isSuccess">{{message}}</v-container>
          <v-container class="text-center" v-else>
            <v-progress-circular :size="70" :width="7" color="purple" indeterminate></v-progress-circular>
          </v-container>
        </v-card-text>

        <v-card-actions v-if="!isSaving">
          <v-spacer></v-spacer>
          <v-btn
            v-show="!isSuccess"
            depressed
            :color="showCancelButton ? 'error' : '' "
            @click="approveDialog()"
          >{{!!okButtonTitle ? okButtonTitle : "Yes"}}</v-btn>
          <v-btn
            color="grey darken-1"
            v-show="showCancelButton"
            text
            @click="closeDialog"
          >{{isSuccess ? "Close" : (!!cancelButtonTitle ? cancelButtonTitle : "Cancel")}}</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from "vuex";
import {
  OPEN_DIALOG,
  CLOSE_DIALOG,
  APPROVE_DIALOG
} from "../store/modules/confirm";

export default {
  computed: {
    ...mapState("confirm", {
      isSuccess: state => state.isSuccess,
      isSaving: state => state.isSaving,
      errorMessage: state => state.errorMessage,
      warningMessage: state => state.warningMessage,
      message: state => state.message,
      title: state => state.title,
      okButtonTitle: state => state.okButtonTitle,
      cancelButtonTitle: state => state.cancelButtonTitle,
      showCancelButton: state => state.showCancelButton
    }),
    dialog: {
      get() {
        return this.$store.state.confirm.dialog;
      },
      set(val) {
        if (val) this.openDialog();
        else this.closeDialog();
      }
    }
  },

  methods: {
    ...mapActions("confirm", {
      closeDialog: CLOSE_DIALOG,
      openDialog: OPEN_DIALOG,
      approveDialog: APPROVE_DIALOG
    })
  }
};
</script>
<style scoped>
.multi-line {
  white-space: pre-line;
}
</style>