<template>
  <div>
    <v-skeleton-loader v-if="isLoading" ref="skeleton" type="table" class="mx-auto"></v-skeleton-loader>
    <v-data-table
      :headers="headers"
      :items="radars"
      class="shadow-xl mx-5 my-5"
      :server-items-length="totalCount"
      :options.sync="options"
      :footer-props="{
        itemsPerPageOptions: [10, 50, 100],
      }"
      v-else
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <div class="d-none d-sm-flex">
            <v-toolbar-title>Radar</v-toolbar-title>
          </div>
          <v-spacer></v-spacer>
          <edit-radar-dialog v-if="hasAuth('Action')" />
        </v-toolbar>
        <v-row class="px-5">
          <v-col cols="12" xs="6" sm="6" md="2">
            <v-text-field v-model="filters.hostKey" label="Host Key"></v-text-field>
          </v-col>
          <v-col cols="12" xs="6" sm="6" md="3">
            <v-text-field v-model="filters.hostName" label="Host Name"></v-text-field>
          </v-col>
          <v-col cols="12" xs="6" sm="6" md="2">
            <v-text-field v-model="filters.username" label="Username"></v-text-field>
          </v-col>
          <v-col cols="12" xs="6" sm="6" md="3">
            <v-autocomplete v-model="filters.status" :items="radarStatuses" clearable item-text="Name" item-value="Id" label="Select Status"></v-autocomplete>
          </v-col>
          <v-col cols="12" xs="6" sm="6" md="2">
            <v-btn depressed color="primary" class="mt-5" @click="filter" block>Refresh</v-btn>
          </v-col>
        </v-row> </template
      ><template v-slot:item.status="{ item }">
        <v-chip class="ma-2" color="primary" text-color="white">
          {{ item.status }}
        </v-chip>
      </template>
      <template v-slot:item.action="{ item }">
        <v-tooltip bottom v-if="hasAuth('Action') && actionControl(item, 'Approved')">
          <template v-slot:activator="{ on }">
            <v-btn depressed class="mx-1" @click="() => editRadar(item.Id, 'Approved')" v-on="on">
              <v-icon size="18">check_box</v-icon>
            </v-btn>
          </template>
          <span>Approve</span>
        </v-tooltip>
        <v-tooltip bottom v-if="hasAuth('Action') && actionControl(item, 'Rejected')">
          <template v-slot:activator="{ on }">
            <v-btn depressed @click="editRadar(item.Id, 'Rejected')" v-on="on">
              <v-icon size="18">cancel</v-icon>
            </v-btn>
          </template>
          <span>Reject</span>
        </v-tooltip>
      </template>
      <template v-slot:no-data></template>
    </v-data-table>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { FILTER_RADARS, FETCH_RADAR_STATUSES, TOGGLE_UPDATE_RADAR_DIALOG } from "../../store/modules/radar";
import { timeAwareFormat } from "../../time";
import EditRadarDialog from "./EditRadarDialog";
import { hasActionAuthorization } from "../../authorization";

export default {
  data: () => ({
    headers: [
      {
        text: "Agent Id",
        align: "left",
        value: "Id",
      },
      {
        text: "Host Key",
        value: "HostKey",
      },
      {
        text: "Status",
        value: "status",
      },
      {
        text: "Host Name",
        value: "HostName",
      },
      {
        text: "User Name",
        value: "Username",
      },
      {
        text: "OS",
        value: "OS",
      },
      {
        text: "Created",
        value: "createdAt",
      },
      {
        text: "Actions",
        value: "action",
      },
    ],
    options: { itemsPerPage: 10, page: 1 },
    filters: { status: "", hostKey: "", hostName: "", username: "" },
  }),
  watch: {
    options: {
      handler(newVal, oldVal) {
        if (newVal.page != oldVal.page || newVal.itemsPerPage != oldVal.itemsPerPage) {
          this.fetchRadars(newVal);
        }
      },
      deep: true,
    },
  },
  components: {
    "edit-radar-dialog": EditRadarDialog,
  },
  computed: {
    ...mapState("radar", {
      radars: (state) =>
        state.pagedRadars.map((i) => ({
          ...i,
          status: i.RadarStatus.Name,
          createdAt: timeAwareFormat(i.createdAt),
        })),
      totalCount: (state) => state.totalCount,
      radarStatuses: (state) => state.radarStatuses,
      isLoading: (state) => state.radarIsLoading,
    }),
  },
  created() {
    this.fetchRadars(this.options);
    this.fetchRadarStatus();
  },
  methods: {
    ...mapActions("radar", {
      fetchRadars: FILTER_RADARS,
      fetchRadarStatus: FETCH_RADAR_STATUSES,
      toggleEditDialog: TOGGLE_UPDATE_RADAR_DIALOG,
    }),
    hasAuth(action) {
      return hasActionAuthorization(this.$store, "Radar", action);
    },
    actionControl(item, status) {
      return item.RadarStatus.Name !== status;
    },
    editRadar(id, status) {
      let data = { id, status };
      this.toggleEditDialog(data);
    },
    filter() {
      this.fetchRadars({ ...this.options, ...this.filters });
    },
  },
};
</script>
