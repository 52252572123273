import Api from "../../hype/api";
import { getDefaultMutations } from "../../hype/helpers/vuex";

const baseState = {
  nodes: [],
  nodeMap: {},
};

export default {
  state: () => baseState,
  mutations: {
    ...getDefaultMutations(baseState),
    setNode(state, node) {
      state.nodeMap[node.name] = node;
      state.nodes[state.nodes.findIndex((n) => n.name == node.name)] = node;
    },
  },
  actions: {
    async loadNodes({ commit }) {
      let nodes = await Api.getNodes();
      let nodeMap = {};
      nodes.forEach((n) => {
        n.icon = n.icon?.startsWith("fa") || n.icon?.startsWith("mdi") ? n.icon.replace(":", "-") : n.icon;
        nodeMap[n.name] = n;
      });
      commit("setNodeMap", nodeMap);
      commit("setNodes", nodes);
    },
    async loadNodesDetailed({ commit }, nodeNames) {
      let nodes = await Api.getNodesDetailed(nodeNames);
      for (const node of nodes) {
        node.icon = node.icon?.startsWith("fa") || node.icon?.startsWith("mdi") ? node.icon.replace(":", "-") : node.icon;
        commit("setNode", node);
      }
    },
  },
  getters: {
    startNode(state) {
      return state.nodes.find((node) => node.name == "n8n-nodes-base.start");
    },
    getNodeTypeByName: (state) => (name) => {
      return state.nodeMap[name];
    },
    getNodesByCredentialType: (state) => (credentialType) => {
      return state.nodes.filter((node) => node.credentials?.find((credential) => credential.name == credentialType));
    },
  },
  namespaced: true,
};
