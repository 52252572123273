var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"padding":"50px"}},[_c('v-card',{attrs:{"flat":"","elevation":2}},[_c('v-card-text',[_c('v-data-table',{attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalExecutions,"page":_vm.currentPage},on:{"update:options":function($event){_vm.options=$event},"update:page":function($event){_vm.currentPage=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"d-flex flex-row"},[_c('span',{staticClass:"title"},[_vm._v("Executions")]),_c('v-spacer'),_c('v-select',{staticClass:"flex-grow-0 mr-5",attrs:{"label":"Workflow","items":_vm.workflows},model:{value:(_vm.filterWorkflow),callback:function ($$v) {_vm.filterWorkflow=$$v},expression:"filterWorkflow"}}),_c('v-select',{staticClass:"flex-grow-0",attrs:{"label":"Status","items":[
                { text: 'all', value: null },
                { text: 'success', value: true },
                { text: 'failure', value: false },
                { text: 'running', value: 'running' } ]},model:{value:(_vm.filterFinished),callback:function ($$v) {_vm.filterFinished=$$v},expression:"filterFinished"}})],1)]},proxy:true},{key:"item.runningTime",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s((new Date(item.stoppedAt) - new Date(item.startedAt)) / 1000)+" sec. ")]}},{key:"item.finished",fn:function(ref){
              var item = ref.item;
return [(item.finished === null)?_c('v-chip',{attrs:{"color":"yellow"}},[_vm._v(" Running ")]):_vm._e(),(item.finished === true)?_c('v-chip',{attrs:{"color":"success"}},[_vm._v("Success")]):_vm._e(),(item.finished === false)?_c('v-chip',{attrs:{"color":"error"}},[_vm._v("Failed")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
              var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"elevation":"0","to":("executions/" + (item.id))}},[_c('v-icon',[_vm._v("visibility")])],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }