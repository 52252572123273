<template>
  <input-wrapper @click="openExpressionEditor" :has-expression="hasExpression" @remove="remove" :removable="removable">
    <template v-slot:default="{ disabled }">
      <v-select
        :label="property.displayName"
        v-model="data"
        :disabled="options.disabled || disabled"
        :append-icon="appendIcon"
        @click:append="openExpressionEditor"
        :multiple="multiple"
        :items="items"
      >
        <template v-slot:append-outer>
          <definition-tip
            :name="property.displayName"
            :definition="property.description"
          ></definition-tip>
        </template>
      </v-select>
    </template>
  </input-wrapper>
</template>

<script>
import DefinitionTip from '../DefinitionTip'
import dynamicProperty from '../../mixins/dynamicProperty'
import InputWrapper from './InputWrapper'
export default {
  components: { InputWrapper, DefinitionTip },
  mixins: [dynamicProperty],
  inheritAttrs: false,
  props: ['value', 'property', 'options', 'path', 'multiple', 'items'],
  mounted() {
    // if (this.property.name === 'calendar') {
    //   debugger
    // }
  },
}
</script>
