<template>
  <div>
    <v-card :disabled="disabled" outlined class="ma-2 pa-3" v-if="value">
      <v-alert
        dense
        type="warning"
        v-if="this.value.rules && this.value.rules.required"
      >This key is required</v-alert>
      <div class="d-flex">
        <div>
          <span class="title">{{this.value.key}}</span>
          <span class="subtitle ml-2">{{this.value.label}}</span>
          <span class="subtitle ml-2 font-italic font-weight-light">{{this.value.type}}</span>
          <p class="grey--text">{{this.value.definition}}</p>
          <div v-if="showSelectionInfo">
            <v-chip
              class="ma-1"
              v-for="value in this.environment.value.split(',')"
              :key="value"
            >{{value}}</v-chip>
          </div>
        </div>

        <div class="ml-auto d-flex justify-center">
          <v-btn class="ma-3" icon @click="openEdit()">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>

          <v-btn @click="() => $emit('remove',this.value)" class="ma-3" icon color="red">
            <v-icon>mdi-trash-can</v-icon>
          </v-btn>
        </div>
      </div>
    </v-card>
    <v-btn
      class="my-3"
      color="primary"
      v-if="!this.value && !disabled && !showForm"
      @click="openSave"
    ><v-icon>mdi-plus</v-icon>add</v-btn>
    <v-expand-transition>
      <v-card v-if="showForm">
        <v-card-text>
          <v-form ref="form">
            <v-row>
              <v-col cols="12">
                <v-alert type="info" v-if="showSelectionInfo">
                  In selections, there are two ways to set items. Text can either be set same with value like
                  <code>foo,bar,baz</code> or it is possible to seperate value from text like
                  <code>foo text ->foo value,bar text ->bar value</code>
                </v-alert>
              </v-col>
              <v-col cols="4">
                <v-select :items="typeNames" v-model="environment.type" label="Type"></v-select>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="environment.key"
                  v-if="environment.type != 'file'"
                  :rules="keyRules"
                  label="Key"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field v-model="environment.value" v-if="showValue" label="Items"></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field v-model="environment.label" label="Placeholder"></v-text-field>
              </v-col>
              <v-col cols="8">
                <v-text-field v-model="environment.definition" label="Description"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-switch v-model="environment.rules.required" label="This field is required" />
              </v-col>
              <v-spacer />
              <v-col cols="12">
                <v-btn color="primary" @click="save">Save</v-btn>
                <v-btn color="grey" text @click="cancel">Cancel</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-expand-transition>
  </div>
</template>

<script>
import { checkLiterals } from "../environmentCheck";
export default {
  props: ["value", "disableFile", "disabled"],
  computed: {
    typeNames() {
      let names = Object.keys(this.types);
      if (
        this.disableFile == true &&
        ((this.value && this.value.type != "file") || !this.value)
      ) {
        names = names.filter(v => v != "file");
      }
      return names;
    },
    showValue() {
      return this.types[this.environment.type].showValue;
    },
    showSelectionInfo() {
      return ["selection", "multiple-selection"].find(
        t => t == this.environment.type
      );
    }
  },
  data() {
    return {
      showForm: false,
      keyRules: [
        v => !!v || "Key is required",
        v => checkLiterals(v) || "Key can only contain letters and dashes"
      ],
      types: {
        text: {
          showValue: false
        },
        file: {
          showValue: false
        },
        selection: {
          showValue: true
        },
        "multiple-selection": {
          showValue: true
        },
        switch: {
          showValue: false
        }
      },
      isRequired: false,
      environment: {
        type: "text",
        key: "",
        value: "",
        placeholder: "",
        definition: "",
        rules: {
          required: false
        }
      }
    };
  },
  created() {
    this.setEnvironment();
  },
  methods: {
    setEnvironment() {
      if (this.value) {
        this.environment = JSON.parse(JSON.stringify(this.value));
        if (!this.environment.rules) {
          this.environment.rules = { required: false };
        }
      } else {
        this.environment = {
          type: "text",
          key: "",
          value: "",
          placeholder: "",
          definition: "",
          rules: {
            required: false
          }
        };
      }
    },
    cancel() {
      this.showForm = false;
      this.$emit("cancel");
    },
    save() {
      if (this.$refs.form.validate()) {
        this.clearUnnecesaryFields();
        if (this.value) {
          this.$emit("input", JSON.parse(JSON.stringify(this.environment)));
        }
        this.$emit("save", JSON.parse(JSON.stringify(this.environment)));
        this.showForm = false;
      }
    },
    openEdit() {
      this.$emit("edit", this.value);
      this.showForm = true;
      this.setEnvironment();
    },
    openSave() {
      this.showForm = true;
      this.setEnvironment();
    },
    clearUnnecesaryFields() {
      if (!this.types[this.environment.type].showValue) {
        this.environment.value = "";
      }

      if (this.environment.type == "file") {
        this.environment.key = "";
      }
    }
  }
};
</script>