<template>
  <div>
    <v-container>
      <v-row>
        <v-col>
          <v-card class="py-5 px-5">
            <h3>Script Templates</h3>
            <p>
              Find the best script for your business. Customize environment
              variables and user attended variables to fit your business needs.
            </p>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <template>
            <v-card class="py-5 px-5">
              <v-tabs
                v-model="currCategory"
                background-color="primary"
                color="white accent-4"
                left
              >
                <v-tab :key="category.Id" v-for="category in categories">{{
                  category.Name
                }}</v-tab>
                <v-tab v-if="categories.length > 0" key="custom">custom</v-tab>
              </v-tabs>
              <v-tabs-items v-model="currCategory">
                <v-tab-item
                  :v-model="category.Name"
                  :key="category.Id + 'item'"
                  v-for="category in categories"
                >
                  <v-container fluid>
                    <v-row>
                      <v-col cols="12">
                        <h3>{{ category.Name }}</h3>
                        <p>{{ category.Description }}</p>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        v-for="plugin in category.Plugins"
                        :key="plugin.Id"
                        cols="12"
                        md="3"
                      >
                        <template>
                          <v-card class="mx-auto" max-width="400">
                            <v-card-title class="justify-center">{{
                              plugin.Name
                            }}</v-card-title>
                            <v-img
                              class="white--text mx-auto"
                              height="200px"
                              max-width="200px"
                              :src="plugin.PhotoURL"
                            ></v-img>
                            <v-card-text class="text--primary text-center">
                              <v-chip
                                class="ma-1"
                                v-for="icon in plugin.PlatformIcons"
                                :key="icon"
                                color="grey lighten-2"
                              >
                                <v-icon>{{ icon }}</v-icon>
                              </v-chip>
                              <div>{{ plugin.Description }}</div>
                            </v-card-text>

                            <v-card-actions>
                              <v-menu
                                origin="center center"
                                transition="slide-x-transition"
                              >
                                <template v-slot:activator="{ on }">
                                  <v-btn
                                    class="flex-grow-1"
                                    color="primary"
                                    dark
                                    v-on="on"
                                    >Use Script</v-btn
                                  >
                                </template>

                                <v-list>
                                  <v-list-item
                                    @click.stop="openBasicPluginDialog(plugin)"
                                  >
                                    <v-list-item-title>Basic</v-list-item-title>
                                  </v-list-item>
                                  <v-list-item
                                    @click.stop="openScriptDialog(plugin)"
                                  >
                                    <v-list-item-title
                                      >Advanced</v-list-item-title
                                    >
                                  </v-list-item>
                                </v-list>
                              </v-menu>
                            </v-card-actions>
                          </v-card>
                        </template>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-tab-item>
                <v-tab-item v-if="categories.length > 0" key="custom">
                  <v-container fluid>
                    <v-row>
                      <v-col cols="12">
                        <h3>Custom</h3>
                        <p>Create a custom script from stratch!</p>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="3">
                        <template>
                          <v-card
                            class="mx-auto justify-center"
                            max-width="400"
                          >
                            <v-card-title class="justify-center"
                              >Custom</v-card-title
                            >
                            <v-img
                              class="white--text mx-auto"
                              height="200px"
                              max-width="200px"
                              :src="
                                this.$assetHelper.getPluginImage('custom.png')
                              "
                            ></v-img>

                            <v-card-text class="text--primary text-center">
                              <div>Create your own script!</div>
                            </v-card-text>

                            <v-card-actions>
                              <v-btn
                                class="flex-grow-1"
                                color="primary"
                                @click="openScriptDialog(null)"
                                >Add Script!</v-btn
                              >
                            </v-card-actions>
                          </v-card>
                        </template>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-tab-item>
              </v-tabs-items>
            </v-card>
          </template>
        </v-col>
      </v-row>
      <NewScriptDialog
        v-model="dialog"
        button-text="Use Plugin"
        :plugin="plugin"
      />
      <AddScriptDialog v-model="basicPluginDialog" :plugin="plugin" />
    </v-container>
  </div>
</template>

<script>
import NewScriptDialog from "./NewScriptDialog";
import AddScriptDialog from "./AddScriptDialog";
import Api from "../api";

export default {
  components: {
    NewScriptDialog,
    AddScriptDialog,
  },
  data() {
    return {
      currCategory: 1,
      categories: [],
      plugin: null,
      dialog: false,
      basicPluginDialog: false,
    };
  },
  async created() {
    let platformIconMapping = {
      windows: "mdi-windows",
      linux: "mdi-linux",
      mac: "mdi-apple",
    };

    this.categories = await Api.getCategoriesWithPlugins();
    this.categories.forEach((category) => {
      category.Plugins.forEach((p) => {
        p.PlatformIcons = p.Platforms.map(
          (platform) => platformIconMapping[platform]
        );
      });
    });
    this.currCategory = 0;
  },
  methods: {
    openScriptDialog(plugin) {
      this.dialog = true;
      this.plugin = plugin;
    },
    openBasicPluginDialog(plugin = null) {
      this.plugin = plugin;
      this.basicPluginDialog = true;
    },
  },
};
</script>
