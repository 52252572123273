import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// import VueAnalytics from "vue-analytics";

import socket from "./socket";
socket.connect();

import vuetify from "./plugins/vuetify";
import Vuetify from "vuetify";
import VueGoogleCharts from "vue-google-charts";
import VueClipboard from "vue-clipboard2";
import DatetimePicker from "vuetify-datetime-picker";
import AssetHelper from "./asset";
import DefinitionTip from "./views/DefinitionTip.vue";
import Collection from "./hype/components/dynamicForm/Collection.vue";
import FixedCollection from "./hype/components/dynamicForm/FixedCollection.vue";
import TextField from "./hype/components/dynamicForm/TextField.vue";
import Boolean from "./hype/components/dynamicForm/Boolean.vue";
import Select from "./hype/components/dynamicForm/Select.vue";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";
import { apiURL } from "./hype/helpers/url";

const plugin = {
  install(Vue) {
    Vue.prototype.$assetHelper = AssetHelper;
  },
};

import VueFormJsonSchema from "vue-form-json-schema";

Vue.component("vue-form-json-schema", VueFormJsonSchema);
Vue.component("dynamic-collection", Collection);
Vue.component("fixed-dynamic-collection", FixedCollection);
Vue.component("dynamic-text-field", TextField);
Vue.component("dynamic-boolean", Boolean);
Vue.component("dynamic-select", Select);
Vue.use(VueGoogleCharts);
Vue.use(VueClipboard);
Vue.use(DatetimePicker);
Vue.use(plugin);
Vue.use(Vuetify);
Vue.component("vue-form-json-schema", VueFormJsonSchema);
Vue.component("definition-tip", DefinitionTip);
Vue.config.productionTip = false;

// Vue.use(VueAnalytics, {
//   id: "UA-162764371-2",
//   router,
// });

Vue.mixin({
  methods: { apiURL },
});

export default new Vue({
  router,
  store,
  vuetify,
  VueGoogleCharts,
  VueClipboard,
  render: (h) => h(App),
}).$mount("#app");
