/**
 * 
 * @param {string} json 
 */
export function check(json) {
    try {
        JSON.parse(json)
        return true
    } catch (error) {
        return error.message
    }
}