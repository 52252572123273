<template>
  <v-card class="shadow-xl mx-5 my-5 pa-2">
    <v-alert
      v-show="updateResult === '0'"
      text
      prominent
      type="error"
      icon="mdi-alert-circle"
      >Payment method has not been changed. Try again later</v-alert
    >
    <v-alert
      v-show="updateResult === '1'"
      text
      prominent
      type="success"
      icon="mdi-cloud-check"
      >Payment method has been changed successfully</v-alert
    >
  </v-card>
</template>
<script>
import Api from "../api";
import { stripePublishableKey } from "../config";

export default {
  data: () => ({
    stripe: null,
    updateResult: null,
  }),
  created() {
    this.updateResult = this.$route.query.r;
    if (!this.updateResult) {
      this.stripe = Stripe(stripePublishableKey); // eslint-disable-line
      Api.updatePaymentMethod().then((response) => {
        this.redirectToCheckout(response);
      });
    }
  },
  methods: {
    redirectToCheckout(response) {
      this.stripe
        .redirectToCheckout({
          sessionId: response.id,
        })
        .then(function(result) {
          console.log(result);
        });
    },
  },
};
</script>
