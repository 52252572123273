<template>
  <div v-show="open" class="outer" @click="checkClickAway">
    <v-slide-y-transition>
      <div
        style="background-color: white; width: 400px"
        ref="list"
        class="nodeListContainer"
        :style="`top: ${positionY}px; left: ${positionX}px`"
        v-show="open"
      >
        <v-text-field
          @keypress.enter="filteredNodes[0] && nodeSelected(filteredNodes[0])"
          autofocus
          autocomplete="off"
          v-model="search"
          :placeholder="`Search for ${selected == 0?  'an action' : 'a trigger'}`"
          rounded
          filled
          dense
        ></v-text-field>
        <v-tabs v-model="selected" color="primary" fixed-tabs>
          <v-tab>action</v-tab>
          <v-tab>trigger</v-tab>
          
        </v-tabs>
        <v-list three-line class="nodeList">
          <node-list-item
            :node="node"
            v-for="node in filteredNodes"
            @click="() => nodeSelected(node)"
            :key="node.name"
          />
        </v-list>
      </div>
    </v-slide-y-transition>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import NodeListItem from './NodeListItem'
import { mapState } from 'vuex'
export default {
  props: ['position-x', 'position-y', 'open'],
  components: {
    NodeListItem,
  },
  data: () => ({
    search: '',
    selected: 0,
  }),
  watch: {
    open(val) {
      if (val) {
        this.search = ''
      }
    },
  },
  computed: {
    ...mapState('node', {
      nodes: state => state.nodes.filter(n => n.name != 'n8n-nodes-base.start'),
    }),
    selectedNodesByType() {
      return this.selected == 2
        ? this.nodes
        : this.nodes.filter(n => this.selected == this.getNodeType(n))
    },
    filteredNodes() {
      return this.search
        ? this.selectedNodesByType.filter(n => {
            return n.displayName
              .toLowerCase()
              .includes(this.search.toLowerCase())
          })
        : this.selectedNodesByType
    },
  },
  methods: {
    getNodeType(node) {
      return node.group.includes('trigger') ? 1 : 0
    },
    checkClickAway(event) {
      if (!this.$refs.list.contains(event.target)) {
        this.$emit('clickaway')
      }
    },
    nodeSelected(selectedNode) {
      this.$emit('nodeSelected', {
        node: selectedNode,
        position: { x: this.positionX, y: this.positionY },
      })
    },
  },
}
</script>

<style scoped>
.outer {
  z-index: 99998;
  position: fixed;
  height: 100%;
  width: 100%;
}

.nodeListContainer {
  z-index: 99999;
  position: fixed;
  -webkit-box-shadow: 0px 0px 21px 2px rgba(222, 220, 247, 1);
  -moz-box-shadow: 0px 0px 21px 2px rgba(222, 220, 247, 1);
  box-shadow: 0px 0px 21px 2px rgba(222, 220, 247, 1);
  padding: 5px;
}

.nodeList {
  height: 200px;
  overflow: scroll;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.nodeList::-webkit-scrollbar {
  display: none;
}
</style>
