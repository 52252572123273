<template>
  <div>
    <v-dialog v-model="show" width="600" persistent>
      <v-card>
        <v-card-title primary-title v-show="!success" class="headline">{{ !this.isUpdate ? "Add Script" : "Update Script" }}</v-card-title>
        <v-card-text class="py-5" v-if="loading | success">
          <v-alert v-show="!!success" text prominent type="success" icon="mdi-cloud-check">Succesfully Created</v-alert>
          <v-container class="text-center" v-if="loading">
            <v-progress-circular indeterminate color="yellow darken-2"></v-progress-circular>
            <p>This process may take a few minutes</p>
          </v-container>
        </v-card-text>
        <v-card-text class="py-5" v-if="!loading && !success">
          <v-alert v-show="!!error || !!fileError" text prominent type="error" icon="mdi-alert-circle">{{ fileError || error }}</v-alert>
          <p v-html="plugin && plugin.HtmlDescription"></p>
          <v-text-field outlined label="Name" v-model="editedItem.Name"></v-text-field>

          <v-textarea outlined label="Description" v-model="editedItem.Description"></v-textarea>

          <v-row>
            <v-col cols="12" sm="3" md="6">
              <v-checkbox v-model="editedItem.Rdp" label="Rdp"></v-checkbox>
            </v-col>

            <v-col cols="12" sm="3" md="6">
              <v-checkbox v-model="editedItem.Queue" label="Queue"></v-checkbox>
            </v-col>
          </v-row>
          <v-form ref="form">
            <vue-form-json-schema v-if="plugin && !success" v-model="editedItem.Environment" :schema="schema" :ui-schema="uiSchema"></vue-form-json-schema>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed @click="save" v-if="!loading && !success" color="primary">SAVE</v-btn>
          <v-btn
            @click.stop="
              () => {
                show = false;
                if (success && !isUpdate) {
                  $router.push('/scripts');
                }
              }
            "
            depressed
            >{{ success ? "OK" : "Cancel" }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import environmentConventer from "../environmentConverter";
import { CREATE_SCRIPT, EDIT_SCRIPT, CLEAR_ALL } from "../store/modules/script";
import { mapActions, mapState } from "vuex";
import Api from "../api";

export default {
  props: ["value", "plugin", "script"],
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    ...mapState("script", {
      loading: (state) => state.createLoading || state.editLoading,
      error: (state) => state.createError || state.editError,
      success: (state) => state.createSuccess || state.editSuccess,
    }),
  },
  watch: {
    value(val) {
      if (val) this.onOpened();
    },
  },
  data: () => ({
    dialogAddScript: "",
    schema: {},
    uiSchema: [],
    editedItem: {},
    isUpdate: false,
    fileError: "",
    fileSize: 0,
  }),
  async created() {
    this.fileSize = await Api.getPlanScriptSize();
  },
  methods: {
    ...mapActions("script", {
      createScript: CREATE_SCRIPT,

      editScript: EDIT_SCRIPT,
      clearAll: CLEAR_ALL,
    }),
    closeDialogAddScript() {
      this.dialogAddScript = false;
    },
    onOpened() {
      this.clearAll();

      if (this.script) {
        this.plugin = { ...this.script.Plugin };
        this.isUpdate = true;
        let fileField = this.plugin.EnvironmentSchema.find((v) => v.type == "file");
        if (fileField) {
          delete fileField.rules;
        }
        this.editedItem = { ...this.script };
        this.editedItem = {
          Name: this.editedItem.Name,
          Description: this.editedItem.Description,
          BashScript: this.editedItem.BashScript,
          CmdScript: this.editedItem.CmdScript,
          Environment: this.editedItem.Environment,
          AttendedEnvironment: this.editedItem.Plugin.AttendedEnvironmentSchema,
          IsBasic: true,
          PluginId: this.editedItem.Plugin.Id,
          ScriptId: this.editedItem.Id,
          Rdp: this.editedItem.Rdp,
          Queue: this.editedItem.Queue,
        };
      } else {
        this.editedItem = {
          Name: this.plugin.Name,
          Description: this.plugin.Description,
          BashScript: this.plugin.BashScript,
          CmdScript: this.plugin.CmdScript,
          Environment: {},
          AttendedEnvironment: this.plugin.AttendedEnvironmentSchema,
          IsBasic: true,
          PluginId: this.plugin.Id,
          Rdp: false,
          Queue: this.plugin.Queue,
        };
      }

      let { schema, uiSchema } = environmentConventer(this.plugin.EnvironmentSchema);

      this.schema = schema;
      this.uiSchema = uiSchema;
    },
    save() {
      this.fileError = "";
      if (!this.$refs.form.validate()) {
        return;
      }

      let formToGetFile = new FormData(this.$refs.form.$el);

      let fileEntry;

      if (formToGetFile.has("File")) {
        fileEntry = formToGetFile.get("File");

        if (this.isUpdate && !fileEntry.size) {
          fileEntry = null;
        }

        if (fileEntry && fileEntry.size) {
          if (this.fileSize > 0) {
            if (fileEntry.size > this.fileSize * 1048576) {
              this.fileError = `Maximum ${this.fileSize} MB`;
              return;
            }
          }
        }
      }

      let form = new FormData();

      Object.entries(this.editedItem).forEach(([key, value]) => {
        form.set(key, typeof value == "string" ? value : JSON.stringify(value));
      });

      form.set("Rdp", this.editedItem.Rdp || false);
      form.set("Queue", this.editedItem.Queue || false);

      if (fileEntry) {
        form.set("File", fileEntry);
      }
      if (this.isUpdate) {
        this.editScript(form);
      } else {
        this.createScript(form);
      }
    },
  },
};
</script>
