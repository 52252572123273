import { unique } from './array'

export function extend(credentialType, credentialTypesMap) {
  //return if not extending
  if (!credentialType.extends) return
  for (const parent of credentialType.extends) {
    let parentCredentialType = credentialTypesMap[parent]

    extend(parentCredentialType, credentialTypesMap)

    if (
      parent === 'oAuth1Api' ||
      parent === 'oAuth2Api' ||
      parentCredentialType.isOAuth
    ) {
      credentialType.isOAuth = true
    }

    let properties = [
      ...credentialType.properties,
      ...credentialTypesMap[parent].properties,
    ]
    credentialType.properties = unique(properties, (a, b) => a.name === b.name)
  }
}
