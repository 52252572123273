<template>
  <v-dialog v-model="dialog" persistent max-width="800px">
    <v-card>
      <v-form ref="form">
        <v-card-title>
          <span class="headline">Labels</span>
        </v-card-title>
        <v-alert v-show="!!error" text prominent type="error" icon="mdi-alert-circle">{{error}}</v-alert>
        <v-alert
          v-show="!!success"
          text
          prominent
          type="success"
          icon="mdi-cloud-check"
        >Succesfully Created</v-alert>
        <v-container>
          <v-row>
            <v-col cols="10" sm="5" md="10">
              <v-text-field
                v-model="name"
                label="Name"
                required
                maxlength="50"
                counter
                :rules="nameRules"
              ></v-text-field>
            </v-col>
            <v-col cols="2" sm="1" md="2">
              <v-btn color="primary" depressed v-on:click="save">Add</v-btn>
            </v-col>
            <v-col cols="12" sm="6" md="12">
              <v-skeleton-loader v-if="isLoading" ref="skeleton" type="table" class="mx-auto"></v-skeleton-loader>
              <v-data-table
                :headers="headers"
                :items="labels"
                class="shadow-xl mx-5 my-5"
                :search="search"
                v-else
                hide-default-footer
              >
                <template v-slot:top>
                  <v-toolbar flat color="white">
                    <v-row>
                      <v-col cols="10" sm="3" md="4" offset-md="8" class="px-5">
                        <v-text-field
                          v-model="search"
                          append-icon="search"
                          label="Search"
                          single-line
                          hide-details
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-toolbar>
                </template>
                <template v-slot:item.Name="props">
                  <v-edit-dialog @save="update(props.item.Id)" @open="setEditName(props.item.Name)">
                    {{ props.item.Name }}
                    <template v-slot:input>
                      <v-text-field
                        v-model="editName"
                        :rules="nameRules"
                        label="Edit"
                        single-line
                        counter
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:item.action="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{on}">
                      <v-btn depressed @click="deleteLabel(item.Id)" v-on="on">
                        <v-icon size="18">delete</v-icon>
                      </v-btn>
                    </template>
                    <span>Delete Label</span>
                  </v-tooltip>
                </template>
                <template v-slot:no-data></template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" text @click="toggleDialog">Close</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from "vuex";
import {
  FETCH_LABELS,
  CREATE_LABEL,
  UPDATE_LABEL,
  DELETE_LABEL,
  TOGGLE_LABEL_DIALOG,
} from "../../store/modules/asset";

export default {
  data: () => ({
    headers: [
      {
        text: "Name",
        value: "Name",
      },
      {
        text: "Actions",
        value: "action",
        align: "right",
      },
    ],
    search: "",
    name: "",
    editName: "",
    nameRules: [(v) => !!v || "Label name is required"],
  }),
  computed: {
    ...mapState("asset", {
      labels: (state) => state.labels,
      isLoading: (state) => state.labelIsLoading,
      dialog: (state) => state.labelDialog,
      success: (state) =>
        state.createLabel.success ||
        state.updateLabel.success ||
        state.deleteLabel.success,
      error: (state) =>
        state.createLabel.error ||
        state.updateLabel.error ||
        state.deleteLabel.error,
    }),
  },
  mounted() {
    this.fetchLabels();
  },
  methods: {
    ...mapActions("asset", {
      fetchLabels: FETCH_LABELS,
      createLabel: CREATE_LABEL,
      updateLabel: UPDATE_LABEL,
      deleteLabel: DELETE_LABEL,
      toggleDialog: TOGGLE_LABEL_DIALOG,
    }),
    save() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.createLabel({ Name: this.name });
      this.name = "";
    },
    setEditName(name) {
      this.editName = name;
    },
    update(id) {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.updateLabel({ labelId: id, label: { Name: this.editName } });
    },
  },
};
</script>