<template>
  <div>
    <v-skeleton-loader v-if="isLoading" ref="skeleton" type="table" class="mx-auto"></v-skeleton-loader>
    <v-data-table
      :headers="headers"
      :items="skills"
      class="shadow-xl mx-5 my-5"
      :footer-props="{
        itemsPerPageOptions: [10, 50, 100],
      }"
      v-else
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <div class="d-none d-sm-flex">
            <v-toolbar-title>Skills</v-toolbar-title>
          </div>
          <v-spacer></v-spacer>
          <new-skill-dialog v-if="digistaffId && hasAuth('Skills', 'New Skill')" :digistaffId="digistaffId" />
          <edit-skill-dialog v-if="digistaffId && hasAuth('Skills', 'Edit Skill')" :digistaffId="digistaffId" />
          <delete-skill-dialog v-if="digistaffId && hasAuth('Skills', 'Delete Skill')" :digistaffId="digistaffId" />
          <department-auth-dialog v-if="hasAuth('Skills', 'Department Auths')" />
          <department-dialog v-if="hasAuth('Departments', 'View')" />
          <title-auth-dialog v-if="hasAuth('Skills', 'Title Auths')" />
          <title-dialog v-if="hasAuth('Titles', 'View')" />
          <user-auth-dialog v-if="hasAuth('Skills', 'User Auths')" />
        </v-toolbar>
      </template>
      <template v-slot:item.action="{ item }">
        <v-tooltip bottom v-if="hasAuth('Skills', 'Edit Skill')">
          <template v-slot:activator="{ on }">
            <v-btn depressed class="mx-1" @click="() => editSkill(item)" v-on="on">
              <v-icon size="18">edit</v-icon>
            </v-btn>
          </template>
          <span>Edit Skill</span>
        </v-tooltip>
        <v-tooltip bottom v-if="hasAuth('Skills', 'Delete Skill')">
          <template v-slot:activator="{ on }">
            <v-btn depressed class="mx-1" @click="deleteSkill(item.Id)" v-on="on">
              <v-icon size="18">delete</v-icon>
            </v-btn>
          </template>
          <span>Delete Skill</span>
        </v-tooltip>
        <v-tooltip bottom v-if="hasAuth('Skills', 'Department Auths')">
          <template v-slot:activator="{ on }">
            <v-btn depressed class="mx-1" color="primary" v-on:click="openDepartmentAuth(item.Id)" v-on="on">
              <v-icon size="18">assignment_ind</v-icon>
            </v-btn>
          </template>
          <span>Department Auth</span>
        </v-tooltip>
        <v-tooltip bottom v-if="hasAuth('Skills', 'Title Auths')">
          <template v-slot:activator="{ on }">
            <v-btn depressed class="mx-1" color="primary" @click="openTitleAuth(item.Id)" v-on="on">
              <v-icon size="18">account_circle</v-icon>
            </v-btn>
          </template>
          <span>Title Auth</span>
        </v-tooltip>
        <v-tooltip bottom v-if="hasAuth('Skills', 'User Auths')">
          <template v-slot:activator="{ on }">
            <v-btn depressed class="mx-1" color="primary" @click="openUserAuth(item.Id)" v-on="on">
              <v-icon size="18">account_box</v-icon>
            </v-btn>
          </template>
          <span>User Auth</span>
        </v-tooltip>
      </template>
      <template v-slot:no-data></template>
    </v-data-table>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import {
  FETCH_SKILLS,
  TOGGLE_UPDATE_SKILL_DIALOG,
  TOGGLE_DELETE_SKILL_DIALOG,
  TOGGLE_DEPARTMENT_AUTH_DIALOG,
  TOGGLE_TITLE_AUTH_DIALOG,
  TOGGLE_USER_AUTH_DIALOG,
} from "../../store/modules/digistaff";
import { timeAwareFormat } from "../../time";
import NewSkillDialog from "./NewSkillDialog";
import EditSkillDialog from "./EditSkillDialog";
import DeleteSkillDialog from "./DeleteSkillDialog";
import { hasActionAuthorization } from "../../authorization";
import DepartmentAuthDialog from "../auths/DepartmentAuthDialog";
import DepartmentDialog from "../department/DepartmentDialog";
import TitleAuthDialog from "../auths/TitleAuthDialog";
import TitleDialog from "../title/TitleDialog";
import UserAuthDialog from "../auths/UserAuthDialog";

export default {
  data: () => ({
    headers: [
      {
        text: "Name",
        value: "Name",
      },
      {
        text: "Code",
        value: "Code",
      },
      {
        text: "Digistaff",
        value: "Digistaff.Name",
      },
      {
        text: "Created Time",
        value: "createdAt",
      },
      {
        text: "Actions",
        value: "action",
      },
    ],
    digistaffId: null,
  }),
  components: {
    "new-skill-dialog": NewSkillDialog,
    "edit-skill-dialog": EditSkillDialog,
    "delete-skill-dialog": DeleteSkillDialog,
    "department-auth-dialog": DepartmentAuthDialog,
    "department-dialog": DepartmentDialog,
    "title-auth-dialog": TitleAuthDialog,
    "title-dialog": TitleDialog,
    "user-auth-dialog": UserAuthDialog,
  },
  computed: {
    ...mapState("digistaff", {
      skills: (state) =>
        state.skills.map((i) => ({
          ...i,
          createdAt: timeAwareFormat(i.createdAt),
        })),
      isLoading: (state) => state.skillIsLoading,
    }),
  },
  created() {
    let digistaffId = this.$route.params.digistaffId;
    if (digistaffId) {
      this.digistaffId = digistaffId;
      this.fetchSkills(digistaffId);
    }
  },
  methods: {
    ...mapActions("digistaff", {
      fetchSkills: FETCH_SKILLS,
      toggleEditDialog: TOGGLE_UPDATE_SKILL_DIALOG,
      toggleDeleteDialog: TOGGLE_DELETE_SKILL_DIALOG,
      toggleDepartmentAuthDialog: TOGGLE_DEPARTMENT_AUTH_DIALOG,
      toggleTitleAuthDialog: TOGGLE_TITLE_AUTH_DIALOG,
      toggleUserAuthDialog: TOGGLE_USER_AUTH_DIALOG,
    }),
    hasAuth(page, action) {
      return hasActionAuthorization(this.$store, page, action);
    },
    editSkill(item) {
      this.toggleEditDialog(item);
    },
    deleteSkill(id) {
      this.toggleDeleteDialog(id);
    },
    openDepartmentAuth(skillId) {
      this.toggleDepartmentAuthDialog(skillId);
    },
    openTitleAuth(skillId) {
      this.toggleTitleAuthDialog(skillId);
    },
    openUserAuth(skillId) {
      this.toggleUserAuthDialog(skillId);
    },
  },
};
</script>
