<template>
  <v-main>
    <v-container fluid mt-5>
      <v-row align="center" justify="center" class="my-4">
        <v-img
          v-bind:src="this.$assetHelper.getImage('logo-black.png')"
          v-bind:lazy-src="this.$assetHelper.getImage('logo-black.png')"
          aspect-ratio="1"
          max-width="207"
          max-height="47"
          class="mr-2 float-left"
        ></v-img>
      </v-row>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="4" xs="8" align="center">
          <v-card class="shadow-xl pa-5">
            <v-card-text>
              <div class="text-center">
                <v-img
                  v-bind:src="
                    this.$assetHelper.getImage('404-page-not-found.png')
                  "
                  v-bind:lazy-src="
                    this.$assetHelper.getImage('404-page-not-found.png')
                  "
                  max-width="600"
                ></v-img>
                <h1>404 Not Found</h1>
                <p>Oops, the page you're looking for does not exist.</p>
              </div>
              <router-link to="/">
                <v-btn
                  depressed
                  color="primary"
                  class="mt-5 mr-5"
                  @click="goBack"
                  >Go Back</v-btn
                >
                <v-btn depressed color="primary" class="mt-5">Home Page</v-btn>
              </router-link>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
export default {
  methods: {
    goBack() {
      return this.$router.go(-1);
    },
  },
};
</script>

<style></style>
