<template>
  <v-dialog v-model="dialog" persistent max-width="800px">
    <v-card>
      <v-form ref="form">
        <v-card-title>
          <span class="headline">Departments</span>
        </v-card-title>
        <v-alert
          v-show="!!error"
          text
          prominent
          type="error"
          icon="mdi-alert-circle"
          >{{ error }}</v-alert
        >
        <v-alert
          v-show="!!success"
          text
          prominent
          type="success"
          icon="mdi-cloud-check"
          >Succesfully Created</v-alert
        >
        <v-container>
          <v-row v-if="hasAuth('Departments', 'New Department')">
            <v-col cols="1" sm="1" md="1"></v-col>
            <v-col cols="6" sm="2" md="6">
              <v-text-field
                v-model="name"
                label="Name"
                required
                maxlength="100"
                counter
                dense
                :rules="nameRules"
              ></v-text-field>
            </v-col>
            <v-col cols="3" sm="1" md="3">
              <v-text-field
                v-model="code"
                label="Code"
                required
                maxlength="100"
                counter
                dense
                :rules="codeRules"
              ></v-text-field>
            </v-col>
            <v-col cols="1" sm="1" md="1">
              <v-btn color="primary" depressed v-on:click="save">Add</v-btn>
            </v-col>
            <v-col cols="1" sm="1" md="1"></v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" md="12">
              <v-data-table
                :headers="headers"
                :items="departments"
                class="shadow-xl mx-5 my-5"
                :search="search"
                hide-default-footer
              >
                <template v-slot:top>
                  <v-toolbar flat color="white">
                    <v-row>
                      <v-col cols="10" sm="3" md="4" offset-md="8" class="px-5">
                        <v-text-field
                          v-model="search"
                          append-icon="search"
                          label="Search"
                          single-line
                          hide-details
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-toolbar>
                </template>
                <template v-slot:item.action="{ item }">
                  <v-tooltip
                    bottom
                    v-if="hasAuth('Departments', 'Delete Department')"
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn
                        depressed
                        @click="deleteDepartment(item.Id)"
                        v-on="on"
                      >
                        <v-icon size="18">delete</v-icon>
                      </v-btn>
                    </template>
                    <span>Delete Department</span>
                  </v-tooltip>
                </template>
                <template v-slot:no-data></template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" text @click="toggleDialog">Close</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from "vuex";
import {
  FETCH_DEPARTMENTS,
  CREATE_DEPARTMENT,
  UPDATE_DEPARTMENT,
  DELETE_DEPARTMENT,
  TOGGLE_DEPARTMENT_DIALOG,
} from "../../store/modules/digistaff";
import { hasActionAuthorization } from "../../authorization";

export default {
  data: () => ({
    headers: [
      {
        text: "Name",
        value: "Name",
      },
      {
        text: "Code",
        value: "Code",
      },
      {
        text: "Actions",
        value: "action",
        align: "right",
      },
    ],
    search: "",
    name: "",
    code: "",
    editName: "",
    nameRules: [(v) => !!v || "Department name is required"],
    codeRules: [(v) => !!v || "Department Code name is required"],
  }),
  computed: {
    ...mapState("digistaff", {
      departments: (state) => state.departments,
      isLoading: (state) => state.departmentIsLoading,
      dialog: (state) => state.departmentDialog,
      success: (state) =>
        state.createDepartment.success ||
        state.updateDepartment.success ||
        state.deleteDepartment.success,
      error: (state) =>
        state.createDepartment.error ||
        state.updateDepartment.error ||
        state.deleteDepartment.error,
    }),
  },
  methods: {
    ...mapActions("digistaff", {
      fetchDepartments: FETCH_DEPARTMENTS,
      createDepartment: CREATE_DEPARTMENT,
      updateDepartment: UPDATE_DEPARTMENT,
      deleteDepartment: DELETE_DEPARTMENT,
      toggleDialog: TOGGLE_DEPARTMENT_DIALOG,
    }),
    save() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.createDepartment({ Name: this.name, Code: this.code });
      this.name = "";
      this.code = "";
    },
    setEditName(name) {
      this.editName = name;
    },
    update(id) {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.updateDepartment({
        departmentId: id,
        department: { Name: this.editName },
      });
    },
    hasAuth(page, action) {
      return hasActionAuthorization(this.$store, page, action);
    },
  },
};
</script>
