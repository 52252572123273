<template>
  <v-dialog v-model="dialog" max-width="500px" persistent>
    <v-card>
      <v-form ref="form" :lazy-validation="false">
        <v-card-title>
          <span class="headline">Delete Queue Item</span>
        </v-card-title>
        <v-alert v-show="!!error" text prominent type="error" icon="mdi-alert-circle">
          <span v-html="error" />
        </v-alert>
        <v-alert
          v-show="!!success"
          text
          prominent
          type="success"
          icon="mdi-cloud-check"
        >Succesfully Deleted</v-alert>
        <v-card-text>
          <v-container v-if="!success">Are you sure to delete this queue item?</v-container>
          <v-container class="text-center" v-if="loading">
            <v-progress-circular :size="70" :width="7" color="purple" indeterminate></v-progress-circular>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn v-show="!success" depressed color="primary" @click="deleteQueue()">Delete</v-btn>
          <v-btn color="grey darken-1" text @click="toggleDialog">{{success ? "Close" : "Cancel"}}</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
import {
  DELETE_QUEUE_ITEM,
  TOGGLE_DELETE_QUEUE_ITEM_DIALOG
} from "../../store/modules/queue";

export default {
  computed: {
    ...mapState("queue", {
      id: state => state.deleteQueueItem.id,
      dialog: state => state.deleteQueueItem.dialog,
      loading: state => state.deleteQueueItem.isLoading,
      success: state => state.deleteQueueItem.success,
      error: state => state.deleteQueueItem.error
    })
  },
  methods: {
    ...mapActions("queue", {
      deleteQueueItem: DELETE_QUEUE_ITEM,
      toggleDialog: TOGGLE_DELETE_QUEUE_ITEM_DIALOG
    }),
    deleteQueue() {
      this.deleteQueueItem({
        queueId: this.$route.params.id,
        queueItemId: this.id
      });
    }
  }
};
</script>