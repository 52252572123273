<template>
  <v-dialog persistent v-model="dialog" max-width="400">
    <v-card>
      <v-card-title v-if="options.title">{{ options.title }}</v-card-title>
      <v-card-text>
        {{ options.text }}
      </v-card-text>
      <v-card-actions class="d-flex justify-center">
        <v-btn text @click="cancelConfirm" color="red">cancel</v-btn>
        <v-btn @click="confirm">confirm</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  data: () => ({
    model: {},
  }),
  computed: {
    ...mapState("formDialog", {
      dialog(state) {
        if (state.dialog) this.onOpen();
        else this.onClose();
        return state.confirmDialog;
      },
      options: (state) => state.confirmDialogOptions,
    }),
  },
  methods: {
    ...mapActions("formDialog", ["confirm", "cancelConfirm"]),
    saveForm() {
      this.save(JSON.parse(JSON.stringify(this.model)));
    },
    onOpen() {
      // console.log('opened')
    },
    onClose() {
      // console.log('closed')
    },
  },
};
</script>
