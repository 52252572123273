<template>
  <div :key="updateKey" class="ml-2  pa-2">
    <span class="d-flex subtitle-2">{{ displayName }} 
      <v-spacer/>
      <v-btn
      v-if="removable"
      icon
      small
      class="ml-3"
      depressed
      color="red"
      @click="remove"
    >
      <v-icon small>mdi-trash-can</v-icon>
    </v-btn>
    </span>
    <v-divider />
    <div v-for="property in properties" :key="property.name">
      <div
        class="ml-2 pt-2"
        v-for="(item, index) in items[property.name]"
        :key="`${property.name}-${index}`"
      >
        <span class="subtitle-2 d-flex">
          {{ item.displayName }}<v-spacer />
          <v-btn icon @click="removeItem(property, index)">
            <v-icon>mdi-trash-can</v-icon></v-btn
          ></span
        >
        <v-divider />
        <vue-form-json-schema
          v-if="item.schema"
          v-model="item.value"
          :schema="item.schema"
          :ui-schema="item.uiSchema"
          @state-change="onChangeState"
          :key="updateKey"
        ></vue-form-json-schema>
      </div>
    </div>
    <br />
    <v-menu top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          outlined
          :disabled="disabled"
          block
          class="mt-5 mb-5"
        >
          <v-icon>mdi-plus</v-icon> Add {{ displayName }}
        </v-btn>
      </template>

      <v-list>
        <v-list-item
          v-for="property in properties"
          :key="`btn-${property.name}`"
          @click="addItem(property)"
        >
          <v-list-item-title> Add {{ property.displayName }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { convert } from '../../helpers/propertyConverter'
import eventBus from '../../mixins/eventBus'
export default {
  mixins: [eventBus],
  props: ['properties', 'value', 'name', 'displayName', 'disabled', 'path','options'],
  data: () => ({
    uiSchema: [],
    schema: {},
    items: {},
    updateKey: 0,
  }),
  computed: {
    removable(){
      return this.options?.removable
    },
  },
  mounted() {
    let items = this.value ?? {}
    for (const property of this.properties) {
      if (items[property.name]) {
        let name = property.name
        let displayName = property.displayName

        this.items[property.name] = items[property.name].map((item, index) => {
          let { schema, uiSchema } = convert(property.values, {
            disabled: this.disabled,
            path: this.path + '.' + property.name + '.' + index,
          })
          return {
            value: item,
            name,
            displayName,
            schema,
            uiSchema,
          }
        })
      }
    }
    this.updateKey++
  },
  methods: {
    addItem(property) {
      let name = property.name
      let displayName = property.displayName

      this.items[property.name] = this.items[property.name] ?? []
      
      let { schema, uiSchema } = convert(property.values, {
        disabled: this.disabled,
        path:
          this.path +
          '.' +
          property.name +
          '.' +
          this.items[property.name].length,
      })
      this.items[property.name].push({
        value: {},
        name,
        displayName,
        schema,
        uiSchema,
      })
      this.emit()
    },
    removeItem(property, index) {
      console.log('delete', index)
      this.items[property.name].splice(index, 1)
      this.emit()
    },
    remove(){
      this.$pushEvent("remove_path_from_data",this.path);
    },
    emit() {
      let items = {}
      for (const key in this.items) {
        items[key] = this.items[key].map(i => i.value)
      }

      this.$emit('input', items)
    },
    onChangeState() {
      this.emit()
    },
  },
}
</script>
