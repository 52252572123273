import { hasActionAuth } from "../../authorization";

const mixin = (resource) => ({
    methods: {
        hasRowAuth(auth, globalAuthControl, action) {
            if (!auth && globalAuthControl) {
              return this.hasAuth(action);
            }
      
            return auth;
        },
        hasAuth(action) {
            return hasActionAuth(resource, action);
        },
    }
})

export default mixin;