<template>
  <v-dialog persistent v-model="show" :max-width="width">
    <v-card>
      <v-card-title>{{ cardTitle }}</v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-alert v-show="!!fileError" text prominent type="error" icon="mdi-alert-circle">{{ fileError }}</v-alert>
          <vue-form-json-schema v-model="formData" :schema="schema" :ui-schema="uiSchema"></vue-form-json-schema>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.stop="cancel" color="red" text>cancel</v-btn>
        <v-btn @click.stop="save" text>{{ saveButtonText || save }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Api from "../api";

export default {
  props: ["value", "width", "schema", "ui-schema", "card-title", "extra-data", "save-button-text"],
  data() {
    return {
      formData: {},
      fileSize: 0,
      fileError: "",
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        if (!value) {
          this.onClosed();
        }
        this.$emit("input", value);
      },
    },
  },
  async created() {
    this.fileError = "";
    this.fileSize = await Api.getPlanScriptSize();
  },
  methods: {
    onClosed() {
      this.formData = {};
    },
    cancel() {
      this.$emit("cancel");
      this.show = false;
    },
    save() {
      this.fileError = "";

      if (!this.$refs.form.validate()) {
        return;
      }

      Object.keys(this.formData).forEach((key) => {
        if (typeof this.formData[key] === "string") {
          if (this.formData[key].length > 2000) {
            this.fileError = `Attended Enviroment ${key} is too long. (Maximum 2000)`;
            return;
          }
        }
      });

      if (this.fileError) return;

      let formData = new FormData(this.$refs.form.$el);

      if (formData.has("File")) {
        let file = formData.get("File");
        if (file.size && this.fileSize > 0) {
          if (file.size > this.fileSize * 1048576) {
            this.fileError = `Maximum ${this.fileSize} MB`;
            return;
          }
        }
      }

      Object.entries(this.formData).forEach((v) => formData.set(...v));

      this.$emit("save", {
        data: this.formData,
        formData: formData,
        extraData: this.extraData,
      });
      this.show = false;
    },
  },
};
</script>
