<template>
  <v-avatar rounded color="white">
    <v-progress-circular indeterminate v-if="loading" />
    <span
      :style="`font-weight: bold; font-size: 25px; color: ${
        isDark ? 'white' : 'black'
      }`"
      v-else-if="!node.icon"
      >{{ node.displayName[0] }}</span
    >
    <v-icon
      large
      :color="isDark ? 'white' : 'black'"
      v-else-if="
        node.icon && (node.icon.startsWith('fa') || node.icon.startsWith('mdi'))
      "
    >
      {{ node.icon }}
    </v-icon>
    <v-img v-else :src="apiURL(`/node-icon/${node.name}`)"></v-img>
  </v-avatar>
</template>

<script>
export default {
  name: "node-avatar",
  props: ["node", "dark", "loading"],
  computed: {
    isDark() {
      if (this.dark === "") {
        return true;
      }

      return this.dark ?? false;
    },
  },
};
</script>
