import Axios from "axios";
import config from "./config";
import { resolve, resolveToArray } from "./helpers/convertExecutionData";
import { objectToQueryString } from "./helpers/http";
import Auth from "../auth";
import router from "../router";
import { gatewayURL } from "../config";
class Api {
  constructor() {
    this._client = Axios.create({
      baseURL: config.restUrl,
      transformRequest: [
        function(data, headers) {
          headers["Authorization"] = `Bearer ${Auth.getToken()}`;

          if (headers["Content-Type"] == "application/json") {
            return JSON.stringify(data);
          }
          return data;
        },
      ],
      headers: {
        "Content-Type": "application/json",
        sessionid: config.sessionId,
      },
    });

    this._client.interceptors.response.use(
      function(response) {
        return response;
      },
      function(error) {
        if (error.response && error.response.data && error.response.data.code == "M00401") {
          router.push("/email-not-verified");
        } else if (error.response && error.response.data && error.response.data.code == "PLN400") {
          router.push("/account");
        } else if (error.response && error.response.data && error.response.data.code == "PLN404") {
          router.push("/account");
        } else if (error.response && error.response.status === 401) {
          const refreshToken = Auth.getRefreshToken();
          if (!refreshToken) {
            Auth.logout();
            router.push("/");
          }

          return Axios.post(`${gatewayURL}/refresh-token`, {
            refreshToken,
          })
            .then((response) => {
              Auth.setToken(response.data.token);
              Auth.setRefreshToken(response.data.refreshToken);
              error.config.headers = {
                Authorization: `Bearer ${response.data.token}`,
                "Content-Type": "application/json",
                sessionid: config.sessionId,
              };

              error.config.data = JSON.parse(error.config.data);

              Axios.defaults.headers.common["Authorization"] = `Bearer ${response.data.token}`;

              return Axios(error.config);
            })
            .catch((refreshError) => {
              console.log("refreshError", refreshError);
              if (refreshError && refreshError.response && refreshError.response.status === 401) {
                Auth.logout();
                router.push("/");
              }

              return Promise.reject(error);
            });
        }

        return Promise.reject(error);
      }
    );
  }

  async getNodes() {
    let response = await this._client.get("node-types");
    return response.data.data.sort((a, b) => a.displayName.localeCompare(b.displayName));
  }

  async getNodesDetailed(nodeNames) {
    let response = await this._client.post("node-types", { nodeNames });
    return response.data.data;
  }

  async getCredentialsTypes() {
    let response = await this._client.get("credential-types");
    return response.data.data;
  }

  async getCredentials() {
    let response = await this._client.get("credentials");
    return response.data.data;
  }

  async deleteCredentials(id) {
    let response = await this._client.delete("credentials/" + id);
    return response;
  }

  async getCredentialInfo(id) {
    let response = await this._client.get(`credentials/${id}?includeData=true`);
    return response.data.data;
  }

  async runWorkflow(runData) {
    let response = await this._client.post("workflows/run", runData);
    return response.data.data;
  }

  async updateCredentials(credentialsData, id) {
    let response = await this._client.patch("/credentials/" + id, credentialsData);
    return response.data.data;
  }

  async createCredentials(credentialsData) {
    let response = await this._client.post("/credentials/", credentialsData);
    return response.data.data;
  }

  async createWorkflow(workflowData) {
    let response = await this._client.post("/workflows", workflowData);
    return response.data.data;
  }

  async updateWorkflow(id, workflowData) {
    let response = await this._client.patch("/workflows/" + id, workflowData);
    return response.data.data;
  }

  async deleteWorkflow(id) {
    let response = await this._client.delete("/workflows/" + id);
    return response.data.data;
  }

  async getWorkflows() {
    let response = await this._client.get("/workflows");
    return response.data.data;
  }

  async getWorkflow(id) {
    let response = await this._client.get("/workflows/" + id);
    return response.data.data;
  }

  async getNodeParameterOptions(data) {
    let response = await this._client.get("/node-parameter-options", {
      params: data,
    });
    return response.data.data;
  }

  async getNodeDynamicProperties(data) {
    let response = await this._client.get("/node-dynamic-properties", {
      params: data,
    });
    return response.data.data;
  }

  async getExecutions(data) {
    let response = await this._client.get("/executions", { params: data });
    return response.data.data;
  }

  async getTotalExecutionsCount() {
    let response = await this._client.get("/executions-count");
    return response.data.data.map((elem) => ({
      Status: elem.status,
      StatusCount: Number(elem.count),
    }));
  }

  async getCurrentExecutions(data) {
    let response = await this._client.get("/executions-current", {
      params: data,
    });
    return response.data.data;
  }

  async getExecutionData(executionId) {
    let response = await this._client.get("/executions/" + executionId);
    let executionStack = JSON.parse(response.data.data.data);
    response.data.data.data = resolveToArray(resolve(executionStack[0], executionStack));
    return response.data.data;
  }

  async stopCurrentExecution(executionId) {
    let response = await this._client.post("/executions-current/" + executionId + "/stop");
    return response.data.data;
  }

  async deleteTestWebhook(flowId) {
    let response = await this._client.delete("/test-webhook/" + flowId);
    return response.data.data;
  }

  async getOAuth2URL(credentialData) {
    let response = await this._client.get("/oauth2-credential/auth?" + objectToQueryString(credentialData));
    return response.data.data;
  }

  async getTimeZones() {
    let response = await this._client.get("options/timezones");
    return response.data.data;
  }
}

export default new Api();
