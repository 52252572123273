import Api from "../../api";
export const FETCH_RADARS = "FETCH_RADARS";
export const FILTER_RADARS = "FILTER_RADARS";
export const FETCH_RADAR_STATUSES = "FETCH_RADAR_STATUSES";
export const TOGGLE_UPDATE_RADAR_DIALOG = "TOGGLE_UPDATE_RADAR_DIALOG";
export const UPDATE_RADAR = "UPDATE_RADAR";

const state = {
  radars: [],
  pagedRadars: [],
  radarStatuses: [],
  totalCount: 0,
  radarIsLoading: false,
  updateRadar: {
    item: {},
    dialog: false,
    isLoading: false,
    success: false,
    error: "",
  },
};

const actions = {
  [FETCH_RADARS]: async ({ commit }) => {
    commit("setRadarLoading", true);

    try {
      let list = await Api.getRadars();
      commit("setRadars", list);
    } catch (err) {
      commit("setRadars", []);
    } finally {
      commit("setRadarLoading", false);
    }
  },
  [FILTER_RADARS]: async ({ commit }, options) => {
    commit("setRadarLoading", true);

    try {
      let result = await Api.filterRadars(options);
      commit("setPagedRadars", result.rows);
      commit("setTotalCount", result.count);
    } catch (err) {
      commit("setPagedRadars", []);
      commit("setTotalCount", 0);
    } finally {
      commit("setRadarLoading", false);
    }
  },
  [FETCH_RADAR_STATUSES]: async ({ commit }) => {
    try {
      let list = await Api.getRadarStatuses();
      commit("setRadarStatuses", list);
    } catch (err) {
      commit("setRadarStatuses", []);
    }
  },
  [TOGGLE_UPDATE_RADAR_DIALOG]: ({ commit }, item = {}) => {
    commit("setUpdateRadarDialog", item);
  },
  [UPDATE_RADAR]: async ({ commit, dispatch }, item) => {
    commit("setUpdateRadarLoading", true);

    try {
      await Api.updateRadar(item);
      commit("setUpdateRadarSuccess");
    } catch (error) {
      commit("setUpdateRadarError", error.response.data.reason);
    } finally {
      commit("setUpdateRadarLoading", false);
      dispatch(FILTER_RADARS, { itemsPerPage: 10, page: 0 });
    }
  },
};

const mutations = {
  setRadarLoading: (state, isLoading) => (state.radarIsLoading = isLoading),
  setRadars: (state, radars) => (state.radars = radars),
  setPagedRadars: (state, radars) => (state.pagedRadars = radars),
  setRadarStatuses: (state, radarStatuses) => (state.radarStatuses = radarStatuses),

  setTotalCount: (state, count) => (state.totalCount = count),

  setUpdateRadarDialog: (state, item) => {
    state.updateRadar.item = item;
    state.updateRadar.dialog = !state.updateRadar.dialog;
    state.updateRadar.isLoading = false;
    state.updateRadar.success = false;
    state.updateRadar.error = "";
  },
  setUpdateRadarLoading: (state, isLoading) => (state.updateRadar.isLoading = isLoading),
  setUpdateRadarSuccess: (state) => (state.updateRadar.success = true),
  setUpdateRadarError: (state, error) => (state.updateRadar.error = error),
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
