var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isLoading)?_c('v-skeleton-loader',{ref:"skeleton",staticClass:"mx-auto",attrs:{"type":"table"}}):_c('v-data-table',{staticClass:"shadow-xl mx-5 my-5",attrs:{"headers":_vm.headers,"items":_vm.scripts,"server-items-length":_vm.totalCount,"options":_vm.options,"footer-props":{
      itemsPerPageOptions: [10, 50, 100],
    }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('div',{staticClass:"d-none d-sm-flex"},[_c('v-toolbar-title',[_vm._v(" Scripts "),_c('v-progress-circular',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRefreshing),expression:"isRefreshing"}],attrs:{"indeterminate":"","color":"primary"}})],1)],1),_c('v-spacer'),_c('edit-script-dialog'),_c('delete-script-dialog')],1),_c('v-row',{staticClass:"px-5"},[_c('v-col',{attrs:{"cols":"12","xs":"6","sm":"6","md":"3"}},[_c('v-text-field',{attrs:{"label":"Script Name"},model:{value:(_vm.filter.name),callback:function ($$v) {_vm.$set(_vm.filter, "name", $$v)},expression:"filter.name"}})],1),_c('v-col',{attrs:{"cols":"12","xs":"6","sm":"6","md":"5"}},[_c('v-text-field',{attrs:{"label":"Description"},model:{value:(_vm.filter.description),callback:function ($$v) {_vm.$set(_vm.filter, "description", $$v)},expression:"filter.description"}})],1),_c('v-col',{attrs:{"cols":"12","xs":"6","sm":"6","md":"2"}},[_c('v-btn',{staticClass:"mt-5",attrs:{"depressed":"","color":"primary","block":""},on:{"click":_vm.getFilterScriptList}},[_vm._v("Refresh")])],1),_c('v-col',{attrs:{"cols":"12","xs":"6","sm":"6","md":"2"}},[_c('router-link',{attrs:{"to":"/addscript"}},[(_vm.hasAuth('New Script'))?_c('v-btn',{staticClass:"mt-5",attrs:{"depressed":"","color":"primary","block":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("add")]),_vm._v("Add Script ")],1):_vm._e()],1)],1)],1)]},proxy:true},{key:"item.Description",fn:function(ref){
    var item = ref.item;
return [(item.Description)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(_vm.substrDescriptionField(item.Description)))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.Description))])]):_vm._e()]}},{key:"item.action",fn:function(ref){
    var item = ref.item;
return [(item.DataEdit)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"depressed":""},on:{"click":function($event){return _vm.openEditScriptDialog(item)}}},on),[_c('v-icon',{attrs:{"size":"18"}},[_vm._v("edit")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit Script")])]):_vm._e(),(item.DataDelete)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"depressed":""},on:{"click":function($event){return _vm.openDeleteDialog(item)}}},on),[_c('v-icon',{attrs:{"size":"18"}},[_vm._v("delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete Script")])]):_vm._e(),(item.DataDownload)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [(item.FileURL)?_c('v-btn',_vm._g({attrs:{"depressed":""},on:{"click":function($event){return _vm.download(item.FileURL)}}},on),[_c('v-icon',{attrs:{"size":"18"}},[_vm._v("get_app")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Download File")])]):_vm._e()]}},{key:"no-data",fn:function(){return undefined},proxy:true},{key:"item.status",fn:function(ref){
    var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.status,"dark":""}},[_vm._v(_vm._s(item.status))])]}}])}),_c('AddScriptDialog',{attrs:{"script":_vm.editedItem},model:{value:(_vm.showBasicScriptDialog),callback:function ($$v) {_vm.showBasicScriptDialog=$$v},expression:"showBasicScriptDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }