<template>
  <div>
    <v-skeleton-loader
      v-if="isLoading"
      ref="skeleton"
      type="table"
      class="mx-auto"
    ></v-skeleton-loader>
    <v-data-table
      :headers="headers"
      :items="assets"
      class="shadow-xl mx-5 my-5"
      :server-items-length="totalCount"
      :options.sync="options"
      :footer-props="{
        itemsPerPageOptions: [10, 50, 100],
      }"
      v-else
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <div class="d-none d-sm-flex">
            <v-toolbar-title>Assets</v-toolbar-title>
          </div>
          <v-spacer></v-spacer>
          <edit-asset-dialog :filterAndOptions="{...options, ...filter}" />
          <delete-asset-dialog :filterAndOptions="{...options, ...filter}" />
          <label-dialog v-if="hasAuth('Label Action')" />
        </v-toolbar>

        <v-row class="px-5">
          <v-col cols="12" xs="6" sm="6" md="3">
            <v-text-field
              v-model="filter.name"
              label="Name"
            ></v-text-field>
          </v-col>
          <v-col cols="12" xs="6" sm="6" md="5">
            <v-text-field
              v-model="filter.description"
              label="Description"
            ></v-text-field>
          </v-col>
          <v-col cols="12" xs="6" sm="6" md="2">
            <v-btn
              depressed
              color="primary"
              class="mt-5"
              @click="getFilterAssetList"
              block
              >Refresh</v-btn
            >
          </v-col>
          <v-col cols="12" xs="6" sm="6" md="2">
            <new-asset-dialog :filterAndOptions="{...options, ...filter}" v-if="hasAuth('New Task')" />
            <!-- <v-btn depressed color="primary"   v-if="hasAuth('New Script')" block> <v-icon left>add</v-icon>Add Script </v-btn> -->
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.Id="{ item }">
        <div class="assets-item my-2" >
             {{ item.Id }}
        </div>
      </template>
      <template v-slot:item.Name="{ item }" >
        <div class="assets-item" style="max-width:200px;">
             {{ item.Name }}
        </div>
      </template>
       <template v-slot:item.Description="{ item }" >
        <div class="assets-item" style="width:200px;">
             {{ item.Description }}
        </div>
      </template>
      <template v-slot:item.createdAt="{ item }" >
        <div class="assets-item" style="width:170px;">
             {{ item.createdAt }}
        </div>
      </template>
      
      <template v-slot:item.Label="{ item }">
        <div class="my-2">
          <v-chip
          v-for="label in item.Labels"
          :key="label.Id"
          class="ma-1"
          color="primary"
          text-color="white"
        >
          {{ label.Name }}
        </v-chip>
        </div>
      </template>
      <template v-slot:item.action="{ item }">
        <div style="display: flex;">
          <v-tooltip bottom v-if="item.DataEdit">
          <template v-slot:activator="{ on }">
            <v-btn
              depressed
              class="mx-1"
              @click="() => editAsset(item)"
              v-on="on"
            >
              <v-icon size="18">edit</v-icon>
            </v-btn>
          </template>
          <span>Edit Asset</span>
        </v-tooltip>
        <v-tooltip bottom v-if="item.DataDelete">
          <template v-slot:activator="{ on }">
            <v-btn depressed @click="deleteAsset(item.Id)" v-on="on">
              <v-icon size="18">delete</v-icon>
            </v-btn>
          </template>
          <span>Delete Asset</span>
        </v-tooltip>
        </div>
      </template>
      <template v-slot:no-data></template>
    </v-data-table>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import {
  FILTER_ASSETS,
  TOGGLE_UPDATE_ASSET_DIALOG,
  TOGGLE_DELETE_ASSET_DIALOG,
} from "../../store/modules/asset";
import { timeAwareFormat } from "../../time";
import NewAssetDialog from "./NewAssetDialog";
import EditAssetDialog from "./EditAssetDialog";
import DeleteAssetDialog from "./DeleteAssetDialog";
import LabelDialog from "../label/LabelDialog";
import { hasActionAuthorization } from "../../authorization";

export default {
  data: () => ({
    filter: {
        name: "",
        description: ""
    },
    headers: [
      {
        text: "Asset Id",
        align: "left",
        value: "Id",
      },
      {
        text: "Name",
        value: "Name",
      },
      {
        text: "Description",
        value: "Description",
      },
      {
        text: "Created",
        value: "createdAt",
      },
      {
        text: "By",
        value: "Created.Name",
      },
      {
        text: "Type",
        value: "Type",
      },
      {
        text: "Value",
        value: "AssetValue",
      },
      {
        text: "Label",
        value: "Label",
      },
      {
        text: "Actions",
        value: "action",
      },
    ],
    options: { itemsPerPage: 10, page: 1 },
  }),
  components: {
    "new-asset-dialog": NewAssetDialog,
    "edit-asset-dialog": EditAssetDialog,
    "delete-asset-dialog": DeleteAssetDialog,
    "label-dialog": LabelDialog,
  },
  computed: {
    ...mapState("asset", {
      assets: (state) =>
        state.pagedAssets.map((i) => ({
          ...i,
          createdAt: timeAwareFormat(i.createdAt),
          Label: i.Labels.map((label) => {
            return label.Name;
          }).join(","),
          Type: i.AssetType.Name,
          AssetValue:
            i.AssetType.Name === "Text" ? i.Value : "Credential Store",
        })),
      totalCount: (state) => state.totalCount,
      isLoading: (state) => state.assetIsLoading,
    }),
  },
  watch: {
    options: {
      handler(newVal, oldVal) {
        if (
          newVal.page != oldVal.page ||
          newVal.itemsPerPage != oldVal.itemsPerPage
        ) {
          this.fetchAssets({ ...newVal, ...this.filter });
        }
      },
      deep: true,
    },
  },
  created() {
    this.fetchAssets({ ...this.options, ...this.filter });
  },
  methods: {
    ...mapActions("asset", {
      fetchAssets: FILTER_ASSETS,
      toggleEditDialog: TOGGLE_UPDATE_ASSET_DIALOG,
      toggleDeleteDialog: TOGGLE_DELETE_ASSET_DIALOG,
    }),
    getFilterAssetList(){
       this.fetchAssets({ ...this.options, ...this.filter });
    },
    hasAuth(action) {
      return hasActionAuthorization(this.$store, "Assets", action);
    },
    editAsset(item) {
      this.toggleEditDialog(item);
    },
    deleteAsset(id) {
      this.toggleDeleteDialog(id);
    },
  },
};
</script>

<style scoped>
  .assets-item {
    word-wrap: break-word;
  }
</style>