import eventBus from "../helpers/eventBus";

const mixin = {
  data: () => ({
    _eventBusHandlers: [],
  }),
  mounted() {
    // console.log('mounted')
    this._eventBusHandlers = [];
  },
  destroyed() {
    // console.log('destroyed')
    this._eventBusHandlers.forEach(({ event, handlerId }) => {
      eventBus.unsubscribe(event, handlerId);
    });
  },
  methods: {
    $subscribe(event, handler) {
      let handlerId = eventBus.subscribe(event, handler);
      this._eventBusHandlers.push({ event, handlerId });
    },
    $pushEvent(event, data) {
      eventBus.push(event, data);
    },
  },
};

export default mixin;
