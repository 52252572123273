import config from "./config";
import { apiURL } from "./helpers/url";
import DeferredPromise from "./helpers/deferredPromise";
import { waitInMilliSeconds } from "./helpers/wait";
/**
 *
 * @typedef EventType
 * @property {string} "test"
 * @property {string} "test3"
 */

class EventListener {
  constructor() {
    this._source = null;
    this._eventMap = {};
    this.types = {
      NODE_EXECUTE_BEFORE: "nodeExecuteBefore",
      NODE_EXECUTE_AFTER: "nodeExecuteAfter",
      EXECUTION_STARTED: "executionStarted",
      EXECUTION_FINISHED: "executionFinished",
      TEST_WEBHOOK_DELETED: "testWebhookDeleted",
      TEST_WEBHOOK_RECEIVED: "testWebhookReceived",
    };
    this._eventQueue = [];
    this._nextEventPromise = new DeferredPromise();
    this._startListeningEventQueue();
  }

  _onError(event) {
    console.log("error occured", event);
    this._source = null;
  }

  _onOpen() {
    console.log("push connection opened");
  }

  connect() {
    if (this._source) return;

    const connectionUrl = apiURL(`/push?sessionId=` + config.sessionId);
    this._source = new EventSource(connectionUrl);
    this._source.addEventListener("error", (e) => this._onError(e), false);
    this._source.addEventListener("open", (e) => this._onOpen(e), false);
    this._source.addEventListener("message", (e) => this._pushMessageReceived(e), false);
  }

  disconnect() {
    this._source.close();
    this._source = null;
  }

  _pushMessageReceived(event) {
    let pushData = JSON.parse(event.data);
    this._addEventQueue(pushData.type, pushData.data);
  }

  async _startListeningEventQueue() {
    // eslint-disable-next-line no-constant-condition
    while (true) {
      let event = await this._getFromEventQueue();
      let duration = this._getWaitDuration(event.type);

      this._dispatch(event.type, event.data);
      console.log(event);
      if (duration) {
        await waitInMilliSeconds(duration);
      }
    }
  }

  _getWaitDuration(type) {
    let durations = {
      [this.types.NODE_EXECUTE_BEFORE]: 500,
    };
    return durations[type];
  }

  _addEventQueue(type, data) {
    this._eventQueue.push({ type, data });
    if (this._nextEventPromise) {
      this._nextEventPromise.resolve();
    }
  }

  async _getFromEventQueue() {
    if (this._nextEventPromise) {
      await this._nextEventPromise.promise;
    }
    let item = this._eventQueue.shift();
    if (this._eventQueue.length == 0) {
      this._nextEventPromise = new DeferredPromise();
    } else {
      this._nextEventPromise = null;
    }
    return item;
  }

  _dispatch(name, data) {
    let func = this._eventMap[name];
    if (func) {
      func(data);
    }
  }

  setListener(/** @type  EventType*/ event, func) {
    this._eventMap[event] = func;
  }

  removeListener(event) {
    delete this._eventMap[event];
  }
}

export default new EventListener();
