<template>
  <v-dialog v-model="dialog" max-width="500px" persistent>
    <v-card>
      <v-form ref="form">
        <v-card-title>
          <span class="headline">Edit Skill</span>
        </v-card-title>
        <v-card-text>
          <v-alert
            v-show="!!error"
            text
            prominent
            type="error"
            icon="mdi-alert-circle"
            >{{ error }}</v-alert
          >
          <v-alert
            v-show="!!success"
            text
            prominent
            type="success"
            icon="mdi-cloud-check"
            >Succesfully Created</v-alert
          >
          <v-container class="text-center" v-if="loading">
            <v-progress-circular
              :size="70"
              :width="7"
              color="purple"
              indeterminate
            ></v-progress-circular>
          </v-container>
          <v-container v-else>
            <v-row>
              <v-col cols="12" sm="6" md="12">
                <v-text-field
                  v-model="editItem.Name"
                  label="Name"
                  required
                  :rules="nameRules"
                  maxlength="50"
                  counter
                  :disabled="success"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="12">
                <v-text-field
                  v-model="editItem.Code"
                  label="Code"
                  required
                  :rules="codeRules"
                  maxlength="50"
                  counter
                  :disabled="success"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            depressed
            color="primary"
            v-show="!success && !loading"
            @click="save"
            >Save</v-btn
          >
          <v-btn color="grey darken-1" text @click="toggleDialog">Cancel</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
import {
  UPDATE_SKILL,
  TOGGLE_UPDATE_SKILL_DIALOG,
} from "../../store/modules/digistaff";
import { hasActionAuthorization } from "../../authorization";

export default {
  props: ["digistaffId"],
  data: () => ({
    editItem: {},
    nameRules: [(v) => !!v || "Skill name is required"],
    codeRules: [(v) => !!v || "Skill code is required"],
  }),
  computed: {
    ...mapState("digistaff", {
      item: (state) => state.updateSkill.item,
      loading: (state) => state.updateSkill.isLoading,
      success: (state) => state.updateSkill.success,
      error: (state) => state.updateSkill.error,
      dialog: (state) => state.updateSkill.dialog,
    }),
  },
  watch: {
    item(value) {
      Object.assign(this.editItem, value);
    },
  },
  methods: {
    ...mapActions("digistaff", {
      updateSkill: UPDATE_SKILL,
      toggleDialog: TOGGLE_UPDATE_SKILL_DIALOG,
    }),
    save() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.updateSkill({
        digistaffId: this.digistaffId,
        id: this.editItem.Id,
        skill: this.editItem,
      });
    },

    hasAuth(action) {
      return hasActionAuthorization(this.$store, "Digistaff", action);
    },
  },
};
</script>
