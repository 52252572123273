<template>
    <v-dialog v-model="dialog" width="400">
        <template v-slot:activator="{ on }">
            <v-btn color="orange white--text" v-on="on" depressed class="mr-3 my-3" @click="e6 = 4">
                <v-icon>mail_outline</v-icon>
                E-Mail
            </v-btn>
        </template>
        <v-card class="px-4 py-4">
            <v-card-title>
                Add E-Mail Alert
            </v-card-title>
            <v-card-text>
                <v-text-field  ref="mailField" label="E-Mails"  :value="value" v-on:change="$emit('input', $event)" :rules="emailRules" dense outlined></v-text-field>
            </v-card-text>

            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="save()" color="primary" depressed>
                    Save
                </v-btn>
                <v-btn @click="cancel()" depressed>
                    Cancel
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

</template>

<script>
export default {
    props: ['value'],
    methods: {
        save(){
            if(!this.$refs.mailField.validate()){
                return;
            }

            this.dialog = false;
        },
        cancel(){
            this.$emit('input',"");
            this.dialog = false;
        }
    },
    data() {
        return {
            dialog: false,
            email:"",
            Status:"",
            emailRules: [
                v => !!v || "email must not be empty",
                v => v.split(";").filter( e => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(e)).length == v.split(';').length  ||
                "E-mail must be valid"
            ],
        }
    },
}
</script>
