<template>
  <div class="ml-2">
    <span class="subtitle-2">{{ displayName }}</span>
    <v-divider />
    <vue-form-json-schema
      :key="'sda' + updateCount"
      v-if="schema"
      v-model="data"
      :schema="schema"
      :ui-schema="showingUISchema"
    ></vue-form-json-schema>
    <div v-show="false">
      <vue-form-json-schema
        :key="'asd' + updateCount"
        v-model="data"
        v-on:state-change="onChangeState"
        v-if="uiSchema"
        :schema="schema"
        :ui-schema="uiSchema"
      ></vue-form-json-schema>
    </div>
    <v-menu max-height="400px" top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          outlined
          :disabled="disabled"
          block
          class="mt-5 mb-5"
        >
          <v-icon>mdi-plus</v-icon> Add {{ name }}
        </v-btn>
      </template>

      <v-list>
        <v-list-item
          v-for="property of showingProperties"
          :key="`btn-${property.name}`"
          @click="addItem(property.name)"
        >
          <v-list-item-title> {{ property.displayName }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { convert } from "../../helpers/propertyConverter";
import { mapState } from "vuex";
import { deepCopy } from "../../helpers/object";
import Vue from "vue";
export default {
  props: ["properties", "value", "name", "disabled", "path", "displayName"],
  data: () => ({
    showingUISchema: [],
    uiSchema: [],
    schema: {},
    showingProperties: [],
    updateCount: 0,
  }),
  computed: {
    data: {
      get() {
        let globalData = deepCopy(this.value ?? {});

        for (const [key, value] of Object.entries(deepCopy(this.globalData))) {
          globalData["/" + key] = value;
        }

        return globalData;
      },
      set(_val) {
        let val = deepCopy(_val);
        for (const [key] of Object.entries(val)) {
          if (key.startsWith("/")) {
            delete val[key];
          }
        }
        this.$emit("input", val);
      },
    },
    ...mapState("dynamicForm", {
      globalData: (state) => {
        return state.data;
      },
    }),
  },
  mounted() {
    let { schema, uiSchema, defaultData } = convert(this.properties, {
      disabled: this.disabled,
      path: this.path,
      removable: true,
    });

    if (!this.value) {
      this.data = defaultData;
    }

    this.uiSchema = uiSchema;
    this.schema = schema;
    this.updateShowingUISchema();
  },
  watch: {
    value: {
      deep: true,
      handler() {
        this.updateCount++;
      },
    },
  },
  methods: {
    addItem(name) {
      let property = this.properties.find((p) => p.name === name);
      Vue.set(this.data, property.name, property.default ?? " ");
      this.updateShowingUISchema();
    },
    updateShowingUISchema() {
      console.log("updating ui schema");
      this.showingUISchema = Object.keys(this.data)
        .map((key) => {
          return this.uiSchema.find((item) => item.model == key);
        })
        .filter((v) => !!v);
      console.log(this.showingUISchema);
    },
    onChangeState(state) {
      this.showingProperties = state.vfjsFieldsActiveModels.map((name) =>
        this.properties.find((p) => p.name === name)
      );
    },
  },
};
</script>
