<template>
  <v-card class="fill-height d-flex flex-column" flat v-if="active">
    <v-card-title class="flex-grow-0"
      >{{ credentialTypeInfo.displayName }}
    </v-card-title>
    
    <v-card-text class="flex-grow-1" style="overflow: scroll">
      <v-text-field label="credentials name" v-model="name"></v-text-field>
   
      <vue-form-json-schema
        v-if="schema"
        v-model="internalCredentialsData"
        :schema="schema"
        :ui-schema="uiSchema"
      ></vue-form-json-schema>
      <v-btn
        outlined
        color="success"
        v-if="credentialTypeInfo.isOAuth"
        @click="connectOAuth"
        block
      >
        <v-icon class="mr-3">mdi-login</v-icon> Connect</v-btn
      >
      <v-alert class="mt-5" color="info" v-if="credentialTypeInfo.isOAuth">
        <div class="d-flex flex-row align-center">
          <div class="white--text">
            <span class="overline">Callback URL: </span>
            {{ callbackURL }}
          </div>
          <div>
            <v-btn color="white" icon @click="copy(callbackURL)">
              <v-icon>mdi-file-document-outline</v-icon></v-btn
            >
          </div>
        </div>
      </v-alert>
    </v-card-text>
    <v-card-actions class="flex-grow-0 d-flex justify-center">
      <v-btn color="error" text @click="close">cancel</v-btn>
      <v-btn @click="save">save</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { convert } from "../../helpers/propertyConverter";
import { mapGetters, mapActions } from "vuex";
import Api from "../../api";
import formDialogMixin from "../../mixins/formDialog";
import copy from "copy-to-clipboard";
import config from "../../config";
export default {
  mixins: [formDialogMixin],
  props: ["active", "credentialsId", "credentialsType"],
  data: () => ({
    internalCredentialsData: {},
    schema: null,
    uiSchema: null,
    credentialTypeInfo: null,
    isUpdate: false,
    name: "",
    callbackURL: config.oauthCallbackURL,
  }),
  watch: {
    active: function (val) {
      if (val) {
        this.onActivated();
      } else {
        this.onDeactivated();
      }
    },
  },
  computed: {
    ...mapGetters("credentials", ["getCredentialType"]),
    ...mapGetters("node", ["getNodesByCredentialType"]),
  },
  methods: {
    copy,
    ...mapActions("credentials", ["loadCredentials"]),
    async onActivated() {
      this.credentialTypeInfo = this.getCredentialType(this.credentialsType);
      let { schema, uiSchema, defaultData } = convert(
        this.credentialTypeInfo.properties,
        {
          hasExpressionEditor: false,
        }
      );

      if (!this.credentialsId) {
        this.isUpdate = false;
        this.internalCredentialsData = defaultData;
      } else {
        let credentialData = await Api.getCredentialInfo(this.credentialsId);
        this.name = credentialData.name;
        this.internalCredentialsData = credentialData.data;
        this.isUpdate = true;
      }

      this.schema = schema;
      this.uiSchema = uiSchema;
    },
    onDeactivated() {
      Object.assign(this, {
        internalCredentialsData: {},
        schema: null,
        uiSchema: null,
        credentialTypeInfo: null,
        isUpdate: false,
        name: "",
      });
    },
    async saveDataToApi() {
      let data = {
        name: this.name,
        data: this.internalCredentialsData,
        nodesAccess: this.getNodesByCredentialType(
          this.credentialTypeInfo.name
        ).map((node) => ({ nodeType: node.name })),
        type: this.credentialTypeInfo.name,
      };
      let result;
      if (this.isUpdate) {
        result = await Api.updateCredentials(data, this.credentialsId);
      } else {
        result = await Api.createCredentials(data);
        await this.loadCredentials();
      }
      return result;
    },
    async save() {
      try {
        await this.saveDataToApi();
      } catch (e) {
        await this.$alert({
          text: e.response.data.message,
          type: "error",
        });
        return;
      }

      this.$emit(
        "save",
        JSON.parse(
          JSON.stringify({ name: this.name, type: this.credentialsType })
        )
      );
    },
    async connectOAuth() {
      let data;
      try {
        data = await this.saveDataToApi();
      } catch (e) {
        await this.$alert({
          text: e.response.data.message,
          type: "error",
        });
        return;
      }
      let url = await Api.getOAuth2URL(data);
      let authWindow = window.open(url, "_blank");
      const onReceived = (event) => {
        if (event.data === "success") {
          authWindow.close();
          this.$emit(
            "save",
            JSON.parse(
              JSON.stringify({ name: this.name, type: this.credentialsType })
            )
          );
        }
      };
      window.addEventListener("message", onReceived, false);
    },
    async create() {
      return;
    },
    update() {},
    close() {
      this.$emit("close");
    },
  },
};
</script>
