<template>
  <v-list-item  @click="$emit('click')" :key="node.displayName">
    <v-list-item-avatar> <node-avatar  :node="node" /> </v-list-item-avatar>

    <v-list-item-content>
      <v-list-item-title v-html="node.displayName"></v-list-item-title>
      <v-list-item-subtitle v-html="node.description"></v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import NodeAvatar from './NodeAvatar'
export default {
  components: { NodeAvatar },
  props: ['node'],
}
</script>
