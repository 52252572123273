/**
 * @typedef EnvironmentTypeData
 * @type {object}
 * @property {string} key
 * @property {string} value
 * @property {('selection'|'multiple-selection'|'text'|'file'|'switch')} type
 * @property {string} label
 * @property {string} definition
 */

let typeFuncBinding = {
    text: text,
    selection: selection,
    'multiple-selection': multipleSelection,
    'switch': switchComponent,
    'file':  fileComponent
} 

let typeDataTypeBinding =  {
    text: 'string',
    selection: 'string',
    'multiple-selection':'array',
    switch: 'boolean',
}

/**
 * 
 * @param {Array<EnvironmentTypeData>} data 
 */
export default function convert(data){

    let uiSchema = data.map( (d) => typeFuncBinding[d.type](d)).reduce( (a , b ) => {
        if(Array.isArray(b)){
            a.push(...b)
        }else{
            a.push(b)
        }

        return a;
    }, [] );
    
    let schema = {
        type: "object",
        properties: {}
    };

    data.forEach( (d) => {
        if(d.type != 'file')
            schema.properties[d.key] = {type: typeDataTypeBinding[d.type]}
    });
    

    return {
        schema,
        uiSchema
    }

}

const ruleRawRuleMapping = {
    required: requiredRule
}

function requiredRule(isRequired){
    return isRequired ? (v) => !!v || "This field is required" : null;
}

function ruleGenerator(rawRules){
    return Object.entries(rawRules).map( ([rule, args]) => ruleRawRuleMapping[rule](args)).filter( r => !!r);
}

/**
 * 
 * @param {EnvironmentTypeData} data 
 */
function text(data){
    let component =  {
        component: 'v-text-field',
        model: data.key,
        fieldOptions:{
            on: ["input"],
            props:{
                placeholder: data.label || data.key,
                outlined: true,
                ...( data.rules ?  {rules: ruleGenerator(data.rules)} : null)
            },
            
        }
    }

    if(data.definition){
        component.children = [ {
            component: 'template',
            fieldOptions: {
                slot: 'append'
            },
            children: [
                {
                    component: 'definition-tip',
                    fieldOptions: {
                        props: {
                            name: data.key,
                            definition: data.definition
                        }
                    }
                }
            ]
        } ] ;
    }
    

    return component;
}

/**
 * 
 * @param {EnvironmentTypeData} data 
 */
function selection(data){
    let component =  {
            component: 'v-select',
            model: data.key,
            
            fieldOptions:{
                on: ["input"],
                props:{
                    label: data.label || data.key,
                    items: extractComboboxItems(data.value),
                    outlined: true,
                    ...( data.rules ?  {rules: ruleGenerator(data.rules)} : null)
                }
            }
        };

        if(data.definition){
            component.children = [ {
                component: 'template',
                fieldOptions: {
                    slot: 'append-outer'
                },
                children: [
                    {
                        component: 'definition-tip',
                        fieldOptions: {
                            props: {
                                name: data.key,
                                definition: data.definition
                            }
                        }
                    }
                ]
            } ] ;
        }
        
    
        return component;
    
}


/**
 * 
 * @param {EnvironmentTypeData} data
 * 
 */
function multipleSelection(data){
    let component =  {
        component: 'v-select',
        model: data.key,
        
        fieldOptions:{
            on: ["input"],
            props:{
                label: data.label || data.key,
                items: extractComboboxItems(data.value),
                multiple: true,
                outlined: true,
                ...( data.rules ?  {rules: ruleGenerator(data.rules)} : null)
            }
        }
    }

    if(data.definition){
        component.children = [ {
            component: 'template',
            fieldOptions: {
                slot: 'append'
            },
            children: [
                {
                    component: 'definition-tip',
                    fieldOptions: {
                        props: {
                            name: data.key,
                            definition: data.definition
                        }
                    }
                }
            ]
        } ] ;
    }
    

    return component;
}

/**
 * 
 * @param {EnvironmentTypeData} data 
 */
function switchComponent(data){
    
    let component =  {
        component: 'v-switch',
        model: data.key,
        valueProp: "input-value",
        fieldOptions:{
            on: [
                "change"
            ],
            props:{
                label: data.label || data.key,
                outlined: true,
                'true-value': true,
                'false-value': false,
                ...( data.rules ?  {rules: ruleGenerator(data.rules)} : null)
            }
        }
    }

    if(data.definition){
        component.children = [ {
            component: 'template',
            fieldOptions: {
                slot: 'append'
            },
            children: [
                {
                    component: 'definition-tip',
                    fieldOptions: {
                        props: {
                            name: data.key,
                            definition: data.definition
                        }
                    }
                }
            ]
        } ] ;
    }
    

    return component;
}

/**
 * 
 * @param {EnvironmentTypeData} data 
 */
function fileComponent(data){
    let component =  {
        component: 'v-file-input',
        fieldOptions:{
            on: ["change"],
            props:{
                label: data.label || data.key,
                outlined: true,
                ...( data.rules ?  {rules: ruleGenerator(data.rules)} : null)
            },
            attrs:{
                name: 'File',
                accept: "application/zip" 
            }
        }
    }

    if(data.definition){
        component.children = [ {
            component: 'template',
            fieldOptions: {
                slot: 'append'
            },
            children: [
                {
                    component: 'definition-tip',
                    fieldOptions: {
                        props: {
                            name: data.key,
                            definition: data.definition
                        }
                    }
                }
            ]
        } ] ;
    }
    

    return component;
}

/**
 * 
 * @param {Array<string>} data 
 */
function extractComboboxItems(data){
    return data.split(',')
        .map( v => {
            if(v.includes('->')){
                let [ text , value] = v.split('->');
                return {text,value}
            }

            return v;
        });
}