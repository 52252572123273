const state = {
  drawer: null,
};

const getters = {
  getDrawer: (state) => (state.drawer)
};

const actions = {
  
};

const mutations = {
  setDrawer: (state, value) => (state.drawer = value),
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
