<template>
  <v-dialog v-model="dialog" max-width="500px" persistent>
    <v-card>
      <v-form ref="form">
        <v-card-title>
          <span class="headline">Edit Queue</span>
        </v-card-title>
        <v-card-text>
          <v-alert v-show="!!error" text prominent type="error" icon="mdi-alert-circle">{{error}}</v-alert>
          <v-alert
            v-show="!!success"
            text
            prominent
            type="success"
            icon="mdi-cloud-check"
          >Succesfully Edited</v-alert>
          <v-container class="text-center" v-if="loading">
            <v-progress-circular :size="70" :width="7" color="purple" indeterminate></v-progress-circular>
          </v-container>
          <v-container v-else>
            <v-row>
              <v-col cols="12" sm="6" md="12">
                <v-text-field
                  v-model="item.Name"
                  label="Name"
                  required
                  :rules="nameRules"
                  maxlength="50"
                  counter
                  :disabled="success"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="12">
                <v-text-field
                  v-model="item.Description"
                  label="Description"
                  maxlength="200"
                  counter
                  :disabled="success"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed color="primary" v-show="!success && !loading" @click="save">Save</v-btn>
          <v-btn color="grey darken-1" text @click="toggleDialog">Cancel</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
import {
  UPDATE_QUEUE,
  TOGGLE_UPDATE_QUEUE_DIALOG
} from "../../store/modules/queue";

export default {
  props: ["filterAndOptions"],
  data: () => ({
    nameRules: [v => !!v || "Queue name is required"]
  }),
  computed: {
    ...mapState("queue", {
      item: state => state.updateQueue.item,
      dialog: state => state.updateQueue.dialog,
      loading: state => state.updateQueue.isLoading,
      success: state => state.updateQueue.success,
      error: state => state.updateQueue.error
    })
  },
  methods: {
    ...mapActions("queue", {
      updateQueue: UPDATE_QUEUE,
      toggleDialog: TOGGLE_UPDATE_QUEUE_DIALOG
    }),
    save() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.updateQueue({
        queueId: this.item.Id,
        queue: { Name: this.item.Name, Description: this.item.Description },
        filterAndOptions: this.filterAndOptions
      });
    }
  }
};
</script>