<template>
  <div>
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <template>
          <v-card>
            <v-card-title>
              Alert
              <v-spacer></v-spacer>
            </v-card-title>
            <v-card-text v-if="!error">
              <v-alert text prominent type="success" icon="mdi-checkbox-marked-circle-outline">
                <strong>Run Task</strong> request has been sent
              </v-alert>
            </v-card-text>
            <v-card-text v-else>
              <v-alert text prominent type="error" icon="mdi-minus-circle-outline">{{error}}</v-alert>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn depressed color="primary" @click="dialog = false">Close</v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { OPEN_RUN_DIALOG, CLOSE_RUN_DIALOG } from "../store/modules/task";

export default {
  computed: {
    dialog: {
      get() {
        return this.$store.state.task.runDialog;
      },
      set(val) {
        if (val) {
          this.openDialog();
        } else {
          this.closeDialog();
        }
      }
    },
    ...mapState("task", {
      error: state => state.runError
    })
  },
  methods: {
    ...mapActions("task", {
      openDialog: OPEN_RUN_DIALOG,
      closeDialog: CLOSE_RUN_DIALOG
    })
  }
};
</script>


<style>
</style>