<template>
  <v-dialog v-model="dialog" persistent max-width="800px">
    <v-card>
      <v-form ref="form">
        <v-card-title>
          <span class="headline">Department Auths</span>
        </v-card-title>
        <v-alert
          v-show="!!error"
          text
          prominent
          type="error"
          icon="mdi-alert-circle"
          >{{ error }}</v-alert
        >
        <v-alert
          v-show="!!success"
          text
          prominent
          type="success"
          icon="mdi-cloud-check"
          >Succesfull</v-alert
        >
        <v-container>
          <v-row>
            <v-row>
              <v-col cols="1" sm="1" md="1"></v-col>
              <v-col cols="6" sm="2" md="6">
                <v-autocomplete
                  v-model="departmentId"
                  :items="departments"
                  item-text="Name"
                  item-value="Id"
                  dense
                  label="Select Department"
                ></v-autocomplete>
              </v-col>
              <v-col cols="1" sm="1" md="1">
                <v-btn color="primary" depressed v-on:click="save">Add</v-btn>
              </v-col>
              <v-col cols="3" sm="1" md="3">
                <v-btn
                  color="primary"
                  depressed
                  v-on:click="openDepartmentDialog"
                  >Departments</v-btn
                >
              </v-col>
              <v-col cols="1" sm="1" md="1"></v-col>
            </v-row>
            <v-col cols="12" sm="6" md="12">
              <v-data-table
                :headers="headers"
                :items="auths"
                class="shadow-xl mx-5 my-5"
                :search="search"
                hide-default-footer
              >
                <template v-slot:top>
                  <v-toolbar flat color="white">
                    <v-row>
                      <v-col cols="10" sm="3" md="4" offset-md="8" class="px-5">
                        <v-text-field
                          v-model="search"
                          append-icon="search"
                          label="Search"
                          single-line
                          hide-details
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-toolbar>
                </template>
                <template v-slot:item.action="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn depressed @click="deleteAuth(item.Id)" v-on="on">
                        <v-icon size="18">delete</v-icon>
                      </v-btn>
                    </template>
                    <span>Delete Auth</span>
                  </v-tooltip>
                </template>
                <template v-slot:no-data></template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" text @click="closeDialog">Close</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from "vuex";
import {
  FETCH_DEPARTMENTS,
  FETCH_DEPARTMENT_AUTH,
  CREATE_DEPARTMENT_AUTH,
  DELETE_DEPARTMENT_AUTH,
  TOGGLE_DEPARTMENT_AUTH_DIALOG,
  TOGGLE_DEPARTMENT_DIALOG,
} from "../../store/modules/digistaff";

export default {
  data: () => ({
    headers: [
      {
        text: "Department",
        value: "Department.Name",
      },
      {
        text: "Skill",
        value: "Skill.Name",
      },
      {
        text: "Actions",
        value: "action",
        align: "right",
      },
    ],
    search: "",
    departmentId: "",
  }),
  computed: {
    ...mapState("digistaff", {
      skillId: (state) => state.departmentAuthSkillId,
      departments: (state) => state.departments,
      auths: (state) => state.departmentAuths,
      isLoading: (state) => state.departmentAuthIsLoading,
      dialog: (state) => state.departmentAuthDialog,
      success: (state) =>
        state.createDepartmentAuth.success ||
        state.deleteDepartmentAuth.success,
      error: (state) =>
        state.createDepartmentAuth.error || state.deleteDepartmentAuth.error,
    }),
  },
  watch: {
    skillId(val) {
      if (val) {
        this.fetchDepartmentAuth(val);
        this.fetchDepartments();
      }
    },
  },
  methods: {
    ...mapActions("digistaff", {
      fetchDepartments: FETCH_DEPARTMENTS,
      fetchDepartmentAuth: FETCH_DEPARTMENT_AUTH,
      createDepartmentAuth: CREATE_DEPARTMENT_AUTH,
      deleteDepartmentAuth: DELETE_DEPARTMENT_AUTH,
      toggleDialog: TOGGLE_DEPARTMENT_AUTH_DIALOG,
      toggleDepartmentDialog: TOGGLE_DEPARTMENT_DIALOG,
    }),
    save() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.createDepartmentAuth({
        SkillId: this.skillId,
        DepartmentId: this.departmentId,
      });

      this.departmentId = "";
    },
    deleteAuth(authId) {
      this.deleteDepartmentAuth({ skillId: this.skillId, authId });

      this.departmentId = "";
    },
    closeDialog() {
      this.toggleDialog();
    },
    openDepartmentDialog() {
      this.toggleDepartmentDialog();
    },
  },
};
</script>
