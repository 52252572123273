<template>
  <v-dialog v-model="dialog" max-width="500px" persistent>
    <template v-slot:activator="{ on }">
      <v-btn color="primary" class="mt-5" block depressed v-on="on">
        <v-icon left>add</v-icon>New Queue
      </v-btn>
    </template>
    <v-card>
      <v-form ref="form">
        <v-card-title>
          <span class="headline">Create Queue</span>
        </v-card-title>
        <v-card-text>
          <v-alert
            v-show="!!error"
            text
            prominent
            type="error"
            icon="mdi-alert-circle"
            >{{ error }}</v-alert
          >
          <v-alert
            v-show="!!success"
            text
            prominent
            type="success"
            icon="mdi-cloud-check"
            >Succesfully Created</v-alert
          >
          <v-container class="text-center" v-if="loading">
            <v-progress-circular
              :size="70"
              :width="7"
              color="purple"
              indeterminate
            ></v-progress-circular>
          </v-container>
          <v-container v-else>
            <v-row>
              <v-col cols="12" sm="6" md="12">
                <v-text-field
                  v-model="item.Name"
                  label="Name"
                  required
                  :rules="nameRules"
                  maxlength="50"
                  counter
                  :disabled="success"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="12">
                <v-text-field
                  v-model="item.Description"
                  label="Description"
                  maxlength="200"
                  counter
                  :disabled="success"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            depressed
            color="primary"
            v-show="!success && !loading"
            @click="save"
            >Save</v-btn
          >
          <v-btn color="grey darken-1" text @click="toggleDialog">Cancel</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
import {
  CREATE_QUEUE,
  TOGGLE_CREATE_QUEUE_DIALOG
} from "../../store/modules/queue";

export default {
  props: ["filterAndOptions"],
  data: () => ({
    nameRules: [v => !!v || "Queue name is required"],
    item: {
      Name: "",
      Description: ""
    }
  }),
  computed: {
    dialog: {
      get() {
        return this.$store.state.queue.createQueue.dialog;
      },
      set() {
        this.toggleDialog();
      }
    },
    ...mapState("queue", {
      loading: state => state.createQueue.isLoading,
      success: state => state.createQueue.success,
      error: state => state.createQueue.error
    })
  },
  methods: {
    ...mapActions("queue", {
      createQueue: CREATE_QUEUE,
      toggleDialog: TOGGLE_CREATE_QUEUE_DIALOG
    }),
    save() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.createQueue({ queue: this.item,filterAndOptions: this.filterAndOptions });
    }
  }
};
</script>
