class Auth {
    constructor() {
        this.$token = localStorage.getItem('token');
        this.$refreshToken = localStorage.getItem('refreshToken')
        this.$user = localStorage.getItem('user');
    }

    setUser(user) {
        this.$user = user;
    }

    getRefreshToken() {
        return this.$refreshToken
    }

    getUser() {
        return this.$user;
    }

    setToken(token) {
        this.$token = token;
        localStorage.setItem('token', token);
    }

    setRefreshToken(token) {
        this.$refreshToken = token
        localStorage.setItem('refreshToken', token)
    }

    getToken() {
        return this.$token
    }

    logout() {
        this.clearToken();
    }

    clearToken() {
        this.$token = null;
        this.$refreshToken = null;
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken')
    }
}

export default new Auth();