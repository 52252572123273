import Vue from "vue";
import Router from "vue-router";
import Dashboard from "./views/Dashboard.vue";
import Layout from "./Layout/DefaultLayout.vue";
import Agent from "./views/Agent.vue";
import Scripts from "./views/Scripts.vue";
import AddScript from "./views/AddScript.vue";
import Tasks from "./views/Tasks.vue";
import Jobs from "./views/Jobs.vue";
import Logs from "./views/Logs.vue";
import Settings from "./views/settings/Settings.vue";
import Login from "./views/Login";
import SignUp from "./views/Signup";
import Lostpassword from "./views/Lostpassword";
import VerifyEmail from "./views/VerifyEmail";
import EmailNotVerified from "./views/EmailNotVerified";
import RenewPassword from "./views/RenewPassword";
import ErrorPage from "./views/ErrorPage";
import Alert from "./views/alerts/AddAlert";
import AddAlertForTask from "./views/alerts/AddAlertForTask";
import Account from "./views/Account";
import PaymentMethod from "./views/PaymentMethod";
import User from "./views/user/User";
import userAuthorization from "./views/user/UserAuthorization";
import Queue from "./views/queue/Queue";
import QueueItem from "./views/queueItem/QueueItem";
import Home from "./hype/views/Home.vue";
import Workflows from "./hype/views/Workflows.vue";
import Executions from "./hype/views/Executions.vue";
import { redirectIfNotAuthenticated, redirectIfAuthenticated } from "./middlewares/token";
import { getPlanStatus } from "./middlewares/plan";
import store from "./store";
import { checkRoute } from "./middlewares/route";
import Asset from "./views/asset/Asset";
import Radar from "./views/radar/Radar";
import Digistaff from "./views/digistaff/Digistaff";
import Skill from "./views/skill/Skill";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/layout",
      name: "layout",
      component: Layout,
      beforeEnter: redirectIfNotAuthenticated,
      children: [
        {
          path: "/dashboard",
          name: "Dashboard",
          component: Dashboard,
          meta: {
            middleware: [checkRoute, getPlanStatus],
          },
        },
        {
          path: "/agents",
          name: "Agents",
          component: Agent,
          meta: {
            middleware: [checkRoute, getPlanStatus],
          },
        },
        {
          path: "/radar",
          name: "Radar",
          component: Radar,
          meta: {
            middleware: [checkRoute, getPlanStatus],
          },
        },
        {
          path: "/scripts",
          name: "Scripts",
          component: Scripts,
          meta: {
            middleware: [checkRoute, getPlanStatus],
          },
        },
        {
          path: "/addscript",
          name: "AddScript",
          component: AddScript,
          meta: {
            middleware: [checkRoute, getPlanStatus],
          },
        },
        {
          path: "/queues",
          name: "Queues",
          component: Queue,
          meta: {
            middleware: [checkRoute, getPlanStatus],
          },
        },
        {
          path: "/queue/item/:id",
          name: "Queue Items",
          component: QueueItem,
          meta: {
            middleware: [checkRoute, getPlanStatus],
          },
        },
        {
          path: "/assets",
          name: "Assets",
          component: Asset,
          meta: {
            middleware: [checkRoute, getPlanStatus],
          },
        },
        {
          path: "/tasks",
          name: "Tasks",
          component: Tasks,
          meta: {
            middleware: [checkRoute, getPlanStatus],
          },
        },
        {
          path: "/jobs",
          name: "Jobs",
          component: Jobs,
          meta: {
            middleware: [checkRoute, getPlanStatus],
          },
        },
        {
          path: "/logs",
          name: "Logs",
          component: Logs,
          meta: {
            middleware: [checkRoute, getPlanStatus],
          },
        },
        {
          path: "/settings",
          name: "Settings",
          component: Settings,
          meta: {
            middleware: [checkRoute, getPlanStatus],
          },
        },
        {
          path: "/alerts",
          name: "Alerts",
          component: Alert,
          meta: {
            middleware: [checkRoute, getPlanStatus],
          },
        },
        {
          path: "/new-alert",
          name: "AddAlertForTask",
          component: AddAlertForTask,
          meta: {
            middleware: [checkRoute, getPlanStatus],
          },
        },
        {
          path: "/alert/:id",
          name: "AlertInfo",
          component: AddAlertForTask,
          meta: {
            middleware: [checkRoute, getPlanStatus],
          },
        },
        {
          path: "/account",
          name: "Account",
          component: Account,
          meta: {
            middleware: [checkRoute, getPlanStatus],
          },
        },
        {
          path: "/account/payment-method",
          name: "PaymentMethod",
          component: PaymentMethod,
          meta: {
            middleware: [checkRoute, getPlanStatus],
          },
        },
        {
          path: "/user",
          name: "User Management",
          component: User,
          meta: {
            middleware: [checkRoute, getPlanStatus],
          },
        },
        {
          path: "/user/authorization/:id",
          name: "UserAuthorization",
          component: userAuthorization,
          meta: {
            middleware: [checkRoute, getPlanStatus],
          },
        },
        {
          path: "/digistaffs",
          name: "Digistaffs",
          component: Digistaff,
          meta: {
            middleware: [checkRoute, getPlanStatus],
          },
        },
        {
          path: "/skills/:digistaffId",
          name: "Skills",
          component: Skill,
          meta: {
            middleware: [checkRoute, getPlanStatus],
          },
        },
        {
          path: "/workflows",
          name: "Workflow",
          component: Workflows,
          meta: {
            middleware: [checkRoute, getPlanStatus],
          },
        },
        {
          path: "/workflow",
          name: "Home",
          component: Home,
          meta: {
            middleware: [checkRoute, getPlanStatus],
          },
        },
        {
          path: "/workflows/:id",
          name: "EditWorkflow",
          component: Home,
          meta: {
            middleware: [checkRoute, getPlanStatus],
          },
        },
        {
          path: "/executions",
          name: "Executions",
          component: Executions,
          meta: {
            middleware: [checkRoute, getPlanStatus],
          },
        },
        {
          path: "/executions/:executionId",
          name: "Execution",
          component: Home,
          meta: {
            middleware: [checkRoute, getPlanStatus],
          },
        },
      ],
    },
    {
      path: "/",
      name: "login",
      component: Login,
      beforeEnter: redirectIfAuthenticated,
    },
    {
      path: "/signup",
      name: "singup",
      component: SignUp,
      beforeEnter: redirectIfAuthenticated,
    },
    {
      path: "/verify-email/:Code",
      name: "verify-email",
      component: VerifyEmail,
    },
    {
      path: "/email-not-verified",
      name: "email-not-verified",
      component: EmailNotVerified,
    },
    {
      path: "/lostpassword",
      name: "lostpassword",
      component: Lostpassword,
      beforeEnter: redirectIfAuthenticated,
    },
    {
      path: "/renew-password/:token",
      name: "renew-password",
      component: RenewPassword,
      beforeEnter: redirectIfAuthenticated,
    },
    {
      path: "/404",
      name: "errorpage",
      component: ErrorPage,
    },
    {
      path: "*",
      redirect: "/404",
    },
  ],
});

router.beforeEach((to, from, next) => {
  const middlewares = to.meta.middleware;
  if (!middlewares) {
    return next();
  }

  const context = {
    to,
    from,
    next,
    store,
  };

  return middlewares.map((middleware) => {
    return middleware({
      ...context,
    });
  });
});

export default router;
