<template>
  <div>
    <v-skeleton-loader v-show="isLoading" ref="skeleton" type="table" class="mx-auto"></v-skeleton-loader>
    <v-data-table
      v-if="!isLoading"
      :headers="headers"
      :items="agents"
      :options.sync="options"
      :server-items-length="totalCount"
      class="shadow-xl mx-5 my-5"
      :footer-props="{
        itemsPerPageOptions: [10, 50, 100],
      }"
    >
      >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <div class="d-none d-sm-flex">
            <v-toolbar-title>
              Agents
              <v-progress-circular v-show="isRefreshing" indeterminate color="primary"></v-progress-circular>
            </v-toolbar-title>
          </div>
          <v-spacer></v-spacer>
          <delete-agent-dialog />
          <new-agent-dialog v-if="hasAuth('New Agent')"></new-agent-dialog>
          <edit-agent-dialog />
          <download-agents class="d-none d-sm-flex" v-if="hasAuth('Download Agent')" />
        </v-toolbar>
        <v-row class="px-5">
          <v-col cols="12" xs="6" sm="6" md="2">
            <v-text-field v-model="filters.Name" label="Agent Name"></v-text-field>
          </v-col>
          <v-col cols="12" xs="6" sm="6" md="3">
            <v-text-field v-model="filters.Id" label="Agent Key"></v-text-field>
          </v-col>
          <v-col cols="12" xs="6" sm="6" md="2">
            <v-text-field v-model="filters.Username" label="Username"></v-text-field>
          </v-col>
          <v-col cols="12" xs="6" sm="6" md="3">
            <v-autocomplete v-model="filters.Status" :items="status" clearable item-text="name" item-value="value" label="Status"></v-autocomplete>
          </v-col>
          <v-col cols="12" xs="6" sm="6" md="2">
            <v-btn depressed color="primary" class="mt-5" @click="filter" block>Refresh</v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="mx-5">
            <download-agents class="d-flex d-sm-none" />
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.action="{ item }">
        <v-tooltip bottom v-if="hasRowAuth(item.DataEdit, item.GlobalAuthControl, 'Edit Agent')">
          <template v-slot:activator="{ on }">
            <v-btn depressed class="mx-1" @click="() => openEditDialog(item)" v-on="on">
              <v-icon size="18">edit</v-icon>
            </v-btn>
          </template>
          <span>Edit Agent</span>
        </v-tooltip>
        <v-tooltip bottom v-if="hasRowAuth(item.DataDelete, item.GlobalAuthControl, 'Delete Agent')">
          <template v-slot:activator="{ on }">
            <v-btn depressed @click="openDeleteDialog(item)" v-on="on">
              <v-icon size="18">delete</v-icon>
            </v-btn>
          </template>
          <span>Delete Agent</span>
        </v-tooltip>
        <v-tooltip bottom v-if="hasRowAuth(item.DataDisconnect, item.GlobalAuthControl, 'Disconnect Agent')">
          <template v-slot:activator="{ on }">
            <v-btn class="mx-1" depressed v-show="item.Status == 'Connected'" @click="disconnect(item.Id)" v-on="on">
              <v-icon size="18" color="red">mdi-close-network</v-icon>
            </v-btn>
          </template>
          <span>Disconnect Agent</span>
        </v-tooltip>
      </template>
      <template v-slot:item.Status="{ item }">
        <v-chip :color="item.Status" dark>{{ item.Status }}</v-chip>
      </template>
      <template v-slot:item.OS="{ item }">{{ item.OS || "not received" }}</template>
      <template v-slot:item.Id="{ item }">
        <input type="text" v-model="item.Id" full-width />
        <button type="button" v-clipboard:copy="item.Id" v-clipboard:success="onCopy" v-clipboard:error="onError">
          <v-icon size="18" color="black">file_copy</v-icon>
        </button>
      </template>
    </v-data-table>
    <v-snackbar v-model="snackbar">
      Succesfully Disconnected
      <v-btn color="pink" text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import NewAgentDialog from "./NewAgentDialog";
import EditAgentDialog from "./EditAgentDialog";
import DeleteAgentDialog from "./DeleteAgentDialog";
import DownloadAgents from "./DownloadAgents";
import { timeAwareFormat } from "../time";
import { mapState, mapActions } from "vuex";
import { FILTER_AGENTS, OPEN_EDIT_DIALOG, CLOSE_EDIT_DIALOG, OPEN_AGENT_DELETE_DIALOG } from "../store/modules/agent";
import Api from "../api";
import { hasActionAuth } from "../authorization";

export default {
  computed: {
    ...mapState("agent", {
      agents: (state) =>
        state.pagedAgents.map((i) => ({
          ...i,
          createdAt: timeAwareFormat(i.createdAt),
        })),
      totalCount: (state) => state.totalCount,
      isRefreshing: (state) => state.listRefreshing,
      isLoading: (state) => state.listLoading,
    }),
  },
  components: {
    "new-agent-dialog": NewAgentDialog,
    "edit-agent-dialog": EditAgentDialog,
    "delete-agent-dialog": DeleteAgentDialog,
    "download-agents": DownloadAgents,
  },
  data: () => ({
    snackbar: false,
    valid: false,
    nameRules: [(v) => !!v || "Name is required"],
    keyRules: [(v) => !!v || "Key is required"],
    menu: false,
    modal: false,
    headers: [
      {
        text: "Name",
        align: "left",
        sortable: true,
        value: "Name",
      },
      {
        text: "Agent Key",
        value: "Id",
      },
      {
        text: "Username",
        value: "Username",
      },
      {
        text: "Platform",
        value: "OS",
      },
      {
        text: "Created Time",
        value: "createdAt",
      },
      {
        text: "Created",
        value: "Created.Name",
      },
      {
        text: "Status",
        value: "Status",
      },
      {
        text: "Actions",
        value: "action",
        sortable: false,
      },
    ],
    defaultItem: {
      name: "",
      key: "",
      createdtime: "",
      platform: "",
      createduser: "",
    },
    options: { itemsPerPage: 10, page: 1 },
    filters: { Name: "", Id: "", Username: "", Status: 0 },
    status: [
      { name: "Connected", value: 2 },
      { name: "Disconnected", value: 3 },
    ],
  }),
  watch: {
    options: {
      handler(newVal, oldVal) {
        if (newVal.page != oldVal.page || newVal.itemsPerPage != oldVal.itemsPerPage) {
          this.fetchAgents({ options: newVal, isRefreshing: true });
        }
      },
      deep: true,
    },
  },
  created() {
    this.fetchAgents({ options: this.options, isRefreshing: false });
  },
  methods: {
    onCopy: function() {
      // alert('You just copied: Hook ID')
    },
    onError: function() {
      alert("Failed to copy Hook ID");
    },
    ...mapActions("agent", {
      fetchAgents: FILTER_AGENTS,
      openEditDialog: OPEN_EDIT_DIALOG,
      closeEditDialog: CLOSE_EDIT_DIALOG,
      openDeleteDialog: OPEN_AGENT_DELETE_DIALOG,
    }),
    async disconnect(id) {
      (await Api.disconnectAgent(id)) && (this.snackbar = true);
    },
    hasAuth(action) {
      return hasActionAuth("Agents", action);
    },
    hasRowAuth(auth, globalAuthControl, action) {
      if (!auth && globalAuthControl) {
        return this.hasAuth(action);
      }

      return auth;
    },
    filter() {
      this.fetchAgents({ options: { ...this.options, ...this.filters }, isRefreshing: false });
    },
  },
};
</script>

<style scoped>
.shadow-xl {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

.Connected {
  background-color: #27ae60 !important;
}

.Disconnected {
  background-color: #c0392b !important;
}
</style>
