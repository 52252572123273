var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isLoading)?_c('v-skeleton-loader',{ref:"skeleton",staticClass:"mx-auto",attrs:{"type":"table"}}):_c('v-data-table',{staticClass:"shadow-xl mx-5 my-5",attrs:{"headers":_vm.headers,"items":_vm.queueItems,"footer-props":{
      itemsPerPageOptions: [10, 50, 100, -1],
    }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('div',{staticClass:"d-none d-sm-flex"},[_c('v-toolbar-title',[_vm._v("Queue Items")])],1),_c('v-spacer'),_c('add-queue-item-dialog',{directives:[{name:"show",rawName:"v-show",value:(_vm.id),expression:"id"}],attrs:{"id":_vm.id}}),_c('queue-item-detail-dialog',{directives:[{name:"show",rawName:"v-show",value:(_vm.id),expression:"id"}]}),_c('delete-queue-item-dialog',{directives:[{name:"show",rawName:"v-show",value:(_vm.id),expression:"id"}]})],1)]},proxy:true},{key:"item.action",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"depressed":""},on:{"click":function () { return _vm.queueItemDetail(item); }}},on),[_c('v-icon',{attrs:{"size":"18"}},[_vm._v("visibility")])],1)]}}],null,true)},[_c('span',[_vm._v("View Queue Item")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"depressed":""},on:{"click":function($event){return _vm.deleteQueueItem(item.Id)}}},on),[_c('v-icon',{attrs:{"size":"18"}},[_vm._v("delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete Queue Item")])])]}},{key:"no-data",fn:function(){return undefined},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }