var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-expansion-panels',{attrs:{"focusable":""},model:{value:(_vm.expansion),callback:function ($$v) {_vm.expansion=$$v},expression:"expansion"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{attrs:{"disable-icon-rotate":""},on:{"click":_vm.toggleView},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"size":"40","color":_vm.setSelectedColor()}},_vm.setTooltip(on)),[_vm._v(_vm._s(_vm.setSelectedIcon()))])]}}])},[_c('span',[_vm._v(_vm._s(_vm.getTooltipText()))])])]},proxy:true}])},[_vm._v(" Data ")]),_c('v-expansion-panel-content',[(_vm.isLoading)?_c('v-skeleton-loader',{ref:"skeleton",staticClass:"mx-auto",attrs:{"type":"table"}}):_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',_vm._l((_vm.headers),function(header,index){return _c('td',{key:index},[(index === 0)?_c('span',[_vm._v(_vm._s(item.Name || item.name))]):_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-switch',_vm._g({staticClass:"my-1",attrs:{"color":"primary","hide-details":"","input-value":_vm.getSwitchValue(
                        item.Id || item.id,
                        header.userDataAuthorizations
                      )},on:{"change":function($event){return _vm.toggle(item.Id || item.id, header.attributeId)}}},header.description ? on : ''))]}}],null,true)},[_c('span',[_vm._v(_vm._s(header.description))])])],1)}),0)]}},{key:"no-data",fn:function(){return undefined},proxy:true}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }