import Api from "../../api";

export const FETCH_ALERTS = "FETCH_ALERTS";
export const FETCH_FILTER_ALERTS = "FETCH_FILTER_ALERTS";

const state = {
  alerts: [],
  filteredAlerts: [],
  totalCount: 0,
  isLoading: false,
};

const getters = {};

const actions = {
  [FETCH_ALERTS]: async ({ commit }) => {
    commit("setLoading", true);
    let alerts = await Api.listAlerts();
    commit("setAlerts", alerts);
    commit("setLoading", false);
    return alerts;
  },
  [FETCH_FILTER_ALERTS]: async ({ commit }, { options }) => {
    commit("setLoading", true);

    let result = await Api.listFilterAlerts(options);

    commit("setTotalCount", result.count);
    commit("setFilteredAlerts", result.rows);

    commit("setLoading", false);
  },
};

const mutations = {
  setAlerts: mutator("alerts"),
  setFilteredAlerts: mutator("filteredAlerts"),
  setTotalCount: mutator("totalCount"),
  setLoading: mutator("isLoading"),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

function mutator(name) {
  return function() {
    arguments[0][name] = arguments[1];
  };
}

export const NotificationTypes = {
  AGENT: 1,
  TASK: 2,
};

export const NotificationNotifyOnStatuses = {
  ALL_JOB_STATUS: 0,
  JOB_FAIL: 1,
  JOB_SUCCESS: 2,
};
