<template>
  <div>
    <v-skeleton-loader v-if="isLoading" ref="skeleton" type="table" class="mx-auto"></v-skeleton-loader>
    <v-data-table
      :headers="headers"
      :items="digistaff"
      class="shadow-xl mx-5 my-5"
      :footer-props="{
        itemsPerPageOptions: [10, 50, 100],
      }"
      v-else
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <div class="d-none d-sm-flex">
            <v-toolbar-title>Digistaff</v-toolbar-title>
          </div>
          <v-spacer></v-spacer>
          <new-digistaff-dialog v-if="hasAuth('Digistaffs', 'New Digistaff')" />
          <edit-digistaff-dialog v-if="hasAuth('Digistaffs', 'Edit Digistaff')" />
          <delete-digistaff-dialog v-if="hasAuth('Digistaffs', 'Delete Digistaff')" />
          <department-dialog v-if="hasAuth('Departments', 'View')" />
        </v-toolbar>
      </template>
      <template v-slot:item.Department="{ item }">
        <v-chip v-for="department in item.Departments" :key="department.Id" class="ma-2" color="primary" text-color="white">
          {{ department.Name }}
        </v-chip>
      </template>
      <template v-slot:item.action="{ item }">
        <v-tooltip bottom v-if="hasAuth('Digistaffs', 'Edit Digistaff')">
          <template v-slot:activator="{ on }">
            <v-btn depressed class="mx-1" @click="() => editDigistaff(item)" v-on="on">
              <v-icon size="18">edit</v-icon>
            </v-btn>
          </template>
          <span>Edit Digistaff</span>
        </v-tooltip>
        <v-tooltip bottom v-if="hasAuth('Digistaffs', 'Delete Digistaff')">
          <template v-slot:activator="{ on }">
            <v-btn depressed @click="deleteDigistaff(item.Id)" v-on="on">
              <v-icon size="18">delete</v-icon>
            </v-btn>
          </template>
          <span>Delete Digistaff</span>
        </v-tooltip>
        <v-tooltip bottom v-if="hasAuth('Skills', 'View')">
          <template v-slot:activator="{ on }">
            <v-btn depressed class="mx-1" @click="() => redirectDetail(item.Id)" v-on="on">
              <v-icon size="18">settings_overscan</v-icon>
            </v-btn>
          </template>
          <span>Skills</span>
        </v-tooltip>
      </template>
      <template v-slot:no-data></template>
    </v-data-table>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { FETCH_DIGISTAFFS, TOGGLE_UPDATE_DIGISTAFF_DIALOG, TOGGLE_DELETE_DIGISTAFF_DIALOG } from "../../store/modules/digistaff";
import { timeAwareFormat } from "../../time";
import NewDigistaffDialog from "./NewDigistaffDialog";
import EditDigistaffDialog from "./EditDigistaffDialog";
import DeleteDigistaffDialog from "./DeleteDigistaffDialog";
import DepartmentDialog from "../department/DepartmentDialog";
import { hasActionAuthorization } from "../../authorization";

export default {
  data: () => ({
    headers: [
      {
        text: "Name",
        value: "Name",
      },
      {
        text: "Departments",
        value: "Department",
        align: "center",
      },
      {
        text: "Created Time",
        value: "createdAt",
      },
      {
        text: "Actions",
        value: "action",
      },
    ],
  }),
  components: {
    "new-digistaff-dialog": NewDigistaffDialog,
    "edit-digistaff-dialog": EditDigistaffDialog,
    "delete-digistaff-dialog": DeleteDigistaffDialog,
    "department-dialog": DepartmentDialog,
  },
  computed: {
    ...mapState("digistaff", {
      digistaff: (state) =>
        state.digistaff.map((i) => ({
          ...i,
          createdAt: timeAwareFormat(i.createdAt),
          Department: i.Departments.map((department) => {
            return department.Name;
          }).join(","),
        })),
      isLoading: (state) => state.digistaffIsLoading,
    }),
  },
  created() {
    this.fetchDigistaff();
  },
  methods: {
    ...mapActions("digistaff", {
      fetchDigistaff: FETCH_DIGISTAFFS,
      toggleEditDialog: TOGGLE_UPDATE_DIGISTAFF_DIALOG,
      toggleDeleteDialog: TOGGLE_DELETE_DIGISTAFF_DIALOG,
    }),
    editDigistaff(item) {
      this.toggleEditDialog(item);
    },
    deleteDigistaff(id) {
      this.toggleDeleteDialog(id);
    },
    redirectDetail(id) {
      this.$router.push(`/skills/${id}`);
    },
    hasAuth(page, action) {
      return hasActionAuthorization(this.$store, page, action);
    },
  },
};
</script>
