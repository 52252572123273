export function resolveVariable(obj, keyPath) {
  let tokens = keyPath.split('.')
  let temp = obj
  for (const token of tokens) {
    temp = temp[token]
  }
  return temp
}

export function setVariable(obj, keyPath, variable) {
  let tokens = keyPath.split('.')
  let last = tokens[tokens.length - 1]
  tokens = tokens.slice(0, tokens.length - 1)
  let temp = obj
  for (const token of tokens) {
    temp = temp[token]
  }
  temp[last] = variable
}

export function removePath(obj,keyPath){
  let tokens = keyPath.split('.')
  let last = tokens[tokens.length - 1]
  tokens = tokens.slice(0, tokens.length - 1)
  let temp = obj
  for (const token of tokens) {
    temp = temp[token]
  }
  delete temp[last]
}

export function getSetter(obj, keyPath) {
  let tokens = keyPath.split('.')
  let last = tokens[tokens.length - 1]
  tokens = tokens.slice(0, tokens.length - 1)
  let temp = obj
  for (const token of tokens) {
    temp = temp[token]
  }
  return function(val) {
    temp[last] = val
  }
}

export function getGetter(obj, keyPath) {
  let tokens = keyPath.split('.')
  let last = tokens[tokens.length - 1]
  tokens = tokens.slice(0, tokens.length - 1)
  let temp = obj
  for (const token of tokens) {
    temp = temp[token]
  }

  return function() {
    return temp[last]
  }
}

export function join(...elems) {
  return elems.filter(e => !!e).join('.')
}
