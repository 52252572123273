<template>
  <div>
    <div>
      <v-navigation-drawer v-model="drawer" app clipped class="py-5">
        <v-list dense>
          <v-list-item
            v-for="item in items"
            :key="item.text"
            router
            :to="item.route"
            link
            v-bind:class="{ bottomline: item.bottomline }"
          >
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
    </div>
    <v-app-bar
      app
      clipped-left
      color="primary"
      dense
      class="white--text shadow-md"
      height="65"
    >
      <v-app-bar-nav-icon dark @click.stop="drawer = !drawer" />
      <v-spacer />
      <router-link to="/" class="v-toolbar__title ml-0 pl-4">
        <v-img
          v-bind:src="this.$assetHelper.getImage('qbitra-logo.png')"
          v-bind:lazy-src="this.$assetHelper.getImage('qbitra-logo.png')"
          max-width="150"
          class="mr-2"
        ></v-img>
      </router-link>
      <v-spacer />
      <div class="d-none d-sm-flex">
        <router-link
          to="/account"
          class="black--text"
          v-show="planStatus.remainingTrialDay"
        >
          <v-chip>Trial {{ planStatus.remainingTrialDay }} days left</v-chip>
        </router-link>
      </div>
      <v-menu>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            text
            class="white--text text-capitalize font-weight-light"
          >
            <v-icon left>account_circle</v-icon>
            <span class="d-none d-sm-flex">{{ user.Name }}</span>
            <v-icon left>keyboard_arrow_down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-subheader>My Account</v-subheader>
          <div v-for="(page, index) in menus" v-bind:key="index">
            <router-link v-bind:to="page.url">
              <v-list-item link>
                <v-list-item-title>{{ page.text }}</v-list-item-title>
              </v-list-item>
            </router-link>
          </div>
          <v-list-item @click="logout" link>
            <v-list-item-title>Log Out</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
  </div>
</template>

<script>
import Auth from "../auth";
import eventBusMixin from "../hype/mixins/eventBus";
import { mapState, mapActions, mapMutations } from "vuex";
import { FETCH_USER } from "../store/modules/user";

export default {
  mixins: [eventBusMixin],
  props: {
    source: String,
  },
  data: () => ({
    drawer: null,
  }),
  created() {
    this.fetchUser();
  },
  mounted() {
    this.$subscribe("navigationDrawerStatus", (status) => {
      this.drawer = status;
    });
  },
  watch: {
    drawer(val){
      this.setDrawer(val);
    }
  },
  computed: {
    ...mapState("user", {
      user: (state) => state.user,
      items: (state) => {
        return state.pages.reduce((filtered, page) => {
          if (
            page.Url &&
            (page.Place === "OnlyMenu" || page.Place === "HeaderAndMenu")
          ) {
            filtered.push({
              icon: page.Icon,
              text: page.Name,
              route: page.Url,
              bottomline: page.Bottomline,
            });
          }
          return filtered;
        }, []);
      },
      menus: (state) => {
        return state.pages.reduce((filtered, page) => {
          if (
            page.Url &&
            (page.Place === "OnlyHeader" || page.Place === "HeaderAndMenu")
          ) {
            filtered.push({
              text: page.Name,
              url: page.Url,
            });
          }
          return filtered;
        }, []);
      },
    }),
    ...mapState("account", {
      planStatus: (state) => state.planStatus,
    }),
  },
  methods: {
    ...mapMutations("user", {
      setPages: "setPages",
    }),
    ...mapMutations("options", {
      setDrawer: "setDrawer",
    }),
    logout() {
      Auth.logout();
      this.setPages([]);
      this.$router.push("/");
    },
    ...mapActions("user", {
      fetchUser: FETCH_USER,
    }),
    trialControl() {
      return this.planStatus.remaining.TrialDay;
    },
  },
};
</script>

<style>
.bottomline {
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 15px;
}
</style>
