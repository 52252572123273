import Vue from "./index";

export function hasActionAuth(page, action) {
  if (!Vue) {
    return;
  }

  const user = Vue.$store.state.user.user;
  if (user.Role === "Owner" || user.Role === "Administrator") {
    return true;
  }

  let auth = Vue.$store.state.user.pages.findIndex((item) => {
    if (item.Name === page && item.AuthorizationAttributes) {
      let attributes = item.AuthorizationAttributes.findIndex((attribute) => {
        return attribute.Action === action && attribute.UserAuthorizations;
      });
      return attributes !== -1;
    }
  });
  return auth !== -1;
}

export function hasActionAuthorization(store, page, action) {
  const user = store.state.user.user;
  if (user.Role === "Owner" || user.Role === "Administrator") {
    return true;
  }

  let auth = store.state.user.pages.findIndex((item) => {
    if (item.Name === page && item.AuthorizationAttributes) {
      let attributes = item.AuthorizationAttributes.findIndex((attribute) => {
        return attribute.Action === action && attribute.UserAuthorizations;
      });
      return attributes !== -1;
    }
  });
  return auth !== -1;
}
