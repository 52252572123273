import Api from "../../api";
export const FETCH_JOBS = "FETCH_JOBS";

const state = {
  jobs: [],
  isLoading: false,
  error: "",
};

const getters = {};

const actions = {
  [FETCH_JOBS]: async ({ commit }) => {
    commit("setLoading", true);
    try {
      let jobs = await Api.listJobs();
      jobs = jobs.map((job) => {
        switch (job.Status) {
          case 1:
            job.Status = "RUNNING";
            break;
          case 2:
            job.Status = "SUCCESS";
            break;
          case 3:
            job.Status = "FAILURE";
            break;
          case 4:
            job.Status = "SENDING";
            break;
          case 5:
            job.Status = "DISCONNECTED";
            break;
          case 6:
            job.Status = "QUEUE";
            break;
          case 7:
            job.Status = "DECLINED";
            break;
        }
        return job;
      });
      commit("setJobs", jobs);
    } catch (err) {
      commit("setError", err.response.data.reason);
    }
    commit("setLoading", false);
  },
};

const mutations = {
  setJobs: (state, jobs) => (state.jobs = jobs),
  setLoading: (state, status) => (state.isLoading = status),
  setError: (state, error) => (state.error = error),
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
