/* eslint-disable no-unused-vars */
export function convertFlowData(flowData) {
  return {
    workflowData: {
      name: "",
      nodes: getNodes(flowData),
      connections: getConnections(flowData),
      active: false,
      settings: {},
    },
    startNodes: getStartNodes(),
  };
}

function getNodes(flowData) {
  let flow = flowData.drawflow.Home.data;
  return Object.values(flow).map((node) => {
    return {
      disabled: node.data.disabled,
      name: node.name,
      type: node.data.node.name,
      typeVersion: node.data.node.version,
      position: [node.pos_x, node.pos_y],
      parameters: node.data.data,
      credentials: node.data.credentials,
      ...node.data.settings,
    };
  });
}

function getConnections(flowData) {
  let flow = flowData.drawflow.Home.data;
  let connections = {};
  for (const node of Object.values(flow)) {
    if (Object.values(node.outputs).every((output) => output.connections.length == 0)) {
      continue;
    }
    connections[node.name] = getConnectionsOfNode(node, flow);
  }
  return connections;
}

function getConnectionsOfNode(node, flow) {
  let connections = {};
  for (const [index, port] of Object.entries(Object.values(node.outputs))) {
    let connType = node.data.node.outputs[index];
    connections[connType] = connections[connType] ?? [];
    connections[connType].push(
      port.connections.map((conn) => {
        return {
          node: flow[conn.node].name,
          type: flow[conn.node].data.node.inputs[conn.output.slice("input_".length) - 1],
          index: 0,
        };
      })
    );
  }

  return connections;
}

function getStartNodes(flowData) {
  return [null];
}
