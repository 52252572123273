export const FETCH_TASKS = "FETCH_TASKS";
export const FETCH_TASK_WITH_PAGE = "FETCH_TASK_WITH_PAGE";
export const OPEN_CREATE_TASK_DIALOG = "OPEN_CREATE_TASK_DIALOG";
export const OPEN_RUN_DIALOG = "OPEN_RUN_DIALOG";
export const CLOSE_RUN_DIALOG = "CLOSE_RUN_DIALOG";
export const CLOSE_CREATE_TASK_DIALOG = "CLOSE_CREATE_TASK_DIALOG";
export const CREATE_TASK = "CREATE_TASK";
export const OPEN_EDIT_TASK_DIALOG = "OPEN_EDIT_TASK_DIALOG";
export const CLOSE_EDIT_TASK_DIALOG = "CLOSE_EDIT_TASK_DIALOG";
export const EDIT_TASK = "EDIT_TASK";
export const DELETE_TASK = "DELETE_TASK";
export const GET_TASKS = "GET_TASKS";
export const RUN_TASK = "RUN_TASK";

import Api from "../../api";
import { FETCH_SCRIPTS } from "./script";
import { FETCH_AGENTS } from "./agent";
import { FETCH_ASSETS } from "./asset";

function mutator(name) {
  return function() {
    arguments[0][name] = arguments[1];
  };
}

const state = {
  tasks: [],
  pageTasks: [],
  totalCount: 0,
  isRefreshing: false,
  isLoading: false,
  fetchError: "",
  runDialog: false,
  createLoading: false,
  createError: "",
  createSuccess: false,
  createDialog: false,
  editLoading: false,
  editError: "",
  editSuccess: false,
  editDialog: false,
  editItem: {
    agents: [],
    ScriptId: "",
    CronDefinition: "",
    Name: "",
    RetryOnFail: false,
    MaxRetries: 3,
    UserPermission: false,
    assetIds: [],
  },
  deleteLoading: false,
  deleteError: "",
  deleteSuccess: false,
  deleteDialog: false,
  runError: "",
};

const getters = {};

const mutations = {
  setTasks: mutator("tasks"),
  setPageTasks: mutator("pageTasks"),
  setTotalCount: mutator("totalCount"),
  setFetching: (state, { status, isRefreshing }) => {
    if (isRefreshing) {
      state.isRefreshing = status;
    } else {
      state.isLoading = status;
    }
  },
  setFetchError: mutator("fetchError"),
  setCreateLoading: mutator("createLoading"),
  setCreateError: mutator("createError"),
  setCreateSuccess: mutator("createSuccess"),
  setCreateDialog: mutator("createDialog"),
  setEditLoading: mutator("editLoading"),
  setEditError: mutator("editError"),
  setEditSuccess: mutator("editSuccess"),
  setEditDialog: mutator("editDialog"),
  setEditItem: mutator("editItem"),
  setDeleteLoading: mutator("deleteLoading"),
  setDeleteError: mutator("deleteError"),
  setDeleteSuccess: mutator("deleteSuccess"),
  setDeleteDialog: mutator("deleteDialog"),
  setRunDialog: mutator("runDialog"),
  clearCreateDialog: (state) => {
    state.createLoading = false;
    state.createError = "";
    state.createSuccess = false;
  },
  clearEditDialog: (state) => {
    state.editLoading = false;
    state.editError = "";
    state.editSuccess = false;
  },
  setRunError: mutator("runError"),
};

const actions = {
  [FETCH_TASKS]: async ({ commit }, isRefreshing = true) => {
    commit("setFetching", {
      status: true,
      isRefreshing,
    });
    let tasks = await Api.listTasks();
    commit("setTasks", tasks);
    commit("setFetching", {
      status: false,
      isRefreshing,
    });
  },
  [FETCH_TASK_WITH_PAGE]: async ({ commit }, { options, isRefreshing = true }) => {
    commit("setFetching", {
      status: true,
      isRefreshing,
    });
    let result = await Api.listTasksWithPage(options);
    commit("setTotalCount", result.count);
    commit("setPageTasks", result.rows);
    commit("setFetching", {
      status: false,
      isRefreshing,
    });
  },
  [CREATE_TASK]: async ({ commit, dispatch }, {task , filterAndOptions}) => {
    commit("setCreateError", "");
    commit("setCreateLoading", true);
    try {
      await Api.createTask(task);
      commit("setCreateLoading", false);
      commit("setCreateSuccess", true);
      dispatch(FETCH_TASK_WITH_PAGE, {
        options: filterAndOptions,
        isRefreshing: true,
      });
    } catch (e) {
      commit("setCreateError", e.response.data.reason);
    }
    commit("setCreateLoading", false);
  },
  [DELETE_TASK]: async ({ commit, dispatch }, {taskId, filterAndOptions}) => {
    commit("setDeleteLoading", true);
    try {
      await Api.deleteTask(taskId);
      commit("setDeleteLoading", false);
      commit("setDeleteSuccess", true);
      dispatch(FETCH_TASK_WITH_PAGE, {
        options: filterAndOptions,
        isRefreshing: true,
      });
    } catch (e) {
      commit("setDeleteError", e.response.data.reason);
    }
    commit("setDeleteLoading", false);
  },
  [EDIT_TASK]: async ({ commit, dispatch }, { task, filterAndOptions}) => {
    console.log("BBBBBB", task, filterAndOptions);
    commit("setEditError", "");
    commit("setEditLoading", true);
    try {
      await Api.updateTask(task);
      commit("setEditLoading", false);
      commit("setEditSuccess", true);
      dispatch(FETCH_TASK_WITH_PAGE, {
        options: filterAndOptions,
        isRefreshing: true,
      });
    } catch (e) {
      commit("setEditError", e.response.data.reason);
    }
    commit("setEditLoading", false);
  },
  [OPEN_CREATE_TASK_DIALOG]: async ({ commit, dispatch }) => {
    commit("clearCreateDialog");
    dispatch("script/" + FETCH_SCRIPTS, false, {
      root: true,
    });
    dispatch("agent/" + FETCH_AGENTS, false, {
      root: true,
    });
    dispatch("asset/" + FETCH_ASSETS, false, {
      root: true,
    });
    commit("setCreateDialog", true);
  },
  [CLOSE_CREATE_TASK_DIALOG]: async ({ commit }) => {
    commit("setCreateDialog", false);
  },
  [OPEN_EDIT_TASK_DIALOG]: async ({ commit, dispatch }, editItem) => {
    commit("clearEditDialog");
    dispatch("script/" + FETCH_SCRIPTS, false, {
      root: true,
    });
    dispatch("agent/" + FETCH_AGENTS, false, {
      root: true,
    });
    dispatch("asset/" + FETCH_ASSETS, false, {
      root: true,
    });
    commit("setEditItem", {
      ...editItem,
      ScriptId: editItem.Script?.Id ?? "",
    });
    commit("setEditDialog", true);
  },
  [CLOSE_EDIT_TASK_DIALOG]: async ({ commit }) => {
    commit("setEditDialog", false);
  },
  [OPEN_RUN_DIALOG]: ({ commit }) => {
    commit("setRunDialog", true);
  },
  [CLOSE_RUN_DIALOG]: ({ commit }) => {
    commit("setRunDialog", false);
  },
  [GET_TASKS]: async () => {
    return Api.listTasks();
  },
  [RUN_TASK]: async ({ commit }, form) => {
    commit("setRunError", "");
    try {
      await Api.runTask(form);
    } catch (error) {
      commit("setRunError", error.response.data.reason);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
