import config from '../config'

export function apiURL(_url) {
  return joinURL(config.restUrl, _url)
}

export function webhookURL(_url, id, nodename, type) {
  return joinURL(
    config.baseURL,
    type == 'PRODUCTION' ? 'webhook' : 'webhook-test',
    escape(`/${id}/${nodename.toLowerCase()}/${_url}`)
  )
}

export function joinURL(...urls) {
  return urls
    .map(urlPart => {
      let part = urlPart
      part = part.endsWith('/') ? part.slice(0, -1) : part
      part = part.startsWith('/') ? part.slice(1) : part
      return part
    })
    .join('/')
}
