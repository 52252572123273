<template>
  <input-wrapper @click="openExpressionEditor" :has-expression="hasExpression" @remove="remove" :removable="removable">
    <template v-slot:default="{ disabled }">
      <v-textarea
        v-if="!property.typeOptions || !property.typeOptions.password"
        :type="property.type"
        :label="property.displayName"
        v-model="data"
        :disabled="options.disabled || disabled"
        :append-icon="appendIcon"
        @click:append="openExpressionEditor"
        :rows="hasExpression ? 1 : rowCount"
        :no-resize="!isTextArea"
      >
        <template v-slot:append-outer>
          <definition-tip
            :name="property.displayName"
            :definition="property.description"
          ></definition-tip>
        </template>
      </v-textarea>
      <v-text-field
        v-else
        type="password"
        :label="property.displayName"
        v-model="data"
        :disabled="options.disabled || disabled"
        :append-icon="appendIcon"
        @click:append="openExpressionEditor"
        :no-resize="!isTextArea"
      >
        <template v-slot:append-outer>
          <definition-tip
            :name="property.displayName"
            :definition="property.description"
          ></definition-tip>
        </template>
      </v-text-field>
    </template>
  </input-wrapper>
</template>

<script>
import DefinitionTip from "../DefinitionTip";
import dynamicProperty from "../../mixins/dynamicProperty";
import InputWrapper from "./InputWrapper";
export default {
  components: { InputWrapper, DefinitionTip },
  mixins: [dynamicProperty],
  inheritAttrs: false,
  props: ["value", "property", "options", "path"],
  computed: {
    rowCount() {
      return this.property.typeOptions?.rows ?? 1;
    },
    isTextArea() {
      return !!this.property.typeOptions?.rows;
    },
  },
};
</script>
