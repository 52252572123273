import { joinURL } from "./helpers/url";
import { hypeURL } from "../config";

const _baseURL = hypeURL ?? document.baseURI;
export default {
  sessionId: Math.random()
    .toString(36)
    .substring(2, 15),
  baseURL: _baseURL,
  restUrl: joinURL(_baseURL, "/rest"),
  oauthCallbackURL: joinURL(_baseURL, "/rest/oauth2-credential/callback"),
};
