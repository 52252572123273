<template>
  <v-main>
    <v-container class="mt-5" fluid>
      <v-row align="center" justify="center" class="my-4">
        <v-img
          v-bind:src="this.$assetHelper.getImage('logo-black.png')"
          v-bind:lazy-src="this.$assetHelper.getImage('logo-black.png')"
          aspect-ratio="1"
          max-width="207"
          max-height="47"
          class="mr-2 float-left"
        ></v-img>
      </v-row>

      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="4">
          <v-card class="shadow-xl">
            <v-toolbar color="primary" dark flat>
              <v-toolbar-title>
                <span>Sign Up</span>
              </v-toolbar-title>
              <v-spacer />
            </v-toolbar>
            <v-progress-linear
              v-show="isLoading"
              indeterminate
              color="yellow darken-2"
            ></v-progress-linear>
            <v-alert
              v-show="!!errorMessage"
              text
              prominent
              type="error"
              icon="mdi-alert-circle"
              >{{ errorMessage }}</v-alert
            >
            <v-card-text>
              <v-form ref="form" class="pa-5">
                <v-text-field
                  label="Full Name"
                  v-model="name"
                  maxlength="30"
                  counter
                  type="text"
                  required
                  :rules="nameRules"
                  outlined
                />
                <v-text-field
                  label="E-Mail"
                  maxlength="50"
                  counter
                  v-model="email"
                  type="text"
                  required
                  :rules="emailRules"
                  outlined
                />
                <v-text-field
                  id="password"
                  maxlength="50"
                  counterlabel="Password"
                  v-model="password"
                  label="Password"
                  name="password"
                  counter
                  prepend-inner-icon="lock"
                  type="password"
                  required
                  :rules="passwordRules"
                  outlined
                />
                <v-text-field
                  id="passwordAgain"
                  maxlength="50"
                  counter
                  label="Password Again"
                  v-model="passwordAgain"
                  name="passwordAgain"
                  prepend-inner-icon="lock"
                  type="password"
                  required
                  :rules="passwordAgainRules"
                  outlined
                />
                <v-row v-show="false">
                  <v-checkbox v-model="agree" :rules="agreeRules"></v-checkbox>
                  <span class="label">
                    <a :href="getAgreementLink()" target="_blank"
                      >User Terms and Conditions</a
                    >
                  </span>
                </v-row>
              </v-form>
              <p class="text-right">
                <router-link to="/lostpassword">Forgot password?</router-link>
              </p>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn color="primary" v-on:click="signin" depressed block
                >Signup</v-btn
              >
            </v-card-actions>
            <v-card-actions class="text-center">
              <div class="text-center">
                <p class="text-center">
                  Already have an account?
                  <router-link to="/">Log in</router-link>
                </p>
              </div>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<style>
.shadow-xl {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
}
.label {
  margin-top: 18px;
  padding-top: 4px;
}
.v-messages__message {
  position: fixed;
}
</style>

<script>
import Api from "../api";
import { getFile } from "../asset";
import { userAgreement } from "../config";

export default {
  data() {
    return {
      emailRules: [(v) => !!v || "E-Mail is required"],
      nameRules: [(v) => !!v || "Name is required"],
      passwordRules: [(v) => !!v || "Password is required"],
      passwordAgainRules: [
        (v) => {
          return this.password == v || "Password does not match";
        },
      ],
      agreeRules: [(v) => !!v || "You must agree to continue!"],
      isLoading: false,
      errorMessage: "",
      name: "",
      email: "",
      password: "",
      passwordAgain: "",
      agree: true,
    };
  },
  props: {
    source: String,
  },
  methods: {
    async signin() {
      if (this.$refs.form.validate()) {
        this.isLoading = true;
        try {
          await Api.signin({
            Email: this.email,
            Password: this.password,
            Name: this.name,
          });
          this.$router.push("dashboard");
        } catch (e) {
          this.errorMessage = e.response.data.reason;
          this.isLoading = false;
        }
      }
    },
    getAgreementLink() {
      return getFile(userAgreement);
    },
  },
};
</script>
