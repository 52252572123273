import Api from "../../hype/api";

const state = {
  workflows: [],
};

const actions = {
  loadWorkflows: async ({ commit }) => {
    let result = await Api.getWorkflows();
    commit("setWorkflows", result);
    return result;
  },
};

const mutations = {
  setWorkflows: (state, workflows) => (state.workflows = workflows),
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
