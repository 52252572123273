import { capitalizeFirstLetter } from "./string";

export function getCommitFunctionByKey(key) {
  return function(state, payload) {
    state[key] = payload;
  };
}

export function getDefaultMutations(baseState) {
  let keys = Object.keys(baseState);
  let keyFuncObject = {};
  for (const key of keys) {
    let commitName = "set" + capitalizeFirstLetter(key);
    let commitFunction = getCommitFunctionByKey(key);
    keyFuncObject[commitName] = commitFunction;
  }
  return keyFuncObject;
}
