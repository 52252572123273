import Auth from "./auth";
import { hookURL } from "./config";

const io = require("socket.io-client");
const socket = io(hookURL);

import { SET_AGENT } from "./store/modules/agent";
import { SET_PLAN_STATUS } from "./store/modules/account";
import Vue from "./index";

socket.on("connect", () => {
  socket.emit("join", Auth.getToken(), (joined) => !joined);
});

socket.on("agents", (agent) => {
  Vue.$store.dispatch("agent/" + SET_AGENT, agent, { root: true });
});

socket.on("update_plan_status", (planStatus) => {
  Vue.$store.commit(`account/${SET_PLAN_STATUS}`, planStatus, { root: true });
});

socket.on("updateQueues", (queue) => {
  Vue.$store.commit(`queue/setQueues`, queue.queues, { root: true });
  Vue.$store.commit(`queue/setQueueItems`, queue.queueItems, { root: true });
});

export default socket;
