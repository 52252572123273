var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"padding":"50px"}},[_c('v-card',{attrs:{"flat":"","elevation":2}},[_c('v-card-text',[_c('v-data-table',{attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.workflows,"sort-by":['updatedAt'],"sort-desc":[true],"search":_vm.search,"items-per-page":7},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"d-flex flex-row"},[_c('span',{staticClass:"title"},[_vm._v("Workflows")]),_c('v-spacer'),_c('v-text-field',{staticClass:"flex-grow-0",attrs:{"append-icon":"mdi-magnify","label":"Search","height":"35px","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),(_vm.hasAuth('New Workflow'))?_c('v-btn',{staticClass:"ml-5",attrs:{"elevation":"0","color":"primary","to":"/workflow"}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" New Workflow ")],1):_vm._e()],1)]},proxy:true},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(item.DataEdit)?_c('v-btn',_vm._g({attrs:{"depressed":""},on:{"click":function($event){return _vm.openWorkflowSettings(item.id)}}},on),[_c('v-icon',{attrs:{"size":"18"}},[_vm._v("mdi-settings")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Workflow Settings")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(item.DataEdit)?_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"depressed":"","to":("workflows/" + (item.id))}},on),[_c('v-icon',{attrs:{"size":"18"}},[_vm._v("mdi-pencil")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Edit Workflow")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(item.DataDelete)?_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"depressed":""},on:{"click":function($event){return _vm.deleteWorkflow(item.id)}}},on),[_c('v-icon',{attrs:{"size":"18"}},[_vm._v("delete")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Delete Workflow")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(item.DataStatus)?_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"depressed":""}},on),[_c('v-switch',{staticClass:"my-1",attrs:{"loading":_vm.activeLoading[item.id] ? "warning" : null,"hide-details":""},on:{"change":function (value) { return _vm.setActiveStatus(item.id, value); }},model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}})],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Enable/Disable")])])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }