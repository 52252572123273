<template>
  <div>
    <v-skeleton-loader v-if="isLoading" ref="skeleton" type="table" class="mx-auto"></v-skeleton-loader>
    <v-data-table
      :headers="headers"
      :items="queueItems"
      class="shadow-xl mx-5 my-5"
      :footer-props="{
        itemsPerPageOptions: [10, 50, 100, -1],
      }"
      v-else
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <div class="d-none d-sm-flex">
            <v-toolbar-title>Queue Items</v-toolbar-title>
          </div>
          <v-spacer></v-spacer>
          <add-queue-item-dialog v-show="id" :id="id" />
          <queue-item-detail-dialog v-show="id" />
          <delete-queue-item-dialog v-show="id" />
        </v-toolbar>
      </template>
      <template v-slot:item.action="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn depressed class="mx-1" @click="() => queueItemDetail(item)" v-on="on">
              <v-icon size="18">visibility</v-icon>
            </v-btn>
          </template>
          <span>View Queue Item</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn depressed @click="deleteQueueItem(item.Id)" v-on="on">
              <v-icon size="18">delete</v-icon>
            </v-btn>
          </template>
          <span>Delete Queue Item</span>
        </v-tooltip>
      </template>
      <template v-slot:no-data></template>
    </v-data-table>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { FETCH_QUEUE_ITEMS, TOGGLE_VIEW_QUEUE_ITEM_DIALOG, TOGGLE_DELETE_QUEUE_ITEM_DIALOG } from "../../store/modules/queue";
import { timeAwareFormat } from "../../time";
import AddQueueItemDialog from "./AddQueueItemDialog";
import QueueItemDetailDialog from "./QueueItemDetailDialog";
import DeleteQueueItemDialog from "./DeleteQueueItemDialog";

export default {
  data: () => ({
    headers: [
      {
        text: "Reference",
        align: "left",
        value: "Reference",
      },
      {
        text: "Status",
        value: "Status",
      },
      {
        text: "Priority",
        value: "Priority",
      },
      {
        text: "Agent",
        value: "Agent",
      },
      {
        text: "Created",
        value: "createdAt",
      },
      {
        text: "Started",
        value: "StartedAt",
      },
      {
        text: "Ended",
        value: "FinishedAt",
      },
      {
        text: "Error",
        value: "Error",
      },
      {
        text: "Actions",
        value: "action",
      },
    ],
    id: null,
  }),
  components: {
    "add-queue-item-dialog": AddQueueItemDialog,
    "queue-item-detail-dialog": QueueItemDetailDialog,
    "delete-queue-item-dialog": DeleteQueueItemDialog,
  },
  computed: {
    ...mapState("queue", {
      queueItems: (state) =>
        state.queueItems.map((i) => ({
          ...i,
          Agent: i.Job !== null && i.Job.Agent !== null ? i.Job.Agent.Name : "",
          createdAt: timeAwareFormat(i.createdAt),
          StartedAt: i.StartedAt ? timeAwareFormat(i.StartedAt) : i.StartedAt,
          FinishedAt: i.FinishedAt ? timeAwareFormat(i.FinishedAt) : i.FinishedAt,
        })),
      isLoading: (state) => state.queueItemIsLoading,
    }),
  },
  created() {
    let id = this.$route.params.id;
    if (id) {
      this.id = id;
      this.fetchQueueItems(id);
    }
  },
  methods: {
    ...mapActions("queue", {
      fetchQueueItems: FETCH_QUEUE_ITEMS,
      toggleViewDialog: TOGGLE_VIEW_QUEUE_ITEM_DIALOG,
      toggleDeleteDialog: TOGGLE_DELETE_QUEUE_ITEM_DIALOG,
    }),
    queueItemDetail(item) {
      this.toggleViewDialog(item);
    },
    deleteQueueItem(id) {
      this.toggleDeleteDialog(id);
    },
  },
};
</script>
