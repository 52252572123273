<template>
  <v-row justify="center">
    <v-col cols="12" md="6">
      <v-card class="shadow-xl pa-5" :loading="isLoading">
        <v-card-text>
          <v-toolbar-title>License</v-toolbar-title>
          <v-divider class="my-5"></v-divider>
          <v-alert v-show="!!error" text prominent type="error" icon="mdi-alert-circle">{{ error }}</v-alert>
          <v-alert v-show="!!success" text prominent type="success" icon="mdi-cloud-check">License key has been updated</v-alert>
          <v-form v-on:submit.prevent="save">
            <v-text-field label="License Key" v-model="key" type="text" required outlined />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" @click="save" :disabled="isLoading" depressed block>Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import Api from "../../api";

export default {
  data: () => ({
    key: "",
    error: "",
    success: false,
    isLoading: false,
  }),
  methods: {
    async save() {
      this.success = false;
      this.error = "";

      try {
        this.isLoading = true;
        await Api.saveLicenseKey(this.key);
        this.success = true;
      } catch (error) {
        this.error = error.response.data.reason;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
