<template>
  <v-hover v-slot="{ hover }">
    <div
      tabindex="-1"
      @keydown.meta.67="copy"
      @keydown.ctrl.67="copy"
      ref="card"
      @mousedown="onMouseDown"
    >
      <v-card
        :class="`mx-auto node-card ${isExecuting ? 'running-background' : ''}`"
        :color="isExecuting ? '' : disabled ? 'grey lighten-1' : 'primary'"
        style="overflow: hidden"
      >
        <v-card-text style="padding: 0" class="d-flex flex-row align-center">
          <node-avatar :loading="isExecuting" :node="_node" />
          <div class="white--text pl-4">{{ name }}</div>
        </v-card-text>

        <v-slide-y-transition>
          <v-overlay
            :opacity="1"
            v-if="hover"
            absolute
            :color="disabled ? 'grey lighten-1' : 'primary'"
          >
            <v-btn
              v-if="_node.name != 'n8n-nodes-base.start'"
              @click="onNodeEdit"
              class="mr-3"
              outlined
              color="white"
              icon
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              class="mr-3"
              @click="onNodeEnabled"
              outlined
              color="white"
              icon
              v-if="disabled"
            >
              <v-icon>mdi-play</v-icon>
            </v-btn>
            <v-btn
              class="mr-3"
              v-else
              @click="onNodeDisabled"
              outlined
              color="white"
              icon
            >
              <v-icon>mdi-pause</v-icon>
            </v-btn>
            <v-btn class="mr-3" @click="onRunNode" outlined color="white" icon>
              <v-icon>mdi-play-circle</v-icon>
            </v-btn>
            <v-btn
              v-if="_node.name != 'n8n-nodes-base.start'"
              @click="onDeleteNode"
              outlined
              color="white"
              icon
            >
              <v-icon>mdi-trash-can</v-icon>
            </v-btn>
          </v-overlay>
        </v-slide-y-transition>
      </v-card>
      <v-fade-transition>
        <v-avatar
          size="30"
          v-show="executions.length && !hover"
          :class="`execution ${error ? `error` : `primary lighten-2`} `"
        >
          <v-icon color="white" v-if="error">mdi-alert-circle-outline </v-icon>
          <span v-else class="white--text">{{ executions.length }}</span>
        </v-avatar>
      </v-fade-transition>
    </div>
  </v-hover>
</template>

<script>
import { TreeView } from "vue-json-tree-view";
import NodeAvatar from "./editor/NodeAvatar";
import EventBusMixin from "../mixins/eventBus";
import copy from "copy-to-clipboard";
export default {
  // eslint-disable-next-line vue/no-unused-components
  components: { NodeAvatar, TreeView },
  data: () => ({
    showTooltip: false,
    lastX: 0,
    lastY: 0,
  }),
  mixins: [EventBusMixin],
  props: [
    "node",
    "data",
    "isExecuting",
    "name",
    "executionResult",
    "executions",
    "drawflowId",
    "disabled",
    "settings",
    "credentials",
    "position",
  ],
  computed: {
    _node() {
      return (
        this.node ?? {
          name: "",
          icon: "",
          description: "",
          dynamicDescription: "",
        }
      );
    },
    _executionData() {
      if (this.executions.length === 0) {
        return null;
      }

      let lastExecution = this.executions[this.executions.length - 1];
      let data = lastExecution?.data?.data.main;
      //find the output with data in it
      for (const output of data) {
        if (output[0] && output[0].json) {
          return output[0].json;
        }
      }
      return null;
    },
    error() {
      let lastExecution = this.executions[this.executions.length - 1];
      return lastExecution?.data?.error;
    },
    displayData() {
      return this._executionData ?? this.data;
    },
    displayDataRootName() {
      return this._executionData ? "result" : "parameters";
    },
    isLargeData() {
      let stringifiedData = JSON.stringify(this.displayData, null, 2);
      let lineCount = stringifiedData.split(`\n`).length;
      return lineCount > 15;
    },
  },
  methods: {
    parse(description) {
      // eslint-disable-next-line no-unused-vars
      let data = this.data;
      const code = "`" + description + "`";
      // console.log(code)
      return eval(code);
    },
    onNodeEdit() {
      if (this.checkDrag()) return;
      this.$pushEvent("onNodeEdit", { nodeId: this.drawflowId });
    },
    onNodeDisabled() {
      if (this.checkDrag()) return;
      this.$pushEvent("onNodeDisabled", { nodeId: this.drawflowId });
    },
    onNodeEnabled() {
      if (this.checkDrag()) return;
      this.$pushEvent("onNodeEnabled", { nodeId: this.drawflowId });
    },
    onRunNode() {
      if (this.checkDrag()) return;
      this.$pushEvent("onNodeRunned", { nodeName: this.name });
    },
    onDeleteNode() {
      if (this.checkDrag()) return;
      this.$pushEvent("onNodeDeleted", { nodeId: this.drawflowId });
    },
    checkDrag() {
      let card = this.$refs.card.getBoundingClientRect();
      return this.lastX != card.x || this.lastY != card.y;
    },
    onMouseDown() {
      let card = this.$refs.card.getBoundingClientRect();
      this.lastX = card.x;
      this.lastY = card.y;
    },
    copy() {
      if (this.node.name != "n8n-nodes-base.start") {
        copy(
          "node-data:" +
            JSON.stringify({
              node: this.node,
              data: this.data,
              name: this.name,
              settings: this.settings,
              credentials: this.credentials,
              position: this.position,
            })
        );
      }
    },
  },
  mounted() {},
};
</script>
<style scoped>
.execution {
  position: absolute;
  top: -10px;
  right: -10px;
}
.node-card {
  padding: 10px;
}
.overlay {
  top: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  background: black;
  background: linear-gradient(
    0deg,
    rgba(255, 204, 0, 1) 0%,
    rgba(255, 204, 0, 1) 16%,
    rgba(245, 224, 140, 0.6418942577030813) 36%,
    rgba(255, 255, 255, 0) 54%
  );
}

.running-background {
  background: linear-gradient(91deg, #0a66c2, #00fce7);
  background-size: 400% 400%;

  -webkit-animation: RunningBackground 1s ease infinite;
  -moz-animation: RunningBackground 1s ease infinite;
  animation: RunningBackground 1s ease infinite;
}

@-webkit-keyframes RunningBackground {
  0% {
    background-position: 0% 5%;
  }
  50% {
    background-position: 100% 96%;
  }
  100% {
    background-position: 0% 5%;
  }
}
@-moz-keyframes RunningBackground {
  0% {
    background-position: 0% 5%;
  }
  50% {
    background-position: 100% 96%;
  }
  100% {
    background-position: 0% 5%;
  }
}
@keyframes RunningBackground {
  0% {
    background-position: 0% 5%;
  }
  50% {
    background-position: 100% 96%;
  }
  100% {
    background-position: 0% 5%;
  }
}
</style>