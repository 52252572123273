<template>
  <v-app>
    <div id="click-block"></div>
    <router-view></router-view>
    <form-dialog />
    <alert-dialog />
    <confirm-dialog />
  </v-app>
</template>

<script>
import ConfirmDialog from "./hype/components/dialog/ConfirmDialog";
import FormDialog from "./hype/components/dialog/FormDialog";
import AlertDialog from "./hype/components/dialog/AlertDialog";

export default {
  name: "App",
  components: { FormDialog, AlertDialog, ConfirmDialog },
};
</script>

<style scoped>
  #click-block {
    background-color: transparent;
    z-index: 10;
    min-height: 100vh;
    min-width: 100%;
    display: none;
    position: absolute;
    top: 0;
    left: 0;
  }
</style>