import Auth from '../auth';

export function checkRoute({ to, next, store }) {
    if (!Auth.getToken()) {
        return next()
    }

    let pages = store.state.user.pages

    if (pages.length === 0) {
        store.dispatch('user/GET_PAGES').then(response => {
            let index = response.findIndex(page => {
                return page.Name === to.name
            })
            return index !== -1 ? next() : next('/404')
        })
    }
    else {
        let index = pages.findIndex(page => {
            return page.Name === to.name
        })
        return index !== -1 ? next() : next('/404')
    }
}