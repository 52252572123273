import Api from '../../hype/api'
import { extend } from '../../hype/helpers/propertyExtender'
import { getDefaultMutations } from '../../hype/helpers/vuex'

const baseState = {
  credentialTypesMap: {},
  credentials: [],
  credentialsMap: {},
}

export default {
  state: () => baseState,
  getters: {
    getCredential: state => name => {
      return state.credentialsMap[name]
    },
    getCredentialType: state => name => {
      return state.credentialTypesMap[name]
    },
    getCredentialsByCredentialType: state => type => {
      return state.credentials.filter(credential => credential.type == type)
    },
  },
  mutations: {
    ...getDefaultMutations(baseState)
  },
  actions: {
    async loadCredentialTypes({ state }) {
      let credentialTypes = await Api.getCredentialsTypes()
      for (const credentialType of credentialTypes) {
        state.credentialTypesMap[credentialType.name] = credentialType
      }

      for (const credentialType of Object.values(state.credentialTypesMap)) {
        extend(credentialType, state.credentialTypesMap)
      }

      console.log(state.credentialTypesMap['googleCalendarOAuth2Api'])
    },
    async loadCredentials({ state ,commit}) {
      let credentials = await Api.getCredentials()
      commit('setCredentials', credentials)
      for (const credential of credentials) {
        state.credentialsMap[credential.name] = credential
      }
    },
  },
  namespaced: true,
}
