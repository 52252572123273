<template>
  <div>
    <v-card class="shadow-xl mx-5 my-5 pa-2">
      <v-card-title>Account</v-card-title>
      <v-card-subtitle>You can view account details.</v-card-subtitle>
      <v-alert
        v-if="paymentResult === 'false'"
        text
        prominent
        type="error"
        icon="mdi-alert-circle"
        >Payment failed. Try again later</v-alert
      >
      <v-alert
        v-if="paymentResult === 'true'"
        text
        prominent
        type="success"
        icon="mdi-cloud-check"
        >Subscription Successfully Completed</v-alert
      >
      <v-divider></v-divider>
      <v-container>
        <v-alert
          v-if="pageLoaded && !activePlan"
          text
          prominent
          type="error"
          icon="mdi-alert-circle"
          >Active plan not found</v-alert
        >
        <v-row v-if="activePlan">
          <v-col
            cols="12"
            xs="12"
            v-bind:sm="getSubscriptionClass()"
            v-bind:md="getSubscriptionClass()"
            v-bind:lg="getSubscriptionClass()"
          >
            <template>
              <v-card class="mx-auto" outlined>
                <v-list-item three-line>
                  <v-list-item-content>
                    <v-list-item-subtitle
                      >Subscription level</v-list-item-subtitle
                    >
                    <v-list-item-title class="headline mb-1"
                      >Zozi {{ getPlanName() }}</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </template>
          </v-col>
          <v-col
            v-if="remainingTrialVisibility()"
            cols="12"
            xs="12"
            sm="6"
            md="6"
            lg="6"
          >
            <template>
              <v-card class="mx-auto" outlined>
                <v-list-item three-line>
                  <v-list-item-content>
                    <v-list-item-subtitle>Trial days left</v-list-item-subtitle>
                    <v-list-item-title class="headline mb-1">{{
                      getRemainingTrial()
                    }}</v-list-item-title>
                    <v-progress-linear
                      v-bind:value="getRemainingTrialProgress()"
                      height="5"
                      color="grey"
                    ></v-progress-linear>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </template>
          </v-col>
        </v-row>
        <v-row v-show="activePlan">
          <v-col cols="6" sm="6">
            <template>
              <v-card class="mx-auto" outlined>
                <v-list-item three-line>
                  <v-list-item-content>
                    <v-list-item-subtitle>Agent used</v-list-item-subtitle>
                    <v-list-item-title class="headline mb-1">{{
                      getRemainingAgent()
                    }}</v-list-item-title>
                    <v-progress-linear
                      v-bind:value="getRemainingAgentProgress()"
                      height="5"
                      color="grey"
                    ></v-progress-linear>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </template>
          </v-col>
          <v-col cols="6" sm="6">
            <template>
              <v-card class="mx-auto" outlined>
                <v-list-item three-line>
                  <v-list-item-content>
                    <v-list-item-subtitle>Job used</v-list-item-subtitle>
                    <v-list-item-title class="headline mb-1">{{
                      getRemainingJob()
                    }}</v-list-item-title>
                    <v-progress-linear
                      v-bind:value="getRemainingJobProgress()"
                      height="5"
                      color="grey"
                    ></v-progress-linear>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </template>
          </v-col>
        </v-row>
        <v-row v-show="hasCustomer">
          <v-col cols="12" sm="12">
            <template>
              <v-card class="mx-auto" outlined>
                <v-list-item three-line>
                  <v-list-item-content>
                    <v-list-item-subtitle>Payment Method</v-list-item-subtitle>
                    <v-list-item-title class="headline mb-1">
                      <v-btn
                        color="primary"
                        v-on:click="redirectToPaymentMethod"
                        depressed
                        class="mr-1"
                        >Update</v-btn
                      >
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </template>
          </v-col>
        </v-row>
        <v-row class="text-center">
          <v-col class="my-5">
            <h1 class="display-1">Find the plan that's right for you</h1>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-center">
            <v-btn-toggle mandatory>
              <v-btn
                value="left"
                class="text-capitalize"
                @click="changePeriod('Monthly')"
                >Monthly</v-btn
              >
              <v-btn
                value="right"
                class="text-capitalize"
                @click="changePeriod('Annual')"
                >Annual (Save %25)</v-btn
              >
            </v-btn-toggle>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            xs="12"
            sm="4"
            md="4"
            lg="4"
            class="text-center"
            v-for="(plan, index) in plans"
            :key="index"
          >
            <template>
              <v-card
                v-bind:class="getClassName(plan)"
                :elevation="getElevation(plan)"
              >
                <div class="price">
                  <div
                    v-show="activePlan && plan.Name === activePlan.Name"
                    class="current"
                  >
                    <span>CURRENT</span>
                  </div>
                  <h2 class="red--text headline">{{ plan.Name }}</h2>
                  <h4
                    v-show="plan.Recommended"
                    class="red lighten-3 white--text"
                  >
                    Recommended
                  </h4>
                  <h1>{{ formatPrice(plan) }}</h1>
                  <p>
                    {{
                      paymentPeriod === "Monthly"
                        ? "Per seat/month"
                        : "Per seat/month/billed annually"
                    }}
                  </p>
                  <p>{{ paymentPeriod }} Package</p>
                  <v-btn
                    depressed
                    color="primary"
                    @click="sendPlanRequest(plan.Name, paymentPeriod)"
                    >Request</v-btn
                  >
                  <!-- silme -->
                  <!-- @click="buy(paymentPeriod === 'Monthly' ? plan.MonthlyPlanId : plan.YearlyPlanId)" -->
                  <ul>
                    <li>
                      <v-icon color="green" small>done</v-icon>
                      {{
                        plan.AgentCount === 1
                          ? `Only ${plan.AgentCount} agent`
                          : `Up to ${plan.AgentCount} Agent`
                      }}
                    </li>
                    <li>
                      <v-icon color="green" small>done</v-icon>
                      {{
                        plan.TaskCount
                          ? `Up to ${plan.TaskCount} Task`
                          : "Unlimited Task"
                      }}
                    </li>
                    <li>
                      <v-icon color="green" small>done</v-icon>
                      {{
                        plan.ScriptCount
                          ? `Up to ${plan.ScriptCount} Script`
                          : "Unlimited Script"
                      }}
                    </li>
                    <li>
                      <v-icon color="green" small>done</v-icon>
                      {{ `Up to ${plan.JobCount} Jobs/month` }}
                    </li>
                    <li>
                      <v-icon color="green" small>done</v-icon>
                      {{
                        plan.SLA
                          ? `%${plan.SLA} uptime SLA`
                          : "No SLA or guarantees"
                      }}
                    </li>
                    <li>
                      <v-icon color="green" small>done</v-icon>
                      Notification By {{ getNotifications(plan) }}
                    </li>
                    <li>
                      <v-icon color="green" small>done</v-icon>
                      {{ plan.Support }}
                    </li>
                    <li>
                      <v-icon color="green" small>done</v-icon>
                      {{ plan.LogType }} Cloud Logs
                    </li>
                    <li>
                      <v-icon color="green" small>done</v-icon>
                      {{ `File size per script ${plan.ScriptSize} MB` }}
                    </li>
                    <li>
                      <v-icon color="green" small>done</v-icon>
                      {{ `Logs storage size ${plan.LogSize} MB` }}
                    </li>
                    <li>
                      <v-icon color="green" small>done</v-icon>
                      {{ `Max Logs count ${plan.LogCount}` }}
                    </li>
                    <li v-if="plan.UserManagement">
                      <v-icon color="green" small>done</v-icon>
                      {{ "User Management" }}
                    </li>
                    <li>&nbsp;</li>
                  </ul>
                </div>
              </v-card>
            </template>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card :elevation="10">
              <v-alert>
                <v-row align="center">
                  <v-col class="grow">
                    <h2 class="red--text headline">
                      Zozi Enterprise (On Premise)
                    </h2>
                    <p class="subtitle-1">
                      Create efficiency and increase security across your
                      organization.
                    </p>
                  </v-col>
                  <v-col class="shrink">
                    <v-btn
                      @click="sendPlanRequest('Enterprise', null)"
                      depressed
                      color="primary"
                      >Get a quote</v-btn
                    >
                  </v-col>
                </v-row>
              </v-alert>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <ConfirmDialog />
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import {
  FETCH_ACCOUNT,
  SET_PAYMENT_SECTION,
  SET_SELECTED_PLAN_ID,
} from "../store/modules/account";
import { HAS_CUSTOMER } from "../store/modules/user";
import { formatMoney } from "../money";
import ConfirmDialog from "../views/ConfirmDialog";
import { OPEN_DIALOG } from "../store/modules/confirm";
import Api from "../api";
import { stripePublishableKey } from "../config";

export default {
  data: () => ({
    paymentPeriod: "Monthly",
    stripe: null,
    paymentResult: null,
  }),
  components: {
    ConfirmDialog,
  },
  computed: {
    ...mapState("account", {
      activePlan: (state) => state.account.activePlan,
      agentCount: (state) => state.account.agentCount,
      jobCount: (state) => state.account.jobCount,
      plans: (state) => state.account.plans,
      remainingTrialDay: (state) => state.planStatus.remainingTrialDay,
      paymentSection: (state) => state.payment.paymentSection,
      pageLoaded: (state) => state.pageLoaded,
    }),
    ...mapState("user", {
      user: (state) => state.user,
      hasCustomer: (state) => state.hasCustomer,
    }),
  },
  mounted() {
    this.paymentResult = this.$route.query.ps;
    this.stripe = Stripe(stripePublishableKey); // eslint-disable-line
  },
  async created() {
    this.fetchAccount();
    this.hasCustomerAction();
  },
  methods: {
    ...mapActions("account", {
      fetchAccount: FETCH_ACCOUNT,
    }),
    ...mapActions("user", {
      hasCustomerAction: HAS_CUSTOMER,
    }),
    ...mapMutations("account", {
      setPaymentSection: SET_PAYMENT_SECTION,
      setSelectedPlanId: SET_SELECTED_PLAN_ID,
    }),
    ...mapActions("confirm", {
      openConfirmDialog: OPEN_DIALOG,
    }),
    async sendPlanRequest(planName, paymentCycle) {
      await Api.sendEnterpriseRequest(planName, paymentCycle);
      this.openConfirmDialog({
        message: `${planName} request has been received successfully. You will be reached via email`,
        title: "Plan Request",
        okButtonTitle: "OK",
        showCancelButton: false,
      });
    },
    async sendEnterpriseRequest() {
      Api.sendEnterpriseRequest();
      this.openConfirmDialog({
        message:
          "Enterprise request has been received successfully. You will be reached via email by enterprise@zozi.io",
        title: "Enterprise Request",
        okButtonTitle: "OK",
        showCancelButton: false,
      });
    },
    getPlanName() {
      if (!this.activePlan) {
        return "";
      }

      return this.activePlan.TrialDay
        ? `${this.activePlan.Name} (Trial)`
        : this.activePlan.Name;
    },
    getSubscriptionClass() {
      return this.activePlan && !this.activePlan.TrialDay ? 12 : 6;
    },
    remainingTrialVisibility() {
      return this.activePlan && this.activePlan.TrialDay;
    },
    getRemainingTrial() {
      if (!this.activePlan) {
        return "";
      }

      return this.activePlan.TrialDay
        ? `${this.remainingTrialDay}/${this.activePlan.TrialDay}`
        : "-";
    },
    getRemainingTrialProgress() {
      if (!this.activePlan) {
        return "";
      }

      return this.activePlan.TrialDay
        ? (this.remainingTrialDay / this.activePlan.TrialDay) * 100
        : 100;
    },
    getRemainingAgent() {
      if (!this.activePlan) {
        return "";
      }

      return `${this.agentCount}/${this.activePlan.AgentCount}`;
    },
    getRemainingAgentProgress() {
      if (!this.activePlan) {
        return "";
      }

      return (this.agentCount / this.activePlan.AgentCount) * 100;
    },
    getRemainingJob() {
      if (!this.activePlan) {
        return "";
      }

      return `${this.jobCount}/${this.activePlan.JobCount}`;
    },
    getRemainingJobProgress() {
      if (!this.activePlan) {
        return "";
      }

      return (this.jobCount / this.activePlan.JobCount) * 100;
    },
    getNotifications(plan) {
      let notificationArray = [];
      if (plan.EmailNotification) {
        notificationArray.push("Email");
      }
      if (plan.SlackNotification) {
        notificationArray.push("Slack");
      }
      if (plan.MSTeamsNotification) {
        notificationArray.push("Microsoft Teams");
      }
      if (plan.GoogleChatNotification) {
        notificationArray.push("Google Chat");
      }
      if (plan.SmsNotification) {
        notificationArray.push("SMS");
      }

      return notificationArray.join(",");
    },
    changePeriod(period) {
      this.paymentPeriod = period;
    },
    getClassName(plan) {
      return `mx-auto ${plan.Recommended ? "py-12" : "py-8 my-10"}`;
    },
    getElevation(plan) {
      return plan.Recommended ? 10 : 2;
    },
    formatPrice(plan) {
      return formatMoney(
        this.paymentPeriod === "Monthly"
          ? plan.MonthlyPrice
          : plan.YearlyPrice / 12,
        plan.Currency
      );
    },
    buy(selectedPlanId) {
      Api.checkout({
        planId: selectedPlanId,
      }).then((response) => {
        this.redirectToCheckout(response);
      });
    },
    redirectToCheckout(response) {
      this.stripe
        .redirectToCheckout({
          sessionId: response.id,
        })
        .then(function(result) {
          console.log(result);
        });
    },
    redirectToPaymentMethod() {
      this.$router.push("/account/payment-method");
    },
  },
};
</script>

<style>
.shadow-xl {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

.price ul {
  margin: 0;
  padding: 10px 0 20px 0;
  padding-left: 0 !important;
}

.price ul li {
  list-style: none;
  font-size: 14px;
  padding: 4px;
}
.current {
  position: absolute;
  top: 8px;
  right: 0;
  left: 0;
}
.current span {
  background-color: #dedede;
  padding: 4px 8px 4px 8px;
  border-radius: 4px;
  font-size: 12px;
}
</style>
