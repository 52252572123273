<template>
  <v-dialog v-model="dialogNewAgent" max-width="500px" persistent>
    <template v-slot:activator="{ on }">
      <v-btn color="primary" v-on="on" depressed class="mr-1"> <v-icon left>add</v-icon>New Agent </v-btn>
    </template>
    <v-card>
      <v-form ref="form" lazy-validation="true">
        <v-card-title>
          <span class="headline">Add New Agent</span>
        </v-card-title>
        <v-card-text>
          <v-container v-if="!isSaving">
            <v-alert v-show="!!errorMessage" text prominent type="error" icon="mdi-alert-circle">{{ errorMessage }}</v-alert>
            <v-alert v-show="!!isSuccess" text prominent type="success" icon="mdi-cloud-check">Succesfully Created</v-alert>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="createdItem.name"
                  label="Agent Name"
                  required
                  maxlength="50"
                  counter
                  :rules="nameRules"
                  :disabled="isSuccess || disableButton"
                ></v-text-field>
                <v-text-field
                  v-model="createdItem.hostKey"
                  label="Host Key"
                  required
                  maxlength="50"
                  counter
                  :rules="hostKeyRules"
                  :disabled="isSuccess || disableButton"
                ></v-text-field>
                <v-text-field v-model="createdItem.username" label="Username" maxlength="100" counter :disabled="isSuccess || disableButton"></v-text-field>
                <v-text-field
                  v-model="createdItem.password"
                  label="Password"
                  maxlength="50"
                  type="password"
                  counter
                  :disabled="isSuccess || disableButton"
                ></v-text-field>
                <v-row>
                  <v-col cols="10" sm="10" md="10" xs="10">
                    <v-text-field v-model="createdItem.agentId" label="Agent Id" :loading="isLoading" disabled></v-text-field>
                  </v-col>
                  <v-col cols="2" sm="2" md="2" xs="2">
                    <v-btn depressed @click.stop.prevent="copyAgentID" class="my-4">
                      <v-icon size="18" color="black">file_copy</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>

                <input type="hidden" id="agent-id" :value="createdItem.agentId" />
              </v-col>
            </v-row>
          </v-container>
          <v-container class="text-center" v-else>
            <v-progress-circular :size="70" :width="7" color="purple" indeterminate></v-progress-circular>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn v-show="!isSuccess && !isSaving && !disableButton" depressed color="primary" @click="save">Save</v-btn>
          <v-btn color="grey darken-1" text @click="close">{{ isSuccess ? "Close" : "Cancel" }}</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import Api from "../api";
import { mapActions } from "vuex";
import { FILTER_AGENTS, DELETE_AGENT } from "../store/modules/agent";
export default {
  data: () => ({
    isSuccess: false,
    isSaving: false,
    isLoading: true,
    dialogNewAgent: false,
    createdItem: {
      name: "",
      hostKey: "",
      username: "",
      password: "",
      agentId: "",
    },
    nameRules: [(v) => !!v || "Name is required"],
    hostKeyRules: [(v) => !!v || "Key is required"],
    errorMessage: "",
    disableButton: false,
  }),
  watch: {
    dialogNewAgent(value) {
      if (value) {
        this.onOpened();
      } else {
        this.onClosed();
      }
    },
  },
  methods: {
    copyAgentID() {
      let agentIDtoCopy = document.querySelector("#agent-id");
      agentIDtoCopy.setAttribute("type", "text");
      agentIDtoCopy.select();
      try {
        document.execCommand("copy");
      } catch (err) {
        console.error(err);
      }
      agentIDtoCopy.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
    },
    async onOpened() {
      this.isLoading = true;
      try {
        let { Id } = await Api.createAgent();
        this.createdItem.agentId = Id;
        this.disableButton = false;
        this.errorMessage = "";
      } catch (error) {
        this.errorMessage = error.response.data.reason;
        this.disableButton = true;
      } finally {
        this.isLoading = false;
      }
    },
    async save() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.errorMessage = "";
      this.isSaving = true;
      try {
        await Api.registerAgent({
          Id: this.createdItem.agentId,
          Name: this.createdItem.name,
          HostKey: this.createdItem.hostKey,
          Username: this.createdItem.username,
          Password: this.createdItem.password,
        });
        this.isSuccess = true;
        this.fetchAgents({ options: { itemsPerPage: 10, page: 0 }, isRefreshing: false });
      } catch (e) {
        this.errorMessage = e.response.data.reason;
      }
      this.isSaving = false;
    },
    close() {
      this.dialogNewAgent = false;
    },
    async onClosed() {
      if (!this.isSuccess) {
        await Api.deleteAgent(this.createdItem.agentId);
      }
      this.clear();
    },
    clear() {
      this.createdItem.name = "";
      this.createdItem.hostKey = "";
      this.createdItem.agentId = "";
      this.isSuccess = false;
      this.isSaving = false;
    },
    ...mapActions("agent", {
      fetchAgents: FILTER_AGENTS,
      deleteAgent: DELETE_AGENT,
    }),
  },
};
</script>
