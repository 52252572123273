import Api from "../../api";

export const FETCH_TIME_ZONES = "FETCH_TIME_ZONES";
export const FETCH_TIME_ZONE = "FETCH_TIME_ZONE";
export const SAVE_TIME_ZONE = "SAVE_TIME_ZONE";

const actions = {
  [FETCH_TIME_ZONES]: async ({ commit }) => {
    commit("setIsLoading", true);

    try {
      let timeZones = await Api.getTimeZones();
      commit("setTimeZones", timeZones);
    } catch (err) {
      commit("setError", err.response.data.reason);
    }

    commit("setIsLoading", false);
  },
  [FETCH_TIME_ZONE]: async ({ commit }) => {
    commit("setIsLoading", true);

    try {
      let timeZone = await Api.getTimeZone();
      commit("setTimeZone", timeZone);
    } catch (err) {
      commit("setError", err.response.data.reason);
    }

    commit("setIsLoading", false);
  },
  [SAVE_TIME_ZONE]: async ({ commit }, timeZone) => {
    commit("setIsLoading", true);

    try {
      await Api.saveTimeZone(timeZone);
      commit("setSuccess", true);
    } catch (err) {
      commit("setError", err.response.data.reason);
    }

    commit("setIsLoading", false);
  },
};

const state = {
  timeZones: [],
  timeZone: "",
  isLoading: false,
  error: "",
  success: false,
};

const mutations = {
  setIsLoading: (state, isLoading) => (state.isLoading = isLoading),
  setTimeZone: (state, timeZone) => (state.timeZone = timeZone),
  setTimeZones: (state, timeZones) => (state.timeZones = timeZones),
  setError: (state, error) => (state.error = error),
  setSuccess: (state, success) => (state.success = success),
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
