class EventBus {
  constructor() {
    this.lastId = 0
    this.eventMap = new Map()
  }

  getId() {
    return ++this.lastId
  }

  subscribe(event, handler) {
    let listeners = this.eventMap.get(event) ?? []
    let id = this.getId()
    listeners.push({ id, handler })
    this.eventMap.set(event, listeners)
    return id
  }

  push(event, data) {
    let listeners = this.eventMap.get(event)
    if (!listeners) return
    listeners.forEach(item => item.handler(data))
  }

  unsubscribe(event, handlerId) {
    let listeners = this.eventMap.get(event)
    if (!listeners) return
    listeners.splice(
      listeners.findIndex(item => item.id == handlerId),
      1
    )
  }
}

export default new EventBus()
