import Api from "../../api";
export const UPDATE_SMTP = "UPDATE_SMTP";
export const FETCH_SMTP = "FETCH_SMTP";

const actions = {
  [UPDATE_SMTP]: async ({ commit }, smtpInformation) => {
    commit("setUpdateLoading", true);
    commit("setUpdateSmtpError", "");
    try {
      let smtp = await Api.updateSmtp(smtpInformation);
      commit("setUpdateSuccess", true);
      commit("setSmtp", smtp?.Value);
    } catch (err) {
      commit("setUpdateSmtpError", err.response.data.reason);
    }
    commit("setUpdateLoading", false);
  },
  [FETCH_SMTP]: async ({ commit }) => {
    commit("setUpdateLoading", true);
    try {
      let smtp = await Api.getSmtp();
      commit("setSmtp", smtp?.Value);
    } catch (err) {
      commit("setUpdateSmtpError", err.response.data.reason);
    }
    commit("setUpdateLoading", false);
  },
};

const state = {
  smtp: {},
  isLoading: false,
  error: "",
  success: false,
};

const mutations = {
  setUpdateLoading: (state, isLoading) => (state.isLoading = isLoading),
  setSmtp: (state, smtp) => (state.smtp = smtp),
  setUpdateSmtpError: (state, error) => (state.error = error),
  setUpdateSuccess: (state, success) => (state.success = success),
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
