<template>
  <v-card class="fill-height" v-if="active" flat>
    <expression-data-selector
      @close="showSelectorStatus = false"
      @select="onSelect"
      v-show="showSelectorStatus"
    />
    <v-card-title
      >Expression Editor <v-spacer />
      <v-btn @click="$emit('full-screen')" icon>
        <v-icon>mdi-fullscreen</v-icon></v-btn
      >
      <v-btn @click="$emit('close')" icon>
        <v-icon>mdi-close</v-icon></v-btn
      >
      
      </v-card-title
    >
    <v-card-text
      v-if="codeEditor"
      style="height: calc(100% - 200px)"
      class="pa-0"
    >
      <codemirror v-model="internalValue" :options="cmOption" />
    </v-card-text>
    
    <v-card-text v-else>
      <v-textarea
        ref="textArea"
        @keydown.ctrl.space="showSelector"
        v-model="internalValue"
      />
      <v-textarea class="disable-area" disabled v-model="interpretedText" />
    </v-card-text>
    <v-card-actions class="d-flex justify-center">
      <v-btn text color="red" @click="clearExpression">clear expression</v-btn>
      <v-btn text @click="$emit('close')">close</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { codemirror } from "vue-codemirror";
// base style
import "codemirror/lib/codemirror.css";
// language
import "codemirror/mode/javascript/javascript.js";

// theme css
import "codemirror/theme/idea.css";

// require active-line.js
import "codemirror/addon/selection/active-line.js";

// styleSelectedText
import "codemirror/addon/selection/mark-selection.js";
import "codemirror/addon/search/searchcursor.js";

// hint
import "codemirror/addon/hint/show-hint.js";
import "codemirror/addon/hint/show-hint.css";
import "codemirror/addon/hint/javascript-hint.js";
import "codemirror/addon/selection/active-line.js";

// highlightSelectionMatches
import "codemirror/addon/scroll/annotatescrollbar.js";
import "codemirror/addon/search/matchesonscrollbar.js";
import "codemirror/addon/search/searchcursor.js";
import "codemirror/addon/search/match-highlighter.js";

// keyMap
import "codemirror/mode/clike/clike.js";
import "codemirror/addon/edit/matchbrackets.js";
import "codemirror/addon/comment/comment.js";
import "codemirror/addon/dialog/dialog.js";
import "codemirror/addon/dialog/dialog.css";
import "codemirror/addon/search/searchcursor.js";
import "codemirror/addon/search/search.js";
import "codemirror/keymap/sublime.js";

// foldGutter
import "codemirror/addon/fold/foldgutter.css";
import "codemirror/addon/fold/brace-fold.js";
import "codemirror/addon/fold/comment-fold.js";
import "codemirror/addon/fold/foldcode.js";
import "codemirror/addon/fold/foldgutter.js";
import "codemirror/addon/fold/indent-fold.js";
import "codemirror/addon/fold/markdown-fold.js";
import "codemirror/addon/fold/xml-fold.js";

import { mapState, mapMutations } from "vuex";
import ExpressionDataSelector from "./ExpressionDataSelector";
import formDialog from "../../mixins/formDialog";
import { evaluate } from "../../helpers/template";
export default {
  mixins: [formDialog],
  data: () => ({
    showSelectorStatus: false,
    cursorLocation: 0,
    internalText: "",
    cmOption: {
      tabSize: 4,
      styleActiveLine: false,
      lineNumbers: true,
      styleSelectedText: false,
      line: true,
      foldGutter: true,
      gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter"],
      highlightSelectionMatches: { showToken: /\w/, annotateScrollbar: true },
      mode: "text/javascript",
      // hint.js options
      hintOptions: {
        completeSingle: false,
      },

      keyMap: "sublime",
      matchBrackets: true,
      showCursorWhenSelecting: true,
      theme: "idea",
      extraKeys: { Ctrl: "autocomplete" },
    },
  }),
  components: {
    ExpressionDataSelector,
    codemirror,
  },
  props: ["active", "value", "currentExecutionData", "keyPath"],
  computed: {
    ...mapState("editor", {
      expressionData: (state) => state.expressionData,
      currentEditedData: (state) => state.currentEditedData,
      codeEditor: (state) => state.codeEditor,
    }),
    internalValue: {
      set(val) {
        let exp = val.startsWith("=") ? val : "=" + val;
        this.internalText = exp;
        this.updateCurrentEditedData(this.internalText);
      },
      get() {
        let val = this.internalText;
        if (!val) return "";
        let exp = val.startsWith("=") ? val.slice(1) : val;
        return exp;
      },
    },
    interpretedText() {
      let text = this.internalValue;
      return evaluate(text, this.expressionData);
    },
  },
  watch: {
    active(val) {
      if (!val) {
        this.showSelectorStatus = false;
      } else {
        this.internalText =
          typeof this.currentEditedData === "string"
            ? this.currentEditedData
            : "";
      }
    },
  },
  methods: {
    ...mapMutations("editor", ["updateCurrentEditedData"]),
    onSelect(path) {
      let exp = `{{${path}}}`;
      this.internalValue =
        this.internalValue.slice(0, this.cursorLocation) +
        exp +
        this.internalValue.slice(
          this.cursorLocation,
          this.internalValue.length
        );
      this.showSelectorStatus = false;
      this.$refs.textArea.focus();
      const position = this.cursorLocation + exp.length;
      let textArea = this.$refs.textArea.$el.querySelector("textarea");
      setTimeout(() => textArea.setSelectionRange(position, position), 100);
    },
    showSelector(val) {
      this.cursorLocation = val.srcElement.selectionStart;
      this.showSelectorStatus = true;
    },
    async clearExpression() {
      let result = await this.$confirm({
        title: "Remove Expression",
        text: "Are you sure you want to remove the expression ? ",
      });

      if (result) {
        this.updateCurrentEditedData("");
        setTimeout(() => this.$emit("close"), 100);
      }
    },
  },
};
</script>

<style>
.vue-codemirror {
  height: 100%;
}

.CodeMirror,
.pre {
  height: 100%;
  margin: 0;
  overflow: auto;
}

.pre {
  display: block;
  padding: 1rem;
  line-height: 1.6;
  word-break: break-all;
  word-wrap: break-word;
}

.disable-area {
  pointer-events: all!important;
}
</style>