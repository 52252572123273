<template>
  <div style="padding: 50px">
    <v-card flat :elevation="2">
      <v-card-text>
        <v-data-table :loading="loading" :headers="headers" :items="items" :options.sync="options" :server-items-length="totalExecutions" :page.sync="currentPage">
          <template v-slot:top>
            <div class="d-flex flex-row">
              <span class="title">Executions</span>
              <v-spacer />
              <v-select class="flex-grow-0 mr-5" label="Workflow" :items="workflows" v-model="filterWorkflow" />
              <v-select
                class="flex-grow-0"
                label="Status"
                :items="[
                  { text: 'all', value: null },
                  { text: 'success', value: true },
                  { text: 'failure', value: false },
                  { text: 'running', value: 'running' },
                ]"
                v-model="filterFinished"
              />

              <!-- TODO SELECTS HERE -->
            </div>
          </template>
          <template v-slot:item.runningTime="{ item }">
            {{ (new Date(item.stoppedAt) - new Date(item.startedAt)) / 1000 }}
            sec.
          </template>
          <template v-slot:item.finished="{ item }">
            <v-chip color="yellow" v-if="item.finished === null">
              Running
            </v-chip>
            <v-chip color="success" v-if="item.finished === true">Success</v-chip>
            <v-chip color="error" v-if="item.finished === false">Failed</v-chip>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn elevation="0" class="mr-2" :to="`executions/${item.id}`">
              <v-icon>visibility</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Api from "../api";
import { mapState, mapActions } from "vuex";
import { timeAwareFormat } from "../../time";

export default {
  computed: {
    ...mapState("workflows", {
      workflows: (state) => [{ text: "all", value: null }, ...state.workflows.map((i) => ({ text: i.name, value: i.id }))],
    }),
    filter() {
      let workflowId = this.filterWorkflow === null ? undefined : this.filterWorkflow;
      let finished = this.filterFinished === null ? undefined : this.filterFinished;
      if (this.filterFinished === "running") {
        return { workflowId };
      } else {
        return { workflowId, finished };
      }
    },
  },
  data: () => ({
    currentPage: 1,
    totalExecutions: 0,
    loading: false,
    headers: [
      { text: "Name", value: "workflowName", sortable: false },
      { text: "Mode", value: "mode", sortable: false },
      { text: "Running Time (Seconds)", value: "runningTime", sortable: false },
      { text: "Started At", value: "startedDate", sortable: false },
      { text: "Status", value: "finished", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    activeLoading: {},
    options: {},
    lastId: null,
    items: [],
    filterFinished: undefined,
    filterWorkflow: undefined,
  }),
  watch: {
    filterFinished() {
      this.loadData();
    },
    filterWorkflow() {
      this.loadData();
    },
    currentPage() {
      this.loadData();
    },
  },
  methods: {
    async loadData() {
      this.loading = true;
      const { itemsPerPage } = this.options;
      let fetchFunctionName = this.filterFinished === "running" ? "getCurrentExecutions" : "getExecutions";

      let result = await Api[fetchFunctionName]({
        limit: itemsPerPage,
        lastId: this.lastId,
        filter: this.filter,
        page: this.currentPage,
      });

      this.items = result.results?.map((item) => {
        return { ...item, startedDate: timeAwareFormat(item.startedAt), stoppedDate: timeAwareFormat(item.stoppedAt) };
      });
      this.totalExecutions = result.count;
      this.loading = false;
    },

    ...mapActions("workflows", ["loadWorkflows"]),
  },
  mounted() {
    this.loadWorkflows();
    this.loadData();
  },
};
</script>
