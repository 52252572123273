<template>
  <div>
    <v-row justify="space-around">
      <v-tooltip
        top
        v-for="(attribute, index) in actionAttributes"
        :key="index"
      >
        <template v-slot:activator="{ on }">
          <div v-on="attribute.description ? on : ''">
            <v-switch
              @change="toggle(attribute.id)"
              color="primary"
              v-model="attribute.value"
              hide-details
              :label="
                attribute.action === 'View'
                  ? `Page ${attribute.action}`
                  : attribute.action
              "
            ></v-switch>
          </div>
        </template>
        <span>{{ attribute.description }}</span>
      </v-tooltip>
    </v-row>
    <v-row justify="space-around" class="row-margin" v-if="dataControl()">
      <data-authorization :attributes="attributes"></data-authorization>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { TOGGLE_ACTION_AUTHORIZATION } from "../../store/modules/user";
import DataAuthorization from "./DataAuthorization";

export default {
  props: ["attributes"],
  data: () => ({
    actionAttributes: [],
  }),
  components: {
    "data-authorization": DataAuthorization,
  },
  created() {
    this.actionAttributes = this.attributes.reduce(function(
      filtered,
      attribute
    ) {
      if (!attribute.Action.startsWith("Data")) {
        filtered.push({
          id: attribute.Id,
          action: attribute.Action,
          value: attribute.UserAuthorizations.length > 0,
          description: attribute.Description,
        });
      }
      return filtered;
    },
    []);
  },
  methods: {
    ...mapActions("user", {
      toggleActionAuthorization: TOGGLE_ACTION_AUTHORIZATION,
    }),
    toggle(attributeId) {
      this.toggleActionAuthorization({
        userId: this.$route.params.id,
        attributeId,
      });
    },
    dataControl() {
      const hasData = this.attributes.findIndex((attribute) => {
        return attribute.Action === "Data";
      });

      return hasData !== -1;
    },
  },
};
</script>

<style scoped>
.row-margin {
  margin-top: 15px;
}
</style>
