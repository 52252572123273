import moment from "moment";

export function now() {
  return moment().format("MMMM Do YYYY, h:mm:ss a");
}

export function certain(time) {
  return moment(time).format("MMMM Do YYYY, h:mm:ss a");
}

export function relative(time) {
  return moment(time).fromNow();
}

export function timeAwareFormat(time) {
  if (moment(time).isBetween(moment().subtract(1, "hour"), moment().add(1, "hour"))) {
    return relative(time);
  } else {
    return certain(time);
  }
}

export function diffAndAverageDate(array) {
  if (array.length === 0) return 0;

  let sum = 0;
  array.forEach((item) => {
    let diffTime = Math.floor(new Date(item.date1).getTime() - new Date(item.date2).getTime());
    sum += Math.floor(diffTime / (1000 * 60));
  });

  return Math.floor(sum / array.length);
}
