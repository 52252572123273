<template>
  <v-main>
    <v-container fluid mt-5>
      <v-row align="center" justify="center" class="my-4">
        <v-img
          v-bind:src="this.$assetHelper.getImage('logo-black.png')"
          v-bind:lazy-src="this.$assetHelper.getImage('logo-black.png')"
          aspect-ratio="1"
          max-width="207"
          max-height="47"
          class="mr-2 float-left"
        ></v-img>
      </v-row>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="4" xs="8">
          <v-card class="shadow-xl">
            <v-card-text>
              <div class="ProfileAvatar">
                <span>
                  <div class="VIPBadge-Container">
                    <div
                      class="VIPBadge VIPBadge-Gold"
                      style="top:-167.12px;left:-167.12px;transform:scale(0.92);"
                    >
                      <svg
                        class="VIPBadge-Rot VIPBadge-Rot1"
                        width="472px"
                        height="472px"
                        viewBox="0 0 472 472"
                      >
                        <defs>
                          <path
                            id="path-1"
                            d="M0,0 L550,0 L550,549 L0,549 L0,0 Z M275.250246,221.799666 C246.254859,221.799666 222.75,245.301068 222.75,274.291698 C222.75,303.282328 246.254859,326.78373 275.250246,326.78373 C304.245141,326.78373 327.75,303.282328 327.75,274.291698 C327.75,245.301068 304.245141,221.799666 275.250246,221.799666 Z"
                          />
                        </defs>
                        <g
                          stroke="none"
                          stroke-width="1"
                          fill="none"
                          fill-rule="evenodd"
                        >
                          <g>
                            <g transform="translate(-39.000000, -38.000000)">
                              <g>
                                <g>
                                  <mask id="mask-2" fill="white">
                                    <use
                                      xmlns:xlink="http://www.w3.org/1999/xlink"
                                      xlink:href="#path-1"
                                    />
                                  </mask>
                                  <g mask="url(#mask-2)">
                                    <g
                                      transform="translate(185.166667, 173.223706)"
                                    >
                                      <g
                                        fill="rgb(255, 196, 31)"
                                        transform="translate(27.742567, 40.634053)"
                                      >
                                        <rect
                                          transform="translate(37.972282, 19.814955) rotate(-18.000000) translate(-37.972282, -19.814955) "
                                          x="16.4451034"
                                          y="6.31719599"
                                          width="43.0543569"
                                          height="26.9955171"
                                        />
                                        <rect
                                          transform="translate(23.659096, 82.156437) rotate(33.000000) translate(-23.659096, -82.156437) "
                                          x="7.8173047"
                                          y="63.4143637"
                                          width="31.6835825"
                                          height="37.4841472"
                                        />
                                        <path
                                          d="M63.787643,110.163196 L23.9731358,64.760371 L59.3503243,59.1240536 L75.1714839,23.975595 L94.113631,23.975595 L138.196288,45.8493625 L106.813802,76.6822173 L100.021977,116.966878 C99.5679703,119.65975 97.0907757,121.147122 94.4974473,120.2918 L63.787643,110.163196 Z"
                                          transform="translate(81.084712, 72.254263) rotate(-15.000000) translate(-81.084712, -72.254263) "
                                        />
                                        <rect
                                          transform="translate(63.731757, 98.907305) rotate(55.000000) translate(-63.731757, -98.907305) "
                                          x="39.9742184"
                                          y="80.165231"
                                          width="47.5150764"
                                          height="37.4841472"
                                        />
                                        <path
                                          d="M102.81718,46.5827985 C103.490293,46.0925436 111.496711,8.91034571 111.007083,8.24145145 C110.516953,7.57255718 82.5162889,6.10910582 80.4029936,6.10910582 C78.2896982,6.10910582 79.9128631,34.1616476 80.4029936,34.8310437 C80.8926214,35.499938 102.144067,47.0725515 102.81718,46.5827985 Z"
                                          fill="rgb(255, 196, 31)"
                                        />
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </svg>
                      <svg
                        class="VIPBadge-Rot VIPBadge-Rot2"
                        width="472px"
                        height="472px"
                        viewBox="0 0 472 472"
                      >
                        <defs>
                          <path
                            id="path-1"
                            d="M0,0 L550,0 L550,549 L0,549 L0,0 Z M275.250246,221.799666 C246.254859,221.799666 222.75,245.301068 222.75,274.291698 C222.75,303.282328 246.254859,326.78373 275.250246,326.78373 C304.245141,326.78373 327.75,303.282328 327.75,274.291698 C327.75,245.301068 304.245141,221.799666 275.250246,221.799666 Z"
                          />
                        </defs>
                        <g
                          stroke="none"
                          stroke-width="1"
                          fill="none"
                          fill-rule="evenodd"
                        >
                          <g>
                            <g transform="translate(-39.000000, -38.000000)">
                              <g>
                                <g>
                                  <mask id="mask-2" fill="white">
                                    <use
                                      xmlns:xlink="http://www.w3.org/1999/xlink"
                                      xlink:href="#path-1"
                                    />
                                  </mask>
                                  <g mask="url(#mask-2)">
                                    <g
                                      transform="translate(185.166667, 173.223706)"
                                    >
                                      <g
                                        fill="rgb(255, 196, 31)"
                                        transform="translate(24.277455, 33.200995)"
                                      >
                                        <rect
                                          transform="translate(76.003530, 28.905413) rotate(33.000000) translate(-76.003530, -28.905413) "
                                          x="52.2459916"
                                          y="10.163339"
                                          width="47.5150764"
                                          height="37.4841472"
                                          rx="2.47640026"
                                        />
                                        <path
                                          d="M92.888662,50.1641542 L132.62142,60.8368926 C133.938683,61.1907267 134.431822,62.4168414 133.713832,63.5902777 L116.003681,92.534608 C115.28974,93.7014265 113.794611,94.0311257 112.65981,93.2680496 L89.3839044,77.6165883 L92.888662,50.1641542 Z"
                                          transform="translate(111.712268, 71.921725) rotate(52.000000) translate(-111.712268, -71.921725) "
                                        />
                                        <rect
                                          transform="translate(30.355125, 70.254116) rotate(33.000000) translate(-30.355125, -70.254116) "
                                          x="6.59758708"
                                          y="51.5120426"
                                          width="47.5150764"
                                          height="37.4841472"
                                        />
                                        <rect
                                          transform="translate(105.557716, 90.465750) rotate(33.000000) translate(-105.557716, -90.465750) "
                                          x="89.7159245"
                                          y="71.723676"
                                          width="31.6835825"
                                          height="37.4841472"
                                        />
                                        <path
                                          d="M12.7708786,20.3697869 C12.6703402,19.5596706 13.2271323,19.0954492 14.0109949,19.3318596 L75.6581441,37.9244423 C76.4435798,38.1613272 77.1662193,39.006732 77.2746586,39.8313702 L82.0859874,76.4196096 C82.193328,77.2358919 81.630066,77.7955704 80.816995,77.6679741 L20.1348142,68.1450401 C19.3266285,68.0182105 18.5900782,67.259602 18.4894236,66.4485493 L12.7708786,20.3697869 Z"
                                          transform="translate(47.434591, 48.400281) rotate(-21.000000) translate(-47.434591, -48.400281) "
                                        />
                                        <path
                                          d="M51.4663619,95.7160904 L69.7456577,125.50524 C71.03502,127.60647 70.0856537,129.513245 67.6358082,129.763058 L16.0372314,135.024625 L51.4663619,95.7160904 Z"
                                          transform="translate(44.058743, 115.370358) rotate(204.000000) translate(-44.058743, -115.370358) "
                                        />
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </svg>
                      <svg
                        class="VIPBadge-Rot VIPBadge-Rot3"
                        width="472px"
                        height="472px"
                        viewBox="0 0 472 472"
                      >
                        <defs>
                          <path
                            id="path-1"
                            d="M0,0 L550,0 L550,549 L0,549 L0,0 Z M275.250246,221.799666 C246.254859,221.799666 222.75,245.301068 222.75,274.291698 C222.75,303.282328 246.254859,326.78373 275.250246,326.78373 C304.245141,326.78373 327.75,303.282328 327.75,274.291698 C327.75,245.301068 304.245141,221.799666 275.250246,221.799666 Z"
                          />
                        </defs>
                        <g
                          stroke="none"
                          stroke-width="1"
                          fill="none"
                          fill-rule="evenodd"
                        >
                          <g>
                            <g transform="translate(-39.000000, -38.000000)">
                              <g>
                                <g>
                                  <mask id="mask-2" fill="white">
                                    <use
                                      xmlns:xlink="http://www.w3.org/1999/xlink"
                                      xlink:href="#path-1"
                                    />
                                  </mask>
                                  <g mask="url(#mask-2)">
                                    <g
                                      transform="translate(185.166667, 173.223706)"
                                    >
                                      <g
                                        fill="rgb(255, 196, 31)"
                                        transform="translate(6.665904, 6.398596)"
                                      >
                                        <rect
                                          transform="translate(63.396954, 6.616548) rotate(19.000000) translate(-63.396954, -6.616548) "
                                          x="58.6380497"
                                          y="1.58797159"
                                          width="9.51780765"
                                          height="10.0571534"
                                        />
                                        <rect
                                          transform="translate(126.815524, 177.569834) rotate(19.000000) translate(-126.815524, -177.569834) "
                                          x="124.664465"
                                          y="175.29688"
                                          width="4.30211879"
                                          height="4.54590704"
                                        />
                                        <rect
                                          transform="translate(80.421052, 179.837195) rotate(19.000000) translate(-80.421052, -179.837195) "
                                          x="78.2699925"
                                          y="177.564241"
                                          width="4.30211879"
                                          height="4.54590704"
                                        />
                                        <rect
                                          transform="translate(3.773090, 56.609560) rotate(19.000000) translate(-3.773090, -56.609560) "
                                          x="1.62203013"
                                          y="54.3366069"
                                          width="4.30211879"
                                          height="4.54590704"
                                        />
                                        <path
                                          d="M72.9454654,15.8064685 C72.9454654,13.8837074 72.0306853,11.9723579 72.0306853,11.9723579 C72.0306853,11.9723579 74.0636675,12.9575676 76.0966497,12.9575676 C78.1296319,12.9575676 80.1626141,11.9723579 80.1626141,11.9723579 C80.1626141,11.9723579 79.4438439,14.0074808 79.4438439,16.0426037 C79.4438439,18.0777265 80.1626141,20.1128494 80.1626141,20.1128494 C80.1626141,20.1128494 78.1296319,19.3790124 76.0966497,19.3790124 C74.0636675,19.3790124 72.0306853,20.1128494 72.0306853,20.1128494 C72.0306853,20.1128494 72.9454654,17.7292296 72.9454654,15.8064685 Z"
                                          transform="translate(76.096650, 16.042604) rotate(-3.000000) translate(-76.096650, -16.042604) "
                                        />
                                        <path
                                          d="M20.7947158,156.940724 C20.7947158,155.017963 19.8799358,153.106614 19.8799358,153.106614 C19.8799358,153.106614 21.912918,154.091823 23.9459002,154.091823 C25.9788824,154.091823 28.0118646,153.106614 28.0118646,153.106614 C28.0118646,153.106614 27.2930944,155.141736 27.2930944,157.176859 C27.2930944,159.211982 28.0118646,161.247105 28.0118646,161.247105 C28.0118646,161.247105 25.9788824,160.513268 23.9459002,160.513268 C21.912918,160.513268 19.8799358,161.247105 19.8799358,161.247105 C19.8799358,161.247105 20.7947158,158.863485 20.7947158,156.940724 Z"
                                          transform="translate(23.945900, 157.176859) rotate(-19.000000) translate(-23.945900, -157.176859) "
                                        />
                                        <path
                                          d="M160.955707,134.395924 C160.955707,134.395924 161.935314,135.731252 162.914922,135.731252 C163.894529,135.731252 164.874136,134.395924 164.874136,134.395924 L164.874136,139.957954 C164.874136,139.957954 163.894529,139.057227 162.914922,139.057227 C161.935314,139.057227 160.955707,139.957954 160.955707,139.957954 L160.955707,134.395924 Z"
                                          transform="translate(162.914922, 137.176939) rotate(-151.000000) translate(-162.914922, -137.176939) "
                                        />
                                        <polygon
                                          transform="translate(149.612400, 34.282386) rotate(-30.000000) translate(-149.612400, -34.282386) "
                                          points="149.6124 36.3884194 147.284695 37.6900196 147.729248 34.9331863 145.846095 32.9807861 148.448548 32.5785695 149.6124 30.0703197 150.776253 32.5785695 153.378706 32.9807861 151.495553 34.9331863 151.940105 37.6900196"
                                        />
                                        <polygon
                                          transform="translate(145.587465, 160.885281) rotate(29.000000) translate(-145.587465, -160.885281) "
                                          points="145.587465 166.211881 140.644276 168.976001 141.588341 163.121501 137.589217 158.975321 143.115871 158.121161 145.587465 152.794561 148.05906 158.121161 153.585713 158.975321 149.586589 163.121501 150.530654 168.976001"
                                        />
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </svg>
                      <svg
                        class="VIPBadge-Rot VIPBadge-Rot4"
                        width="472px"
                        height="472px"
                        viewBox="0 0 472 472"
                      >
                        <defs>
                          <path
                            id="path-1"
                            d="M0,0 L550,0 L550,549 L0,549 L0,0 Z M275.250246,221.799666 C246.254859,221.799666 222.75,245.301068 222.75,274.291698 C222.75,303.282328 246.254859,326.78373 275.250246,326.78373 C304.245141,326.78373 327.75,303.282328 327.75,274.291698 C327.75,245.301068 304.245141,221.799666 275.250246,221.799666 Z"
                          />
                        </defs>
                        <g
                          stroke="none"
                          stroke-width="1"
                          fill="none"
                          fill-rule="evenodd"
                        >
                          <g>
                            <g transform="translate(-39.000000, -38.000000)">
                              <g>
                                <g>
                                  <mask id="mask-2" fill="white">
                                    <use
                                      xmlns:xlink="http://www.w3.org/1999/xlink"
                                      xlink:href="#path-1"
                                    />
                                  </mask>
                                  <g mask="url(#mask-2)">
                                    <g
                                      transform="translate(185.166667, 173.223706)"
                                    >
                                      <g
                                        transform="translate(0.195037, 0.450858)"
                                      >
                                        <g
                                          fill="rgb(255, 196, 31)"
                                          transform="translate(12.870416, 0.000000)"
                                        >
                                          <rect
                                            transform="translate(5.861318, 45.369129) rotate(-24.000000) translate(-5.861318, -45.369129) "
                                            x="1.73478559"
                                            y="40.9204917"
                                            width="8.25306484"
                                            height="8.89727464"
                                          />
                                          <rect
                                            transform="translate(23.249321, 185.784933) rotate(-24.000000) translate(-23.249321, -185.784933) "
                                            x="19.1227887"
                                            y="181.336296"
                                            width="8.25306484"
                                            height="8.89727464"
                                          />
                                          <rect
                                            transform="translate(173.930600, 103.728276) rotate(-24.000000) translate(-173.930600, -103.728276) "
                                            x="169.804068"
                                            y="99.2796388"
                                            width="8.25306484"
                                            height="8.89727464"
                                          />
                                          <path
                                            d="M87.1851044,10.5900741 C87.1851044,6.53814775 85.2573446,2.51026973 85.2573446,2.51026973 C85.2573446,2.51026973 89.5415452,4.58644909 93.8257457,4.58644909 C98.1099463,4.58644909 102.394147,2.51026973 102.394147,2.51026973 C102.394147,2.51026973 100.879448,6.79898139 100.879448,11.0876931 C100.879448,15.3764047 102.394147,19.6651164 102.394147,19.6651164 C102.394147,19.6651164 98.1099463,18.1186667 93.8257457,18.1186667 C89.5415452,18.1186667 85.2573446,19.6651164 85.2573446,19.6651164 C85.2573446,19.6651164 87.1851044,14.6420004 87.1851044,10.5900741 Z"
                                            transform="translate(93.825746, 11.087693) rotate(19.000000) translate(-93.825746, -11.087693) "
                                          />
                                          <path
                                            d="M142.842934,45.8680448 C142.842934,45.8680448 144.822998,47.7715362 146.803062,47.7715362 C148.783126,47.7715362 150.76319,45.8680448 150.76319,45.8680448 L150.76319,53.7966406 C150.76319,53.7966406 148.783126,52.5126673 146.803062,52.5126673 C144.822998,52.5126673 142.842934,53.7966406 142.842934,53.7966406 L142.842934,45.8680448 Z"
                                            transform="translate(146.803062, 49.832343) rotate(-66.000000) translate(-146.803062, -49.832343) "
                                          />
                                          <path
                                            d="M93.0821087,182.683374 C93.0821087,178.890082 91.2773974,175.119302 91.2773974,175.119302 C91.2773974,175.119302 95.2881384,177.062959 99.2988793,177.062959 C103.30962,177.062959 107.320361,175.119302 107.320361,175.119302 C107.320361,175.119302 105.902345,179.134266 105.902345,183.14923 C105.902345,187.164194 107.320361,191.179159 107.320361,191.179159 C107.320361,191.179159 103.30962,189.731418 99.2988793,189.731418 C95.2881384,189.731418 91.2773974,191.179159 91.2773974,191.179159 C91.2773974,191.179159 93.0821087,186.476667 93.0821087,182.683374 Z"
                                            transform="translate(99.298879, 183.149230) rotate(19.000000) translate(-99.298879, -183.149230) "
                                          />
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </svg>
                      <svg
                        class="VIPBadge-Rot VIPBadge-Rot5"
                        width="472px"
                        height="472px"
                        viewBox="0 0 472 472"
                      >
                        <defs>
                          <path
                            id="path-1"
                            d="M0,0 L550,0 L550,549 L0,549 L0,0 Z M275.250246,221.799666 C246.254859,221.799666 222.75,245.301068 222.75,274.291698 C222.75,303.282328 246.254859,326.78373 275.250246,326.78373 C304.245141,326.78373 327.75,303.282328 327.75,274.291698 C327.75,245.301068 304.245141,221.799666 275.250246,221.799666 Z"
                          />
                        </defs>
                        <g
                          stroke="none"
                          stroke-width="1"
                          fill="none"
                          fill-rule="evenodd"
                        >
                          <g>
                            <g transform="translate(-39.000000, -38.000000)">
                              <g>
                                <g>
                                  <mask id="mask-2" fill="white">
                                    <use
                                      xmlns:xlink="http://www.w3.org/1999/xlink"
                                      xlink:href="#path-1"
                                    />
                                  </mask>
                                  <g mask="url(#mask-2)">
                                    <g
                                      transform="translate(185.166667, 173.223706)"
                                    >
                                      <g
                                        transform="translate(0.195037, 0.450858)"
                                      >
                                        <g
                                          fill="rgb(255, 196, 31)"
                                          transform="translate(0.000000, 11.892894)"
                                        >
                                          <path
                                            d="M1.55866417,71.1221863 C1.55866417,69.1994252 0.643884102,67.2880758 0.643884102,67.2880758 C0.643884102,67.2880758 2.6768663,68.2732854 4.70984851,68.2732854 C6.74283071,68.2732854 8.77581291,67.2880758 8.77581291,67.2880758 C8.77581291,67.2880758 8.05704272,69.3231986 8.05704272,71.3583215 C8.05704272,73.3934443 8.77581291,75.4285672 8.77581291,75.4285672 C8.77581291,75.4285672 6.74283071,74.6947302 4.70984851,74.6947302 C2.6768663,74.6947302 0.643884102,75.4285672 0.643884102,75.4285672 C0.643884102,75.4285672 1.55866417,73.0449474 1.55866417,71.1221863 Z"
                                            transform="translate(4.709849, 71.358321) rotate(-3.000000) translate(-4.709849, -71.358321) "
                                          />
                                          <rect
                                            transform="translate(140.424867, 18.277515) rotate(-24.000000) translate(-140.424867, -18.277515) "
                                            x="136.298335"
                                            y="13.8288775"
                                            width="8.25306484"
                                            height="8.89727464"
                                          />
                                          <path
                                            d="M10.2489386,150.14384 C10.2489386,148.93709 9.67481041,147.737501 9.67481041,147.737501 C9.67481041,147.737501 10.9507372,148.355832 12.2266639,148.355832 C13.5025907,148.355832 14.7785175,147.737501 14.7785175,147.737501 C14.7785175,147.737501 14.3274077,149.014772 14.3274077,150.292042 C14.3274077,151.569312 14.7785175,152.846583 14.7785175,152.846583 C14.7785175,152.846583 13.5025907,152.386017 12.2266639,152.386017 C10.9507372,152.386017 9.67481041,152.846583 9.67481041,152.846583 C9.67481041,152.846583 10.2489386,151.350591 10.2489386,150.14384 Z"
                                          />
                                          <path
                                            d="M49.1311949,18.4596197 C49.1311949,18.4596197 51.1112589,20.363111 53.0913229,20.363111 C55.0713869,20.363111 57.0514509,18.4596197 57.0514509,18.4596197 L57.0514509,26.3882155 C57.0514509,26.3882155 55.0713869,25.1042422 53.0913229,25.1042422 C51.1112589,25.1042422 49.1311949,26.3882155 49.1311949,26.3882155 L49.1311949,18.4596197 Z"
                                            transform="translate(53.091323, 22.423918) rotate(-51.000000) translate(-53.091323, -22.423918) "
                                          />
                                          <path
                                            d="M64.0988667,187.059595 C64.0988667,187.059595 66.0789307,188.963086 68.0589947,188.963086 C70.0390587,188.963086 72.0191227,187.059595 72.0191227,187.059595 L72.0191227,194.988191 C72.0191227,194.988191 70.0390587,193.704217 68.0589947,193.704217 C66.0789307,193.704217 64.0988667,194.988191 64.0988667,194.988191 L64.0988667,187.059595 Z"
                                            transform="translate(68.058995, 191.023893) rotate(-102.000000) translate(-68.058995, -191.023893) "
                                          />
                                          <polygon
                                            transform="translate(40.679526, 5.629906) rotate(-30.000000) translate(-40.679526, -5.629906) "
                                            points="40.6795255 7.73593878 38.3518207 9.03753891 38.7963727 6.2807056 36.91322 4.3283054 39.5156731 3.92608884 40.6795255 1.41783903 41.8433779 3.92608884 44.4458311 4.3283054 42.5626783 6.2807056 43.0072303 9.03753891"
                                          />
                                          <polygon
                                            transform="translate(163.217654, 70.241049) rotate(35.000000) translate(-163.217654, -70.241049) "
                                            points="163.217654 73.4751317 160.216362 75.1533868 160.789558 71.5987855 158.361461 69.0814029 161.717008 68.5627936 163.217654 65.3287106 164.7183 68.5627936 168.073847 69.0814029 165.645751 71.5987855 166.218947 75.1533868"
                                          />
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </svg>
                    </div>
                    <div class="VIPBadge-Avatar">
                      <img
                        class="ProfileAvatar-image"
                        v-bind:src="
                          this.$assetHelper.getImage('un-verify-account.png')
                        "
                      />
                    </div>
                  </div>
                </span>
                <div class="errorMessage">
                  <span>Please Verify Your Email</span>
                  <br />
                  <br />
                  <span style="color:#000;">
                    Your email address must be verified before you can sign in.
                    <br />Please check your email.
                  </span>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import Auth from "../auth";

export default {
  created() {
    Auth.clearToken();
  },
};
</script>

<style>
.ProfileAvatar {
  padding-top: 65px;
  padding-bottom: 35px;
  background-color: #fff;
  width: 100%;
  text-align: center;
  border-radius: 2px;
}

.VIPBadge-Container {
  position: relative;
  display: inline-block;
}

.VIPBadge {
  position: absolute;
  pointer-events: none;
}

.VIPBadge-Avatar {
  position: relative;
  z-index: 5;
}

.ProfileAvatar-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 2px solid #fff;
}

.VIPBadge-Gold .VIPBadge-Rot1 {
  -webkit-animation: spin 12s infinite linear normal;
  animation: spin 12s infinite linear normal;
}

.VIPBadge-Gold .VIPBadge-Rot2 {
  -webkit-animation: spin 40s infinite linear reverse;
  animation: spin 40s infinite linear reverse;
}

.VIPBadge-Gold .VIPBadge-Rot3 {
  -webkit-animation: spin 12s infinite linear reverse;
  animation: spin 12s infinite linear reverse;
}

.VIPBadge-Gold .VIPBadge-Rot4 {
  -webkit-animation: spin 24s infinite linear reverse;
  animation: spin 24s infinite linear reverse;
}

.VIPBadge-Gold .VIPBadge-Rot5 {
  -webkit-animation: spin 8s infinite linear reverse;
  animation: spin 8s infinite linear reverse;
}

.VIPBadge-Rot {
  position: absolute;
  opacity: 0.46;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.errorMessage {
  color: #e74c3d;
  font-size: 16px;
  margin-top: 60px;
  font-weight: 500;
}
</style>
