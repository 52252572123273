<template>
  <div>
    <v-skeleton-loader v-if="isLoading" ref="skeleton" type="table" class="mx-auto"></v-skeleton-loader>
    <v-data-table
      :headers="headers"
      :items="alerts"
      class="shadow-xl mx-5 my-5"
      :server-items-length="totalCount"
      :options.sync="options"
      :footer-props="{
        itemsPerPageOptions: [10, 50, 100],
      }"
      v-else
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <div class="d-none d-sm-flex">
            <v-toolbar-title>Alerts</v-toolbar-title>
          </div>
          <v-spacer></v-spacer>
          <router-link to="/new-alert" v-if="hasAuth('New Alert')">
            <v-btn color="primary" class="mr-5 text-capitalize" depressed> <v-icon>add</v-icon>NEW ALERT </v-btn>
          </router-link>
        </v-toolbar>
      </template>
      <template v-slot:item.To="{ item }">
        <v-tooltip v-for="(to, index) in item.To" :key="index" bottom color="black">
          <template v-slot:activator="{ on }">
            <v-chip class="ma-2" color="primary" text-color="white" v-on="on">
              {{ to.Name }}
            </v-chip>
          </template>
          <span>{{ to.Value }}</span>
        </v-tooltip>
      </template>
      <template v-slot:item.action="{ item }">
        <v-tooltip bottom v-if="item.DataTest && !!item.EmailList">
          <template v-slot:activator="{ on }">
            <span v-on="on">
              <v-btn depressed class="mx-1" @click="testMail(item.EmailList)">
                <v-icon size="18">email</v-icon>
              </v-btn>
            </span>
          </template>
          <span>Test Email Now</span>
        </v-tooltip>
        <v-tooltip bottom v-if="item.DataTest && !!item.SlackWebHookUrl">
          <template v-slot:activator="{ on }">
            <span v-on="on">
              <v-btn depressed class="mx-1" @click="testWebHook(item.SlackWebHookUrl)">
                <v-icon size="18">sync_alt</v-icon>
              </v-btn>
            </span>
          </template>
          <span>Test Slack Now</span>
        </v-tooltip>
        <v-tooltip bottom v-if="item.DataTest && !!item.GoogleChatWebHookUrl">
          <template v-slot:activator="{ on }">
            <span v-on="on">
              <v-btn depressed class="mx-1" @click="testWebHook(item.GoogleChatWebHookUrl)">
                <v-icon size="18">sync_alt</v-icon>
              </v-btn>
            </span>
          </template>
          <span>Test Google Chat Now</span>
        </v-tooltip>
        <v-tooltip bottom v-if="item.DataTest && !!item.MicrosoftTeamsWebHookUrl">
          <template v-slot:activator="{ on }">
            <span v-on="on">
              <v-btn depressed class="mx-1" @click="testWebHook(item.MicrosoftTeamsWebHookUrl)">
                <v-icon size="18">sync_alt</v-icon>
              </v-btn>
            </span>
          </template>
          <span>Test Microsoft Teams Now</span>
        </v-tooltip>
        <v-tooltip bottom v-if="item.DataTest && !!item.HypeWebHookUrl">
          <template v-slot:activator="{ on }">
            <span v-on="on">
              <v-btn depressed class="mx-1" @click="testWebHook(item.HypeWebHookUrl)">
                <v-icon size="18">sync_alt</v-icon>
              </v-btn>
            </span>
          </template>
          <span>Test Hype Now</span>
        </v-tooltip>
        <v-tooltip bottom v-if="item.DataTest && !!item.WebHookUrl">
          <template v-slot:activator="{ on }">
            <span v-on="on">
              <v-btn depressed class="mx-1" @click="testWebHook(item.WebHookUrl)">
                <v-icon size="18">sync_alt</v-icon>
              </v-btn>
            </span>
          </template>
          <span>Test Webhook Now</span>
        </v-tooltip>
        <v-tooltip bottom v-if="item.DataEdit">
          <template v-slot:activator="{ on }">
            <v-btn depressed class="mx-1" :to="`/alert/${item.Id}`" v-on="on">
              <v-icon size="18">edit</v-icon>
            </v-btn>
          </template>
          <span>Edit Alert</span>
        </v-tooltip>
        <v-tooltip bottom v-if="item.DataDelete">
          <template v-slot:activator="{ on }">
            <v-btn depressed class="mx-1" @click="deleteAlert(item.Id)" v-on="on">
              <v-icon size="18">delete</v-icon>
            </v-btn>
          </template>
          <span>Delete Alert</span>
        </v-tooltip>
      </template>
      <template v-slot:no-data></template>
    </v-data-table>
    <ConfirmDialog />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { FETCH_FILTER_ALERTS } from "../../store/modules/alert";
import Api from "../../api";
import ConfirmDialog from "../ConfirmDialog";
import { OPEN_DIALOG } from "../../store/modules/confirm";
import { hasActionAuthorization } from "../../authorization";
import { timeAwareFormat } from "../../time";

export default {
  components: {
    ConfirmDialog,
  },
  data: () => ({
    options: { itemsPerPage: 10, page: 1 },
    headers: [
      {
        text: "Alert Name",
        align: "left",
        sortable: true,
        value: "Name",
      },
      {
        text: "Selected Script",
        value: "Script.Name",
        sortable: false,
      },
      {
        text: "To",
        value: "To",
        align: "center",
        sortable: false,
      },
      {
        text: "Created Time",
        value: "createdAt",
      },
      {
        text: "Created By",
        value: "Created.Name",
      },
      {
        text: "Actions",
        value: "action",
        align: "center",
        sortable: false,
      },
    ],
  }),
  watch: {
    options: {
      handler(newVal, oldVal) {
        if (newVal.page != oldVal.page || newVal.itemsPerPage != oldVal.itemsPerPage) {
          this.fetchAlerts({ options: newVal });
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapState("alert", {
      alerts: (state) =>
        state.filteredAlerts.map((i) => ({
          ...i,
          createdAt: timeAwareFormat(i.createdAt),
          To: [
            ...(i.EmailList && [{ Name: "Email", Value: i.EmailList }]),
            ...(i.SlackWebHookUrl && [{ Name: "Slack", Value: i.SlackWebHookUrl }]),
            ...(i.GoogleChatWebHookUrl && [{ Name: "Google Chat", Value: i.GoogleChatWebHookUrl }]),
            ...(i.HypeWebHookUrl && [{ Name: "Workflow", Value: i.HypeWebHookUrl }]),
            ...(i.MicrosoftTeamsWebHookUrl && [{ Name: "MS Teams", Value: i.MicrosoftTeamsWebHookUrl }]),
            ...(i.WebHookUrl && [{ Name: "Webhook", Value: i.WebHookUrl }]),
          ],
        })),
      totalCount: (state) => state.totalCount,
      isLoading: (state) => state.isLoading,
    }),
  },
  created() {
    this.fetchAlerts({ options: this.options });
  },
  methods: {
    ...mapActions("confirm", {
      confirm: OPEN_DIALOG,
    }),
    ...mapActions("alert", {
      fetchAlerts: FETCH_FILTER_ALERTS,
    }),
    async deleteAlert(id) {
      (await this.confirm({
        message: "Do you confirm to delete alerts?",
        title: "confirm",
      })) && (await Api.deleteAlert(id));
      this.fetchAlerts({ options: this.options });
    },
    async testMail(mails) {
      await Api.sendTestMail(mails);
      this.confirm({
        message: "A test mail has been sent.",
        title: "information",
        showCancelButton: false,
      });
    },
    async testWebHook(url) {
      await Api.sendTestWebHookMessage(url);
      this.confirm({
        message: "A test message has been sent.",
        title: "information",
        showCancelButton: false,
      });
    },
    hasAuth(action) {
      return hasActionAuthorization(this.$store, "Alerts", action);
    },
  },
};
</script>

<style>
.shadow-xl {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
}
</style>
