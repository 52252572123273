<template>
  <div>
    <v-container class="grey lighten-5">
      <v-row>
        <v-col cols="12" sm="4" v-for="item in smallWidget" :key="item.swh1">
          <v-card class="mx-auto shadow-xl" router :to="item.link" link>
            <v-list-item three-line>
              <v-list-item-avatar tile size="80" color="grey lighten-4">
                <v-icon large>{{ item.swicon }}</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <div class="overline mb-4">{{ item.swh1 }}</div>
                <v-list-item-title class="headline mb-1">{{
                  item.swcount
                }}</v-list-item-title>
                <v-list-item-subtitle>{{ item.swdesc }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-row>
        <v-col cols="12" sm="6" class="gcharts-d">
          <v-card class="shadow-xl">
            <h5 class="py-4 px-4">AGENTS</h5>
            <v-subheader v-show="agents.length == 0"
              >No data available</v-subheader
            >
            <GChart
              v-show="agents.length != 0"
              type="PieChart"
              :data="chartData"
              :options="chartOptions"
            />
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" class="gcharts-d">
          <v-card class="shadow-xl">
            <h5 class="py-4 px-4">TRANSACTIONS</h5>
            <v-subheader v-if="chartOptions2.colors == 0"
              >No data available</v-subheader
            >
            <GChart
              v-else
              type="PieChart"
              :data="chartData2"
              :options="chartOptions2"
            />
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import HypeApi from "../hype/api";
import RunApi from "../api";
import { mapState, mapActions } from "vuex";
import { FETCH_AGENTS } from "../store/modules/agent";
import { FETCH_TASK_WITH_PAGE } from "../store/modules/task";
import { FETCH_SCRIPTS } from "../store/modules/script";

export default {
  async created() {
    this.fetchAgents();
    this.fetchScripts();
    this.fetchTasks({ options: {}, isRefreshing: false });
    this.fetchWorkflows();

    let jobStatus = await RunApi.getTaskStatus();
    this.jobs = jobStatus.map((job) => {
      return {
        Status: this.getStatusText(job.Status),
        StatusCount: job.StatusCount,
      };
    });

    let executionStatus = await HypeApi.getTotalExecutionsCount();
    executionStatus = executionStatus.map((execution) => {
      return {
        Status: this.capitalizeFirstLetter(execution.Status),
        StatusCount: execution.StatusCount,
      };
    });

    this.jobs = this.jobs.reduce((filtered, job) => {
      let execution = executionStatus.find((item) => {
        return item.Status === job.Status;
      });
      filtered.push({
        Status: job.Status,
        StatusCount: execution
          ? execution.StatusCount + job.StatusCount
          : job.StatusCount,
      });

      return filtered;
    }, []);

    this.totalJobs = this.jobs.reduce(
      (total, item) => (total += item.StatusCount),
      0
    );

    this.chartOptions2.colors = this.getColor();
  },
  computed: {
    ...mapState("agent", {
      agents: (state) => state.agents,
    }),
    ...mapState("script", {
      scripts: (state) => state.scripts,
    }),
    ...mapState("task", {
      taskCount: (state) => state.totalCount,
    }),
    ...mapState("workflows", {
      workflows: (state) => state.workflows,
    }),
    ...mapState("account", {
      planStatus: (state) => state.planStatus,
    }),
    smallWidget() {
      return [
        {
          swicon: "laptop_mac",
          swh1: "AGENTS",
          swcount: this.agents.length,
          swdesc: "",
          link: "/agents",
        },
        {
          swicon: "queue_play_next",
          swh1: "SCRIPTS",
          swcount: this.scripts.length,
          link: "/scripts",
        },
        {
          swicon: "playlist_add_check",
          swh1: "TASKS",
          swcount: this.taskCount,
          link: "/tasks",
        },
        {
          swicon: "timeline",
          swh1: "WORKFLOWS",
          swcount: this.workflows.length,
          link: "/workflows",
        },
        {
          swicon: "playlist_play",
          swh1: "TRANSACTIONS",
          swcount: this.totalJobs,
          link: "/jobs",
        },
        {
          swicon: "functions",
          swh1: "REMAINING TRANSACTIONS",
          swcount: this.planStatus.remainingJob,
          link: "/jobs",
        },
      ];
    },
    chartData() {
      let connectedNumber = this.agents.filter(
        (agent) => agent.Status == "Connected"
      ).length;

      return [
        ["Status", "Per"],
        ["Connected", connectedNumber],
        ["Disconnected", this.agents.length - connectedNumber],
      ];
    },
    chartData2() {
      let data = this.jobs.map((job) => {
        return [job.Status, job.StatusCount];
      });

      data.unshift(["Status", "Per"]);
      return data;
    },
  },
  methods: {
    ...mapActions("agent", {
      fetchAgents: FETCH_AGENTS,
    }),
    ...mapActions("task", {
      fetchTasks: FETCH_TASK_WITH_PAGE,
    }),
    ...mapActions("script", {
      fetchScripts: FETCH_SCRIPTS,
    }),
    ...mapActions("workflows", {
      fetchWorkflows: "loadWorkflows",
    }),
    getColor() {
      return this.jobs.map((job) => {
        switch (job.Status) {
          case "Success":
            return "green";
          case "Failure":
            return "red";
          case "Running":
            return "yellow";
          case "Queue":
            return "purple";
          case "Sending":
            return "magenta";
          case "Disconnected":
            return "orange";
          case "Declined":
            return "brown";
        }
      });
    },
    getStatusText(status) {
      switch (status) {
        case 1:
          return "Running";
        case 2:
          return "Success";
        case 3:
          return "Failure";
        case 4:
          return "Sending";
        case 5:
          return "Disconnected";
        case 6:
          return "Queue";
        case 7:
          return "Declined";
      }
    },
    capitalizeFirstLetter(value) {
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },
  data() {
    return {
      chartOptions: {
        height: 400,
      },
      chartOptions2: {
        colors: [],
        height: 400,
      },
      jobs: [],
      totalJobs: 0,
    };
  },
};
</script>

<style scoped>
.shadow-xl {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
}
</style>
