var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isLoading)?_c('v-skeleton-loader',{ref:"skeleton",staticClass:"mx-auto",attrs:{"type":"table"}}):_c('v-data-table',{staticClass:"shadow-xl mx-5 my-5",attrs:{"headers":_vm.headers,"items":_vm.assets,"server-items-length":_vm.totalCount,"options":_vm.options,"footer-props":{
      itemsPerPageOptions: [10, 50, 100],
    }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('div',{staticClass:"d-none d-sm-flex"},[_c('v-toolbar-title',[_vm._v("Assets")])],1),_c('v-spacer'),_c('edit-asset-dialog',{attrs:{"filterAndOptions":Object.assign({}, _vm.options, _vm.filter)}}),_c('delete-asset-dialog',{attrs:{"filterAndOptions":Object.assign({}, _vm.options, _vm.filter)}}),(_vm.hasAuth('Label Action'))?_c('label-dialog'):_vm._e()],1),_c('v-row',{staticClass:"px-5"},[_c('v-col',{attrs:{"cols":"12","xs":"6","sm":"6","md":"3"}},[_c('v-text-field',{attrs:{"label":"Name"},model:{value:(_vm.filter.name),callback:function ($$v) {_vm.$set(_vm.filter, "name", $$v)},expression:"filter.name"}})],1),_c('v-col',{attrs:{"cols":"12","xs":"6","sm":"6","md":"5"}},[_c('v-text-field',{attrs:{"label":"Description"},model:{value:(_vm.filter.description),callback:function ($$v) {_vm.$set(_vm.filter, "description", $$v)},expression:"filter.description"}})],1),_c('v-col',{attrs:{"cols":"12","xs":"6","sm":"6","md":"2"}},[_c('v-btn',{staticClass:"mt-5",attrs:{"depressed":"","color":"primary","block":""},on:{"click":_vm.getFilterAssetList}},[_vm._v("Refresh")])],1),_c('v-col',{attrs:{"cols":"12","xs":"6","sm":"6","md":"2"}},[(_vm.hasAuth('New Task'))?_c('new-asset-dialog',{attrs:{"filterAndOptions":Object.assign({}, _vm.options, _vm.filter)}}):_vm._e()],1)],1)]},proxy:true},{key:"item.Id",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"assets-item my-2"},[_vm._v(" "+_vm._s(item.Id)+" ")])]}},{key:"item.Name",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"assets-item",staticStyle:{"max-width":"200px"}},[_vm._v(" "+_vm._s(item.Name)+" ")])]}},{key:"item.Description",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"assets-item",staticStyle:{"width":"200px"}},[_vm._v(" "+_vm._s(item.Description)+" ")])]}},{key:"item.createdAt",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"assets-item",staticStyle:{"width":"170px"}},[_vm._v(" "+_vm._s(item.createdAt)+" ")])]}},{key:"item.Label",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"my-2"},_vm._l((item.Labels),function(label){return _c('v-chip',{key:label.Id,staticClass:"ma-1",attrs:{"color":"primary","text-color":"white"}},[_vm._v(" "+_vm._s(label.Name)+" ")])}),1)]}},{key:"item.action",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex"}},[(item.DataEdit)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"depressed":""},on:{"click":function () { return _vm.editAsset(item); }}},on),[_c('v-icon',{attrs:{"size":"18"}},[_vm._v("edit")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit Asset")])]):_vm._e(),(item.DataDelete)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"depressed":""},on:{"click":function($event){return _vm.deleteAsset(item.Id)}}},on),[_c('v-icon',{attrs:{"size":"18"}},[_vm._v("delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete Asset")])]):_vm._e()],1)]}},{key:"no-data",fn:function(){return undefined},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }