<template>
  <v-dialog v-model="dialog" persistent max-width="800px">
    <v-card>
      <v-form ref="form">
        <v-card-title>
          <span class="headline">User Auths</span>
        </v-card-title>
        <v-alert
          v-show="!!error"
          text
          prominent
          type="error"
          icon="mdi-alert-circle"
          >{{ error }}</v-alert
        >
        <v-alert
          v-show="!!success"
          text
          prominent
          type="success"
          icon="mdi-cloud-check"
          >Succesfull</v-alert
        >
        <v-container>
          <v-row>
            <v-row>
              <v-col cols="1" sm="1" md="1"></v-col>
              <v-col cols="9" sm="2" md="9">
                <v-autocomplete
                  v-model="userId"
                  :items="users"
                  item-text="Name"
                  item-value="Id"
                  dense
                  label="Select User"
                ></v-autocomplete>
              </v-col>
              <v-col cols="1" sm="1" md="1">
                <v-btn color="primary" depressed v-on:click="save">Add</v-btn>
              </v-col>
              <v-col cols="1" sm="1" md="1"></v-col>
            </v-row>
            <v-col cols="12" sm="6" md="12">
              <v-data-table
                :headers="headers"
                :items="auths"
                class="shadow-xl mx-5 my-5"
                :search="search"
                hide-default-footer
              >
                <template v-slot:top>
                  <v-toolbar flat color="white">
                    <v-row>
                      <v-col cols="10" sm="3" md="4" offset-md="8" class="px-5">
                        <v-text-field
                          v-model="search"
                          append-icon="search"
                          label="Search"
                          single-line
                          hide-details
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-toolbar>
                </template>
                <template v-slot:item.Auth="{ item }">
                  <v-switch
                    @change="toggleAuth(item.Id, item.Auth)"
                    :input-value="item.Auth"
                    color="primary"
                    class="my-1"
                    hide-details
                    :loading="toggleIsLoading"
                  ></v-switch>
                </template>
                <template v-slot:item.action="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn depressed @click="deleteAuth(item.Id)" v-on="on">
                        <v-icon size="18">delete</v-icon>
                      </v-btn>
                    </template>
                    <span>Delete Auth</span>
                  </v-tooltip>
                </template>
                <template v-slot:no-data></template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" text @click="closeDialog">Close</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from "vuex";
import {
  FETCH_USER_AUTH,
  CREATE_USER_AUTH,
  TOGGLE_USER_AUTH,
  DELETE_USER_AUTH,
  TOGGLE_USER_AUTH_DIALOG,
} from "../../store/modules/digistaff";
import { GET_SUB_USER } from "../../store/modules/user";

export default {
  data: () => ({
    headers: [
      {
        text: "User",
        value: "User.Name",
      },
      {
        text: "Skill",
        value: "Skill.Name",
      },
      {
        text: "Auth",
        value: "Auth",
      },
      {
        text: "Actions",
        value: "action",
        align: "right",
      },
    ],
    search: "",
    userId: "",
  }),
  computed: {
    ...mapState("digistaff", {
      skillId: (state) => state.userAuthSkillId,
      auths: (state) => state.userAuths,
      isLoading: (state) => state.userAuthIsLoading,
      dialog: (state) => state.userAuthDialog,
      success: (state) =>
        state.createUserAuth.success ||
        state.toggleUserAuth.success ||
        state.deleteUserAuth.success,
      error: (state) =>
        state.createUserAuth.error ||
        state.toggleUserAuth.error ||
        state.deleteUserAuth.error,
      toggleIsLoading: (state) => state.toggleUserAuth.isLoading,
    }),
    ...mapState("user", {
      users: (state) => state.subUser.subUsers,
    }),
  },
  watch: {
    skillId(val) {
      if (val) {
        this.fetchUserAuth(val);
        this.fetchUsers();
      }
    },
  },
  methods: {
    ...mapActions("digistaff", {
      fetchUserAuth: FETCH_USER_AUTH,
      createUserAuth: CREATE_USER_AUTH,
      toggleUserAuth: TOGGLE_USER_AUTH,
      deleteUserAuth: DELETE_USER_AUTH,
      toggleDialog: TOGGLE_USER_AUTH_DIALOG,
    }),
    ...mapActions("user", {
      fetchUsers: GET_SUB_USER,
    }),
    save() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.createUserAuth({
        SkillId: this.skillId,
        UserId: this.userId,
        Auth: true,
      });

      this.userId = "";
    },
    toggleAuth(authId, auth) {
      this.toggleUserAuth({
        authId,
        skillId: this.skillId,
        auth: { Auth: !auth },
      });
    },
    deleteAuth(authId) {
      this.deleteUserAuth({ skillId: this.skillId, authId });

      this.userId = "";
    },
    closeDialog() {
      this.toggleDialog();
    },
  },
};
</script>
