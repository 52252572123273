var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isLoading)?_c('v-skeleton-loader',{ref:"skeleton",staticClass:"mx-auto",attrs:{"type":"table"}}):_c('v-data-table',{staticClass:"shadow-xl mx-5 my-5",attrs:{"headers":_vm.headers,"items":_vm.queues,"server-items-length":_vm.totalCount,"options":_vm.options,"footer-props":{
      itemsPerPageOptions: [10, 50, 100],
    }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('div',{staticClass:"d-none d-sm-flex"},[_c('v-toolbar-title',[_vm._v("Queues")])],1),_c('v-spacer'),_c('edit-queue-dialog',{attrs:{"filterAndOptions":Object.assign({}, _vm.options, _vm.filter)}}),_c('delete-queue-dialog',{attrs:{"filterAndOptions":Object.assign({}, _vm.options, _vm.filter)}})],1),_c('v-row',{staticClass:"px-5"},[_c('v-col',{attrs:{"cols":"12","xs":"6","sm":"6","md":"8"}},[_c('v-text-field',{attrs:{"label":"Queue Name"},model:{value:(_vm.filter.name),callback:function ($$v) {_vm.$set(_vm.filter, "name", $$v)},expression:"filter.name"}})],1),_c('v-col',{attrs:{"cols":"12","xs":"6","sm":"6","md":"2"}},[_c('v-btn',{staticClass:"mt-5",attrs:{"depressed":"","color":"primary","block":""},on:{"click":_vm.getFilterQueueList}},[_vm._v("Refresh")])],1),_c('v-col',{attrs:{"cols":"12","xs":"6","sm":"6","md":"2"}},[(_vm.hasAuth('New Queue'))?_c('new-queue-dialog',{staticClass:"mt-5",attrs:{"filterAndOptions":Object.assign({}, _vm.options, _vm.filter),"block":""}}):_vm._e()],1)],1)]},proxy:true},{key:"item.action",fn:function(ref){
    var item = ref.item;
return [(item.DataItems)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"depressed":""},on:{"click":function () { return _vm.redirectDetail(item.Id); }}},on),[_c('v-icon',{attrs:{"size":"18"}},[_vm._v("add")])],1)]}}],null,true)},[_c('span',[_vm._v("Queue Items")])]):_vm._e(),(item.DataExport)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"depressed":""},on:{"click":function () { return _vm.exportToExcel(item.Id); }}},on),[_c('v-icon',{attrs:{"size":"18"}},[_vm._v("cloud_download")])],1)]}}],null,true)},[_c('span',[_vm._v("Download Queue Items")])]):_vm._e(),(item.DataEdit)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"depressed":""},on:{"click":function () { return _vm.editQueue(item); }}},on),[_c('v-icon',{attrs:{"size":"18"}},[_vm._v("edit")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit Queue")])]):_vm._e(),(item.DataDelete)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"depressed":""},on:{"click":function($event){return _vm.deleteQueue(item.Id)}}},on),[_c('v-icon',{attrs:{"size":"18"}},[_vm._v("delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete Queue")])]):_vm._e()]}},{key:"no-data",fn:function(){return undefined},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }