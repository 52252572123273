<template>
  <v-navigation-drawer
    class="primary-drawer"
    id="navigation-drawer"
    :style="{ 'min-width': secondaryDrawer ?  editorWidth.after + 'px' : editorWidth.base + 'px'}"
    absolute
    right
    v-show="value"
  >
    <v-row class="fill-height" style="flex-wrap: nowrap;" no-gutters>
      <v-col
        id="ex-editor"
        class="secondary-drawer"
        absolute
        permanent
        v-show="secondaryDrawer"
      >
        <credentials-editor
          :active="credentialsEditor"
          :credentials-id="editCredentialsId"
          :credentials-type="editCredentialsType"
          @close="deactivateCredentialsEditor"
          @full-screen="fullScreenEditor"
          @save="onCredentialsSaved"
        />
        <expression-editor
          :style="{'max-width': (editorWidth.after - 450) + 'px' }"
          @close="deactivateExpressionEditor"
          :active="expressionEditorParameter"
          :data="data"
          @full-screen="fullScreenEditor"
          :key-path="expressionEditorParameter"
        />
      </v-col>
      <v-col
        v-if="!internalEditedNode"
        class="d-flex justify-center align-center"
        style="height: calc(100% - 2px)"
      >
        <div>no node specified</div>
      </v-col>
      <v-col
        style="max-width: 400px;min-width: 400px; width: 400px;"
        class="d-flex flex-column fill-height fill-width"
        v-else
      >
        <div class="flex-grow-0 flex-shrink-0">
          <v-list-item>
            <node-avatar class="mr-5" dark :node="editedNode.data.node" />
            <v-list-item-content>
              <v-list-item-title class="title" v-if="!isNameEdited">
                {{ internalEditedNode.data.name }}
              </v-list-item-title>

              <v-list-item-title class="title" v-else>
                <v-text-field
                  clear-icon="mdi-close"
                  clearable
                  append-icon="mdi-check"
                  @click:clear="closeEditName"
                  @click:append="saveName"
                  v-model="editedName"
                ></v-text-field>
              </v-list-item-title>

              <!-- <v-switch v-model="secondaryDrawer" label="secondary" /> -->
            </v-list-item-content>
            <v-list-item-action>
              <span class="d-flex column" v-if="!isNameEdited">
                <v-btn @click="openEditName" icon
                  ><v-icon>mdi-pencil</v-icon></v-btn
                >
                <v-btn @click="close" icon><v-icon>mdi-close</v-icon></v-btn>
              </span>
            </v-list-item-action>
          </v-list-item>
        </div>
        <div class="flex-grow-1">
          <v-tabs v-model="tab" fixed-tabs>
            <v-tab>Properties</v-tab>
            <v-tab>Settings</v-tab>
            <v-tab
              v-if="
                editedNode.data.executionResult &&
                editedNode.data.executionResult.error
              "
              >Error</v-tab
            >
            <v-tab
              v-if="
                editedNode.data.executionResult &&
                !editedNode.data.executionResult.error
              "
            >
              Result<v-chip class="ml-2" color="primary">
                {{ resultData && resultData.length }}
              </v-chip>
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item class="pa-5" eager>
              <div>
                <span v-if="webhooks" class="subtitle-2">Webhooks</span>
                <div v-if="webhooks">
                  <v-switch
                    :disabled="disableEdit"
                    true-value="PRODUCTION"
                    false-value="TEST"
                    v-model="displayWebhookType"
                    :label="displayWebhookType"
                    >{{ displayWebhookType }}</v-switch
                  >
                  <div v-for="webhook in webhooks" :key="webhook.path">
                    <v-chip
                      style="overflow: ellipsis"
                      @click="
                        copy(
                          webhookURL(
                            webhook.path,
                            $route.params.id,
                            editedNode.data.name,
                            displayWebhookType
                          )
                        )
                      "
                      color="primary"
                    >
                      {{
                        webhookURL(
                          webhook.path,
                          $route.params.id,
                          editedNode.data.name,
                          displayWebhookType
                        )
                      }}
                    </v-chip>
                  </div>
                </div>
                <span v-if="hasActiveCredentials" class="subtitle-2"
                  >Credentials</span
                >
                <div>
                  <v-combobox
                    :disabled="disableEdit"
                    ref="credentialSelection"
                    v-for="credential in internalEditedNode.data.node
                      .credentials"
                    :key="credential.name"
                    :label="getCredentialType(credential.name).displayName"
                    :items="getCredentialsByCredentialType(credential.name)"
                    v-model="credentials[credential.name]"
                    v-show="credentialShow[credential.name]"
                    item-text="name"
                    @change="onCredentialChange"
                  >
                    <template v-slot:item="{ item }">
                      {{ item.name }}
                      <v-spacer />
                      <v-list-item-action>
                        <v-list-item-icon class="mr-0" @click.stop>
                          <v-btn
                            icon
                            @click.stop.prevent="
                              activateCredentialsEditor(
                                credential.name,
                                item.id
                              )
                            "
                          >
                            <v-icon> mdi-pencil</v-icon>
                          </v-btn>
                        </v-list-item-icon>
                      </v-list-item-action>
                      <v-list-item-action>
                        <v-list-item-icon class="mr-0" @click.stop>
                          <v-btn
                            icon
                            @click.stop.prevent="
                              deleteCredential(credential.name, item.id)
                            "
                          >
                            <v-icon> mdi-delete</v-icon>
                          </v-btn>
                        </v-list-item-icon>
                      </v-list-item-action>
                    </template>
                    <template v-slot:append-item>
                      <v-divider class="mt-2"></v-divider>
                      <v-list-item
                        ripple
                        @click.stop.prevent="
                          activateCredentialsEditor(credential.name)
                        "
                      >
                        <v-list-item-action>
                          <v-icon color="primary"> mdi-plus</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>
                            Add New
                            {{ getCredentialType(credential.name).displayName }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            No Credentials Found
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-combobox>
                </div>
                <span
                  v-if="internalEditedNode.data.node.credentials"
                  class="subtitle-2"
                  >Parameters</span
                >
                <vue-form-json-schema
                  :key="jsonFormUpdateCount"
                  v-if="internalEditedNode"
                  v-model="data"
                  :schema="schema"
                  :ui-schema="uiSchema"
                  @state-change="onFormStateChanged"
                ></vue-form-json-schema>
              </div>
            </v-tab-item>
            <v-tab-item>
              <node-settings v-model="internalEditedNode.data.settings" />
            </v-tab-item>
            <v-tab-item
              v-if="
                editedNode.data.executionResult &&
                editedNode.data.executionResult.error
              "
              class="pa-5"
            >
              <v-alert type="error">
                {{ editedNode.data.executionResult.error.message }}
              </v-alert>

              <code>
                {{ editedNode.data.executionResult.error.stack }}
              </code>
            </v-tab-item>
            <v-tab-item
              class="full-height"
              v-if="
                editedNode.data.executionResult &&
                editedNode.data.executionResult.data
              "
            >
              <v-tabs
                background-color="primary"
                full-height
                centered
                v-model="resultTab"
              >
                <v-tab><v-icon>mdi-json</v-icon></v-tab>
                <v-tab v-if="hasBinaryData"><v-icon>mdi-file</v-icon></v-tab>

                <v-tab-item class="pa-5">
                  <v-select
                    background-color="white"
                    outlined
                    v-if="executions.length > 1"
                    v-model="currentExecutionIndex"
                    :items="executionItems"
                    label="Current Execution"
                  />
                  <v-alert type="warning" v-if="isLargeData && !showLargeData">
                    This node contains large amount of data. Displaying it may
                    cause problems
                    <div class="d-flex justify-content mt-5">
                      <v-btn outlined @click="showLargeData = true"
                        >Show Large Data
                      </v-btn>
                    </div>
                  </v-alert>
                  <paginated-result v-else :data="resultData" />
                </v-tab-item>
                <v-tab-item class="pa-5">
                  <v-select
                    background-color="white"
                    outlined
                    v-if="executions.length > 1"
                    v-model="currentExecutionIndex"
                    :items="executionItems"
                    label="Current Execution"
                  />
                  <binary-result :data="resultBinaryData" />
                </v-tab-item>
                <!-- </v-tabs-items> -->
              </v-tabs>
            </v-tab-item>
          </v-tabs-items>
        </div>
        <div class="flex-grow-0 pa-5 d-flex justify-center">
          <v-btn text color="error" @click="close">cancel</v-btn>
          <v-btn :disabled="disableEdit" @click="save">save</v-btn>
        </div>
      </v-col>
    </v-row>
  </v-navigation-drawer>
</template>

<script>
import ExpressionEditor from "./ExpressionEditor";
import BinaryResult from "./BinaryResult";
import NodeAvatar from "./NodeAvatar";
import { convert } from "../../helpers/propertyConverter";
import NodeNamer from "../../helpers/nodeNamer";
import { mapGetters, mapState, mapMutations, mapActions } from "vuex";
import CredentialsEditor from "../credentials/CredentialsEditor";
import Api from "../../api";
import { webhookURL } from "../../helpers/url";
import copy from "copy-to-clipboard";
import { deepCopy } from "../../helpers/object";
import PaginatedResult from "./PaginatedResult";
import formDialog from "../../mixins/formDialog";
import eventBus from "../../mixins/eventBus";
import { removePath } from "../../helpers/dotNotation";
import NodeSettings from "./NodeSettings";
const tmpl = require("riot-tmpl");
tmpl.brackets.set("{{ }}");
tmpl.tmpl.errorHandler = () => {};
export default {
  mixins: [formDialog, eventBus],
  props: ["value", "edited-node", "disable-edit"],
  components: {
    NodeAvatar,
    CredentialsEditor,
    // eslint-disable-next-line vue/no-unused-components
    ExpressionEditor,
    PaginatedResult,
    BinaryResult,
    NodeSettings,
  },
  data: () => ({
    jsonFormUpdateCount: 0,
    internalEditedNode: null,
    editorWidth: {
      base: 400,
      after: 900
    },
    credentials: {},
    data: {},
    schema: {},
    drawer: {
      status: null
    },
    uiState: {},
    uiSchema: [],
    tab: 0,
    resultTab: 0,
    isNameEdited: false,
    editedName: "",
    secondaryDrawer: false,
    credentialsEditor: false,
    editCredentialsId: null,
    editCredentialsType: null,
    showLargeData: false,
    dynamicNodeParameters: {},
    displayWebhookType: "PRODUCTION",
    currentExecutionIndex: 0,
    latestDynamicNodePropertiesCache: null,
  }),
  created(){
    this.drawer.status = this.getDrawer;
  },
  computed: {
    ...mapGetters("credentials", [
      "getCredentialType",
      "getCredentialsByCredentialType",
      "getCredential",
    ]),
    ...mapGetters("options", [
      "getDrawer",
    ]),
    ...mapState("editor", {
      expressionEditorParameter: (state) => state.expressionEditorParameter,
    }),
    isLargeData() {
      return (
        JSON.stringify(
          this.editedNode.data.executionResult?.data,
          null,
          2
        ).split("\n").length > 30
      );
    },
    webhooks() {
      let webhooks =
        this.editedNode.data.node.webhooks?.map((webhook) => {
          let data = {
            $parameter: this.data,
          };
          let path = tmpl.tmpl(
            webhook.path.startsWith("=") ? webhook.path.slice(1) : webhook.path,
            data
          );
          return {
            method: tmpl.tmpl(webhook.httpMethod.slice(1), data),
            path,
          };
        }) ?? null;
      // console.log(webhooks)
      return webhooks;
    },
    credentialShow() {
      let credentialShow = {};
      if (!this.editedNode.data.node.credentials) return credentialShow;
      for (const credential of this.editedNode.data.node.credentials) {
        if (this.shouldShowCredentials(credential.displayOptions)) {
          credentialShow[credential.name] = true;
        }
      }
      return credentialShow;
    },
    hasActiveCredentials() {
      return Object.entries(this.credentialShow).length > 0;
    },
    executionItems() {
      let length = this.editedNode.data.executions.length;
      let arr = [];
      for (let index = 0; index < length; index++) {
        arr.push({ text: `${index + 1} / ${length}`, value: index });
      }
      return arr;
    },
    executions() {
      return this.editedNode.data.executions;
    },
    resultData() {
      console.log("rerendered");
      let executions = this.editedNode.data.executions;
      if (executions.length === 0) {
        return null;
      }

      let lastExecution = executions[this.currentExecutionIndex];
      let data = lastExecution?.data?.data?.main;
      //find the output with data in it
      // for (const output of data) {
      //   if (output[0] && output[0].json) {
      //     return output[0].json
      //   }
      // }
      if (data && Array.isArray(data[0])) {
        return data[0] && data[0]?.map((item) => item.json);
      } else {
        return [];
      }
    },
    resultBinaryData() {
      let executions = this.editedNode.data.executions;
      if (executions.length === 0) {
        return null;
      }

      let lastExecution = executions[this.currentExecutionIndex];
      let data = lastExecution?.data?.data?.main;
      if (Array.isArray(data[0])) {
        return data[0]?.map((item) => item.binary).filter((i) => !!i);
      } else {
        return [];
      }
    },
    hasBinaryData() {
      if (this.resultBinaryData === null) return null;
      return Object.keys(this.resultBinaryData).length > 0;
    },
  },
  watch: {
    getDrawer(){
      this.drawer.status = this.getDrawer;
    },
    expressionEditorParameter(val) {
      if (val) {
        this.secondaryDrawer = true;
      }
    },
    data: {
      deep: true,
      handler(data) {
        this.updateDynamicFormData(data);
      },
    },
    editedNode(newData, oldData) {
      if (newData?.name !== oldData?.name) {
        this.onClose();
        this.onOpen();
      }
    },
    value(open) {
      console.log("open status: " + open);
      if (open) {
        this.jsonFormUpdateCount++;
        this.onOpen();
      } else {
        this.onClose();
      }
    },
  },
  mounted() {
    this.$subscribe("remove_path_from_data", (path) => {
      let _path = path.split("/").join(".");
      removePath(this.data, _path);
      this.jsonFormUpdateCount++;
    });

    this.$escHandler = (ev) => {
      if (ev.code === "Escape") {
        this.onESC();
      }
    };

    // const parrentAreaElement = document.getElementById('navigation-drawer');
    const exEditorElement = document.getElementById('ex-editor');
    const resizer = document.createElement('div');
    resizer.style.width = '20px';
    resizer.style.height = '100%';
    resizer.style.position = 'absolute';
    resizer.style.top = 0;
    resizer.style.left = 0;
    resizer.style.cursor = 'col-resize';
    resizer.style.zIndex = '11';

    const appElement = document.getElementById("click-block");

    exEditorElement.appendChild(resizer);

    resizer.addEventListener('mousedown', initResize, false);

    function initResize() {
      window.addEventListener('mousemove', Resize, false);
      window.addEventListener('mouseup', stopResize, false);
    }
    
    let editorWidth = this.editorWidth;
    let drawer = this.drawer;

    function Resize(e) {
      appElement.style.display = "block";
        
        const widthValue = (document.documentElement.clientWidth) - e.clientX;

        const drawerControl = drawer.status ? document.documentElement.clientWidth - 290 : document.documentElement.clientWidth; 

        if (widthValue > 900 && widthValue < drawerControl ) {
              editorWidth.after = widthValue;
        }
    }

    function stopResize() {
      appElement.style.display = "none";
        window.removeEventListener('mousemove', Resize, false);
        window.removeEventListener('mouseup', stopResize, false);
    }
  },
  methods: {
    copy,
    webhookURL,
    fullScreenEditor(){
      const fullScreenValue = this.drawer.status ? document.documentElement.clientWidth - 290 : document.documentElement.clientWidth - 40;
      this.editorWidth.after = fullScreenValue;
    },
    listenESC() {
      console.log("esc handler mounted");
      window.addEventListener("keydown", this.$escHandler);
    },
    removeESC() {
      console.log("esc handler removed");
      window.removeEventListener("keydown", this.$escHandler);
    },
    onESC() {
      console.log("esc clicked");
      if (this.secondaryDrawer) {
        if (this.credentialsEditor) {
          this.deactivateCredentialsEditor();
        }
        if (this.expressionEditorParameter) {
          this.deactivateExpressionEditor();
        }
      } else {
        this.close();
      }
    },
    async deleteCredential(credentialType, id) {
      let result = await this.$confirm({
        title: "Remove Credentials",
        text: "Are you sure you want to remove the credentials ? ",
      });

      if (result.status) {
        await Api.deleteCredentials(id);
        if (this.credentials[credentialType]?.id === id) {
          this.credentials[credentialType] = null;
        }
        await this.loadCredentials();
      }
    },
    shouldShowCredentials(options) {
      if (!options) return true;
      for (const [key, values] of Object.entries(options.show)) {
        if (values.includes(this.data[key])) {
          return true;
        }
      }
      return false;
    },
    ...mapMutations("editor", ["updateExpressionEditorParameter"]),
    ...mapMutations("dynamicForm", ["updateDynamicFormData"]),
    ...mapActions("credentials", ["loadCredentials"]),
    onOpen() {
      this.listenESC();

      this.tab = 0;
      this.internalEditedNode = JSON.parse(JSON.stringify(this.editedNode));
      if (this.internalEditedNode.data.credentials) {
        let credentials = {};
        for (const [type, value] of Object.entries(
          this.internalEditedNode.data.credentials
        )) {
          credentials[type] = this.getCredential(value);
        }
        this.credentials = credentials;
      } else {
        this.credentials = {};
      }
      console.log(this.editedNode.data.node.name);
      let {
        schema,
        uiSchema,
        defaultData,
        dynamicNodeParameters,
        dynamicNodeProperties,
      } = convert(this.internalEditedNode.data.node.properties, {
        disabled: this.disableEdit,
        isWebhookNode:
          this.editedNode.data.node.name === "n8n-nodes-base.webhook",
        codeEditor: [
          "n8n-nodes-base.function",
          "n8n-nodes-base.functionItem",
        ].includes(this.editedNode.data.node.name),
      });

      if (Object.keys(this.internalEditedNode.data?.data ?? {}).length > 0) {
        this.data = this.internalEditedNode.data.data;
      } else {
        this.data = defaultData;
      }

      this.schema = schema;
      this.uiSchema = uiSchema;
      this.dynamicNodeParameters = dynamicNodeParameters;
      this.dynamicNodeProperties = dynamicNodeProperties;
    },
    onClose() {
      console.log("close");
      this.uiState = null;
      this.deactivateCredentialsEditor();
      this.deactivateExpressionEditor();
      this.cleanUp();
      this.removeESC();
    },
    onCredentialChange(data) {
      if (data === null) return;
      this.loadDynamicNodeParameters(Object.keys(this.dynamicNodeParameters));
    },
    onFormStateChanged(state) {
      if (state.vfjsFieldsActiveModels.length == 0) {
        return;
      }

      if (this.dynamicNodeProperties.length > 0) {
        let currentCache = this.dynamicNodeProperties
          .map((i) => JSON.stringify(this.data[i]))
          .join(":");
        console.log(this.latestDynamicNodePropertiesCache);
        if (
          this.latestDynamicNodePropertiesCache == null ||
          currentCache != this.latestDynamicNodePropertiesCache
        ) {
          this.loadDynamicNodeProperties();
          this.latestDynamicNodePropertiesCache = currentCache;
        }
      }

      let fieldsWithLoadingMethod = Object.keys(this.dynamicNodeParameters);

      let newAppearingProperties = fieldsWithLoadingMethod.filter(
        (propertyName) => {
          return (
            state.vfjsFieldsActiveModels.includes(propertyName) &&
            !this.uiState?.vfjsFieldsActiveModels?.includes(propertyName)
          );
        }
      );
      if (newAppearingProperties.length > 0) {
        this.loadDynamicNodeParameters(newAppearingProperties);
      }

      this.uiState = state;
    },
    async loadDynamicNodeProperties() {
      const credentials = {};
      Object.entries(this.credentials).forEach(([key, credential]) => {
        credentials[key] = credential.name;
      });

      let properties = await Api.getNodeDynamicProperties({
        nodeType: this.editedNode.data.node.name,
        credentials,
        currentNodeParameters: this.data,
      });

      if (properties === undefined || properties === null) return;

      let { schema, uiSchema } = convert(properties, {
        disabled: this.disableEdit,
        isWebhookNode:
          this.editedNode.data.node.name === "n8n-nodes-base.webhook",
        codeEditor: [
          "n8n-nodes-base.function",
          "n8n-nodes-base.functionItem",
        ].includes(this.editedNode.data.node.name),
      });

      if (JSON.stringify(this.schema) === JSON.stringify(schema)) {
        return;
      }
      console.log("update");
      this.schema = schema;
      this.uiSchema = uiSchema;
      this.jsonFormUpdateCount++;
      this.loadDynamicNodeParameters(Object.keys(this.dynamicNodeParameters));
    },
    async loadDynamicNodeParameters(propertyNames) {
      let credentials = {};
      Object.entries(this.credentials).forEach(([key, credential]) => {
        credentials[key] = credential.name;
      });
      let promises = [];
      for (const name of propertyNames) {
        let promise = Api.getNodeParameterOptions({
          nodeType: this.editedNode.data.node.name,
          methodName: this.dynamicNodeParameters[name],
          credentials,
          currentNodeParameters: this.data,
        }).then((data) => {
          let item = this.uiSchema.find((component) => component.model == name);
          item.fieldOptions.props.items = data.map((item) => ({
            text: item.name,
            value: item.value,
          }));
        });
        promises.push(promise);
      }
      await Promise.all(promises).then(() => {
        this.jsonFormUpdateCount++;
      });
    },
    deactivateExpressionEditor() {
      this.secondaryDrawer = false;
      this.updateExpressionEditorParameter({ path: null });
    },
    cleanUp() {
      Object.assign(this, {
        jsonFormUpdateCount: 0,
        internalEditedNode: null,
        credentials: {},
        data: {},
        schema: {},
        uiState: {},
        uiSchema: [],
        tab: 0,
        resultTab: 0,
        isNameEdited: false,
        editedName: "",
        secondaryDrawer: false,
        credentialsEditor: false,
        editCredentialsId: null,
        editCredentialsType: null,
        showLargeData: false,
        dynamicNodeParameters: {},
        displayWebhookType: "PRODUCTION",
        currentExecutionIndex: 0,
        latestDynamicNodePropertiesCache: null,
      });
    },
    activateCredentialsEditor(credentialsType, id = null) {
      this.$refs.credentialSelection[0].blur();
      this.secondaryDrawer = true;
      this.credentialsEditor = true;
      this.editCredentialsType = credentialsType;
      this.editCredentialsId = id;
    },
    deactivateCredentialsEditor() {
      this.secondaryDrawer = false;
      this.credentialsEditor = false;
    },
    onCredentialsSaved({ type, name }) {
      this.deactivateCredentialsEditor();
      this.credentials[type] = this.getCredential(name);
      this.loadDynamicNodeParameters(Object.keys(this.dynamicNodeParameters));
    },
    save() {
      if (!this.checkForm()) {
        return;
      }
      let credentials = {};
      Object.entries(this.credentials).forEach(([key, credential]) => {
        credentials[key] = credential.name;
      });
      console.log(this.data);
      this.$emit(
        "save",
        deepCopy({
          data: this.data,
          id: this.internalEditedNode.id,
          credentials: credentials,
          settings: this.internalEditedNode.data.settings,
        })
      );
      this.$emit("input", false);
    },
    checkForm() {
      return true;
    },
    close() {
      this.$emit("input", false);
    },
    openEditName() {
      this.editedName = this.internalEditedNode.data.name;
      this.isNameEdited = true;
    },
    closeEditName() {
      this.isNameEdited = false;
    },
    saveName() {
      this.isNameEdited = false;
      if (this.editedName == this.internalEditedNode.data.name) {
        return;
      }
      const name = NodeNamer.getName(this.editedName);
      this.internalEditedNode.data.name = name;
      this.$emit("nameUpdated", { name, id: this.internalEditedNode.id });
    },
  },
};
</script>

<style scoped>
.v-navigation-drawer {
  overflow: unset;
}

.primary-drawer {
  z-index: 5;
}

.secondary-drawer {
  overflow: unset;
  z-index: 10;
  height: 97vh;
  background: white;
  border-left: 1px solid grey;
}
</style>
