export const OPEN_DIALOG = 'OPEN_EDIT_DIALOG';
export const CLOSE_DIALOG = 'CLOSE_EDIT_DIALOG';
export const APPROVE_DIALOG = 'APPROVE_DIALOG'; 

class DeferredPromise{
    constructor(){
        this.promise = new Promise((res , rej)=>{
            this.resolve =  res;
            this.reject = rej;
        });
    }
}



function mutator(name){
    return function(){
        arguments[0][name] =  arguments[1];
    }
}

const state = {
    
    dialog: false,
    message: '',
    errorMessage: '',
    warningMessage: '',
    isSuccess: false,
    isSaving: false,
    deferred: null,
    title:'',
    okButtonTitle: 'yes',
    cancelButtonTitle: 'cancel',
    showCancelButton: true
}

const getters = {

}

const actions = {
    
    [OPEN_DIALOG]: ({commit , state},{message,title,okButtonTitle,cancelButtonTitle,showCancelButton,warningMessage}) => {
        commit('setMessage',message);
        commit('setTitle', title);
        commit('setDialog' , true);
        commit('setOkButtonTitle',okButtonTitle);
        commit('setCancelButtonTitle',cancelButtonTitle);
        commit('setWarningMessage',warningMessage);
        commit('setShowCancelButton',showCancelButton != null ? showCancelButton : true);
        commit('setDeferred',new DeferredPromise()); 
        return state.deferred.promise;
    },

    [CLOSE_DIALOG]: ({commit , state}) => {
        commit('setDialog',false)
        state.deferred.resolve(false);
    },
    [APPROVE_DIALOG]: ({commit}) => {
        commit('setDialog',false)
        state.deferred.resolve(true);
    }
}

const mutations = {
    setDialog: mutator('dialog'),
    setMessage: mutator('message'),
    setDeferred: mutator('deferred'),
    setTitle: mutator('title'),
    setOkButtonTitle: mutator('okButtonTitle'),
    setCancelButtonTitle: mutator('cancelButtonTitle'),
    setShowCancelButton: mutator('showCancelButton'),
    setWarningMessage: mutator('warningMessage')
}

export default {
    namespaced:true,
    state,
    getters,
    mutations,
    actions
}